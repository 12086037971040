enum ProvincesEnum {
  ON = 'ON',
  QC = 'QC',
  AB = 'AB',
  BC = 'BC',
  PEI = 'PEI', 
  MB = 'MB',
  NB = 'NB',
  NS = 'NS',
  NU = 'NU',
  SK = 'SK',
  TNL = 'TNL',
  TNO = 'TNO',
  YU = 'YU',
}

export default ProvincesEnum;
