import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import AccessOrdersDto from '../../dto/access-orders/out/access-orders.dto';

class GetAccessOrderHasVipParkingLabelService {
  get({ vipParkingQuantity }: AccessOrderDto | AccessOrdersDto): string {
    if (vipParkingQuantity === undefined || vipParkingQuantity === null) {
      return '';
    }

    return vipParkingQuantity > 0 ? 'Oui' : 'Non';
  }
}

export default GetAccessOrderHasVipParkingLabelService;
