import {
  useState,
  FC,
  useCallback,
  MouseEvent,
  MouseEventHandler,
  useMemo,
} from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FiBookOpen, FiUser } from 'react-icons/fi';

import Footer from './Footer';
import Logo from './Logo';
import useFindActiveEdition from '../../hooks/editions/find-active-edition.hook';
import { authState } from '../../http/auth.state';
import { PATHS } from '../../utils/paths';
import { roleIsAdmin } from '../../utils/roles';
import { toast } from '../../utils/toast';
import SignOutService from '../../services/auth/sign-out.service';

const DOCUMENTS_LINKS = [
  {
    label: 'Échéancier des priorités',
    url: PATHS.documents.timetable.path,
  },
  {
    label: 'Plan du Salon',
    url: PATHS.documents.plan.path,
  },
  {
    label: "Guide de l'exposant",
    url: PATHS.documents.guide.path,
  },
  {
    label: 'FAQ',
    url: PATHS.documents.faq.path,
  },
  {
    label: 'Liste de prix Visibilité',
    url: PATHS.documents.visibilityRates.path,
  },
  {
    label: 'Bons de commande – TSE',
    url: PATHS.documents.purchaseOrderTSE.path,
  },
];

const ADMIN_LINKS = [
  { label: 'Inscriptions', url: PATHS.admin.profiles.path },
  { label: 'Fiches exposant', url: PATHS.admin.detailedSheets.path },
  { label: 'Montage et démontage', url: PATHS.admin.assemblies.path },
  { label: "Commandes d'accès", url: PATHS.admin.accessOrders.path },
  { label: 'Emplacements', url: PATHS.admin.locations.path },
  { label: 'Édition', url: PATHS.admin.activationCodes.path },
];

const EXHIBITORS_LINKS = [
  { label: 'Accueil', url: PATHS.exhibitors.path },
  { label: 'Inscription', url: PATHS.exhibitors.profile.path },
  { label: 'Fiche exposant', url: PATHS.exhibitors.detailedSheet.path },
  { label: 'Montage et démontage', url: PATHS.exhibitors.assembly.path },
  { label: "Commandes d'accès", url: PATHS.exhibitors.accessOrder.path },
];

const MENU_BUTTON_STYLE = {
  my: 2,
  ml: 4,
  display: {
    xs: 'none',
    md: 'inline-flex',
  },
};

const MENU_TEXT_STYLE = {
  ml: 1,
  pb: '2px',
  fontSize: '1rem',
};

const Layout: FC = () => {
  const { pathname } = useLocation();
  const context = useOutletContext();
  const navigate = useNavigate();
  const isAdmin = roleIsAdmin();

  const { data: edition } = useFindActiveEdition({
    enabled: !isAdmin,
  });

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [anchorUser, setAnchorUser] = useState<null | HTMLElement>(null);

  const handleSignOut = useCallback(async () => {
    try {
      await new SignOutService().signOut();

      window.location.href = PATHS.auth.signIn.path;

      authState.clear();
    } catch (error) {
      toast.error();
    }
  }, []);

  const handleNavigate = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, url: string) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(url);

        setAnchorMenu(null);
      }
    },
    [navigate],
  );

  const handleNavigateHome: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(PATHS.admin.profiles.path);
      }
    },
    [navigate],
  );

  const showMenu = useMemo(() => {
    return (
      isAdmin ||
      (!edition?.closed && !pathname.includes('users/activation-codes'))
    );
  }, [isAdmin, edition, pathname]);

  const exhibitorsLinks = useMemo(() => {
    const links = [];

    for (const exhibitorsLink of EXHIBITORS_LINKS) {
      switch (exhibitorsLink.label) {
        case 'Fiche exposant': {
          if (edition?.detailedSheetsOpened) {
            links.push(exhibitorsLink);
          }
          break;
        }
        case 'Montage et démontage': {
          if (edition?.assembliesOpened) {
            links.push(exhibitorsLink);
          }
          break;
        }
        case "Commandes d'accès": {
          if (edition?.accessOrdersOpened) {
            links.push(exhibitorsLink);
          }
          break;
        }
        default: {
          links.push(exhibitorsLink);
          break;
        }
      }
    }

    return links;
  }, [edition]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        p: 0,
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)',
        }}
      >
        <Container maxWidth={false} disableGutters>
          <Toolbar>
            <Box
              sx={{
                paddingRight: '2rem',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Link onClick={handleNavigateHome} sx={{ cursor: 'pointer' }}>
                <Box component="span" sx={{ maxWidth: '180px' }}>
                  <Logo />
                </Box>
              </Link>

              <Typography
                variant="body2"
                sx={{ fontSize: '0.8rem', mb: '12px' }}
              >
                Expo habitat Québec - Portail exposant
              </Typography>
            </Box>

            <Box
              sx={{
                flexGrow: { xs: 0, md: 1 },
                display: { xs: 'flex' },
                justifyContent: { md: 'flex-end' },
              }}
            >
              {showMenu && (
                <>
                  {isAdmin &&
                    ADMIN_LINKS.map(({ label, url }, index) => (
                      <Button
                        key={index}
                        href={url}
                        onClick={(event): void => handleNavigate(event, url)}
                        sx={MENU_BUTTON_STYLE}
                      >
                        <Typography variant="body2" sx={MENU_TEXT_STYLE}>
                          {label}
                        </Typography>
                      </Button>
                    ))}

                  {!isAdmin &&
                    exhibitorsLinks.map(({ label, url }, index) => (
                      <Button
                        key={index}
                        href={url}
                        onClick={(event): void => handleNavigate(event, url)}
                        sx={MENU_BUTTON_STYLE}
                      >
                        <Typography variant="body2" sx={MENU_TEXT_STYLE}>
                          {label}
                        </Typography>
                      </Button>
                    ))}

                  <Box>
                    <IconButton
                      size="large"
                      aria-haspopup="true"
                      onClick={(event): void =>
                        setAnchorMenu(event.currentTarget)
                      }
                      color="inherit"
                      sx={{ display: { md: 'none' } }}
                    >
                      <MenuIcon />
                    </IconButton>

                    <Button
                      onClick={(event): void =>
                        setAnchorMenu(event.currentTarget)
                      }
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        ...MENU_BUTTON_STYLE,
                        mr: { md: 2 },
                      }}
                    >
                      <FiBookOpen />
                      <Typography variant="body2" sx={MENU_TEXT_STYLE}>
                        Documents
                      </Typography>
                    </Button>
                  </Box>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={!!anchorMenu}
                    onClose={(): void => setAnchorMenu(null)}
                    sx={{
                      display: { xs: 'block' },
                    }}
                  >
                    {isAdmin && [
                      ADMIN_LINKS.map(({ label, url }, index) => (
                        <MenuItem
                          key={index}
                          href={url}
                          onClick={(event): void => handleNavigate(event, url)}
                          sx={{
                            display: { xs: 'block', md: 'none' },
                          }}
                        >
                          <Typography>{label}</Typography>
                        </MenuItem>
                      )),
                    ]}
                    {!isAdmin && [
                      exhibitorsLinks.map(({ label, url }, index) => (
                        <MenuItem
                          key={index}
                          href={url}
                          onClick={(event): void => handleNavigate(event, url)}
                          sx={{
                            display: { xs: 'block', md: 'none' },
                          }}
                        >
                          <Typography>{label}</Typography>
                        </MenuItem>
                      )),
                    ]}
                    <Divider
                      key="-1"
                      sx={{
                        display: { xs: 'block', md: 'none' },
                      }}
                    />
                    {DOCUMENTS_LINKS.map((document, index) => (
                      <MenuItem
                        key={index}
                        component={Link}
                        onClick={(): void => setAnchorMenu(null)}
                        href={document.url}
                        target="_blank"
                      >
                        <Typography>{document.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Box>

            {/* Logo Desktop */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: { xs: 'center', md: 'flex-start' },
                paddingRight: '2rem',
                paddingLeft: '2rem',
              }}
            >
              <Link
                onClick={handleNavigateHome}
                sx={{ flex: 1, cursor: 'pointer' }}
              >
                <Logo />
              </Link>
            </Box>

            {/* Menu profil */}

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Compte utilisateur">
                <IconButton
                  color="primary"
                  size="large"
                  onClick={(event): void => setAnchorUser(event.currentTarget)}
                  sx={{ p: 2 }}
                >
                  <FiUser />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorUser}
                onClose={(): void => setAnchorUser(null)}
              >
                <MenuItem onClick={handleSignOut}>
                  <Typography textAlign="center">Déconnexion</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="main" sx={{ flex: '1', mt: { xs: 6, md: 10, lg: 12 } }}>
        <Container maxWidth={isAdmin ? 'lg' : 'md'}>
          <Outlet context={context} />
        </Container>
      </Box>

      <Footer />
    </Container>
  );
};

export default Layout;
