import { Components, Theme } from '@mui/material';

const alert = (theme: Theme): Components<Theme> => {
  return {
    MuiAlert: {
      defaultProps: {},

      styleOverrides: {
        root: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),

          [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        },
        icon: {
          fontSize: '30px',
          marginTop: '-4px',
        },
      },
    },

    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(2),
        },
      },
    },
  };
};

export default alert;
