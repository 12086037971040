import Grid from '@mui/material/Grid';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import Select from '../forms/Select';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DatePeriodsEnum from '../../enums/date-periods.enum';
import OptionInterface from '../../interfaces/option.interface';
import AssemblyFieldValues from '../../interfaces/assemblies/assembly-field-values.interface';
import { validateRequired } from '../../utils/validations';
import {
  ASSEMBLY_DELIVERY_DATES,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
} from '../../values';

const SECTION_CODE_KITCHEN = 'SCU';

interface Props {
  profile: ProfileDto;
}

const AssemblyDeliveryPreferenceForm: FC<Props> = ({ profile }) => {
  const { control } = useFormContext<AssemblyFieldValues>();

  const deliveryDateOptions = useMemo(() => {
    const options: OptionInterface[] = [{ label: 'Sélectionner', value: '' }];

    if (profile.section?.code === SECTION_CODE_KITCHEN) {
      options.push(ASSEMBLY_DELIVERY_DATE_KITCHEN);
    }

    return [...options, ...ASSEMBLY_DELIVERY_DATES];
  }, [profile]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <Select
              control={control}
              name="deliveryDatePreference"
              label="Journée et période désirées"
              options={deliveryDateOptions}
              rules={validateRequired()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <RadioButtons
            control={control}
            name="deliveryDatePeriodPreference"
            options={[
              { label: 'En matinée', value: DatePeriodsEnum.AM },
              { label: 'En après-midi', value: DatePeriodsEnum.PM },
            ]}
            rules={validateRequired()}
            row
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AssemblyDeliveryPreferenceForm;
