import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { FC } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const Link: FC<MuiLinkProps & RouterLinkProps> = (props) => {
  return (
    <MuiLink component={RouterLink} {...props}>
      Revenir à l&apos;accueil
    </MuiLink>
  );
};

export default Link;
