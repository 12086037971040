import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import AssemblyFieldValues from '../../interfaces/assemblies/assembly-field-values.interface';
import {
  validateEmail,
  validatePhoneNumberFormat,
  validateRequired,
} from '../../utils/validations';

const AdminAssemblyOnSiteRepresentativeForm: FC = () => {
  const { control } = useFormContext<AssemblyFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            fullWidth
            label="Prénom"
            name="firstName"
            rules={validateRequired()}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            fullWidth
            label="Nom"
            name="lastName"
            rules={validateRequired()}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            fullWidth
            label="Adresse courriel"
            name="email"
            rules={validateEmail()}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            control={control}
            label="Cellulaire"
            name="phoneNumber"
            rules={validatePhoneNumberFormat()}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAssemblyOnSiteRepresentativeForm;
