import Chip, { ChipProps } from '@mui/material/Chip';
import { FC, useMemo } from 'react';

import NotificationRecipientsDto from '../../dto/notifications/out/notification-recipients.dto';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';
import AssemblyStatusesEnum from '../../enums/assemblies/assembly-statuses.enum';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';

interface Props {
  notificationRecipient: NotificationRecipientsDto;
}

const NotificationRecipientsTableStatusChip: FC<Props> = ({
  notificationRecipient,
}) => {
  const { status } = notificationRecipient;

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      default:
      case ProfileStatusesEnum.CANCELED:
      case DetailedSheetStatusesEnum.CANCELED:
      case AssemblyStatusesEnum.CANCELED:
      case AccessOrderStatusesEnum.CANCELED: {
        return { color: 'default' };
      }
      case ProfileStatusesEnum.INCOMPLETE:
      case DetailedSheetStatusesEnum.INCOMPLETE:
      case AssemblyStatusesEnum.INCOMPLETE:
      case AccessOrderStatusesEnum.INCOMPLETE: {
        return { color: 'error' };
      }
      case ProfileStatusesEnum.PROCESSING:
      case DetailedSheetStatusesEnum.PROCESSING:
      case AssemblyStatusesEnum.PROCESSING:
      case AccessOrderStatusesEnum.PROCESSING: {
        return { color: 'warning' };
      }
      case ProfileStatusesEnum.BOOKED:
      case DetailedSheetStatusesEnum.APPROVED:
      case AssemblyStatusesEnum.CONFIRMED:
      case AccessOrderStatusesEnum.CONFIRMED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  return (
    <Chip
      {...props}
      sx={{
        height: '16px',
        width: '16px',
      }}
    />
  );
};

export default NotificationRecipientsTableStatusChip;
