import { Components, Theme } from '@mui/material';

import { getFontSizesForBreakpoints, getRmFromPx } from '../../utils/fonts';

const button = (theme: Theme): Components<Theme> => {

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: getRmFromPx(16),
          ...getFontSizesForBreakpoints({ sm: 16, md: 17, lg: 18 }),

          textTransform: 'initial',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          textTransform: 'initial',
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          color: 'white',
          boxShadow: theme.customShadows?.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows?.secondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
};

export default button;
