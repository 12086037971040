import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import BookingDto from '../../dto/bookings/out/booking.dto';
import FindBookingByUserIdService from '../../services/bookings/find-booking-by-user-id.service';

const useFindBookingByUserId = (
  id: string,
  options?: Omit<
    UseQueryOptions<BookingDto | undefined, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<BookingDto | undefined, Error> => {
  return useQuery(
    ['users/booking', id],
    () => new FindBookingByUserIdService().find(id),
    options,
  );
};

export default useFindBookingByUserId;
