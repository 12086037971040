import Typography, { TypographyProps } from '@mui/material/Typography';
import { FC } from 'react';

const ReadonlyFieldLabel: FC<PropsWithChildren & TypographyProps> = ({
  children,
  sx = {},
}) => {
  return (
    <Typography
      variant="body2"
      sx={{ mb: 1, mt: 3, color: 'grey.600', fontSize: '1rem', ...sx }}
    >
      {children}
    </Typography>
  );
};

export default ReadonlyFieldLabel;
