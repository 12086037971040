import SignInDto from '../../dto/auth/sign-in.dto';
import JwtTokenResponseDto from '../../dto/jwt/jwt-token-response.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SignInService {
  async signIn(dto: SignInDto): Promise<JwtTokenResponseDto> {
    try {
      const { data } = await apiV1.post<JwtTokenResponseDto>(
        '/auth/sign-in',
        dto,
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SignInService;
