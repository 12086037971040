import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AssembliesDto from '../../dto/assemblies/out/assemblies.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  assembly: AssembliesDto;
}

const AssembliesDataTableAnchor: FC<Props> = ({ assembly }) => {
  const navigate = useNavigate();

  const companyName = useMemo(() => {
    const { profile } = assembly;

    if (profile.commonCompanyName) return profile.commonCompanyName;
    if (profile.legalCompanyName) return profile.legalCompanyName;

    return null;
  }, [assembly]);

  const href = useMemo(() => {
    return PATHS.admin.assemblies.edit.path(assembly.profile.id ?? '');
  }, [assembly]);

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate],
  );

  return (
    <Link href={href} onClick={handleClick}>
      {companyName}
    </Link>
  );
};

export default AssembliesDataTableAnchor;
