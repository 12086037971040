import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Select from '../forms/Select';
import OptionInterface from '../../interfaces/option.interface';
import AssemblyFieldValues from '../../interfaces/assemblies/assembly-field-values.interface';
import { validateRequired } from '../../utils/validations';
import { DISASSEMBLY_DELIVERY_DATES } from '../../values';

const DELIVERY_DATE_OPTIONS: OptionInterface[] = [
  { label: 'Sélectionner', value: '' },
  ...DISASSEMBLY_DELIVERY_DATES,
];

const AdminAssemblyDisassemblyForm: FC = () => {
  const { control } = useFormContext<AssemblyFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12} md={6}>
          <Select
            control={control}
            name="disassemblyDate"
            options={DELIVERY_DATE_OPTIONS}
            rules={validateRequired()}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAssemblyDisassemblyForm;
