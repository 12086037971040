import SortDirectionEnum from '../../../enums/data-tables/sorting/sort-directions.enum';

class GetSortDirectionService {
  static get(
    sortDirection: SortDirectionEnum,
    active: boolean,
  ): SortDirectionEnum {
    return active ? sortDirection : SortDirectionEnum.ASC;
  }
}

export default GetSortDirectionService;
