import SaveActivationCodeDto from '../../dto/activation-codes/in/save-activation-code.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveActivationCode {
  async save(id: string, dto: SaveActivationCodeDto): Promise<void> {
    try {
      await apiV1.put<void>(`/activation-codes/${id}`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveActivationCode;
