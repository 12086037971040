import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { FC, useCallback, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

const DataTableBodyRowAction: FC<PropsWithChildren> = ({ children }) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  const handleCloseMenu = useCallback(() => setAnchorMenu(null), []);

  return (
    <>
      <IconButton onClick={(event): void => setAnchorMenu(event.currentTarget)}>
        <FiMoreVertical />
      </IconButton>

      <Menu
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        {children}
      </Menu>
    </>
  );
};

export default DataTableBodyRowAction;
