import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import AccessOrdersDataTableOptionsDto from '../../dto/access-orders/in/access-orders-data-table-options.dto';
import AccessOrdersDto from '../../dto/access-orders/out/access-orders.dto';
import FindAccessOrdersService from '../../services/access-orders/find-access-orders.service';

const useFindAccessOrders = (
  dto: AccessOrdersDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<AccessOrdersDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<AccessOrdersDto>, Error> => {
  return useQuery(
    ['access-orders', dto],
    () => new FindAccessOrdersService().find(dto),
    options,
  );
};

export default useFindAccessOrders;
