import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import FindDetailedSheetByUserIdService from '../../services/detailed-sheets/find-detailed-sheet-by-user-id.service';

const useFindDetailedSheetByUserId = (
  id: string,
  options?: Omit<
    UseQueryOptions<DetailedSheetDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<DetailedSheetDto, Error> => {
  return useQuery(
    ['users/detailed-sheet', id],
    () => new FindDetailedSheetByUserIdService().find(id),
    options,
  );
};

export default useFindDetailedSheetByUserId;
