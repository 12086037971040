import Chip from '@mui/material/Chip';
import { FC } from 'react';

import CategoryDto from '../../dto/categories/category.dto';

interface Props {
  category: CategoryDto;
}

const CategoryCheckboxLabel: FC<Props> = ({ category }) => {
  const { name, closed } = category;

  if (!closed) return <>{name}</>;

  return (
    <>
      {name}{' '}
      <Chip
        label=" Complet"
        color="error"
        size="small"
        sx={{
          ml: 1,
          mt: '-2px',
        }}
      />
    </>
  );
};

export default CategoryCheckboxLabel;
