import ProvincesEnum from '../../../enums/provinces.enum';
import SaveManagerDto from '../../managers/in/save-manager.dto';
import SaveProfileCategoriesDto from '../../profile-categories/in/save-profile-categories.dto';

class UpdateProfileDto {
  email!: string;
  legalCompanyName!: string;
  commonCompanyName?: string;
  address!: string;
  city!: string;
  province!: ProvincesEnum;
  postalCode!: string;
  apchqMemberNumber?: string;
  rbqMemberNumber?: string;
  managers!: SaveManagerDto[];
  sectionId!: string;
  profileCategories!: SaveProfileCategoriesDto[];
}

export default UpdateProfileDto;
