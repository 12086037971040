import { FC } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import UserStepsEnum from '../../../../enums/users/user-steps.enum';
import useFindBookingPreference from '../../../../hooks/booking-preferences/find-user-booking-preference.hook';
import { PATHS } from '../../../../utils/paths';

const { ACTIVATION_CODE_STEP, COMPLETED_STEP, PROFILE_STEP } = UserStepsEnum;

const SaveBookingPreferenceWorkflow: FC = () => {
  const { data, status } = useFindBookingPreference();
  const currentStep = useOutletContext<UserStepsEnum>();

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;

  switch (currentStep) {
    case ACTIVATION_CODE_STEP: {
      return <Navigate to={PATHS.exhibitors.activationCode.path} replace />;
    }
    case PROFILE_STEP: {
      return <Navigate to={PATHS.exhibitors.profiles.create.path} replace />;
    }
    case COMPLETED_STEP: {
      return <Navigate to={PATHS.exhibitors.path} replace />;
    }
    default: {
      return <Outlet context={data} />;
    }
  }
};

export default SaveBookingPreferenceWorkflow;
