import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetsDataTableOptionsDto from '../../dto/detailed-sheets/in/detailed-sheets-data-table-options.dto';
import DetailedSheetsDto from '../../dto/detailed-sheets/out/detailed-sheets.dto';
import FindDetailedSheetsService from '../../services/detailed-sheets/find-detailed-sheets.service';

const useFindDetailedSheets = (
  dto: DetailedSheetsDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<DetailedSheetsDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<DetailedSheetsDto>, Error> => {
  return useQuery(
    ['detailed-sheets', dto],
    () => new FindDetailedSheetsService().find(dto),
    options,
  );
};

export default useFindDetailedSheets;
