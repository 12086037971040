import { Components, Theme } from '@mui/material';

const formControlLabel = (theme: Theme): Components<Theme> => {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.CheckboxLabelWrapper": {
            [theme.breakpoints.down('md')]: {
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1), 
            }, 
          },
          
        },
        label: {
          lineHeight: '1.25rem'
        }
      }
    },
  };
};

export default formControlLabel;
