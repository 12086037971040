import { useMutation, UseMutationResult } from 'react-query';

import SaveBookingDto from '../../dto/bookings/save-booking.dto';
import SaveBookingService from '../../services/bookings/save-booking.service';

const useSaveBooking = (): UseMutationResult<void, Error, SaveBookingDto> => {
  return useMutation(async (dto: SaveBookingDto) =>
    new SaveBookingService().save(dto),
  );
};

export default useSaveBooking;
