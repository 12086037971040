import OptionInterface from './interfaces/option.interface';

export const ASSEMBLY_DELIVERY_DATE_KITCHEN: OptionInterface = {
  label: 'Vendredi 14 février 2025',
  value: '2025-02-14',
};

export const ASSEMBLY_DELIVERY_DATES: OptionInterface[] = [
  { label: 'Samedi 15 février 2025', value: '2025-02-15' },
  { label: 'Dimanche 16 février 2025', value: '2025-02-16' },
  { label: 'Lundi 17 février 2025', value: '2025-02-17' },
  { label: 'Mardi 18 février 2025', value: '2025-02-18' },
  { label: 'Mercredi 19 février 2025', value: '2025-02-19' },
];

export const DISASSEMBLY_DELIVERY_DATES: OptionInterface[] = [
  { label: 'Dimanche 23 février 2025 de 17h à 23h', value: '2025-02-23' },
  { label: 'Lundi 24 février 2025 de 7h à 17h', value: '2025-02-24' },
];
