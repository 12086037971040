import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Select from '../../../forms/Select';
import TextField from '../../../forms/TextField';
import Section from '../../../layouts/Section';
import ContractDownload from '../../../profiles/ContractDownload';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import SaveProfileContractDto from '../../../../dto/profiles/in/save-profile-contract.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import ContractStatusesEnum from '../../../../enums/profiles/contract-statuses.enum';
import useFindProfile from '../../../../hooks/profiles/find-profile.hook';
import useSaveProfileContract from '../../../../hooks/profiles/save-profile-contract.hook';
import OptionInterface from '../../../../interfaces/option.interface';
import ProfileContractFieldValuesInterface from '../../../../interfaces/profiles/profile-contract-field-values.interface';
import GetContractStatusLabelService from '../../../../services/profiles/get-contract-status-label.service';
import { toast } from '../../../../utils/toast';
import {
  onInvalidSubmit,
  validateRequired,
} from '../../../../utils/validations';

const { INITIAL, SENT, SIGNED } = ContractStatusesEnum;

const getContractStatusLabelService = new GetContractStatusLabelService();

const OPTIONS: OptionInterface[] = [
  {
    label: 'Aucun',
    value: INITIAL,
  },
  {
    label: getContractStatusLabelService.get(SENT),
    value: SENT,
  },
  {
    label: getContractStatusLabelService.get(SIGNED),
    value: SIGNED,
  },
];

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  edition: EditionDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const AdminProfileContractPage: FC = () => {
  const { accessOrder, assembly, booking, edition, detailedSheet, profile } =
    useOutletContext<OutletContext>();

  const { refetch } = useFindProfile(profile.id);

  const { mutateAsync: saveProfileContract } = useSaveProfileContract();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<ProfileContractFieldValuesInterface>({
    defaultValues: {
      contractNotes: profile.contractNotes ?? '',
      contractStatus: profile.contractStatus,
    },
  });

  const onSubmit: SubmitHandler<ProfileContractFieldValuesInterface> =
    useCallback(
      async (fieldValues) => {
        try {
          const dto = plainToClass(SaveProfileContractDto, {
            contractStatus: fieldValues.contractStatus,
            contractNotes: fieldValues.contractNotes,
          });

          await saveProfileContract({ id: profile.id, dto });
          await refetch();

          toast.success('Enregistré avec succès');
        } catch (error) {
          toast.error();
        }
      },
      [profile, refetch, saveProfileContract],
    );

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="contract" />

      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Section>
          <FormStack>
            <FormGrid>
              <Grid item xs={12}>
                <Typography variant="overline" component="h2">
                  Contrat
                </Typography>
              </Grid>

              <Grid xs={12} sm={4} item>
                <Select
                  control={control}
                  label="Statut"
                  name="contractStatus"
                  options={OPTIONS}
                  rules={validateRequired()}
                  fullWidth
                />
              </Grid>

              <Grid
                xs={12}
                md={8}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                item
              />

              <Grid xs={12} sm={6} item>
                <TextField
                  control={control}
                  label="Notes de suivi (visible par les administrateurs seulement)"
                  name="contractNotes"
                  fullWidth
                  multiline
                />
              </Grid>

              <Grid xs={12} sm={6} item />

              <Grid xs={12} sm={6} item>
                <ContractDownload
                  edition={edition}
                  profile={profile}
                  booking={booking}
                />
              </Grid>
            </FormGrid>
          </FormStack>
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer le contrat
        </Button>
      </form>
    </>
  );
};

export default AdminProfileContractPage;
