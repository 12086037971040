import SaveLocationDto from '../../dto/locations/in/save-location.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveLocationService {
  async save(dto: SaveLocationDto): Promise<void> {
    try {
      const method = !dto.id ? 'POST' : 'PUT';

      await apiV1.request<void>({
        url: '/locations',
        data: dto,
        method,
      });
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveLocationService;
