import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindAccessOrderByUserIdService {
  async find(id: string): Promise<AccessOrderDto> {
    try {
      const { data } = await apiV1.get<AccessOrderDto>(
        `/users/${id}/access-order`,
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindAccessOrderByUserIdService;
