import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, MouseEventHandler, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ButtonLoadingIcon from '../../forms/ButtonLoadingIcon';
import TextField from '../../forms/TextField';
import SignUpDto from '../../../dto/auth/sign-up.dto';
import ErrorTypesEnum from '../../../enums/error-types.enum';
import useSignUp from '../../../hooks/auth/sign-up.hook';
import SignUpFieldValues from '../../../interfaces/auth/sign-up-field-values.interface';
import { PATHS } from '../../../utils/paths';
import { toast } from '../../../utils/toast';
import {
  validateEmailFormat,
  validatePasswordMatch,
  validateRequired,
} from '../../../utils/validations';

const SignUpPage: FC = () => {
  const { mutateAsync: signUp } = useSignUp();
  const navigate = useNavigate();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignUpFieldValues>({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit: SubmitHandler<SignUpFieldValues> = useCallback(
    async ({ email, password }: SignUpFieldValues) => {
      try {
        const dto = plainToClass(SignUpDto, {
          email: email.trim(),
          password: password.trim(),
        });

        await signUp(dto);

        toast.success('Création du compte réussie');

        navigate(PATHS.auth.signIn.path);
      } catch (error) {
        const { message } = error as Error;

        switch (message) {
          case ErrorTypesEnum.DUPLICATE_ENTRY_ERROR: {
            toast.error(`L'utilisateur ${email} existe déjà`);

            break;
          }
          default: {
            toast.error();

            break;
          }
        }
      }
    },
    [navigate, signUp],
  );

  const navigateToSignIn: MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      event.preventDefault();

      navigate(PATHS.auth.signIn.path);
    },
    [navigate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ alignItems: 'flex-start' }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Créer un compte
        </Typography>

        <TextField
          control={control}
          fullWidth
          label="Courriel"
          name="email"
          rules={{
            ...validateRequired(),
            ...validateEmailFormat(),
          }}
          type="email"
        />

        <TextField
          control={control}
          fullWidth
          label="Mot de passe"
          name="password"
          rules={{
            ...validateRequired(),
          }}
          type="password"
        />

        <TextField
          control={control}
          fullWidth
          label="Confirmation du mot de passe"
          name="passwordConfirmation"
          rules={{
            ...validateRequired(),
            ...validatePasswordMatch(getValues),
          }}
          type="password"
        />

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            sx={{ mx: 'auto' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Créer le compte
          </Button>

          <Typography sx={{ mt: 4 }}>
            <Link href="#" onClick={navigateToSignIn}>
              Vous avez déjà un compte ? Connectez-vous.
            </Link>
          </Typography>
        </Box>
      </Stack>
    </form>
  );
};

export default SignUpPage;
