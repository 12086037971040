import Chip, { ChipProps } from '@mui/material/Chip';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { FC, useMemo } from 'react';

import LocationsDto from '../../dto/locations/out/locations.dto';

interface Props {
  location: LocationsDto;
}

const LocationStatusChip: FC<Props> = ({ location }) => {
  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (true) {
      case !location.active: {
        return { color: 'default' };
      }
      case !location.bookingLocations.length: {
        return { color: 'primary' };
      }
      default: {
        return { color: 'success' };
      }
    }
  }, [location]);

  const tooltipProps = useMemo((): Pick<TooltipProps, 'title'> => {
    switch (true) {
      case !location.active: {
        return { title: 'Inactif' };
      }
      case !location.bookingLocations.length: {
        return { title: 'Non réservé' };
      }
      default: {
        return { title: 'Réservé' };
      }
    }
  }, [location]);

  return (
    <Tooltip {...tooltipProps} arrow>
      <Chip
        {...props}
        sx={{
          height: '16px',
          width: '16px',
        }}
      />
    </Tooltip>
  );
};

export default LocationStatusChip;
