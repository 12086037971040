import DropzoneStatesEnum from '../enums/dropzone-states.enum';

const { ACCEPTED, ACTIVE, DEFAULT, ERROR } = DropzoneStatesEnum;

interface GetDropzoneStatusArgs {
  error: boolean;
  isDragReject: boolean;
  isDragAccept: boolean;
  isDragActive: boolean;
}

export const getDropzoneStatus = ({
  isDragReject,
  isDragAccept,
  isDragActive,
  error,
}: GetDropzoneStatusArgs): DropzoneStatesEnum => {
  switch (true) {
    case isDragReject: {
      return ERROR;
    }
    case isDragAccept: {
      return ACCEPTED;
    }
    case isDragActive: {
      return ACTIVE;
    }
    case error: {
      return ERROR;
    }
    default: {
      return DEFAULT;
    }
  }
};
