import { useCallback } from 'react';

import PaginationHookInterface from '../../interfaces/data-tables/pagination/pagination-hook.interface';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';

const usePagination = (): PaginationHookInterface => {
  const { dataTableOptions, setDataTableOptions } = useDataTable();

  const { page = 1 } = dataTableOptions;

  const navigateToPreviousPage = useCallback(() => {
    setDataTableOptions({
      ...dataTableOptions,
      page: page === 1 ? 1 : page - 1,
    });
  }, [dataTableOptions, page, setDataTableOptions]);

  const navigateToNextPage = useCallback(() => {
    setDataTableOptions({
      ...dataTableOptions,
      page: page + 1,
    });
  }, [dataTableOptions, page, setDataTableOptions]);

  return { navigateToPreviousPage, navigateToNextPage, page };
};

export default usePagination;
