import { useMutation, UseMutationResult } from 'react-query';

import SaveProfileContractDto from '../../dto/profiles/in/save-profile-contract.dto';
import SaveProfileContractService from '../../services/profiles/save-profile-contract.service';

const useSaveProfileContract = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveProfileContractDto }
> => {
  return useMutation((variables) =>
    new SaveProfileContractService().save(variables.id, variables.dto),
  );
};

export default useSaveProfileContract;
