import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SendProfileReminderService {
  async send(id: string): Promise<void> {
    try {
      await apiV1.post<void>(`/profiles/${id}/notify`);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SendProfileReminderService;
