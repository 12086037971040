import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import { formatDateLong, getDatePeriodLabel } from '../../utils/date';

interface Props {
  assembly: AssemblyDto;
}

const AssemblyDeliveryPreferenceSection: FC<Props> = ({ assembly }) => {
  const renderDeliveryDate = useMemo(() => {
    if (!assembly.deliveryDatePreference) return null;

    const date = formatDateLong(parseISO(assembly.deliveryDatePreference));

    return (
      <Grid item xs={12} md={6}>
        <ReadonlyFieldLabel>Date</ReadonlyFieldLabel>

        <Typography>{date}</Typography>
      </Grid>
    );
  }, [assembly]);

  const period = useMemo(() => {
    if (!assembly.deliveryDatePeriodPreference) return null;

    return getDatePeriodLabel(assembly.deliveryDatePeriodPreference);
  }, [assembly]);

  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Horaire de montage
      </Typography>

      <FormStack>
        <FormGrid>
          {renderDeliveryDate}

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Période</ReadonlyFieldLabel>

            <Typography>{period}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AssemblyDeliveryPreferenceSection;
