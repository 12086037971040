import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import { getFileKey } from '../../utils/files';

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetImagesSection: FC<Props> = ({ detailedSheet }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Images
      </Typography>

      <FormStack>
        <FormGrid>
          {detailedSheet.logo && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Logo</ReadonlyFieldLabel>

              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/${getFileKey(
                  detailedSheet.logo,
                )}`}
                alt="Logo"
              />
            </Grid>
          )}

          {detailedSheet.image && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Image</ReadonlyFieldLabel>

              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/${getFileKey(
                  detailedSheet.image,
                )}`}
                alt="Logo"
              />
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetImagesSection;
