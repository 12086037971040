import ContractStatusesEnum from '../../enums/profiles/contract-statuses.enum';

const { INITIAL, SENT, SIGNED } = ContractStatusesEnum;

class GetContractStatusLabelService {
  get(paymentStatus: ContractStatusesEnum): string {
    switch (paymentStatus) {
      default:
      case INITIAL: {
        return '';
      }
      case SENT: {
        return 'Envoyé';
      }
      case SIGNED: {
        return 'Signé';
      }
    }
  }
}

export default GetContractStatusLabelService;
