import SaveUserAccessOrderDto from '../../dto/access-orders/in/save-user-access-order.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveUserAccessOrderService {
  async save(dto: SaveUserAccessOrderDto): Promise<void> {
    try {
      await apiV1.put<void>('/access-orders/me', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveUserAccessOrderService;
