import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, useCallback } from 'react';
import {
  FiBookOpen,
  FiFileText,
  FiLock,
  FiMail,
  FiSliders,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../utils/paths';

const TABS = [
  {
    Icon: FiLock,
    label: "Codes d'activation",
    value: 'activationCodes',
  },
  {
    Icon: FiSliders,
    label: 'Étapes',
    value: 'stages',
  },
  {
    Icon: FiBookOpen,
    label: 'Documents',
    value: 'documents',
  },
  {
    Icon: FiFileText,
    label: 'Rapports',
    value: 'reports',
  },
  {
    Icon: FiMail,
    label: 'Notifications',
    value: 'notifications',
  },
];

type TabValues =
  | 'activationCodes'
  | 'stages'
  | 'documents'
  | 'reports'
  | 'notifications';

interface Props {
  value: TabValues;
}

const EditionTabs: FC<Props> = ({ value }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (newValue: TabValues) => {
      if (newValue === value) return;

      switch (newValue) {
        case 'activationCodes': {
          navigate(PATHS.admin.activationCodes.path);
          break;
        }
        case 'stages': {
          navigate(PATHS.admin.stages.path);
          break;
        }
        case 'documents': {
          navigate(PATHS.admin.files.path);
          break;
        }
        case 'reports': {
          navigate(PATHS.admin.reports.path);
          break;
        }
        case 'notifications': {
          navigate(PATHS.admin.notifications.path);
          break;
        }
      }
    },
    [navigate, value],
  );

  return (
    <Tabs value={value} sx={{ mt: 2 }}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          icon={<tab.Icon />}
          iconPosition="start"
          label={tab.label}
          onClick={(): void => handleClick(tab.value as TabValues)}
          value={tab.value}
        />
      ))}
    </Tabs>
  );
};

export default EditionTabs;
