import { useQuery, UseQueryResult } from 'react-query';
import DataTableOptionsDto from '../../dto/data-tables/data-table-options.dto';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import ActivityFieldDto from '../../dto/activity-fields/activity-field.dto';
import FindActivityFieldsService from '../../services/activity-fields/find-activity-fields.service';

const useFindActivityFields = (
  dto: DataTableOptionsDto = {},
): UseQueryResult<PaginatedResultDto<ActivityFieldDto>> => {
  return useQuery<unknown, unknown, PaginatedResultDto<ActivityFieldDto>>(
    ['activity-fields', dto],
    () => new FindActivityFieldsService().find(dto),
  );
};

export default useFindActivityFields;
