import {
  TimePicker as MuiTimePicker,
  TimePickerProps,
} from '@mui/x-date-pickers';
import { ReactElement } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

const TimePicker = <T extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: TimePickerProps<Date> & UseControllerProps<T>): ReactElement => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ control, name, rules });

  return (
    <MuiTimePicker
      {...props}
      {...field}
      ampm={false}
      slotProps={{
        textField: { error: !!error, helperText: error?.message },
      }}
      onChange={(value): void => onChange(value as PathValue<T, Path<T>>)}
    />
  );
};

export default TimePicker;
