import { plainToClass } from 'class-transformer';
import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';

import EditionDto from '../../../dto/editions/out/edition.dto';
import SaveFileDto from '../../../dto/files/in/save-file.dto';
import ProfileDto from '../../../dto/profiles/out/profile.dto';
import { getFileExtension } from '../../../utils/files';

class MapNewDetailedSheetImageToSaveFileDtoService {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    detailedSheetImage: DetailedSheetDto['logo' | 'image'],
    file: File,
    fileName: string,
  ): SaveFileDto {
    const extension = getFileExtension(file);

    const fileFullName = `${fileName}.${extension}`;

    return plainToClass(SaveFileDto, {
      id: detailedSheetImage?.id,
      key: `images/original/${edition.name}/profiles/${profile.id}/${fileFullName}`,
      name: fileFullName,
      contentType: file.type,
      size: file.size,
      originalName: file.name,
      hasSmallVariant: false,
      extension,
    });
  }
}

export default MapNewDetailedSheetImageToSaveFileDtoService;
