import { useMutation, UseMutationResult } from 'react-query';

import RequestPasswordRequestDto from '../../dto/auth/request-password-reset.dto';
import RequestPasswordResetService from '../../services/auth/request-password-request.service';

const useRequestPasswordReset = (): UseMutationResult<
  void,
  Error,
  RequestPasswordRequestDto
> => {
  return useMutation(new RequestPasswordResetService().request);
};

export default useRequestPasswordReset;
