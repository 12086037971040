import { plainToClass } from 'class-transformer';

import RemoveEmptyStringService from '../forms/remove-empty-string.service';
import CreateBookingPreferenceDto from '../../dto/booking-preferences/in/save-booking-preference.dto';
import BookingPreferenceFieldValuesInterface from '../../interfaces/booking-preferences/booking-preference-field-values.interface';
import BookingPreferenceDto from '../../dto/booking-preferences/out/booking-preference.dto';
import SaveBookingPreferenceDto from '../../dto/booking-preferences/in/save-booking-preference.dto';
import SaveBookingPreferenceLocationDto from '../../dto/booking-preference-locations/in/save-booking-preference-location.dto';
import SaveProfileCategoriesDto from '../../dto/profile-categories/in/save-profile-categories.dto';

const removeEmptyStringService = new RemoveEmptyStringService();

class MapSaveBookingPreferenceFormToDtoService {
  map(
    {
      areaId,
      bookingPreferenceLocations1,
      bookingPreferenceLocations2,
      bookingPreferenceLocations3,
      bookingPreferenceLocationComments1,
      bookingPreferenceLocationComments2,
      bookingPreferenceLocationComments3,
      categories,
      comments,
      sectionId,
    }: BookingPreferenceFieldValuesInterface,
    dto?: BookingPreferenceDto,
  ): SaveBookingPreferenceDto {
    const { bookingPreference } = dto ?? {};

    const bookingPreferenceLocations = [
      ...bookingPreferenceLocations1.map((location, index) =>
        plainToClass(SaveBookingPreferenceLocationDto, {
          comments: removeEmptyStringService.remove(
            bookingPreferenceLocationComments1,
          ),
          displayOrder: index,
          group: '1',
          location,
        }),
      ),
      ...bookingPreferenceLocations2.map((location, index) =>
        plainToClass(SaveBookingPreferenceLocationDto, {
          comments: removeEmptyStringService.remove(
            bookingPreferenceLocationComments2,
          ),
          displayOrder: index,
          group: '2',
          location,
        }),
      ),
      ...bookingPreferenceLocations3.map((location, index) =>
        plainToClass(SaveBookingPreferenceLocationDto, {
          comments: removeEmptyStringService.remove(
            bookingPreferenceLocationComments3,
          ),
          displayOrder: index,
          group: '3',
          location,
        }),
      ),
    ];

    return plainToClass(CreateBookingPreferenceDto, {
      id: bookingPreference?.id,
      area: areaId,
      comments: removeEmptyStringService.remove(comments),
      section: sectionId,
      bookingPreferenceLocations,
      profileCategories: categories.map((category) =>
        plainToClass(SaveProfileCategoriesDto, {
          category,
        }),
      ),
    });
  }
}

export default MapSaveBookingPreferenceFormToDtoService;
