import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SignOutService {
  async signOut(): Promise<void> {
    try {
      await apiV1.post<void>('/auth/sign-out');
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SignOutService;
