import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { FC } from 'react';

const Section: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper sx={{ my: 4 }}>
      <Box
        component="section"
        sx={{
          py: { xs: 4, sm: 6 },
          px: { xs: 2, sm: 6 },
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default Section;
