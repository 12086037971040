import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import DoorTypesEnum from '../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../enums/assemblies/handling-equipments.enum';

interface Props {
  assembly: AssemblyDto;
}

const AssemblyNeedsSection: FC<Props> = ({ assembly }) => {
  const handlingEquipment = useMemo(() => {
    if (!assembly.handlingEquipment) return '';

    switch (assembly.handlingEquipment) {
      default:
      case HandlingEquipmentsEnum.FORKLIFT: {
        return 'Chariot élévateur';
      }
      case HandlingEquipmentsEnum.DOLLY: {
        return 'Chariot sur roulettes';
      }
      case HandlingEquipmentsEnum.NONE: {
        return 'Je suis autonome';
      }
    }
  }, [assembly]);

  const doorType = useMemo(() => {
    if (!assembly.doorType) return '';

    switch (assembly.doorType) {
      default:
      case DoorTypesEnum.GARAGE_GROUND_LEVEL: {
        return 'Porte de garage au sol';
      }
      case DoorTypesEnum.LOADING_DOCK: {
        return 'Quai de déchargement';
      }
    }
  }, [assembly]);

  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Service de manutention
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Besoins en manutention</ReadonlyFieldLabel>

            <Typography>{handlingEquipment}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Type de porte</ReadonlyFieldLabel>

            <Typography>{doorType}</Typography>
          </Grid>

          {assembly.needsNotes && (
            <Grid item xs={12} md={9}>
              <ReadonlyFieldLabel>
                Autres besoins ou commentaires
              </ReadonlyFieldLabel>

              <Typography>{assembly.needsNotes}</Typography>
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AssemblyNeedsSection;
