import Typography from '@mui/material/Typography';
import { FC } from 'react';

import AssembliesDataTable from '../../../assemblies/AssembliesDataTable';
import SectionHeader from '../../../layouts/SectionHeader';
import { DataTableProvider } from '../../../../providers/data-tables/DataTableProvider';

const AdminAssembliesListPage: FC = () => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Montage et démontage</Typography>
      </SectionHeader>

      <DataTableProvider dataTableOptions={{ showArchives: true }}>
        <AssembliesDataTable />
      </DataTableProvider>
    </>
  );
};

export default AdminAssembliesListPage;
