import SaveAssemblyDeliveryPreferencesDto from '../../dto/assemblies/in/save-assembly-delivery-preferences.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveAssemblyDeliveryPreferencesService {
  async save(
    id: string,
    dto: SaveAssemblyDeliveryPreferencesDto,
  ): Promise<void> {
    try {
      await apiV1.put<void>(`/assemblies/${id}/delivery-preferences`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveAssemblyDeliveryPreferencesService;
