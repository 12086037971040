import SaveBookingPreferenceLocationDto from '../../booking-preference-locations/in/save-booking-preference-location.dto';
import SaveProfileCategoriesDto from '../../profile-categories/in/save-profile-categories.dto';

class SaveBookingPreferenceDto {
  id?: string;
  comments?: string;
  area!: string;
  bookingPreferenceLocations!: SaveBookingPreferenceLocationDto[];
  profileCategories!: SaveProfileCategoriesDto[];
  section!: string;
}

export default SaveBookingPreferenceDto;
