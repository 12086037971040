import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ButtonLoadingIcon from '../../forms/ButtonLoadingIcon';
import TextField from '../../forms/TextField';
import RequestPasswordRequestDto from '../../../dto/auth/request-password-reset.dto';
import useRequestPasswordReset from '../../../hooks/auth/request-password-reset.hook';
import RequestPasswordResetFieldValues from '../../../interfaces/auth/request-password-reset-field-values.interface';
import { PATHS } from '../../../utils/paths';
import { toast } from '../../../utils/toast';
import {
  onInvalidSubmit,
  validateEmailFormat,
  validateRequired,
} from '../../../utils/validations';

const ForgottenPasswordPage: FC = () => {
  const navigate = useNavigate();

  const { mutateAsync: requestPasswordReset, status } =
    useRequestPasswordReset();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RequestPasswordResetFieldValues>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<RequestPasswordResetFieldValues> = useCallback(
    async ({ email }: RequestPasswordResetFieldValues) => {
      try {
        const dto = plainToClass(RequestPasswordRequestDto, {
          email: email.trim(),
        });

        await requestPasswordReset(dto);

        toast.success('Courriel envoyé');
      } catch (error) {
        toast.error();
      }
    },
    [requestPasswordReset],
  );

  const navigateToSignIn = useCallback(() => {
    navigate(PATHS.auth.signIn.path);
  }, [navigate]);

  const renderForm = useMemo(() => {
    return (
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Stack spacing={4} sx={{ alignItems: 'flex-start' }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Mot de passe oublié ?
          </Typography>

          <Typography>
            Confirmez nous votre courriel et nous vous enverrons des
            instructions.
          </Typography>

          <TextField
            control={control}
            fullWidth
            label="Courriel"
            name="email"
            rules={{
              ...validateRequired(),
              ...validateEmailFormat(),
            }}
            type="email"
          />

          <Box sx={{ width: '100%', pt: 2, textAlign: 'center' }}>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
              sx={{ mx: 'auto' }}
              startIcon={isSubmitting && <ButtonLoadingIcon />}
            >
              Réinitialiser le mot de passe
            </Button>
          </Box>
        </Stack>
      </form>
    );
  }, [control, handleSubmit, isSubmitting, onSubmit]);

  const renderSuccessMessage = useMemo(() => {
    return (
      <Stack spacing={4} sx={{ alignItems: 'flex-start' }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Vérifiez votre courriel
        </Typography>

        <Typography sx={{ width: '100%', textAlign: 'center' }}>
          Nous vous avons envoyé des instructions.
        </Typography>

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            onClick={navigateToSignIn}
            type="submit"
            variant="contained"
            size="large"
          >
            Retour à la connexion
          </Button>
        </Box>
      </Stack>
    );
  }, [navigateToSignIn]);

  return status === 'success' ? renderSuccessMessage : renderForm;
};

export default ForgottenPasswordPage;
