export const sortByCreatedAt = <T extends { createdAt: Date }[]>(
  array: T,
): T => {
  return array.sort((a, b): number => {
    const { createdAt: createdAtA } = a;
    const { createdAt: createdAtB } = b;

    switch (true) {
      case createdAtA < createdAtB: {
        return -1;
      }
      case createdAtA > createdAtB: {
        return 1;
      }
      default: {
        return 0;
      }
    }
  });
};

export const sortByDisplayOrder = <T extends { displayOrder: string }[]>(
  array: T,
): T => {
  return array.sort((a, b): number => {
    const { displayOrder: displayOrderA } = a;
    const { displayOrder: displayOrderB } = b;

    switch (true) {
      case displayOrderA < displayOrderB: {
        return -1;
      }
      case displayOrderA > displayOrderB: {
        return 1;
      }
      default: {
        return 0;
      }
    }
  });
};
