import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import EditionDto from '../../dto/editions/out/edition.dto';
import FindActiveEditionService from '../../services/editions/find-active-edition.service';

const useFindActiveEdition = (
  options?: Omit<UseQueryOptions<EditionDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<EditionDto, Error> => {
  return useQuery(
    'editions/active',
    new FindActiveEditionService().find,
    options,
  );
};

export default useFindActiveEdition;
