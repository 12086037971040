import { useCallback } from 'react';

import FiltersHookInterface from '../../interfaces/data-tables/filters/filters-hook.interface';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';

const useFilter = (): FiltersHookInterface => {
  const { dataTableOptions, debouncedFilter, setDebouncedFilter } =
    useDataTable();

  const { filter = '' } = dataTableOptions;

  const setFilter = useCallback(
    (filter: string): void => {
      setDebouncedFilter(filter);
    },
    [setDebouncedFilter],
  );

  return { debouncedFilter, filter, setFilter };
};

export default useFilter;
