import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { authState } from '../../http/auth.state';
import { queryClient } from '../../utils/query-client';

const AuthRoute: FC = () => {
  const { token } = authState;

  if (token) return <Navigate to="/admin" state={{}} replace />;

  queryClient.invalidateQueries();

  return <Outlet />;
};

export default AuthRoute;
