import LocationDetailedDto from '../../dto/locations/out/location-detailed.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindLocationService {
  async find(id: string): Promise<LocationDetailedDto> {
    try {
      const { data } = await apiV1.get<LocationDetailedDto>(`/locations/${id}`);

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindLocationService;
