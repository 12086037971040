export const PATHS = {
  admin: {
    path: '/admin',
    bookings: {
      new: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/bookings/new`;
        },
      },
      edit: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/bookings/edit`;
        },
      },
    },
    activationCodes: {
      path: '/admin/activation-codes',
    },
    accessOrders: {
      path: '/admin/access-orders',
      edit: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/access-orders/edit`;
        },
      },
    },
    assemblies: {
      path: '/admin/assemblies',
      edit: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/assemblies/edit`;
        },
      },
    },
    detailedSheets: {
      path: '/admin/detailed-sheets',
      edit: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/detailed-sheets/edit`;
        },
      },
    },
    files: {
      path: '/admin/files',
    },
    locations: {
      path: '/admin/locations',
      new: { path: '/admin/locations/new' },
      edit: {
        path(locationId: string): string {
          return `/admin/locations/${locationId}/edit`;
        },
      },
    },
    profiles: {
      path: '/admin/profiles',
      edit: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/edit`;
        },
      },
      payment: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/payment`;
        },
      },
      contract: {
        path(profileId: string): string {
          return `/admin/profiles/${profileId}/contract`;
        },
      },
    },
    notifications: {
      path: '/admin/notifications',
    },
    reports: {
      path: '/admin/reports',
    },
    stages: {
      path: '/admin/stages',
    },
  },
  auth: {
    forgottenPassword: {
      path: '/forgotten-password',
    },
    signIn: {
      path: '/sign-in',
    },
    signUp: {
      path: '/sign-up',
    },
  },
  documents: {
    faq: {
      path: `${process.env.REACT_APP_ASSETS_URL}/faq.pdf`,
    },
    guide: {
      path: `${process.env.REACT_APP_ASSETS_URL}/guide-exposant.pdf`,
    },
    rentalProperties: {
      path: `${process.env.REACT_APP_ASSETS_URL}/allee-des-immeubles-locatifs.pdf`,
    },
    plan: {
      path: `${process.env.REACT_APP_ASSETS_URL}/plan.pdf`,
    },
    purchaseOrderTSE: {
      path: `${process.env.REACT_APP_ASSETS_URL}/bon-commande-tse.pdf`,
    },
    squareFeetPolicy: {
      path: `${process.env.REACT_APP_ASSETS_URL}/politique-pieds-carres.pdf`,
    },
    timetable: {
      path: `${process.env.REACT_APP_ASSETS_URL}/echeancier-priorites-location.pdf`,
    },
    contract: {
      path: `${process.env.REACT_APP_ASSETS_URL}/contrat.pdf`,
    },
    visibilityRates: {
      path: `${process.env.REACT_APP_ASSETS_URL}/liste-prix-visibilite.pdf`,
    },
    detailedSheetWebExample: {
      path: `${process.env.REACT_APP_ASSETS_URL}/exemple-fiche-exposant-web.pdf`,
    }
  },
  exhibitors: {
    path: '/exhibitors',
    noActiveEdition: {
      path: '/exhibitors/no-active-edition',
    },
    activationCode: {
      path: '/exhibitors/users/activation-codes',
    },
    accessOrder: {
      path: '/exhibitors/access-order',
    },
    accessOrders: {
      edit: {
        path: '/exhibitors/access-orders/edit',
      },
    },
    assembly: {
      path: '/exhibitors/assembly',
    },
    assemblies: {
      edit: {
        path: '/exhibitors/assemblies/edit',
      },
    },
    bookingPreferences: {
      create: {
        path: '/exhibitors/booking-preferences/new',
      },
      edit: {
        path: '/exhibitors/booking-preferences/edit',
      },
      confirmation: {
        path: '/exhibitors/booking-preferences/confirmation',
      },
    },
    detailedSheet: {
      path: '/exhibitors/detailed-sheet',
    },
    detailedSheets: {
      edit: {
        path: '/exhibitors/detailed-sheets/edit',
      },
    },
    profile: {
      path: '/exhibitors/profile',
    },
    profiles: {
      create: {
        path: '/exhibitors/profiles/new',
      },
      edit: {
        path: '/exhibitors/profiles/edit',
      },
    },
    termsAndConditions: {
      path: '/exhibitors/profiles/terms-and-conditions',
    },
  },
};
