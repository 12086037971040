import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import AccessOrdersDataTableOptionsDto from '../../dto/access-orders/in/access-orders-data-table-options.dto';
import AccessOrdersDto from '../../dto/access-orders/out/access-orders.dto';
import { apiV1 } from '../../http';

class FindAccessOrdersService {
  async find(
    dto: AccessOrdersDataTableOptionsDto,
  ): Promise<PaginatedResultDto<AccessOrdersDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<AccessOrdersDto>>(
      '/access-orders',
      { params: dto },
    );

    return data;
  }
}

export default FindAccessOrdersService;
