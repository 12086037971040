import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserAccessOrderService {
  async find(): Promise<AccessOrderDto> {
    try {
      const { data } = await apiV1.get<AccessOrderDto>('access-orders/me');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserAccessOrderService;
