import { useMutation, UseMutationResult } from 'react-query';

import UpdateProfileDto from '../../dto/profiles/in/update-profile.dto';
import UpdateProfileService from '../../services/profiles/update-profile.service';

const useUpdateProfile = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: UpdateProfileDto }
> => {
  return useMutation((variables) =>
    new UpdateProfileService().update(variables.id, variables.dto),
  );
};

export default useUpdateProfile;
