import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderPresaleTicketsSection: FC<Props> = ({ accessOrder }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Billets de prévente
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Nombre de billets de prévente
            </ReadonlyFieldLabel>

            <Typography>{accessOrder.presaleTicketQuantity}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AccessOrderPresaleTicketsSection;
