import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import AccessOrderAccreditationsSection from '../../../access-orders/AccessOrderAccreditationsSection';
import AccessOrderConfirmationAlert from '../../../access-orders/AccessOrderConfirmationAlert';
import AccessOrderParkingsSection from '../../../access-orders/AccessOrderParkingsSection';
import AccessOrderPresaleTicketsSection from '../../../access-orders/AccessOrderPresaleTicketsSection';
import AccessOrderProcessingAlert from '../../../access-orders/AccessOrderProcessingAlert';
import SectionHeader from '../../../layouts/SectionHeader';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AccessOrderStatusesEnum from '../../../../enums/access-orders/access-order-statuses.enum';

interface OutletContext {
  accessOrder: AccessOrderDto;
}

const AccessOrderPage: FC = () => {
  const { accessOrder } = useOutletContext<OutletContext>();

  const renderAlert = useMemo(() => {
    switch (accessOrder.status) {
      case AccessOrderStatusesEnum.PROCESSING: {
        return <AccessOrderProcessingAlert />;
      }
      case AccessOrderStatusesEnum.CONFIRMED: {
        return <AccessOrderConfirmationAlert accessOrder={accessOrder} />;
      }
      default: {
        return null;
      }
    }
  }, [accessOrder]);

  return (
    <>
      <SectionHeader>
        <Typography variant="h1" gutterBottom>
          Commandes d&apos;accès au Salon
        </Typography>
      </SectionHeader>

      {renderAlert}

      <AccessOrderAccreditationsSection accessOrder={accessOrder} />
      <AccessOrderParkingsSection accessOrder={accessOrder} />
      <AccessOrderPresaleTicketsSection accessOrder={accessOrder} />
    </>
  );
};

export default AccessOrderPage;
