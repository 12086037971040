import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Checkbox from '../../../forms/Checkbox';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import useSetProfileTermsAndConditions from '../../../../hooks/profiles/set-profile-terms-and-conditions.hook';
import ProfileTermsAndConditionsFieldValuesInterface from '../../../../interfaces/profiles/profile-terms-and-conditions-field-values.interface';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import {
  onInvalidSubmit,
  validateRequired,
} from '../../../../utils/validations';
import UserStepsEnum from '../../../../enums/users/user-steps.enum';
import { queryClient } from '../../../../utils/query-client';
import {
  getFontSizesForBreakpoints,
  getRmFromPx,
} from '../../../../styles/utils/fonts';

const {
  ACTIVATION_CODE_STEP,
  BOOKING_PREFERENCE_STEP,
  COMPLETED_STEP,
  PROFILE_STEP,
} = UserStepsEnum;

const SetProfileTermsAndConditionsPage: FC = () => {
  const navigate = useNavigate();
  const currentStep = useOutletContext<UserStepsEnum>();

  const { mutateAsync: setProfileTermsAndConditions } =
    useSetProfileTermsAndConditions();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProfileTermsAndConditionsFieldValuesInterface>({
    defaultValues: { acceptedTermsAndConditions: false },
  });

  const handleNavigate = useCallback(() => {
    navigate(PATHS.exhibitors.bookingPreferences.edit.path);
  }, [navigate]);

  const onSubmit: SubmitHandler<ProfileTermsAndConditionsFieldValuesInterface> =
    useCallback(async () => {
      try {
        await setProfileTermsAndConditions();

        await Promise.all([
          queryClient.invalidateQueries('users/step'),
          queryClient.invalidateQueries('users/info'),
        ]);

        toast.success('Enregistré avec succès');

        navigate(PATHS.exhibitors.bookingPreferences.confirmation.path);
      } catch (error) {
        toast.error();
      }
    }, [navigate, setProfileTermsAndConditions]);

  switch (currentStep) {
    case ACTIVATION_CODE_STEP: {
      return <Navigate to={PATHS.exhibitors.activationCode.path} replace />;
    }
    case PROFILE_STEP: {
      return <Navigate to={PATHS.exhibitors.profiles.create.path} replace />;
    }
    case BOOKING_PREFERENCE_STEP: {
      return (
        <Navigate
          to={PATHS.exhibitors.bookingPreferences.create.path}
          replace
        />
      );
    }
    case COMPLETED_STEP: {
      return <Navigate to={PATHS.exhibitors.path} replace />;
    }
  }

  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Termes et conditions de location</Typography>
      </SectionHeader>

      <Button
        onClick={handleNavigate}
        startIcon={<FiChevronLeft />}
        variant="text"
      >
        Étape précédente
      </Button>

      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Section>
          <Box
            sx={{
              pb: { xs: 0, sm: 4 },
              pt: { xs: 0, sm: 2 },
              px: { xs: 0, sm: 4 },
              ' p': {
                marginBottom: 1,
              },
              ' h4': {
                mt: 3,
                mb: 1,
                fontSize: getRmFromPx(19),
                ...getFontSizesForBreakpoints({ sm: 19, md: 20, lg: 20 }),
              },
              ' h5': {
                mt: 3,
                mb: 1,
                fontSize: getRmFromPx(16),
                ...getFontSizesForBreakpoints({ sm: 16, md: 18, lg: 18 }),
              },
              ' h6': {
                mt: 3,
                mb: 1,
                fontSize: getRmFromPx(16),
                ...getFontSizesForBreakpoints({ sm: 16, md: 18, lg: 18 }),
                fontWeight: '600',
              },
              ' h5 + h6': {
                mt: 0,
              },
              ' ul': {
                ml: 1,
              },
              ' li': {
                mb: 1,
              },
            }}
          >
            <p>
              Le bailleur loue au locataire, qui accepte, un emplacement
              destin&eacute; &agrave; l&#39;exposition &laquo;&nbsp;Expo habitat
              Qu&eacute;bec&nbsp;&raquo;, le tout selon les termes, conditions
              et modalit&eacute;s stipul&eacute;s ci-apr&egrave;s :
            </p>

            <Typography variant="h4">TERME</Typography>

            <p>
              Cette location est faite pour toute la dur&eacute;e d&rsquo;Expo
              habitat Qu&eacute;bec, &eacute;dition 2025, soit pour une
              p&eacute;riode de quatre (4) jours commen&ccedil;ant le 20
              f&eacute;vrier 2025 et se terminant le 23 f&eacute;vrier 2025.
              Malgr&eacute; ce qui pr&eacute;c&egrave;de, le bailleur se
              r&eacute;serve le droit de modifi&shy;er la dur&eacute;e
              d&#39;Expo habitat Qu&eacute;bec, pour tout motif qu&#39;il juge
              raisonnable, &eacute;tant entendu qu&#39;une telle
              modifi&shy;cation n&#39;affectera pas le prix payable par le
              locataire.
            </p>

            <Typography variant="h4">INSTALLATION</Typography>

            <p>
              Le locataire s&rsquo;engage &agrave; respecter l&rsquo;heure
              d&rsquo;entr&eacute;e qui lui sera assign&eacute;e pour la bonne
              marche du montage. L&rsquo;installation du stand doit &ecirc;tre
              termin&eacute;e et les lieux lou&eacute;s doivent &ecirc;tre
              pr&ecirc;ts, pour la tenue d&#39;Expo habitat Qu&eacute;bec,
              &agrave; 17 h, le 19 f&eacute;vrier 2025, pour des fins de
              nettoyage et de pr&eacute;paration par le bailleur. En aucun
              temps, le locataire ne doit obstruer les passages et les sorties
              de secours. Aucun stand ou mat&eacute;riel d&rsquo;exposition ne
              sera transport&eacute; hors du site d&rsquo;exposition pendant la
              tenue d&#39;Expo habitat Qu&eacute;bec &agrave; moins d&rsquo;une
              autorisation &eacute;crite du bailleur.
            </p>

            <Typography variant="h4">D&Eacute;MANT&Egrave;LEMENT</Typography>

            <p>
              Le locataire sera en droit de d&eacute;buter le
              d&eacute;mant&egrave;lement de son stand d&rsquo;exposition
              &agrave; compter du 23 f&eacute;vrier 2025 &agrave; 17 h. Le
              d&eacute;mant&egrave;lement devra &ecirc;tre termin&eacute;
              &agrave; 17 h, le 24 f&eacute;vrier 2025. Le locataire devra avoir
              d&eacute;gag&eacute; le site d&rsquo;Expo habitat Qu&eacute;bec de
              tout son mat&eacute;riel avant cette date.
            </p>

            <p>
              Tout exposant retardataire qui n&rsquo;aura pas quitt&eacute; les
              lieux aux dates pr&eacute;cit&eacute;es paiera 500 $ par heure de
              retard au bailleur. De plus, la responsabilit&eacute; des
              mat&eacute;riaux du stand, de son d&eacute;mant&egrave;lement et
              la lib&eacute;ration des lieux de tous r&eacute;sidus de ruban
              adh&eacute;sif et/ou de colle est celle du locataire.
            </p>
            <Typography variant="h4">
              R&Eacute;SILIATION PAR LE LOCATAIRE
            </Typography>

            <p>
              Advenant la r&eacute;siliation du pr&eacute;sent contrat de bail
              par le locataire, le bailleur sera autoris&eacute; &agrave;
              relouer l&#39;emplacement faisant l&#39;objet du pr&eacute;sent
              bail, il conservera l&#39;argent d&eacute;j&agrave; vers&eacute;
              &agrave; titre de dommages et int&eacute;r&ecirc;ts liquides et
              pourra recouvrer la totalit&eacute; des sommes dues en vertu de
              celles d&eacute;taill&eacute;es sur la facture.
            </p>

            <Typography variant="h4">D&Eacute;FAUT</Typography>

            <p>
              En cas de d&eacute;faut de paiement de l&rsquo;emplacement
              lou&eacute; par le locataire ou de manquement &agrave; l&rsquo;une
              de ses obligations aux termes du pr&eacute;sent contrat de bail et
              de ses annexes, le bailleur pourra mettre fin au bail sur avis
              &eacute;crit de vingt-quatre (24) heures, reprendre possession des
              lieux lou&eacute;s et en expulser toute personne ou faire enlever
              tout bien sans &ecirc;tre oblig&eacute; de recourir &agrave; une
              proc&eacute;dure judiciaire. En cas de r&eacute;siliation dans ces
              conditions, le bailleur sera en droit de conserver toute somme
              per&ccedil;ue aux termes de ce bail et, en outre, de recouvrer
              tout loyer et tous dommages et int&eacute;r&ecirc;ts
              d&eacute;coulant d&rsquo;un manquement aux conditions
              stipul&eacute;es dans ce bail.
            </p>

            <p>
              Si le locataire devient insolvable ou s&rsquo;il est
              d&eacute;clar&eacute; en faillite ou fait une proposition
              concordataire aux termes de la Loi sur la faillite et
              l&rsquo;insolvabilit&eacute;, le bailleur peut r&eacute;silier le
              bail de plein droit en avisant le locataire par &eacute;crit. Les
              dispositions du paragraphe ci-dessus s&rsquo;appliquent alors en
              faisant les changements n&eacute;cessaires.
            </p>

            <p>
              Le bailleur peut &eacute;galement r&eacute;silier le bail de plein
              droit en avisant le locataire par &eacute;crit si le locataire
              commet un acte ou adopte un comportement indigne ou nuisible aux
              buts poursuivis par le bailleur, &agrave; ses
              int&eacute;r&ecirc;ts ou &agrave; sa bonne r&eacute;putation,
              selon le jugement du bailleur. Sans limiter la
              g&eacute;n&eacute;ralit&eacute; de ce qui pr&eacute;c&egrave;de,
              le bailleur peut r&eacute;silier le bail de plein droit si le
              locataire ne respecte pas les principes pr&eacute;vus &agrave;
              l&rsquo;article 3 des R&egrave;glements g&eacute;n&eacute;raux de
              l&rsquo;APCHQ - r&eacute;gion de Qu&eacute;bec (&laquo; Code de
              d&eacute;ontologie et Comit&eacute; de discipline &raquo;),
              lesquels sont r&eacute;put&eacute;s faire partie int&eacute;grante
              du pr&eacute;sent contrat de bail. Les dispositions du premier
              paragraphe s&rsquo;appliquent alors en faisant les changements
              n&eacute;cessaires.
            </p>

            <Typography variant="h4">
              R&Eacute;SILIATION PAR LE BAILLEUR
            </Typography>

            <p>
              Advenant l&#39;impossibilit&eacute; pour le bailleur de tenir Expo
              habitat Qu&eacute;bec, &eacute;dition 2025, et ce, pour quelque
              raison ou circonstance que ce soit, le bailleur pourra
              r&eacute;silier le pr&eacute;sent contrat.
            </p>

            <p>
              Dans ce cas de r&eacute;siliation pr&eacute;vue au pr&eacute;sent
              paragraphe, la responsabilit&eacute; du bailleur se limitera au
              remboursement des argents vers&eacute;s par le locataire pour la
              location de l&#39;emplacement. Le locataire d&eacute;gage en
              cons&eacute;quence le bailleur de toute responsabilit&eacute; et
              renonce &agrave; l&#39;avance &agrave; toute r&eacute;clamation ou
              recours qu&#39;il pourrait exercer contre le bailleur en raison de
              cette r&eacute;siliation.
            </p>

            <Typography variant="h4">SERVICES</Typography>
            <p>
              Les services d&rsquo;eau courante,
              d&rsquo;&eacute;lectricit&eacute;, de prise
              t&eacute;l&eacute;phonique et d&#39;Internet sont disponibles sur
              les lieux aupr&egrave;s de Solotech et ExpoCit&eacute;. Ces
              services et les raccordements &agrave; ces services sont
              enti&egrave;rement &agrave; la charge de l&rsquo;exposant
              locataire.
            </p>

            <p>
              De plus, la publicit&eacute; initi&eacute;e ou effectu&eacute;e
              par le bailleur dans le cadre de la tenue d&rsquo;Expo habitat
              Qu&eacute;bec sera assum&eacute;e par le bailleur.
            </p>
            <Typography variant="h4">R&Eacute;PARTITION</Typography>

            <p>
              Le bailleur se r&eacute;serve le droit, en tout temps avant la
              tenue de l&rsquo;&eacute;v&eacute;nement, d&rsquo;allouer
              d&rsquo;autres espaces que ceux pr&eacute;vus aux pr&eacute;sentes
              pour l&rsquo;emplacement du stand du locataire, advenant la
              n&eacute;cessit&eacute; d&rsquo;effectuer un changement dans la
              disposition physique de l&rsquo;exposition, &agrave; la suite
              d&rsquo;une d&eacute;cision du propri&eacute;taire ou mandataire
              des lieux lou&eacute;s, ou encore pour des raisons de
              n&eacute;cessit&eacute;, de commodit&eacute; ou autrement.
            </p>

            <Typography variant="h4">CONCOURS</Typography>

            <p>
              Les r&eacute;clames commerciales et les concours organis&eacute;s
              par les exposants ne doivent, en aucun cas, engager le promoteur.
              L&#39;exposant d&eacute;gage
              l&#39;APCHQ - r&eacute;gion de Qu&eacute;bec de toutes
              responsabilit&eacute;s.
            </p>

            <Typography variant="h4">
              INTERDICTION DE VENDRE SUR PLACE
            </Typography>

            <p>
              Le locataire ne peut, pendant la dur&eacute;e de la tenue
              d&#39;Expo habitat Qu&eacute;bec, vendre des biens avec lesquels
              les visiteurs peuvent repartir du site, &eacute;tant entendu
              toutefois que le locataire peut signer des estimations, des
              soumissions ou des contrats sur place. Malgr&eacute; ce qui
              pr&eacute;c&egrave;de, le bailleur pourrait, dans certaines
              circonstances, permettre &agrave; un locataire de vendre des biens
              sur place, auquel cas une entente &eacute;crite interviendra entre
              les parties.
            </p>

            <Typography variant="h4">ASSURANCES</Typography>

            <p>
              Pour toute la p&eacute;riode comprise entre l&#39;installation et
              le d&eacute;mant&egrave;lement, le locataire contractera, en
              faveur du bailleur, une police d&rsquo;assurance
              g&eacute;n&eacute;rale de responsabilit&eacute; civile sur
              lui-m&ecirc;me et chacun de ses repr&eacute;sentants et
              l&#39;ensemble de ses biens, d&#39;un montant minimal de 2 000 000
              $.
            </p>

            <p>
              Le locataire devra fournir au bailleur, avant d&rsquo;occuper
              l&rsquo;emplacement lou&eacute;, une preuve d&rsquo;assurance
              &agrave; cet effet.
            </p>

            <Typography variant="h4">RESPONSABILIT&Eacute;S</Typography>

            <p>
              Le locataire d&eacute;gage le bailleur de toute
              responsabilit&eacute; pour dommages de quelque nature qu&rsquo;ils
              soient, qui peuvent na&icirc;tre lors de l&rsquo;utilisation de
              l&rsquo;emplacement lou&eacute; par le locataire, ses mandataires,
              pr&eacute;pos&eacute;s ou toute autre personne sous son
              contr&ocirc;le.
            </p>

            <p>
              Le locataire indemnisera le bailleur pour toute
              r&eacute;clamation, action civile ou jugement qui pourrait
              &ecirc;tre intent&eacute; ou rendu contre le bailleur et
              r&eacute;sultant de tout acte, faute, omission et/ou manquement
              aux obligations du locataire envers toute personne dans le cadre
              de la tenue d&rsquo;Expo habitat Qu&eacute;bec.
            </p>

            <p>
              Advenant que, par voie de l&eacute;gislation ou de
              r&eacute;glementation, une autorit&eacute; gouvernementale
              d&eacute;cr&egrave;te et impose au bailleur, au regard du
              pr&eacute;sent contrat de location d&rsquo;espaces
              d&rsquo;exposition ou de publicit&eacute; commerciale, une taxe
              sur les produits et services, une taxe sur la valeur
              ajout&eacute;e, une taxe sur les op&eacute;rations commerciales ou
              toute autre taxe analogue, le locataire convient de
              d&eacute;frayer et de rembourser au bailleur le montant
              assum&eacute; et/ou impos&eacute; &agrave; ce dernier pour le
              paiement de telles taxes.
            </p>

            <p>
              Le locataire a la responsabilit&eacute; de d&eacute;tenir toutes
              les cat&eacute;gories et sous-cat&eacute;gories de licences
              exig&eacute;es par la R&eacute;gie du b&acirc;timent du
              Qu&eacute;bec pour les activit&eacute;s dont il fait la promotion
              dans le cadre de/&agrave; Expo habitat Qu&eacute;bec.
            </p>

            <p>
              Le locataire, titulaire d&#39;une licence &agrave; la R&eacute;gie
              du b&acirc;timent du Qu&eacute;bec, a la responsabilit&eacute;
              d&#39;indiquer dans toute forme de publicit&eacute; qu&#39;il
              fait, notamment dans son stand, sur ses estimations, ses
              soumissions, ses contrats, ses &eacute;tats de compte et sur tout
              autre document d&eacute;termin&eacute; par r&egrave;glement de la
              R&eacute;gie, son num&eacute;ro de licence et la mention &laquo;
              titulaire d&#39;une licence d&eacute;livr&eacute;e en vertu de la
              loi sur le b&acirc;timent du Qu&eacute;bec &raquo;.
            </p>

            <Typography variant="h4">FOURNISSEURS OFFICIELS</Typography>

            <p>
              En devenant exposant.e à Expo habitat Québec, j’accepte que mes coordonnées soient transmises aux fournisseurs officiels du Salon relativement à l’aménagement des stands (ex. tapis, électricité, connexion internet, etc.) et autorise ceux-ci à communiquer avec nous afin de vérifier nos besoins en la matière. Ce contact peut se faire par courriel ou par téléphone.
            </p>

            <Checkbox
              control={control}
              name="acceptedTermsAndConditions"
              label="J'ai compris et j'accepte les termes et conditions de location"
              rules={validateRequired()}
              sx={{ pt: 4 }}
            />
          </Box>
        </Section>
        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          J&apos;accepte et je confirme l&apos;inscription
        </Button>
      </form>
    </>
  );
};

export default SetProfileTermsAndConditionsPage;
