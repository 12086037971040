import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useMemo } from 'react';
import UserInfoDto from '../../dto/users/user-info.dto';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import LocationsChips from '../locations/LocationsChips';

interface Props {
  bookingPreference: WithRequiredKeys<
    UserInfoDto,
    'bookingPreference'
  >['bookingPreference'];
}

const BookingPreferenceLocationsChips: FC<Props> = ({ bookingPreference }) => {
  const getBookingPreferenceLocations = useCallback(
    (group: '1' | '2' | '3') => {
      if (!bookingPreference) return [];

      return bookingPreference.bookingPreferenceLocations.filter(
        (bookingPreferenceLocation) =>
          bookingPreferenceLocation.group === group,
      );
    },
    [bookingPreference],
  );

  const getLocations = useCallback(
    (group: '1' | '2' | '3') => {
      return getBookingPreferenceLocations(group).map(
        ({ location }) => location,
      );
    },
    [getBookingPreferenceLocations],
  );

  const comments1 = useMemo(
    () => getBookingPreferenceLocations('1')[0]?.comments,
    [getBookingPreferenceLocations],
  );

  const comments2 = useMemo(
    () => getBookingPreferenceLocations('2')[0]?.comments,
    [getBookingPreferenceLocations],
  );

  const comments3 = useMemo(
    () => getBookingPreferenceLocations('3')[0]?.comments,
    [getBookingPreferenceLocations],
  );

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={9}>
          <ReadonlyFieldLabel>
            Préférences d&apos;emplacements 1
          </ReadonlyFieldLabel>

          <LocationsChips locations={getLocations('1')} />

          {comments1 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {comments1}
            </Typography>
          )}
        </Grid>

        <Grid item xs={9}>
          <ReadonlyFieldLabel>
            Préférences d&apos;emplacements 2
          </ReadonlyFieldLabel>

          <LocationsChips locations={getLocations('2')} />

          {comments2 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {comments2}
            </Typography>
          )}
        </Grid>

        <Grid item xs={9}>
          <ReadonlyFieldLabel>
            Préférences d&apos;emplacements 3
          </ReadonlyFieldLabel>

          <LocationsChips locations={getLocations('3')} />

          {comments3 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {comments3}
            </Typography>
          )}
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default BookingPreferenceLocationsChips;
