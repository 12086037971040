import GetSignedUrlDto from '../../dto/files/in/get-signed-url.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class GetSignedUrlService {
  async get(dtos: GetSignedUrlDto[]): Promise<string[]> {
    try {
      const { data } = await apiV1.post<string[]>('/files/signed-urls', dtos);

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default GetSignedUrlService;
