import { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import AdminCompanyForm from './AdminCompanyForm';
import Section from '../layouts/Section';
import AdminManagersForm from '../managers/AdminManagersForm';
import AdminSectionForm from '../sections/AdminSectionForm';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import SectionDto from '../../dto/sections/section.dto';
import AdminProfileFieldValuesInterface from '../../interfaces/profiles/admin-profile-field-values.interface';

interface Props {
  control: Control<AdminProfileFieldValuesInterface>;
  profile: ProfileDto;
  sections: PaginatedResultDto<SectionDto>;
  setValue: UseFormSetValue<AdminProfileFieldValuesInterface>;
}

const AdminSaveProfileForm: FC<Props> = ({
  control,
  profile,
  sections,
  setValue,
}) => {
  return (
    <>
      <Section>
        <AdminCompanyForm
          control={control}
          profile={profile}
          setValue={setValue}
        />
      </Section>

      <Section>
        <AdminManagersForm control={control} setValue={setValue} />
      </Section>

      <Section>
        <AdminSectionForm
          control={control}
          profile={profile}
          sections={sections}
          setValue={setValue}
        />
      </Section>
    </>
  );
};

export default AdminSaveProfileForm;
