import { FC } from 'react';
import { useParams, Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindAccessOrderByUserId from '../../../hooks/access-orders/find-access-order-by-user-id.hook';
import useFindAssemblyByUserId from '../../../hooks/assemblies/find-assembly-by-user-id.hook';
import useFindBookingByUserId from '../../../hooks/bookings/find-booking-by-user-id.hook';
import useFindProfile from '../../../hooks/profiles/find-profile.hook';
import useFindSections from '../../../hooks/sections/find-sections.hook';
import useFindDetailedSheetByUserId from '../../../hooks/detailed-sheets/find-detailed-sheet-by-user-id.hook';

const AdminEditProfileContainer: FC = () => {
  const { id = '' } = useParams<{ id: string }>();

  const { data: profile, status: profileStatus } = useFindProfile(id);

  const { data: sections, status: sectionsStatus } = useFindSections({
    take: 1000,
  });

  const userId = profile?.user.id ?? '';

  const { data: accessOrder, status: accessOrderStatus } =
    useFindAccessOrderByUserId(userId, { enabled: !!profile });

  const { data: assembly, status: assemblyStatus } = useFindAssemblyByUserId(
    userId,
    { enabled: !!profile },
  );

  const { data: booking, status: bookingStatus } = useFindBookingByUserId(
    profile?.user.id ?? '',
    {
      enabled: !!profile,
    },
  );

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindDetailedSheetByUserId(userId, { enabled: !!profile });

  if (
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle' ||
    bookingStatus === 'loading' ||
    bookingStatus === 'idle' ||
    detailedSheetStatus === 'loading' ||
    detailedSheetStatus === 'idle' ||
    profileStatus === 'loading' ||
    profileStatus === 'idle' ||
    sectionsStatus === 'loading' ||
    sectionsStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (
    accessOrderStatus === 'error' ||
    assemblyStatus === 'error' ||
    bookingStatus === 'error' ||
    detailedSheetStatus === 'error' ||
    profileStatus === 'error' ||
    sectionsStatus === 'error'
  ) {
    return <Error />;
  }

  return (
    <Outlet
      context={{
        accessOrder,
        assembly,
        booking,
        detailedSheet,
        profile,
        sections,
      }}
    />
  );
};

export default AdminEditProfileContainer;
