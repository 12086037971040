import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindUserAccessOrder from '../../../hooks/access-orders/find-user-access-order.hook';
import useFindUserProfile from '../../../hooks/profiles/find-user-profile.hook';

const AccessOrderContainer: FC = () => {
  const { data: profile, status: profileStatus } = useFindUserProfile();

  const { data: accessOrder, status: accessOrderStatus } =
    useFindUserAccessOrder();

  if (
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle' ||
    profileStatus === 'loading' ||
    profileStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (accessOrderStatus === 'error' || profileStatus === 'error') {
    return <Error />;
  }

  return <Outlet context={{ accessOrder, profile }} />;
};

export default AccessOrderContainer;
