import RoleCodesEnum from '../enums/roles/role-codes.enum';
import RefreshTokenSubscriberInterface from './interfaces/refresh-token-subscriber.interface';
import RefreshTokenService from '../services/auth/refresh-token.service';
import GetJwtPayloadService from '../services/jwt/get-jwt-payload.service';

const getJwtPayloadService = new GetJwtPayloadService();

const INITIAL_STATE = {
  refreshing: false,
  role: undefined as RoleCodesEnum | undefined,
  token: '',
};

class AuthState {
  private state = { ...INITIAL_STATE };

  queue: RefreshTokenSubscriberInterface[] = [];

  get refreshing(): boolean {
    return this.state.refreshing;
  }

  get role(): RoleCodesEnum | undefined {
    return this.state.role;
  }

  get token(): string {
    return this.state.token;
  }

  clear(): void {
    this.state = { ...INITIAL_STATE };
  }

  async refresh(): Promise<void> {
    const { token } = await new RefreshTokenService().refresh();

    for (const subscriber of this.queue) subscriber(token);

    this.queue = [];
  }

  setRefreshing(value: boolean): void {
    this.state.refreshing = value;
  }

  setToken(value: string): void {
    const role = value ? getJwtPayloadService.get(value).role : undefined;

    this.state.role = role;
    this.state.token = value;
  }
}

export const authState = new AuthState();
