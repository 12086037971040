import Box from '@mui/material/Box';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import ExhibitorDashboardAccessOrderCard from '../../../dashboard/ExhibitorDashboardAccessOrderCard';
import ExhibitorDashboardAssemblyCard from '../../../dashboard/ExhibitorDashboardAssemblyCard';
import ExhibitorDashboardBookingCard from '../../../dashboard/ExhibitorDashboardBookingCard';
import ExhibitorDashboardDetailedSheetCard from '../../../dashboard/ExhibitorDashboardDetailedSheetCard';
import ProfileCancelledAlert from '../../../profiles/ProfileCancelledAlert';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import ProfileStatusesEnum from '../../../../enums/profiles/profile-statuses.enum';

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
  profile: ProfileDto;
}

const ExhibitorDashboardPage: FC = () => {
  const { accessOrder, assembly, detailedSheet, edition, profile } =
    useOutletContext<OutletContext>();

  if (profile.status === ProfileStatusesEnum.CANCELED) {
    return (
      <>
        <ProfileCancelledAlert showBackToDashboard={false} />

        <Box sx={{ mt: 4 }}>
          <ExhibitorDashboardBookingCard profile={profile} />
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ mx: { md: 10 }, mb: { md: 4 }, mt: { md: 4 } }}>
      <ExhibitorDashboardBookingCard profile={profile} />

      <ExhibitorDashboardDetailedSheetCard
        detailedSheet={detailedSheet}
        edition={edition}
      />

      <ExhibitorDashboardAssemblyCard assembly={assembly} edition={edition} />

      <ExhibitorDashboardAccessOrderCard
        accessOrder={accessOrder}
        edition={edition}
      />
    </Box>
  );
};

export default ExhibitorDashboardPage;
