import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';

import EditionTabs from '../../../editions/EditionTabs';
import Error from '../../../Error';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import Loading from '../../../Loading';
import SaveActivationCodeDto from '../../../../dto/activation-codes/in/save-activation-code.dto';
import useFindActivationCodes from '../../../../hooks/activation-codes/find-activation-codes.hook';
import useSaveActivationCode from '../../../../hooks/activation-codes/save-activation-code.hook';
import { queryClient } from '../../../../utils/query-client';
import { toast } from '../../../../utils/toast';

const ActivationCodesPage: FC = () => {
  const { data: activationCodes, status } = useFindActivationCodes();
  const { mutateAsync: saveActivationCode } = useSaveActivationCode();

  const handleChange = useCallback(
    async (id: string, active: boolean) => {
      try {
        const dto = plainToClass(SaveActivationCodeDto, {
          active,
        });

        await saveActivationCode({ id, dto });

        await queryClient.invalidateQueries('activation-codes');

        const actionText = active ? 'activé' : 'désactivé';

        toast.success(`Code ${actionText} avec succès`);
      } catch (error) {
        toast.error();
      }
    },
    [saveActivationCode],
  );

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;

  return (
    <>
      <SectionHeader>
        <Typography variant="h2">Édition</Typography>
      </SectionHeader>

      <EditionTabs value="activationCodes" />

      <Section>
        <FormStack>
          <FormGrid>
            <Grid xs={12} item>
              <Typography variant="overline" component="h2">
                Codes d&apos;activation
              </Typography>
            </Grid>

            {activationCodes.map(({ id, active, code }, index) => (
              <Grid xs={12} item key={index}>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={active}
                      onChange={(_, checked): Promise<void> =>
                        handleChange(id, checked)
                      }
                    />
                  }
                  label={code}
                />
              </Grid>
            ))}
          </FormGrid>
        </FormStack>
      </Section>
    </>
  );
};

export default ActivationCodesPage;
