import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import ProfilesDataTableOptionsDto from '../../dto/profiles/in/profiles-data-table-options.dto';
import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import { apiV1 } from '../../http';

class FindProfilesService {
  async find(
    dto: ProfilesDataTableOptionsDto,
  ): Promise<PaginatedResultDto<ProfilesDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<ProfilesDto>>(
      '/profiles',
      { params: dto },
    );

    return data;
  }
}

export default FindProfilesService;
