import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Modal from '../Modal';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import SaveIncludedAccreditationQuantityDto from '../../dto/access-orders/in/save-included-accreditation-quantity.dto';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import useFindAccessOrderByUserId from '../../hooks/access-orders/find-access-order-by-user-id.hook';
import useSaveIncludedAccreditationQuantity from '../../hooks/access-orders/save-included-accreditation-quantity.hook';
import { toast } from '../../utils/toast';
import { onInvalidSubmit, validateRequired } from '../../utils/validations';

interface Props {
  accessOrder: AccessOrderDto;
  opened: boolean;
  profile: ProfileDto;
  onClose: () => void;
}

interface AdminIncludedAccreditationQuantityFieldValues {
  includedAccreditationQuantity: number;
}

const AdminIncludedAccreditationQuantityModal: FC<Props> = ({
  accessOrder,
  opened,
  profile,
  onClose,
}) => {
  const { mutateAsync: saveIncludedAccreditationQuantity } =
    useSaveIncludedAccreditationQuantity();

  const { refetch } = useFindAccessOrderByUserId(profile.user.id);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<AdminIncludedAccreditationQuantityFieldValues>({
    defaultValues: {
      includedAccreditationQuantity: accessOrder.includedAccreditationQuantity,
    },
  });

  const onSubmit: SubmitHandler<AdminIncludedAccreditationQuantityFieldValues> =
    useCallback(
      async (fieldValues) => {
        try {
          const dto = plainToClass(SaveIncludedAccreditationQuantityDto, {
            includedAccreditationQuantity:
              fieldValues.includedAccreditationQuantity,
          });

          await saveIncludedAccreditationQuantity({ id: accessOrder.id, dto });

          await refetch();

          onClose();

          toast.success("Nombre d'accréditations incluses modifié avec succès");
        } catch (error) {
          toast.error();
        }
      },
      [accessOrder, onClose, refetch, saveIncludedAccreditationQuantity],
    );

  return (
    <Modal open={opened} onClose={onClose}>
      <form>
        <FormStack>
          <FormGrid>
            <Grid item xs={12}>
              <Typography variant="overline" component="h2">
                Modifier
              </Typography>
            </Grid>

            <Grid item xs={12} mb={2}>
              <TextField
                control={control}
                name="includedAccreditationQuantity"
                label="Nombre d'accréditations incluses"
                rules={validateRequired()}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} textAlign="right">
              <Button sx={{ mr: 4 }} onClick={onClose}>
                Annuler
              </Button>

              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{ float: 'right' }}
                startIcon={isSubmitting && <ButtonLoadingIcon />}
                onClick={handleSubmit(onSubmit, onInvalidSubmit)}
              >
                Enregistrer
              </Button>
            </Grid>
          </FormGrid>
        </FormStack>
      </form>
    </Modal>
  );
};

export default AdminIncludedAccreditationQuantityModal;
