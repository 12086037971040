import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import LocationDto from '../../dto/locations/out/location.dto';
import LocationsDataTableOptionsDto from '../../dto/locations/in/locations-data-table-options.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import FindLocationsService from '../../services/locations/find-locations.service';

const useFindLocations = (
  dto: LocationsDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<LocationDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<LocationDto>, Error> => {
  return useQuery(
    ['locations', dto],
    () => new FindLocationsService().find(dto),
    options,
  );
};

export default useFindLocations;
