import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class UnapproveDetailedSheetService {
  async unapprove(id: string): Promise<void> {
    try {
      await apiV1.put<void>(`/detailed-sheets/${id}/unapprove`);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default UnapproveDetailedSheetService;
