import { Components, Theme } from '@mui/material';

const paper = (theme: Theme): Components<Theme> => {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 6,
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderRadius: theme.spacing(2),
          boxShadow: theme.customShadows?.z8
        },
      },
    },
  };
};

export default paper;
