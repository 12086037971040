import DatePeriodsEnum from '../../../enums/date-periods.enum';
import DoorTypesEnum from '../../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../../enums/assemblies/handling-equipments.enum';

class SaveUserAssemblyDto {
  firstName!: string;
  lastName!: string;
  email!: string;
  phoneNumber!: string;
  deliveryDatePreference!: string;
  deliveryDatePeriodPreference!: DatePeriodsEnum;
  handlingEquipment!: HandlingEquipmentsEnum;
  doorType!: DoorTypesEnum;
  needsNotes?: string;
  disassemblyDate!: string;
}

export default SaveUserAssemblyDto;
