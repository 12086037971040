import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PATHS } from '../../../utils/paths';
import { roleIsAdmin } from '../../../utils/roles';

const AdminWorkflow: FC = () => {
  const isAdmin = roleIsAdmin();

  if (!isAdmin) return <Navigate to={PATHS.exhibitors.path} replace />;

  return <Outlet />;
};

export default AdminWorkflow;
