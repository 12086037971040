import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Error from '../../../Error';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import Loading from '../../../Loading';
import SaveLocationForm from '../../../locations/SaveLocationForm';
import NoData from '../../../NoData';
import SaveLocationDto from '../../../../dto/locations/in/save-location.dto';
import useFindAreas from '../../../../hooks/areas/find-areas.hook';
import useFindLocation from '../../../../hooks/locations/find-location.hook';
import useSaveLocation from '../../../../hooks/locations/save-location.hook';
import useFindSections from '../../../../hooks/sections/find-sections.hook';
import LocationFieldValuesInterface from '../../../../interfaces/locations/location-field-values.interface';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const EditLocationPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { mutateAsync: saveLocation } = useSaveLocation();

  const { data: location, status: statusLocation } = useFindLocation(id ?? '', {
    enabled: !!id,
  });

  const { data: areas, status: statusAreas } = useFindAreas();

  const { data: sections, status: statusSections } = useFindSections({
    take: 1000,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<LocationFieldValuesInterface>({
    defaultValues: {
      active: false,
      name: '',
      areaId: '',
      sectionId: '',
    },
  });

  useEffect(() => {
    if (location) {
      reset({
        active: location.active,
        name: location.name,
        areaId: location.area?.id ?? '',
        sectionId: location.section?.id ?? '',
      });
    }
  }, [location, reset]);

  const onSubmit: SubmitHandler<LocationFieldValuesInterface> = useCallback(
    async (fieldValues) => {
      try {
        const dto = plainToClass(SaveLocationDto, {
          id: location?.id,
          name: fieldValues.name,
          active: fieldValues.active,
          areaId: fieldValues.areaId,
          sectionId: fieldValues.sectionId,
        });

        await saveLocation(dto);

        toast.success('Enregistré avec succès');
      } catch (error) {
        toast.error();
      }
    },
    [location, saveLocation],
  );

  if ([statusAreas, statusLocation, statusSections].includes('loading')) {
    return <Loading />;
  }

  if (
    statusAreas !== 'success' ||
    statusLocation !== 'success' ||
    statusSections !== 'success'
  ) {
    return <Error />;
  }

  if (!location) return <NoData />;

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <SectionHeader>
        <Typography variant="h1">Emplacement #{location.name}</Typography>
      </SectionHeader>

      <Section>
        {location?.bookingLocations.length && (
          <Alert severity="warning" sx={{ mb: 4 }}>
            <AlertTitle>Emplacement assigné</AlertTitle>
            Attention, cet emplacement est assigné.
            <br /> Des modifications pourraient affecter les réservations.
          </Alert>
        )}

        <SaveLocationForm
          control={control}
          areas={areas}
          location={location}
          sections={sections}
        />
      </Section>

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        sx={{ float: 'right' }}
        startIcon={isSubmitting && <ButtonLoadingIcon />}
      >
        Enregistrer l&apos;emplacement
      </Button>
    </form>
  );
};

export default EditLocationPage;
