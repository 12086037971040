import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import NotificationRecipientsTable from './NotificationRecipientsTable';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import NotificationRecipientsDto from '../../dto/notifications/out/notification-recipients.dto';
import useSendNotification from '../../hooks/notifications/send-notification.hook';
import useSendNotificationPreview from '../../hooks/notifications/send-notification-preview.hook';
import NotificationFieldValuesInterface from '../../interfaces/notifications/notification-field-values.interface';
import MapSendNotificationFormToDtoService from '../../services/notifications/map-send-notification-form-to-dto.service';
import { toast } from '../../utils/toast';
import theme from '../../styles/theme/index';

const mapSendNotificationFormToDtoService =
  new MapSendNotificationFormToDtoService();

interface Props {
  notificationRecipients: NotificationRecipientsDto[];
  onClose: () => void;
}

const NotificationModalConfirmationContent: FC<Props> = ({
  notificationRecipients,
  onClose,
}) => {
  const [sending, toggleSending] = useToggle(false);
  const [sendingPreview, toggleSendingPreview] = useToggle(false);
  const { getValues } = useFormContext<NotificationFieldValuesInterface>();
  const { mutateAsync: sendNotification } = useSendNotification();
  const { mutateAsync: sendNotificationPreview } = useSendNotificationPreview();

  const handleSendNotification = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = mapSendNotificationFormToDtoService.map(fieldValues);

      toggleSending(true);

      await sendNotification(dto);

      toast.success('Notification envoyée avec succès');
    } catch (error) {
      toast.error();
    } finally {
      onClose();

      toggleSending(false);
    }
  }, [getValues, onClose, sendNotification, toggleSending]);

  const handleSendNotificationPreview = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = mapSendNotificationFormToDtoService.map(fieldValues);

      toggleSendingPreview(true);

      await sendNotificationPreview(dto);

      toast.success('Notification envoyée avec succès');
    } catch (error) {
      toast.error();
    } finally {
      onClose();

      toggleSendingPreview(false);
    }
  }, [getValues, onClose, sendNotificationPreview, toggleSendingPreview]);

  const renderContent = useMemo(() => {
    if (!notificationRecipients.length) {
      return (
        <>
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Aucun exposant à notifier</AlertTitle>
              Veuillez modifier les filtres sélectionnés.
            </Alert>
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button variant="contained" onClick={onClose}>
              Fermer
            </Button>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12} mb={2}>
          <Typography>
            Êtes-vous certain de vouloir notifier{' '}
            <strong>{notificationRecipients.length}</strong> exposant(s) ?
          </Typography>
        </Grid>

        <Box
          sx={{
            height: '200px',
            width: '100%',
            overflow: 'scroll',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
        >
          <NotificationRecipientsTable
            notificationRecipients={notificationRecipients}
          />
        </Box>

        <Grid item xs={12} textAlign="right">
          <Button sx={{ mr: 4 }} onClick={onClose}>
            Annuler
          </Button>

          <Button
            variant="outlined"
            disabled={sendingPreview}
            startIcon={sendingPreview && <ButtonLoadingIcon />}
            sx={{ mr: 4 }}
            onClick={handleSendNotificationPreview}
          >
            Envoyer un test
          </Button>

          <Button
            variant="contained"
            disabled={sending}
            startIcon={sending && <ButtonLoadingIcon />}
            onClick={handleSendNotification}
          >
            Oui, continuer
          </Button>
        </Grid>
      </>
    );
  }, [
    notificationRecipients,
    sending,
    sendingPreview,
    handleSendNotification,
    handleSendNotificationPreview,
    onClose,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="overline" component="h2">
          Envoi de la notification
        </Typography>
      </Grid>

      {renderContent}
    </Grid>
  );
};

export default NotificationModalConfirmationContent;
