import 'react-toastify/dist/ReactToastify.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import LoadingScreen from './LoadingScreen';
import AuthLayout from './layouts/AuthLayout';
import Layout from './layouts/Layout';
import AccessOrderContainer from './containers/access-orders/AccessOrderContainer';
import AdminEditAccessOrderContainer from './containers/access-orders/AdminEditAccessOrderContainer';
import AdminEditAssemblyContainer from './containers/assemblies/AdminEditAssemblyContainer';
import EditAccessOrderContainer from './containers/access-orders/EditAccessOrderContainer';
import AssemblyContainer from './containers/assemblies/AssemblyContainer';
import EditAssemblyContainer from './containers/assemblies/EditAssemblyContainer';
import AdminCreateBookingContainer from './containers/bookings/AdminCreateBookingContainer';
import AdminEditBookingContainer from './containers/bookings/AdminEditBookingContainer';
import ExhibitorDashboardContainer from './containers/dashboard/ExhibitorDashboardContainer';
import AdminEditDetailedSheetPage from './pages/admin/detailed-sheets/AdminEditDetailedSheetPage';
import DetailedSheetContainer from './containers/detailed-sheets/DetailedSheetContainer';
import EditDetailedSheetContainer from './containers/detailed-sheets/EditDetailedSheetContainer';
import StagesContainer from './containers/editions/StagesContainer';
import AdminEditProfileContainer from './containers/profiles/AdminEditProfileContainer';
import AdminProfileContractContainer from './containers/profiles/AdminProfileContractContainer';
import AdminProfilePaymentContainer from './containers/profiles/AdminProfilePaymentContainer';
import AdminAssembliesListPage from './pages/admin/assemblies/AdminAssembliesListPage';
import AdminCreateBookingPage from './pages/admin/bookings/AdminCreateBookingPage';
import AdminEditBookingPage from './pages/admin/bookings/AdminEditBookingPage';
import LocationsListPage from './pages/admin/locations/LocationsListPage';
import ForgottenPasswordPage from './pages/auth/ForgottenPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import SignInPage from './pages/auth/SignInPage';
import SignUpPage from './pages/auth/SignUpPage';
import ActivationCodesPage from './pages/admin/activation-codes/ActivationCodesPage';
import AdminEditAssemblyPage from './pages/admin/assemblies/AdminEditAssemblyPage';
import AdminDetailedSheetsListPage from './pages/admin/detailed-sheets/AdminDetailedSheetsListPage';
import AdminEditDetailedSheetContainer from './containers/detailed-sheets/AdminEditDetailedSheetContainer';
import FilesPage from './pages/admin/files/FilesPage';
import CreateLocationPage from './pages/admin/locations/CreateLocationPage';
import EditLocationPage from './pages/admin/locations/EditLocationPage';
import AdminNotificationPage from './pages/admin/notifications/AdminNotificationPage';
import AdminEditProfilePage from './pages/admin/profiles/AdminEditProfilePage';
import AdminProfileContractPage from './pages/admin/profiles/AdminProfileContractPage';
import AdminProfilesListPage from './pages/admin/profiles/AdminProfilesListPage';
import AdminProfilePaymentPage from './pages/admin/profiles/AdminProfilePaymentPage';
import ReportsPage from './pages/admin/reports/ReportsPage';
import AccessOrderPage from './pages/exhibitors/access-orders/AccessOrderPage';
import AdminAccessOrdersListPage from './pages/admin/access-orders/AdminAccessOrdersListPage';
import AdminEditAccessOrderPage from './pages/admin/access-orders/AdminEditAccessOrderPage';
import StageCodesPage from './pages/admin/stages/StagesPage';
import EditAccessOrderPage from './pages/exhibitors/access-orders/EditAccessOrderPage';
import AssemblyPage from './pages/exhibitors/assemblies/AssemblyPage';
import EditAssemblyPage from './pages/exhibitors/assemblies/EditAssemblyPage';
import ExhibitorDashboardPage from './pages/exhibitors/dashboard/ExhibitorDashboardPage';
import BookingPreferenceConfirmationPage from './pages/exhibitors/booking-preferences/BookingPreferenceConfirmationPage';
import CreateBookingPreferencePage from './pages/exhibitors/booking-preferences/CreateBookingPreferencePage';
import EditBookingPreferencePage from './pages/exhibitors/booking-preferences/EditBookingPreferencePage';
import NoActiveEditionPage from './pages/exhibitors/editions/NoActiveEditionPage';
import DetailedSheetPage from './pages/exhibitors/detailed-sheets/DetailedSheetPage';
import EditDetailedSheetPage from './pages/exhibitors/detailed-sheets/EditDetailedSheetPage';
import CreateProfilePage from './pages/exhibitors/profiles/CreateProfilePage';
import EditProfilePage from './pages/exhibitors/profiles/EditProfilePage';
import ProfilePage from './pages/exhibitors/profiles/ProfilePage';
import SetProfileTermsAndConditionsPage from './pages/exhibitors/profiles/SetProfileTermsAndConditionsPage';
import CreateUserActivationCodePage from './pages/exhibitors/users/CreateUserActivationCodePage';
import AuthRoute from './routes/AuthRoute';
import PrivateRoute from './routes/PrivateRoute';
import theme from '../styles/theme';
import AppWorkflow from './workflows/AppWorkflow';
import AdminWorkflow from './workflows/admin/AdminWorkflow';
import AccessOrderWorkflow from './workflows/exhibitors/access-orders/AccessOrdersWorkflow';
import EditAccessOrderWorkflow from './workflows/exhibitors/access-orders/EditAccessOrdersWorkflow';
import AssemblyWorkflow from './workflows/exhibitors/assemblies/AssemblyWorkflow';
import EditAssemblyWorkflow from './workflows/exhibitors/assemblies/EditAssemblyWorkflow';
import AdminCreateBookingWorkflow from './workflows/admin/bookings/AdminCreateBookingWorkflow';
import AdminEditBookingWorkflow from './workflows/admin/bookings/AdminEditBookingWorkflow';
import ExhibitorsWorkflow from './workflows/exhibitors/ExhibitorsWorkflow';
import RegisteredWorkflow from './workflows/exhibitors/RegisteredWorkflow';
import SaveBookingPreferenceWorkflow from './workflows/exhibitors/booking-preferences/SaveBookingPreferenceWorkflow';
import DetailedSheetWorkflow from './workflows/exhibitors/detailed-sheets/DetailedSheetWorkflow';
import EditDetailedSheetWorkflow from './workflows/exhibitors/detailed-sheets/EditDetailedSheetWorkflow';
import SaveProfileWorkflow from './workflows/exhibitors/profiles/SaveProfileWorkflow';
import { queryClient } from '../utils/query-client';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />

        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LoadingScreen>
              <Routes>
                <Route element={<AuthRoute />}>
                  <Route element={<AuthLayout />}>
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/sign-in" element={<SignInPage />} />
                    <Route
                      path="/forgotten-password"
                      element={<ForgottenPasswordPage />}
                    />
                    <Route
                      path="/reset-password/:id"
                      element={<ResetPasswordPage />}
                    />
                  </Route>
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route element={<AdminWorkflow />}>
                    <Route path="/admin" element={<Layout />}>
                      <Route path="files" element={<FilesPage />} />
                      <Route
                        path="activation-codes"
                        element={<ActivationCodesPage />}
                      />
                      <Route element={<StagesContainer />}>
                        <Route path="stages" element={<StageCodesPage />} />
                      </Route>
                      <Route path="locations" element={<LocationsListPage />} />
                      <Route
                        path="locations/new"
                        element={<CreateLocationPage />}
                      />
                      <Route
                        path="locations/:id/edit"
                        element={<EditLocationPage />}
                      />
                      <Route
                        path="profiles"
                        element={<AdminProfilesListPage />}
                      />
                      <Route
                        path="access-orders"
                        element={<AdminAccessOrdersListPage />}
                      />
                      <Route
                        path="assemblies"
                        element={<AdminAssembliesListPage />}
                      />
                      <Route
                        path="detailed-sheets"
                        element={<AdminDetailedSheetsListPage />}
                      />
                      <Route element={<AdminEditProfileContainer />}>
                        <Route
                          path="profiles/:id/edit"
                          element={<AdminEditProfilePage />}
                        />
                      </Route>
                      <Route element={<AdminProfilePaymentContainer />}>
                        <Route
                          path="profiles/:id/payment"
                          element={<AdminProfilePaymentPage />}
                        />
                      </Route>
                      <Route element={<AdminProfileContractContainer />}>
                        <Route
                          path="profiles/:id/contract"
                          element={<AdminProfileContractPage />}
                        />
                      </Route>
                      <Route element={<AdminEditAccessOrderContainer />}>
                        <Route
                          path="profiles/:id/access-orders/edit"
                          element={<AdminEditAccessOrderPage />}
                        />
                      </Route>
                      <Route element={<AdminEditAssemblyContainer />}>
                        <Route
                          path="profiles/:id/assemblies/edit"
                          element={<AdminEditAssemblyPage />}
                        />
                      </Route>
                      <Route element={<AdminEditDetailedSheetContainer />}>
                        <Route
                          path="profiles/:id/detailed-sheets/edit"
                          element={<AdminEditDetailedSheetPage />}
                        />
                      </Route>
                      <Route element={<AdminCreateBookingWorkflow />}>
                        <Route element={<AdminCreateBookingContainer />}>
                          <Route
                            path="profiles/:id/bookings/new"
                            element={<AdminCreateBookingPage />}
                          />
                        </Route>
                      </Route>
                      <Route element={<AdminEditBookingWorkflow />}>
                        <Route element={<AdminEditBookingContainer />}>
                          <Route
                            path="profiles/:id/bookings/edit"
                            element={<AdminEditBookingPage />}
                          />
                        </Route>
                      </Route>
                      <Route path="reports" element={<ReportsPage />} />
                      <Route
                        path="notifications"
                        element={<AdminNotificationPage />}
                      />
                      <Route
                        element={<Navigate to="/admin/profiles" replace />}
                        index
                      />
                    </Route>
                  </Route>

                  <Route element={<ExhibitorsWorkflow />}>
                    <Route path="/exhibitors" element={<Layout />}>
                      <Route element={<RegisteredWorkflow />}>
                        <Route element={<ExhibitorDashboardContainer />}>
                          <Route index element={<ExhibitorDashboardPage />} />
                        </Route>

                        <Route path="profile" element={<ProfilePage />} />

                        <Route element={<EditDetailedSheetWorkflow />}>
                          <Route element={<EditDetailedSheetContainer />}>
                            <Route
                              path="detailed-sheets/edit"
                              element={<EditDetailedSheetPage />}
                            />
                          </Route>
                        </Route>

                        <Route element={<DetailedSheetWorkflow />}>
                          <Route element={<DetailedSheetContainer />}>
                            <Route
                              path="detailed-sheet"
                              element={<DetailedSheetPage />}
                            />
                          </Route>
                        </Route>

                        <Route element={<EditAssemblyWorkflow />}>
                          <Route element={<EditAssemblyContainer />}>
                            <Route
                              path="assemblies/edit"
                              element={<EditAssemblyPage />}
                            />
                          </Route>
                        </Route>

                        <Route element={<AssemblyWorkflow />}>
                          <Route element={<AssemblyContainer />}>
                            <Route path="assembly" element={<AssemblyPage />} />
                          </Route>
                        </Route>

                        <Route element={<EditAccessOrderWorkflow />}>
                          <Route element={<EditAccessOrderContainer />}>
                            <Route
                              path="access-orders/edit"
                              element={<EditAccessOrderPage />}
                            />
                          </Route>
                        </Route>

                        <Route element={<AccessOrderWorkflow />}>
                          <Route element={<AccessOrderContainer />}>
                            <Route
                              path="access-order"
                              element={<AccessOrderPage />}
                            />
                          </Route>
                        </Route>
                      </Route>

                      <Route
                        path="users/activation-codes"
                        element={<CreateUserActivationCodePage />}
                      />
                      <Route element={<SaveProfileWorkflow />}>
                        <Route
                          path="profiles/new"
                          element={<CreateProfilePage />}
                        />
                        <Route
                          path="profiles/edit"
                          element={<EditProfilePage />}
                        />
                      </Route>
                      <Route element={<SaveBookingPreferenceWorkflow />}>
                        <Route
                          path="booking-preferences/new"
                          element={<CreateBookingPreferencePage />}
                        />
                        <Route
                          path="booking-preferences/edit"
                          element={<EditBookingPreferencePage />}
                        />
                      </Route>
                      <Route
                        path="booking-preferences/confirmation"
                        element={<BookingPreferenceConfirmationPage />}
                      />
                      <Route
                        path="profiles/terms-and-conditions"
                        element={<SetProfileTermsAndConditionsPage />}
                      />
                      <Route
                        path="no-active-edition"
                        element={<NoActiveEditionPage />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="*" element={<AppWorkflow />} />
              </Routes>
              <ToastContainer />
            </LoadingScreen>
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
