import { useMutation, UseMutationResult } from 'react-query';

import SaveProfilePaymentDto from '../../dto/profiles/in/save-profile-payment.dto';
import SaveProfilePaymentService from '../../services/profiles/save-profile-payment.service';

const useSaveProfilePayment = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveProfilePaymentDto }
> => {
  return useMutation((variables) =>
    new SaveProfilePaymentService().save(variables.id, variables.dto),
  );
};

export default useSaveProfilePayment;
