import Grid from '@mui/material/Grid';
import { FC, useMemo } from 'react';
import { Control } from 'react-hook-form';
import AreaDto from '../../dto/areas/area.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import LocationDetailedDto from '../../dto/locations/out/location-detailed.dto';
import SectionDto from '../../dto/sections/section.dto';
import LocationFieldValuesInterface from '../../interfaces/locations/location-field-values.interface';
import { validateRequired } from '../../utils/validations';
import Checkbox from '../forms/Checkbox';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Select from '../forms/Select';
import TextField from '../forms/TextField';

interface Props {
  areas: PaginatedResultDto<AreaDto>;
  control: Control<LocationFieldValuesInterface>;
  location?: LocationDetailedDto;
  sections: PaginatedResultDto<SectionDto>;
}

const SaveLocationForm: FC<Props> = ({
  areas,
  control,
  location,
  sections,
}) => {
  const booked = useMemo(() => !!location?.bookingLocations.length, [location]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12} md={4}>
          <TextField
            control={control}
            disabled={booked}
            label="Numéro"
            name="name"
            rules={validateRequired()}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

        <Grid item xs={12} md={4}>
          <Select
            control={control}
            label="Superficie souhaitée"
            name="areaId"
            options={areas.nodes.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            rules={validateRequired()}
            fullWidth
          />
        </Grid>
        <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

        <Grid item xs={12} md={4}>
          <Select
            control={control}
            label="Secteur"
            name="sectionId"
            options={sections.nodes.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            rules={validateRequired()}
            fullWidth
          />
        </Grid>
        <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

        <Grid item xs={12} md={6}>
          <Checkbox
            control={control}
            disabled={booked}
            name="active"
            label="Actif"
            sx={{ pt: 4 }}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default SaveLocationForm;
