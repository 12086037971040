import { useMutation, UseMutationResult } from 'react-query';

import SaveActivationCodeDto from '../../dto/activation-codes/in/save-activation-code.dto';
import SaveActivationCode from '../../services/activation-codes/save-activation-code.service';

const useSaveActivationCode = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveActivationCodeDto }
> => {
  return useMutation((variables) =>
    new SaveActivationCode().save(variables.id, variables.dto),
  );
};

export default useSaveActivationCode;
