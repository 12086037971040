import Chip, { ChipProps } from '@mui/material/Chip';
import { FC, useMemo } from 'react';

import AssembliesDto from '../../dto/assemblies/out/assemblies.dto';
import AssemblyStatusesEnum from '../../enums/assemblies/assembly-statuses.enum';

const { CANCELED, CONFIRMED, INCOMPLETE, PROCESSING } = AssemblyStatusesEnum;

interface Props {
  assembly: AssembliesDto;
}

const AssembliesStatusChip: FC<Props> = ({ assembly }) => {
  const { status } = assembly;

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case CONFIRMED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  return (
    <Chip
      {...props}
      sx={{
        height: '16px',
        width: '16px',
      }}
    />
  );
};

export default AssembliesStatusChip;
