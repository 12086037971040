import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import Section from '../layouts/Section';
import UserInfoDto from '../../dto/users/user-info.dto';

interface Props {
  profile: WithRequiredKeys<UserInfoDto, 'profile'>['profile'];
}

const ManagersSection: FC<Props> = ({ profile }) => {
  const renderManagers = useMemo(() => {
    return profile.managers.map(
      (
        {
          email,
          firstName,
          lastName,
          phoneNumber1,
          phoneNumber1Ext,
          phoneNumber2,
          phoneNumber2Ext,
        },
        index,
      ) => (
        <Grid item xs={12} md={6} key={index}>
          <Typography variant="subtitle1">
            {firstName} {lastName}
          </Typography>

          <Typography>{email}</Typography>

          <Typography sx={{ mt: 1 }}>
            {phoneNumber1}
            {phoneNumber1Ext && <> Ext: {phoneNumber1Ext}</>}
          </Typography>

          <Typography>
            {phoneNumber2}
            {phoneNumber2Ext && <> Ext: {phoneNumber2Ext}</>}
          </Typography>
        </Grid>
      ),
    );
  }, [profile]);

  return (
    <Section>
      <Typography variant="overline" component="h2">
        Responsable(s) du Salon
      </Typography>

      <Grid container rowSpacing={3} sx={{ mt: 0 }}>
        {renderManagers}
      </Grid>
    </Section>
  );
};

export default ManagersSection;
