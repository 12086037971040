import { useCallback } from 'react';

import SortDirectionEnum from '../../enums/data-tables/sorting/sort-directions.enum';
import SortingHookInterface from '../../interfaces/data-tables/sorting/sorting-hook.interface';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import GetNextSortDirectionService from '../../services/data-tables/sorting/get-next-sort-direction.service';
import GetSortDirectionService from '../../services/data-tables/sorting/get-sort-direction.service';

const useSorting = (): SortingHookInterface => {
  const { dataTableOptions, setDataTableOptions } = useDataTable();

  const { sortColumn = 'id', sortDirection = SortDirectionEnum.ASC } =
    dataTableOptions;

  const isActive = useCallback(
    (column: string): boolean => {
      return column === sortColumn;
    },
    [sortColumn],
  );

  const getDirection = useCallback(
    (column: string) => {
      const active = isActive(column);

      return GetSortDirectionService.get(sortDirection, active);
    },
    [isActive, sortDirection],
  );

  const setSort = useCallback(
    (column: string): void => {
      const active = isActive(column);

      const direction = GetNextSortDirectionService.get(sortDirection, active);

      setDataTableOptions({
        ...dataTableOptions,
        sortColumn: column,
        sortDirection: direction,
      });
    },
    [dataTableOptions, isActive, setDataTableOptions, sortDirection],
  );

  return { getDirection, isActive, setSort, sortColumn, sortDirection };
};

export default useSorting;
