import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { NextState } from '@react-hookz/web/cjs/util/resolveHookState';
import { FiSearch, FiSliders } from 'react-icons/fi';
import { FC } from 'react';

import useFilter from '../../hooks/data-tables/filters.hook';

interface Props {
  toggleFiltersModal: (
    nextState?: NextState<boolean, boolean> | undefined,
  ) => void;
}

const DetailedSheetsDataTableToolbar: FC<Props> = ({ toggleFiltersModal }) => {
  const { debouncedFilter, setFilter } = useFilter();

  return (
    <Toolbar
      sx={{
        justifyContent: 'flex-start',
        pt: 5,
        pb: 2,
      }}
    >
      <TextField
        placeholder="Filtrer les résultats"
        onChange={({ target }): void => setFilter(target.value)}
        value={debouncedFilter}
        sx={{ width: '100%', maxWidth: '400px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FiSearch />
            </InputAdornment>
          ),
        }}
      />

      <IconButton
        color="primary"
        onClick={(): void => toggleFiltersModal(true)}
        sx={{ ml: 1 }}
      >
        <FiSliders />
      </IconButton>
    </Toolbar>
  );
};

export default DetailedSheetsDataTableToolbar;
