import PaymentStatusesEnum from '../../enums/profiles/payment-statuses.enum';

const {
  INITIAL,
  INVOICE_PENDING,
  PAID,
  PAID_PARTIALLY,
  PAYMENT_PENDING,
  PARTNER,
} = PaymentStatusesEnum;

class GetPaymentStatusLabelService {
  get(paymentStatus: PaymentStatusesEnum): string {
    switch (paymentStatus) {
      default:
      case INITIAL: {
        return '';
      }
      case INVOICE_PENDING: {
        return 'À facturer';
      }
      case PAYMENT_PENDING: {
        return 'Facturé';
      }
      case PAID_PARTIALLY: {
        return 'Partiellement payé';
      }
      case PAID: {
        return 'Payé';
      }
      case PARTNER: {
        return 'Partenaire';
      }
    }
  }
}

export default GetPaymentStatusLabelService;
