import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindActiveEdition from '../../../hooks/editions/find-active-edition.hook';

const StagesContainer: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();

  if (editionStatus === 'loading' || editionStatus === 'idle') {
    return <Loading />;
  }

  if (editionStatus === 'error') {
    return <Error />;
  }

  return <Outlet context={{ edition }} />;
};

export default StagesContainer;
