import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import AssemblyConfirmationAlert from '../../../assemblies/AssemblyConfirmationAlert';
import AssemblyDeliveryPreferenceSection from '../../../assemblies/AssemblyDeliveryPreferenceSection';
import AssemblyDisassemblySection from '../../../assemblies/AssemblyDisassemblySection';
import AssemblyNeedsSection from '../../../assemblies/AssemblyNeedsSection';
import AssemblyOnSiteRepresentativeSection from '../../../assemblies/AssemblyOnSiteRepresentativeSection';
import AssemblyProcessingAlert from '../../../assemblies/AssemblyProcessingAlert';
import SectionHeader from '../../../layouts/SectionHeader';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import AssemblyStatusesEnum from '../../../../enums/assemblies/assembly-statuses.enum';

interface OutletContext {
  assembly: AssemblyDto;
}

const AssemblyPage: FC = () => {
  const { assembly } = useOutletContext<OutletContext>();

  const renderAlert = useMemo(() => {
    switch (assembly.status) {
      case AssemblyStatusesEnum.PROCESSING: {
        return <AssemblyProcessingAlert />;
      }
      case AssemblyStatusesEnum.CONFIRMED: {
        return <AssemblyConfirmationAlert assembly={assembly} />;
      }
      default: {
        return null;
      }
    }
  }, [assembly]);

  const renderSections = useMemo(() => {
    if (assembly.status !== AssemblyStatusesEnum.PROCESSING) return null;

    return (
      <>
        <AssemblyDeliveryPreferenceSection assembly={assembly} />
        <AssemblyNeedsSection assembly={assembly} />
        <AssemblyDisassemblySection assembly={assembly} />
      </>
    );
  }, [assembly]);

  return (
    <>
      <SectionHeader>
        <Typography variant="h1" gutterBottom>
          Montage et démontage
        </Typography>
      </SectionHeader>

      {renderAlert}

      <AssemblyOnSiteRepresentativeSection assembly={assembly} />

      {renderSections}
    </>
  );
};

export default AssemblyPage;
