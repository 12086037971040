import MuiCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import { ReactElement, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props {
  label?: string;
  disabled?: boolean;
}

const Checkbox = <T extends FieldValues>({
  label,
  disabled = false,
  ...props
}: UseControllerProps<T> & FormGroupProps & Props): ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const renderHelperText = useMemo(() => {
    if (!error) return null;

    return <FormHelperText>{error.message}</FormHelperText>;
  }, [error]);

  return (
    <FormControl error={!!error}>
      <FormGroup {...props}>
        <FormControlLabel
          control={<MuiCheckbox {...field} />}
          checked={`${field.value}` === 'true'}
          disabled={disabled}
          label={label}
        />
        {renderHelperText}
      </FormGroup>
    </FormControl>
  );
};

export default Checkbox;
