import Chip, { ChipProps } from '@mui/material/Chip';
import { FC, useMemo } from 'react';

import DetailedSheetsDto from '../../dto/detailed-sheets/out/detailed-sheets.dto';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';

const { CANCELED, APPROVED, INCOMPLETE, PROCESSING } =
  DetailedSheetStatusesEnum;

interface Props {
  detailedSheet: DetailedSheetsDto;
}

const DetailedSheetsStatusChip: FC<Props> = ({ detailedSheet }) => {
  const { status } = detailedSheet;

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case APPROVED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  return (
    <Chip
      {...props}
      sx={{
        height: '16px',
        width: '16px',
      }}
    />
  );
};

export default DetailedSheetsStatusChip;
