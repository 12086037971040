import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import { ContentState, EditorState } from 'draft-js';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import EditionTabs from '../../../editions/EditionTabs';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import NotificationModalConfirmation from '../../../notifications/NotificationModalConfirmation';
import SaveNotificationForm from '../../../notifications/SaveNotificationForm';
import StagesEnum from '../../../../enums/editions/stages.enum';
import NotificationFieldValuesInterface from '../../../../interfaces/notifications/notification-field-values.interface';
import { onInvalidSubmit } from '../../../../utils/validations';

const AdminNotificationPage: FC = () => {
  const [modalOpened, toggleModal] = useToggle(false);

  const formMethods = useForm<NotificationFieldValuesInterface>({
    defaultValues: {
      stage: StagesEnum.REGISTRATIONS,
      statuses: [],
      paymentStatus: '',
      subject: '',
      body: EditorState.createWithContent(ContentState.createFromText('')),
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = formMethods;

  const onSubmit: SubmitHandler<NotificationFieldValuesInterface> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <>
      <SectionHeader>
        <Typography variant="h2">Édition</Typography>
      </SectionHeader>

      <EditionTabs value="notifications" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Notifications
            </Typography>

            <SaveNotificationForm />
          </Section>

          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ float: 'right' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Notifier
          </Button>

          {modalOpened && (
            <NotificationModalConfirmation
              opened={modalOpened}
              onClose={(): void => toggleModal(false)}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default AdminNotificationPage;
