import { useMutation, UseMutationResult } from 'react-query';

import SaveAssemblyDto from '../../dto/assemblies/in/save-assembly.dto';
import SaveAssemblyService from '../../services/assemblies/save-assembly.service';

const useSaveAssembly = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveAssemblyDto }
> => {
  return useMutation((variables) =>
    new SaveAssemblyService().save(variables.id, variables.dto),
  );
};

export default useSaveAssembly;
