import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import AccessOrderStatusesEnum from '../../../../enums/access-orders/access-order-statuses.enum';
import useFindUserAccessOrder from '../../../../hooks/access-orders/find-user-access-order.hook';
import useFindActiveEdition from '../../../../hooks/editions/find-active-edition.hook';
import { PATHS } from '../../../../utils/paths';

const EditAccessOrderWorkflow: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();

  const { data: accessOrder, status: accessOrderStatus } =
    useFindUserAccessOrder();

  if (
    editionStatus === 'loading' ||
    editionStatus === 'idle' ||
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (editionStatus === 'error' || accessOrderStatus === 'error') {
    return <Error />;
  }

  if (
    !edition.accessOrdersOpened ||
    accessOrder.status === AccessOrderStatusesEnum.CANCELED
  ) {
    return <Navigate to={PATHS.exhibitors.path} replace />;
  }

  if (accessOrder.status !== AccessOrderStatusesEnum.INCOMPLETE) {
    return <Navigate to={PATHS.exhibitors.accessOrder.path} replace />;
  }

  return <Outlet />;
};

export default EditAccessOrderWorkflow;
