import { useMutation, UseMutationResult } from 'react-query';

import SetProfileTermsAndConditionsService from '../../services/profiles/set-profile-terms-and-conditions.service';

const useSetProfileTermsAndConditions = (): UseMutationResult<
  void,
  Error,
  void
> => {
  return useMutation(new SetProfileTermsAndConditionsService().set);
};

export default useSetProfileTermsAndConditions;
