import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class ApproveDetailedSheetService {
  async approve(id: string): Promise<void> {
    try {
      await apiV1.put<void>(`/detailed-sheets/${id}/approve`);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default ApproveDetailedSheetService;
