import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';

interface Props {
  assembly: AssemblyDto;
}

const AssemblyOnSiteRepresentativeSection: FC<Props> = ({ assembly }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Représentant sur le site
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              {assembly.firstName} {assembly.lastName}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel sx={{ mt: 0 }}>Courriel</ReadonlyFieldLabel>

            <Typography>{assembly.email}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel sx={{ mt: 0 }}>Téléphone</ReadonlyFieldLabel>

            <Typography>{assembly.phoneNumber}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AssemblyOnSiteRepresentativeSection;
