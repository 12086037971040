import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { ReactElement, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import OptionInterface from '../../interfaces/option.interface';

interface Props {
  label?: React.ReactNode;
  options: OptionInterface[];
}

const RadioButtons = <T extends FieldValues>({
  children,
  label,
  options,
  ...props
}: UseControllerProps<T> & RadioGroupProps & Props): ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const renderHelperText = useMemo(() => {
    if (!error) return null;

    return <FormHelperText>{error.message}</FormHelperText>;
  }, [error]);

  const renderFormControlLabel = useMemo(() => {
    return options.map(({ label, value }, index) => (
      <FormControlLabel
        key={index}
        control={<Radio />}
        label={label}
        value={value}
      />
    ));
  }, [options]);

  return (
    <FormControl error={!!error}>
      <FormLabel>{label}</FormLabel>

      <RadioGroup {...props} {...field}>
        {renderFormControlLabel}
      </RadioGroup>

      {renderHelperText}
    </FormControl>
  );
};

export default RadioButtons;
