import JwtTokenResponseDto from '../../dto/jwt/jwt-token-response.dto';
import { apiV1 } from '../../http';
import { authState } from '../../http/auth.state';

class RefreshTokenService {
  async refresh(): Promise<JwtTokenResponseDto> {
    authState.setRefreshing(true);

    const { data } = await apiV1.post<JwtTokenResponseDto>(
      '/auth/refresh-token',
    );

    authState.setToken(data.token);
    authState.setRefreshing(false);

    return data;
  }
}

export default RefreshTokenService;
