import { useMutation, UseMutationResult } from 'react-query';

import SaveUserDetailedSheetDto from '../../dto/detailed-sheets/in/save-user-detailed-sheet.dto';
import SaveUserDetailedSheetService from '../../services/detailed-sheets/save-user-detailed-sheet.service';

const useSaveUserDetailedSheet = (): UseMutationResult<
  void,
  Error,
  SaveUserDetailedSheetDto
> => {
  return useMutation(new SaveUserDetailedSheetService().save);
};

export default useSaveUserDetailedSheet;
