import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { FC } from 'react';

const Error: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Alert severity="error" sx={{ mt: 4, maxWidth: '500px', width: '100%' }}>
        <AlertTitle>Erreur</AlertTitle>
        Oups, quelque chose ne s&apos;est pas passé comme prévu. <br />
        Veuillez rafraîchir la page de votre navigateur et essayer de nouveau.
      </Alert>
    </Box>
  );
};

export default Error;
