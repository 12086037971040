import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderDeliveryPreferenceSection: FC<Props> = ({ accessOrder }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Accréditations
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Nom de l&apos;entreprise à afficher sur l&apos;accréditation
            </ReadonlyFieldLabel>

            <Typography>{accessOrder.companyName}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Nombre d&apos;accréditations
            </ReadonlyFieldLabel>

            <Typography>
              {accessOrder.includedAccreditationQuantity} sans frais
              <br />
              {accessOrder.additionalAccreditationQuantity} supplémentaire(s)
            </Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AccessOrderDeliveryPreferenceSection;
