import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import ProfileDto from '../../dto/profiles/out/profile.dto';
import FindUserMostRecentProfileService from '../../services/profiles/find-user-most-recent-profile.service';

const useFindUserMostRecentProfile = (
  options?: Omit<UseQueryOptions<ProfileDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<ProfileDto, Error> => {
  return useQuery(
    'profiles/me/most-recent',
    new FindUserMostRecentProfileService().find,
    options,
  );
};

export default useFindUserMostRecentProfile;
