import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindUserBooking from '../../../hooks/bookings/find-user-booking.hook';
import useFindUserDetailedSheet from '../../../hooks/detailed-sheets/find-user-detailed-sheet.hook';
import useFindUserProfile from '../../../hooks/profiles/find-user-profile.hook';

const DetailedSheetContainer: FC = () => {
  const { data: profile, status: profileStatus } = useFindUserProfile();
  const { data: booking, status: bookingStatus } = useFindUserBooking();

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindUserDetailedSheet();

  if (
    profileStatus === 'loading' ||
    bookingStatus === 'loading' ||
    detailedSheetStatus === 'loading'
  ) {
    return <Loading />;
  }

  if (
    profileStatus === 'error' ||
    bookingStatus === 'error' ||
    detailedSheetStatus === 'error'
  ) {
    return <Error />;
  }

  return <Outlet context={{ profile, booking, detailedSheet }} />;
};

export default DetailedSheetContainer;
