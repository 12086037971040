import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ExhibitorDashboardCard from './ExhibitorDashboardCard';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import AssemblyStatusesEnum from '../../enums/assemblies/assembly-statuses.enum';
import { PATHS } from '../../utils/paths';

const { CANCELED, CONFIRMED, INCOMPLETE, PROCESSING } = AssemblyStatusesEnum;

interface Props {
  assembly: AssemblyDto;
  edition: EditionDto;
}

const ExhibitorDashboardAssemblyCard: FC<Props> = ({ assembly, edition }) => {
  const navigate = useNavigate();

  const assemblyStatus = useMemo(
    () => assembly?.status ?? INCOMPLETE,
    [assembly],
  );

  const chipColor = useMemo(() => {
    if (!edition.assembliesOpened) return 'default';

    switch (assemblyStatus) {
      case CANCELED: {
        return 'default';
      }
      case INCOMPLETE: {
        return 'error';
      }
      case PROCESSING: {
        return 'warning';
      }
      case CONFIRMED: {
        return 'success';
      }
    }
  }, [assemblyStatus, edition]);

  const chipLabel = useMemo(() => {
    if (!edition.assembliesOpened) return 'Disponible prochainement';

    switch (assemblyStatus) {
      default:
      case INCOMPLETE: {
        return 'Incomplet';
      }
      case PROCESSING: {
        return 'En traitement';
      }
      case CONFIRMED: {
        return 'Réservé';
      }
      case CANCELED: {
        return 'Annulé';
      }
    }
  }, [assemblyStatus, edition]);

  const buttonText = useMemo(
    () => (assemblyStatus === INCOMPLETE ? 'Compléter' : 'Consulter'),
    [assemblyStatus],
  );

  const buttonVariant = useMemo(
    () => (assemblyStatus === INCOMPLETE ? 'contained' : 'text'),
    [assemblyStatus],
  );

  const handleNavigation: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();

      const path =
        assemblyStatus === INCOMPLETE
          ? PATHS.exhibitors.assemblies.edit.path
          : PATHS.exhibitors.assembly.path;

      navigate(path);
    },
    [assemblyStatus, navigate],
  );

  return (
    <ExhibitorDashboardCard>
      <CardContent>
        <Chip color={chipColor as 'default'} label={chipLabel} sx={{ mb: 3 }} />

        <Typography gutterBottom variant="h3">
          Montage et démontage
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Afin de vous offrir une expérience agréable lors du montage, vous
          devez nous transmettre vos préférences en termes d&apos;horaire de
          montage ainsi que vos besoins en termes de manutention. Par la suite,
          une confirmation d&apos;horaire vous sera envoyée et celle-ci devra
          être respectée lors de votre montage. Concernant le démontage, aucun
          horaire ne vous sera transmis.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          À remplir au plus tard le <strong>lundi 2 décembre 2024</strong>.
        </Typography>
      </CardContent>

      {edition.assembliesOpened && (
        <CardActions>
          <Button variant={buttonVariant} onClick={handleNavigation}>
            {buttonText}
          </Button>
        </CardActions>
      )}
    </ExhibitorDashboardCard>
  );
};

export default ExhibitorDashboardAssemblyCard;
