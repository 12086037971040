import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import DetailedSheetStatusesEnum from '../../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import useFindUserDetailedSheet from '../../../../hooks/detailed-sheets/find-user-detailed-sheet.hook';
import useFindActiveEdition from '../../../../hooks/editions/find-active-edition.hook';
import { PATHS } from '../../../../utils/paths';

const {
  exhibitors: { detailedSheets, path: exhibitorsPath },
} = PATHS;

const DetailedSheetWorkflow: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindUserDetailedSheet();

  if (
    editionStatus === 'loading' ||
    editionStatus === 'idle' ||
    detailedSheetStatus === 'loading' ||
    detailedSheetStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (editionStatus === 'error' || detailedSheetStatus === 'error')
    return <Error />;

  if (
    !edition.detailedSheetsOpened ||
    detailedSheet.status === DetailedSheetStatusesEnum.CANCELED
  ) {
    return <Navigate to={exhibitorsPath} replace />;
  }

  if (detailedSheet.status === DetailedSheetStatusesEnum.INCOMPLETE) {
    return <Navigate to={detailedSheets.edit.path} replace />;
  }

  return <Outlet />;
};

export default DetailedSheetWorkflow;
