import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import AdminAccessOrderFieldValues from '../../interfaces/access-orders/admin-access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

const AdminAccessOrderPresaleTicketsForm: FC = () => {
  const { control } = useFormContext<AdminAccessOrderFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <TextField
            control={control}
            label="Quantité"
            name="presaleTicketQuantity"
            rules={validateRequired()}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAccessOrderPresaleTicketsForm;
