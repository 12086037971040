import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { IconContext } from 'react-icons';
import { FiCheckCircle } from 'react-icons/fi';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import Section from '../../../layouts/Section';
import { PATHS } from '../../../../utils/paths';
import UserStepsEnum from '../../../../enums/users/user-steps.enum';
import { queryClient } from '../../../../utils/query-client';

const {
  ACTIVATION_CODE_STEP,
  BOOKING_PREFERENCE_STEP,
  PROFILE_STEP,
  TERMS_AND_CONDITIONS_STEP,
} = UserStepsEnum;

const BookingPreferenceConfirmationPage: FC = () => {
  const navigate = useNavigate();
  const currentStep = useOutletContext<UserStepsEnum>();

  const handleNavigate = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries('users/step'),
      queryClient.invalidateQueries('users/info'),
    ]);

    navigate(PATHS.exhibitors.profile.path);
  }, [navigate]);

  switch (currentStep) {
    case ACTIVATION_CODE_STEP: {
      return <Navigate to={PATHS.exhibitors.activationCode.path} replace />;
    }
    case PROFILE_STEP: {
      return <Navigate to={PATHS.exhibitors.profiles.create.path} replace />;
    }
    case BOOKING_PREFERENCE_STEP: {
      return (
        <Navigate
          to={PATHS.exhibitors.bookingPreferences.create.path}
          replace
        />
      );
    }
    case TERMS_AND_CONDITIONS_STEP: {
      return <Navigate to={PATHS.exhibitors.termsAndConditions.path} replace />;
    }
  }

  return (
    <>
      <Section>
        <Box
          sx={{
            ' p': { mb: 3 },
            pb: 4,
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={[
              {
                display: 'block',
                width: '100%',
                mb: 8,
                color: 'success.main',
                textAlign: 'center',
              },
              {
                ' p': { backgroundColor: 'grey' },
              },
            ]}
          >
            <Box sx={{ mr: 2, textAlign: 'center' }}>
              <IconContext.Provider value={{ size: '2em' }}>
                <FiCheckCircle />
              </IconContext.Provider>
            </Box>
            Demande de réservation finalisée
          </Typography>
          <Typography>
            Merci d&apos;avoir effectué votre demande de réservation
            d&apos;espace.
          </Typography>
          <Typography>
            Nous prendrons connaissance de vos préférences et notre équipe
            s&apos;assurera de vous attribuer le meilleur emplacement possible.
          </Typography>
          <Typography>
            Vous recevrez un courriel de confirmation et un membre de notre
            équipe communiquera avec vous prochainement pour confirmer votre
            numéro d&apos;emplacement officiel.
          </Typography>
          <Typography>
            À tout moment, vous pouvez entrer dans votre portail exposant pour
            voir l&apos;état de votre demande et vos informations de
            réservation.
          </Typography>
        </Box>
      </Section>

      <Button
        variant="contained"
        onClick={handleNavigate}
        sx={{ float: 'right' }}
      >
        Visualiser ma demande de réservation
      </Button>
    </>
  );
};

export default BookingPreferenceConfirmationPage;
