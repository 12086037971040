import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class ConfirmAssemblyService {
  async confirm(id: string): Promise<void> {
    try {
      await apiV1.put<void>(`/assemblies/${id}/confirm`);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default ConfirmAssemblyService;
