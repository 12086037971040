import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import RadioButtons from '../forms/RadioButtons';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import AccessOrderFieldValues from '../../interfaces/access-orders/access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderAccreditationsForm: FC<Props> = ({ accessOrder }) => {
  const { control, formState, trigger } =
    useFormContext<AccessOrderFieldValues>();

  const hasAdditionalAccreditation =
    useWatch({ name: 'hasAdditionalAccreditation', control }) === 'true';

  useEffect(() => {
    if (formState.isSubmitted) trigger('additionalAccreditationQuantity');
  }, [hasAdditionalAccreditation, formState, trigger]);

  return (
    <>
      <Typography component="p" sx={{ mb: 3 }}>
        Avec votre espace, vous bénéficiez de{' '}
        <strong>{accessOrder.includedAccreditationQuantity} accréditation(s)</strong> sans frais
        pour vos représentants. Ces accréditations ne sont pas nominatives et
        n&apos;indiquent que le nom de l&apos;entreprise.
      </Typography>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <TextField
              control={control}
              label="Nom de l'entreprise à afficher sur l'accréditation"
              name="companyName"
              rules={validateRequired()}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioButtons
              control={control}
              label="Désirez-vous acheter des accréditations supplémentaires au coùt unitaire de 11&nbsp;$ ?"
              name="hasAdditionalAccreditation"
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>

          {hasAdditionalAccreditation && (
            <Grid item xs={12}>
              <TextField
                control={control}
                label="Nombre d'accréditations supplémentaires"
                name="additionalAccreditationQuantity"
                rules={validateRequired(hasAdditionalAccreditation)}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </>
  );
};

export default AccessOrderAccreditationsForm;
