import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import FindNotificationRecipientsDto from '../../dto/notifications/in/find-notification-recipients.dto';
import NotificationRecipientsDto from '../../dto/notifications/out/notification-recipients.dto';
import FindNotificationRecipientsService from '../../services/notifications/find-notification-recipients.service';

const useFindNotificationRecipients = (
  dto: FindNotificationRecipientsDto,
  options?: Omit<
    UseQueryOptions<NotificationRecipientsDto[], Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<NotificationRecipientsDto[], Error> => {
  return useQuery(
    ['notifications/recipients', dto],
    () => new FindNotificationRecipientsService().find(dto),
    options,
  );
};

export default useFindNotificationRecipients;
