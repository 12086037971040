import { useToggle } from '@react-hookz/web';
import { FC, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Loading from './layouts/Logo';
import useRefreshToken from '../hooks/auth/refresh-token.hook';
import { authState } from '../http/auth.state';

const LoadingScreen: FC<PropsWithChildren> = ({ children }) => {
  const { mutateAsync: refreshToken } = useRefreshToken();
  const location = useLocation();
  const [loading, toggleLoading] = useToggle(true);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await refreshToken();
      } catch (error) {
        authState.setToken('');
      }

      toggleLoading(false);
    })();
  }, [refreshToken, toggleLoading]);

  if (loading) return <Loading />;

  return children;
};

export default LoadingScreen;
