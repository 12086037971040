import ActivityFieldDto from '../../dto/activity-fields/activity-field.dto';
import DataTableOptionsDto from '../../dto/data-tables/data-table-options.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import { apiV1 } from '../../http';

class FindActivityFieldsService {
  async find(
    dto: DataTableOptionsDto,
  ): Promise<PaginatedResultDto<ActivityFieldDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<ActivityFieldDto>>(
      '/activity-fields',
      { params: dto },
    );

    return data;
  }
}

export default FindActivityFieldsService;
