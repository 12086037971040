import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useToggle } from '@react-hookz/web';
import { parseISO } from 'date-fns';
import { FC, useState } from 'react';

import AssembliesDataTableAnchor from './AssembliesDataTableAnchor';
import AssembliesDataTableFiltersModal from './AssembliesDataTableFiltersModal';
import AssembliesDataTableToolbar from './AssembliesDataTableToolbar';
import AssemblieStatusChip from './AssembliesStatusChip';
import Error from '../Error';
import Loading from '../Loading';
import NoData from '../NoData';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import DataTableFooter from '../data-tables/DataTableFooter';
import DataTableHead from '../data-tables/DataTableHead';
import AssemblyStatusesEnum from '../../enums/assemblies/assembly-statuses.enum';
import useFindAssemblies from '../../hooks/assemblies/find-assemblies.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import GetDoorTypeLabelService from '../../services/assemblies/get-door-type-label.service';
import {
  formatDateAndTime,
  formatDateLong,
  formatTime,
  getDateFromDateAndTimeString,
} from '../../utils/date';

const { CONFIRMED, INCOMPLETE, PROCESSING } = AssemblyStatusesEnum;

const getDoorTypeLabelService = new GetDoorTypeLabelService();

const COLUMNS: DataTableColumnInterface[] = [
  { key: 'status', label: '', disabled: true, sx: { width: '60px', px: 0 } },
  {
    key: 'submittedAt',
    label: 'Date de création',
    sx: { width: '175px' },
  },
  { key: 'commonCompanyName', label: 'Entreprise', sx: { width: '180px' } },
  { key: 'deliveryDate', label: 'Journée confirmée', sx: { width: '180px' } },
  {
    key: 'deliveryTime',
    label: 'Heure confirmée',
    disabled: true,
    sx: { width: '180px' },
  },
  {
    key: 'doorType',
    label: 'Porte',
    sx: { width: '180px' },
  },
  {
    key: 'doorNumber',
    label: 'Num. porte',
    sx: { width: '180px' },
  },
];

const AssembliesDataTable: FC = () => {
  const { dataTableOptions } = useDataTable();

  const [assemblyStatuses, setAssemblyStatuses] = useState<
    AssemblyStatusesEnum[]
  >([INCOMPLETE, PROCESSING, CONFIRMED]);

  const [filtersModalOpened, toggleFiltersModal] = useToggle(false);

  const { data, status } = useFindAssemblies(
    { ...dataTableOptions, assemblyStatuses },
    {
      keepPreviousData: true,
    },
  );

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;
  if (!data) return <NoData />;

  return (
    <Paper>
      <AssembliesDataTableToolbar toggleFiltersModal={toggleFiltersModal} />

      <Box sx={{ overflow: 'scroll' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <DataTableHead columns={COLUMNS} />

          <TableBody>
            {data.nodes.map((assembly) => {
              const {
                id,
                doorType,
                doorNumber,
                deliveryDate,
                deliveryTime,
                submittedAt,
              } = assembly;

              return (
                <DataTableBodyRow key={id}>
                  <TableCell>
                    <AssemblieStatusChip assembly={assembly} />
                  </TableCell>
                  <TableCell>
                    {submittedAt ? formatDateAndTime(submittedAt) : null}
                  </TableCell>
                  <TableCell>
                    <AssembliesDataTableAnchor assembly={assembly} />
                  </TableCell>
                  <TableCell>
                    {deliveryDate
                      ? formatDateLong(parseISO(deliveryDate))
                      : null}
                  </TableCell>
                  <TableCell>
                    {deliveryDate && deliveryTime
                      ? formatTime(
                          getDateFromDateAndTimeString(
                            deliveryDate,
                            deliveryTime,
                          ),
                        )
                      : null}
                  </TableCell>
                  <TableCell>
                    {doorType ? getDoorTypeLabelService.get(doorType) : null}
                  </TableCell>
                  <TableCell>{doorNumber}</TableCell>
                </DataTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <DataTableFooter pageInfo={data.pageInfo} />

      <AssembliesDataTableFiltersModal
        opened={filtersModalOpened}
        onClose={(): void => toggleFiltersModal(false)}
        assemblyStatuses={assemblyStatuses}
        onAssemblyStatusesChange={(assemblyStatuses): void =>
          setAssemblyStatuses(assemblyStatuses)
        }
      />
    </Paper>
  );
};

export default AssembliesDataTable;
