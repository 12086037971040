import { format, parseISO } from 'date-fns';
import { frCA } from 'date-fns/locale';

import { capitalize } from './strings';
import DatePeriodsEnum from '../enums/date-periods.enum';

export const getDateFromDateAndTimeString = (
  date: string,
  time: string,
): Date => parseISO(`${date}T${time}`);

export const getDatePeriodLabel = (period: DatePeriodsEnum): string =>
  period === DatePeriodsEnum.AM ? 'Avant-midi' : 'Après-midi';

export const getTimestampFromDate = (date: Date): string => {
  return `${+date}`;
};

export const formatDate = (dateString?: string): string | null => {
  if (!dateString) return null;

  return format(new Date(dateString), 'yyyy-MM-dd');
};

export const formatDateLong = (date: Date): string => {
  const formattedDate = format(date, 'EEEE dd LLLL yyyy', { locale: frCA });

  return capitalize(formattedDate);
};

export const formatDateAndTime = (dateString?: string): string | null => {
  if (!dateString) return null;

  return format(new Date(dateString), 'yyyy-MM-dd HH:mm:ss');
};

export const formatTime = (date: Date): string => {
  return format(date, 'HH:mm');
};
