import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindAccessOrderByUserId from '../../../hooks/access-orders/find-access-order-by-user-id.hook';
import useFindActivityFields from '../../../hooks/activity-fields/find-activity-fields.hook';
import useFindAssemblyByUserId from '../../../hooks/assemblies/find-assembly-by-user-id.hook';
import useFindBookingByUserId from '../../../hooks/bookings/find-booking-by-user-id.hook';
import useFindDetailedSheetByUserId from '../../../hooks/detailed-sheets/find-detailed-sheet-by-user-id.hook';
import useFindActiveEdition from '../../../hooks/editions/find-active-edition.hook';
import useFindProfile from '../../../hooks/profiles/find-profile.hook';

const AdminEditDetailedSheetContainer: FC = () => {
  const { id = '' } = useParams<{ id: string }>();

  const { data: profile, status: profileStatus } = useFindProfile(id);
  const { data: edition, status: editionStatus } = useFindActiveEdition();

  const userId = profile?.user.id ?? '';

  const { data: accessOrder, status: accessOrderStatus } =
    useFindAccessOrderByUserId(userId, { enabled: !!profile });

  const { data: activityFields, status: activityFieldsStatus } =
    useFindActivityFields({ take: 1000 });

  const { data: assembly, status: assemblyStatus } = useFindAssemblyByUserId(
    userId,
    { enabled: !!profile },
  );

  const { data: booking, status: bookingStatus } = useFindBookingByUserId(
    userId,
    { enabled: !!profile },
  );

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindDetailedSheetByUserId(userId, { enabled: !!profile });

  if (
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle' ||
    activityFieldsStatus === 'loading' ||
    activityFieldsStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle' ||
    bookingStatus === 'loading' ||
    bookingStatus === 'idle' ||
    detailedSheetStatus === 'loading' ||
    detailedSheetStatus === 'idle' ||
    editionStatus === 'loading' ||
    editionStatus === 'idle' ||
    profileStatus === 'loading' ||
    profileStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (
    accessOrderStatus === 'error' ||
    activityFieldsStatus === 'error' ||
    assemblyStatus === 'error' ||
    bookingStatus === 'error' ||
    editionStatus === 'error' ||
    detailedSheetStatus === 'error' ||
    profileStatus === 'error'
  ) {
    return <Error />;
  }

  return (
    <Outlet
      context={{
        accessOrder,
        activityFields,
        assembly,
        booking,
        detailedSheet,
        edition,
        profile,
      }}
    />
  );
};

export default AdminEditDetailedSheetContainer;
