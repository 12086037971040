import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import FindAssemblyByUserIdService from '../../services/assemblies/find-assembly-by-user-id.service';

const useFindAssemblyByUserId = (
  id: string,
  options?: Omit<UseQueryOptions<AssemblyDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<AssemblyDto, Error> => {
  return useQuery(
    ['users/assembly', id],
    () => new FindAssemblyByUserIdService().find(id),
    options,
  );
};

export default useFindAssemblyByUserId;
