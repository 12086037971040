import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import AssembliesDataTableOptionsDto from '../../dto/assemblies/in/assemblies-data-table-options.dto';
import AssembliesDto from '../../dto/assemblies/out/assemblies.dto';
import FindAssembliesService from '../../services/assemblies/find-assemblies.service';

const useFindAssemblies = (
  dto: AssembliesDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<AssembliesDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<AssembliesDto>, Error> => {
  return useQuery(
    ['assemblies', dto],
    () => new FindAssembliesService().find(dto),
    options,
  );
};

export default useFindAssemblies;
