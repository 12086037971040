import AssembliesReportDto from '../../dto/assemblies/out/assemblies-report.dto';
import { apiV1 } from '../../http';

class GetAssembliesReportService {
  async get(editionId: string): Promise<AssembliesReportDto[]> {
    const { data } = await apiV1.get<AssembliesReportDto[]>(
      `/editions/${editionId}/report/assemblies`,
    );

    return data;
  }
}

export default GetAssembliesReportService;
