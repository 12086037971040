import { FC, useMemo } from 'react';

import DataTableAnchor from '../data-tables/DataTableAnchor';
import LocationsDto from '../../dto/locations/out/locations.dto';
import { sortByCreatedAt } from '../../utils/arrays';
import { PATHS } from '../../utils/paths';

interface Props {
  location: LocationsDto;
}

const LocationsDataTableCompanyNameAnchor: FC<Props> = ({ location }) => {
  const sortedBookingLocations = useMemo(
    () => sortByCreatedAt(location.bookingLocations),
    [location.bookingLocations],
  );

  if (!sortedBookingLocations.length) return null;

  return (
    <>
      {sortedBookingLocations.map((bookingLocation, index) => (
        <>
          {index > 0 && ' | '}
          <DataTableAnchor
            key={bookingLocation.id}
            href={PATHS.admin.profiles.edit.path(
              bookingLocation.booking.user.profile.id,
            )}
            label={bookingLocation.booking.user.profile.legalCompanyName}
          />
        </>
      ))}
    </>
  );
};

export default LocationsDataTableCompanyNameAnchor;
