import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { FC } from 'react';

import LocationsDataTableCompanyNameAnchor from './LocationsDataTableCompanyNameAnchor';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import DataTableFooter from '../data-tables/DataTableFooter';
import DataTableHead from '../data-tables/DataTableHead';
import DataTableToolbar from '../data-tables/DataTableToolbar';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import useFindLocationsDetailed from '../../hooks/locations/find-locations-detailed.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import LocationStatusChip from './LocationStatusChip';
import LocationsDataTableLocationNameAnchor from './LocationsDataTableLocationNameAnchor copy';

const COLUMNS: DataTableColumnInterface[] = [
  { key: 'status', label: '', disabled: true, sx: { width: '60px', px: 0 } },
  { key: 'name', label: '#', sx: { width: '100px' } },
  { key: 'sections.name', label: 'Secteur', sx: { width: '300px', px: 0 } },
  { key: 'areas.name', label: 'Superficie', sx: { width: '150px', px: 0 } },
  { key: 'profiles.legalCompanyName', label: 'Entreprise' },
];

const LocationsDataTable: FC = () => {
  const { dataTableOptions } = useDataTable();

  const { data } = useFindLocationsDetailed(dataTableOptions, {
    keepPreviousData: true,
  });

  if (!data) return null;

  return (
    <Paper>
      <DataTableToolbar />

      <Table>
        <DataTableHead columns={COLUMNS} />

        <TableBody>
          {data.nodes.map((location) => {
            const { id, area, section } = location;

            return (
              <DataTableBodyRow key={id}>
                <TableCell>
                  <LocationStatusChip location={location} />
                </TableCell>
                <TableCell>
                  <LocationsDataTableLocationNameAnchor location={location} />
                </TableCell>
                <TableCell>{section?.name}</TableCell>
                <TableCell>{area?.name}</TableCell>
                <TableCell>
                  <LocationsDataTableCompanyNameAnchor location={location} />
                </TableCell>
              </DataTableBodyRow>
            );
          })}
        </TableBody>
      </Table>

      <DataTableFooter pageInfo={data.pageInfo} />
    </Paper>
  );
};

export default LocationsDataTable;
