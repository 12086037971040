import Grid from '@mui/material/Grid';
import { FC } from 'react';

const FormGrid: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid container spacing={3}>
      {children}
    </Grid>
  );
};

export default FormGrid;
