import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import LocationsDataTableOptionsDto from '../../dto/locations/in/locations-data-table-options.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import FindLocationsDetailedService from '../../services/locations/find-locations-detailed.service';
import LocationsDto from '../../dto/locations/out/locations.dto';

const useFindLocationsDetailed = (
  dto: LocationsDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<LocationsDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<LocationsDto>, Error> => {
  return useQuery(
    ['locations/detailed', dto],
    () => new FindLocationsDetailedService().find(dto),
    options,
  );
};

export default useFindLocationsDetailed;
