import { plainToClass } from 'class-transformer';

import FindNotificationRecipientsDto, {
  FindNotificationAccessOrdersProfilesDto,
  FindNotificationRecipientsAssembliesDto,
  FindNotificationRecipientsDetailedSheetsDto,
  FindNotificationRecipientsProfilesDto,
} from '../../dto/notifications/in/find-notification-recipients.dto';
import StagesEnum from '../../enums/editions/stages.enum';
import NotificationFieldValuesInterface from '../../interfaces/notifications/notification-field-values.interface';

class MapFindNotificationRecipientsFormToDtoService {
  map(
    fieldValues: NotificationFieldValuesInterface,
  ): FindNotificationRecipientsDto {
    switch (fieldValues.stage) {
      default:
      case StagesEnum.REGISTRATIONS: {
        return plainToClass(FindNotificationRecipientsProfilesDto, {
          stage: StagesEnum.REGISTRATIONS,
          statuses: fieldValues.statuses,
          paymentStatus: fieldValues.paymentStatus
            ? fieldValues.paymentStatus
            : undefined,
        });
      }
      case StagesEnum.DETAILED_SHEETS: {
        return plainToClass(FindNotificationRecipientsDetailedSheetsDto, {
          stage: StagesEnum.DETAILED_SHEETS,
          statuses: fieldValues.statuses,
        });
      }
      case StagesEnum.ASSEMBLIES: {
        return plainToClass(FindNotificationRecipientsAssembliesDto, {
          stage: StagesEnum.ASSEMBLIES,
          statuses: fieldValues.statuses,
        });
      }
      case StagesEnum.ACCESS_ORDERS: {
        return plainToClass(FindNotificationAccessOrdersProfilesDto, {
          stage: StagesEnum.ACCESS_ORDERS,
          statuses: fieldValues.statuses,
          paymentStatus: fieldValues.paymentStatus
            ? fieldValues.paymentStatus
            : undefined,
        });
      }
    }
  }
}

export default MapFindNotificationRecipientsFormToDtoService;
