import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import LocationDto from '../../dto/locations/out/location.dto';
import LocationsDataTableOptionsDto from '../../dto/locations/in/locations-data-table-options.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindLocationsService {
  async find(
    dto: LocationsDataTableOptionsDto,
  ): Promise<PaginatedResultDto<LocationDto>> {
    try {
      const { data } = await apiV1.get<PaginatedResultDto<LocationDto>>(
        '/locations',
        { params: dto },
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindLocationsService;
