import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import StatusesChips from '../StatusesChips';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';

interface Props {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const ProfileTitle: FC<Props> = ({
  accessOrder,
  assembly,
  detailedSheet,
  profile,
}) => {
  return (
    <Stack
      direction="column"
      alignItems={{ xs: 'flex-start', md: 'center' }}
      justifyContent="space-between"
      sx={{ my: 4 }}
    >
      <Typography variant="h2" sx={{ mb: 1, width: '100%' }}>
        {profile.legalCompanyName}
      </Typography>

      <StatusesChips
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />
    </Stack>
  );
};

export default ProfileTitle;
