import { useMutation, UseMutationResult } from 'react-query';

import CreateUserActivationCodeDto from '../../dto/users/create-user-activation-code.dto';
import CreateUserActivationCodeService from '../../services/users/create-user-activation-code.service';

const useCreateUserActivationCode = (): UseMutationResult<
  void,
  Error,
  CreateUserActivationCodeDto
> => {
  return useMutation(async (dto: CreateUserActivationCodeDto) =>
    new CreateUserActivationCodeService().create(dto),
  );
};

export default useCreateUserActivationCode;
