import jwtDecode from 'jwt-decode';

import JwtTokenPayloadDto from '../../dto/jwt/jwt-token-payload.dto';

class GetJwtPayloadService {
  get(token: string): JwtTokenPayloadDto {
    return jwtDecode<JwtTokenPayloadDto>(token);
  }
}

export default GetJwtPayloadService;
