import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { ReactElement } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

const TextField = <T extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: UseControllerProps<T> & TextFieldProps): ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules });

  return (
    <MuiTextField
      {...props}
      {...field}
      error={!!error}
      helperText={error?.message}
    />
  );
};

export default TextField;
