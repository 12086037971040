
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { palette } from './palette';

import { getFontSizesForBreakpoints, getRmFromPx } from '../utils/fonts';

export const PRIMARY_FONT = "'Poppins', sans-serif";

export const typography: TypographyOptions = {
  fontFamily: PRIMARY_FONT,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: getRmFromPx(32),
    ...getFontSizesForBreakpoints({ sm: 38, md: 44, lg: 48 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: getRmFromPx(26),
    ...getFontSizesForBreakpoints({ sm: 26, md: 30, lg: 32 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: getRmFromPx(20),
    ...getFontSizesForBreakpoints({ sm: 20, md: 24, lg: 24 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: getRmFromPx(19),
    ...getFontSizesForBreakpoints({ sm: 19, md: 20, lg: 20 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: getRmFromPx(18),
    ...getFontSizesForBreakpoints({ sm: 18, md: 18, lg: 18 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: getRmFromPx(17),
    ...getFontSizesForBreakpoints({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
  },
  button: {
    fontSize: getRmFromPx(16),
    ...getFontSizesForBreakpoints({ sm: 16, md: 18, lg: 18 }),
  },
  overline: {
    color: palette.primary.main,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: getRmFromPx(18),
    ...getFontSizesForBreakpoints({ sm: 18, md: 22, lg: 24 }),
  },
};
