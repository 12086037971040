import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import LocationsDataTableOptionsDto from '../../dto/locations/in/locations-data-table-options.dto';
import LocationsDto from '../../dto/locations/out/locations.dto';
import { apiV1 } from '../../http';

class FindLocationsDetailedService {
  async find(
    dto: LocationsDataTableOptionsDto,
  ): Promise<PaginatedResultDto<LocationsDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<LocationsDto>>(
      '/locations/detailed',
      { params: dto },
    );

    return data;
  }
}

export default FindLocationsDetailedService;
