import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useCallback, useState } from 'react';
import { FiFileText } from 'react-icons/fi';

import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import EditionsDto from '../../dto/editions/out/editions.dto';
import ErrorTypesEnum from '../../enums/error-types.enum';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import DownloadEditionReportService from '../../services/editions/download-edition-report.service';
import GetEditionReportService from '../../services/editions/get-edition-report.service';
import { axiosHttpErrorToError } from '../../utils/errors';
import { toast } from '../../utils/toast';

interface Props {
  edition: EditionsDto;
  label: string;
  profileStatus?: ProfileStatusesEnum;
}

const ReportFile: FC<Props> = ({ edition, label, profileStatus }) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true);

      const data = await new GetEditionReportService().get(edition.id, {
        profileStatus,
      });

      new DownloadEditionReportService().download(edition, data, label);
    } catch (error) {
      const { message } = axiosHttpErrorToError(error);

      switch (message) {
        case ErrorTypesEnum.NOT_FOUND_ERROR: {
          toast.error('Le rapport ne contient aucun exposant');

          break;
        }
        default: {
          toast.error();

          break;
        }
      }
    } finally {
      setDownloading(false);
    }
  }, [edition, label, profileStatus, setDownloading]);

  return (
    <Grid xs={12} item>
      <Button
        onClick={(event): void => {
          event.preventDefault();

          handleDownload();
        }}
        startIcon={downloading ? <ButtonLoadingIcon /> : <FiFileText />}
      >
        {label}
      </Button>
    </Grid>
  );
};

export default ReportFile;
