import { FC } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import useFindBookingByUserId from '../../../../hooks/bookings/find-booking-by-user-id.hook';
import useFindProfile from '../../../../hooks/profiles/find-profile.hook';
import { PATHS } from '../../../../utils/paths';

const AdminCreateBookingWorkflow: FC = () => {
  const { id = '' } = useParams<{ id: string }>();

  const { data: profile, status: profileStatus } = useFindProfile(id);

  const userId = profile?.user.id ?? '';

  const { data: booking, status: bookingStatus } = useFindBookingByUserId(
    userId,
    {
      enabled: !!profile,
    },
  );

  if (
    profileStatus === 'loading' ||
    profileStatus === 'idle' ||
    bookingStatus === 'loading' ||
    bookingStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (profileStatus === 'error' || bookingStatus === 'error') {
    return <Error />;
  }

  if (booking) {
    return <Navigate to={PATHS.admin.bookings.edit.path(profile.id)} replace />;
  }

  return <Outlet />;
};

export default AdminCreateBookingWorkflow;
