import { useMutation, UseMutationResult } from 'react-query';

import SendNotificationDto from '../../dto/notifications/in/send-notification.dto';
import SendNotifcationPreviewService from '../../services/notifications/send-notification-preview.service';

const useSendNotificationPreview = (): UseMutationResult<
  void,
  Error,
  SendNotificationDto
> => {
  return useMutation(new SendNotifcationPreviewService().send);
};

export default useSendNotificationPreview;
