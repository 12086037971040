import Typography from '@mui/material/Typography';
import { FC } from 'react';

import SectionHeader from '../../../layouts/SectionHeader';
import ProfilesDataTable from '../../../profiles/ProfilesDataTable';
import { DataTableProvider } from '../../../../providers/data-tables/DataTableProvider';

const AdminProfilesListPage: FC = () => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Inscriptions</Typography>
      </SectionHeader>

      <DataTableProvider dataTableOptions={{ showArchives: true }}>
        <ProfilesDataTable />
      </DataTableProvider>
    </>
  );
};

export default AdminProfilesListPage;
