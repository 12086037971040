import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import { FC } from 'react';

const AccessOrderProcessingAlert: FC = () => {
  return (
    <Alert severity="warning">
      <AlertTitle>Demande en traitement</AlertTitle>
      Pour toute question ou modification, veuillez contacter Jessica Légaré au{' '}
      <Link href="mailto:jlegare@apchqquebec.ca" underline="none">
        jlegare@apchqquebec.ca
      </Link>{' '}
      ou au 418-682-3353.
    </Alert>
  );
};

export default AccessOrderProcessingAlert;
