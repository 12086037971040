import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

import LocationDto from '../../dto/locations/out/location.dto';

interface Props {
  locations: Pick<LocationDto, 'name'>[];
}

const LocationsChips: FC<Props> = ({ locations }) => {
  return (
    <Stack direction="row" spacing={1}>
      {locations.map(({ name }, index) => (
        <Chip key={index} label={name} sx={{ minWidth: '45px' }} />
      ))}
    </Stack>
  );
};

export default LocationsChips;
