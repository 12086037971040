import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import BookingPreferenceForm from '../../../booking-preferences/BookingPreferenceForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import SectionHeader from '../../../layouts/SectionHeader';
import BookingPreferenceDto from '../../../../dto/booking-preferences/out/booking-preference.dto';
import useSaveBookingPreference from '../../../../hooks/booking-preferences/save-booking-preference.hook';
import BookingPreferenceFieldValuesInterface from '../../../../interfaces/booking-preferences/booking-preference-field-values.interface';
import MapSaveBookingPreferenceFormToDtoService from '../../../../services/booking-preferences/map-save-booking-preference-form-to-dto.service';
import { PATHS } from '../../../../utils/paths';
import { queryClient } from '../../../../utils/query-client';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const mapCreateBookingPreferenceFormToDtoService =
  new MapSaveBookingPreferenceFormToDtoService();

const EditBookingPreferencePage: FC = () => {
  const navigate = useNavigate();

  const { bookingPreference, profile } =
    useOutletContext<BookingPreferenceDto>();

  const { mutateAsync: saveBookingPreference } = useSaveBookingPreference();

  const getBookingLocationsByGroup = useCallback(
    (group: '1' | '2' | '3') =>
      bookingPreference?.bookingPreferenceLocations.filter(
        (bookingPreferenceLocation) =>
          bookingPreferenceLocation.group === group,
      ) ?? [],
    [bookingPreference],
  );

  const filterBookingLocationsByGroup = useCallback(
    (group: '1' | '2' | '3') =>
      getBookingLocationsByGroup(group).map(({ location }) => location.id),
    [getBookingLocationsByGroup],
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<BookingPreferenceFieldValuesInterface>({
    defaultValues: {
      comments: profile?.comments ?? '',
      areaId: profile?.area?.id ?? '',
      sectionId: profile?.section?.id ?? '',
      bookingPreferenceLocations1: filterBookingLocationsByGroup('1'),
      bookingPreferenceLocationComments1:
        getBookingLocationsByGroup('1')[0]?.comments ?? '',
      bookingPreferenceLocations2: filterBookingLocationsByGroup('2'),
      bookingPreferenceLocationComments2:
        getBookingLocationsByGroup('2')[0]?.comments ?? '',
      bookingPreferenceLocations3: filterBookingLocationsByGroup('3'),
      bookingPreferenceLocationComments3:
        getBookingLocationsByGroup('3')[0]?.comments ?? '',
      categories:
        profile?.profileCategories.map(({ category }) => category.id) ?? [],
    },
  });

  const handleNavigate = useCallback(() => {
    navigate(PATHS.exhibitors.profiles.edit.path);
  }, [navigate]);

  const onSubmit: SubmitHandler<BookingPreferenceFieldValuesInterface> =
    useCallback(
      async (fieldValues: BookingPreferenceFieldValuesInterface) => {
        try {
          const dto = mapCreateBookingPreferenceFormToDtoService.map(
            fieldValues,
            { bookingPreference },
          );

          await saveBookingPreference(dto);

          await queryClient.invalidateQueries('users/step');

          toast.success("Préférences d'emplacements sauvegardées avec succès");

          navigate(PATHS.exhibitors.termsAndConditions.path);

          queryClient.invalidateQueries('booking-preferences/me');
        } catch (error) {
          toast.error();
        }
      },
      [bookingPreference, navigate, saveBookingPreference],
    );

  if (!bookingPreference && !isSubmitting) {
    return (
      <Navigate to={PATHS.exhibitors.bookingPreferences.create.path} replace />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <SectionHeader>
        <Typography variant="h1" gutterBottom>
          Remplissez votre demande d&apos;emplacement
        </Typography>
      </SectionHeader>

      <Button
        onClick={handleNavigate}
        startIcon={<FiChevronLeft />}
        variant="text"
      >
        Étape précédente
      </Button>

      <BookingPreferenceForm control={control} setValue={setValue} />

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        sx={{ float: 'right' }}
        startIcon={isSubmitting && <ButtonLoadingIcon />}
      >
        Continuer l&apos;inscription
      </Button>
    </form>
  );
};

export default EditBookingPreferencePage;
