import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { FC } from 'react';

const NoData: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Alert
        severity="warning"
        sx={{ mt: 4, maxWidth: '500px', width: '100%' }}
      >
        <AlertTitle>Aucune donnée</AlertTitle>
        Aucune donnée ne correspond.
      </Alert>
    </Box>
  );
};

export default NoData;
