import { FC } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

import UserStepsEnum from '../../../enums/users/user-steps.enum';
import { PATHS } from '../../../utils/paths';

const {
  ACTIVATION_CODE_STEP,
  BOOKING_PREFERENCE_STEP,
  PROFILE_STEP,
  TERMS_AND_CONDITIONS_STEP,
} = UserStepsEnum;

const {
  exhibitors: {
    activationCode,
    profiles,
    bookingPreferences,
    termsAndConditions,
  },
} = PATHS;

const RegisteredWorkflow: FC = () => {
  const currentStep = useOutletContext<UserStepsEnum>();

  switch (currentStep) {
    case ACTIVATION_CODE_STEP: {
      return <Navigate to={activationCode.path} replace />;
    }
    case PROFILE_STEP: {
      return <Navigate to={profiles.create.path} replace />;
    }
    case BOOKING_PREFERENCE_STEP: {
      return <Navigate to={bookingPreferences.create.path} replace />;
    }
    case TERMS_AND_CONDITIONS_STEP: {
      return <Navigate to={termsAndConditions.path} replace />;
    }
  }

  return <Outlet />;
};

export default RegisteredWorkflow;
