import Stack from '@mui/material/Stack';
import { FC } from 'react';

const SectionHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      justifyContent="space-between"
      sx={{ my: 4 }}
    >
      {children}
    </Stack>
  );
};

export default SectionHeader;
