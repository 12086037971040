import { FC, ReactElement } from 'react';

import NotificationModalConfirmationContent from './NotificationModalConfirmationContent';
import Modal from '../Modal';
import NotificationModalConfirmationContainer from '../containers/notifications/NotificationModalConfirmationContainer';

interface Props {
  opened: boolean;
  onClose: () => void;
}

const NotificationModalConfirmation: FC<Props> = ({ opened, onClose }) => {
  return (
    <Modal open={opened} onClose={onClose}>
      <NotificationModalConfirmationContainer
        enabled={opened}
        render={({ notificationRecipients }): ReactElement => (
          <NotificationModalConfirmationContent
            notificationRecipients={notificationRecipients}
            onClose={onClose}
          />
        )}
      />
    </Modal>
  );
};

export default NotificationModalConfirmation;
