import { utils, writeFile } from 'xlsx';

import AssembliesReportDto from '../../dto/assemblies/out/assemblies-report.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import { formatDate } from '../../utils/date';

class DownloadAssembliesReportService {
  download(edition: EditionDto, data: AssembliesReportDto[]): void {
    const worksheet = utils.json_to_sheet(data);

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Rapport');

    writeFile(
      workbook,
      `Expo habitat ${edition.name} (Montage et démontage) - ${formatDate(
        new Date().toString(),
      )}.xlsx`,
    );
  }
}

export default DownloadAssembliesReportService;
