import { plainToClass } from 'class-transformer';

import RemoveEmptyStringService from '../forms/remove-empty-string.service';
import SaveManagerDto from '../../dto/managers/in/save-manager.dto';
import SaveProfileDto from '../../dto/profiles/in/save-profile.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import ProfileFieldValuesInterface from '../../interfaces/profiles/profile-field-values.interface';

const removeEmptyStringService = new RemoveEmptyStringService();

class MapSaveProfileFormToDtoService {
  map(
    {
      address,
      apchqMemberNumber,
      rbqMemberNumber,
      legalCompanyName,
      commonCompanyName,
      postalCode,
      city,
      province,
      manager1Salutation,
      manager1FirstName,
      manager1LastName,
      manager1Email,
      manager1PhoneNumber1,
      manager1PhoneNumber1Ext,
      manager1PhoneNumber2,
      manager1PhoneNumber2Ext,
      manager2Salutation,
      manager2FirstName,
      manager2LastName,
      manager2Email,
      manager2PhoneNumber1,
      manager2PhoneNumber1Ext,
      manager2PhoneNumber2,
      manager2PhoneNumber2Ext,
    }: ProfileFieldValuesInterface,
    profile?: ProfileDto,
  ): SaveProfileDto {
    const managers = [
      plainToClass(SaveManagerDto, {
        id: profile?.managers[0]?.id,
        salutation: manager1Salutation,
        firstName: manager1FirstName.trim(),
        lastName: manager1LastName.trim(),
        email: manager1Email.trim(),
        phoneNumber1: manager1PhoneNumber1.trim(),
        phoneNumber1Ext: removeEmptyStringService.remove(
          manager1PhoneNumber1Ext,
        ),
        phoneNumber2: removeEmptyStringService.remove(manager1PhoneNumber2),
        phoneNumber2Ext: removeEmptyStringService.remove(
          manager1PhoneNumber2Ext,
        ),
        displayOrder: '1',
      }),
    ];

    if (
      manager2Salutation &&
      manager2FirstName &&
      manager2LastName &&
      manager2Email &&
      manager2PhoneNumber1
    ) {
      managers.push(
        plainToClass(SaveManagerDto, {
          id: profile?.managers[1]?.id,
          salutation: manager2Salutation,
          firstName: manager2FirstName.trim(),
          lastName: manager2LastName.trim(),
          email: manager2Email.trim(),
          phoneNumber1: manager2PhoneNumber1,
          phoneNumber1Ext: removeEmptyStringService.remove(
            manager2PhoneNumber1Ext,
          ),
          phoneNumber2: removeEmptyStringService.remove(manager2PhoneNumber2),
          phoneNumber2Ext: removeEmptyStringService.remove(
            manager2PhoneNumber2Ext,
          ),
          displayOrder: '2',
        }),
      );
    }

    return plainToClass(SaveProfileDto, {
      id: profile?.id,
      commonCompanyName: removeEmptyStringService.remove(commonCompanyName),
      legalCompanyName: legalCompanyName.trim(),
      address: address.trim(),
      postalCode: postalCode.trim(),
      apchqMemberNumber: apchqMemberNumber?.trim(),
      rbqMemberNumber: rbqMemberNumber?.trim(),
      city,
      province,
      managers,
    });
  }
}

export default MapSaveProfileFormToDtoService;
