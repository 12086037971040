import AccessOrderPaymentStatusesEnum from '../../enums/access-orders/access-order-payment-statuses.enum';

const { INITIAL, INVOICE_PENDING, PAID, PAYMENT_PENDING } =
  AccessOrderPaymentStatusesEnum;

class GetAccessOrderPaymentStatusLabelService {
  get(paymentStatus: AccessOrderPaymentStatusesEnum): string {
    switch (paymentStatus) {
      default:
      case INITIAL: {
        return '';
      }
      case INVOICE_PENDING: {
        return 'À facturer';
      }
      case PAYMENT_PENDING: {
        return 'Facturé';
      }
      case PAID: {
        return 'Payé';
      }
    }
  }
}

export default GetAccessOrderPaymentStatusLabelService;
