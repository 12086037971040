import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ExhibitorDashboardCard from './ExhibitorDashboardCard';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import { PATHS } from '../../utils/paths';

const { BOOKED, CANCELED, INCOMPLETE, PROCESSING } = ProfileStatusesEnum;

interface Props {
  profile: ProfileDto;
}

const ExhibitorDashboardBookingCard: FC<Props> = ({ profile }) => {
  const navigate = useNavigate();

  const chipColor = useMemo(() => {
    switch (profile.status) {
      case CANCELED: {
        return 'default';
      }
      case INCOMPLETE: {
        return 'error';
      }
      case PROCESSING: {
        return 'warning';
      }
      case BOOKED: {
        return 'success';
      }
    }
  }, [profile]);

  const chipLabel = useMemo(() => {
    switch (profile.status) {
      default:
      case INCOMPLETE: {
        return 'Incomplet';
      }
      case PROCESSING: {
        return 'En traitement';
      }
      case BOOKED: {
        return 'Réservé';
      }
      case CANCELED: {
        return 'Annulé';
      }
    }
  }, [profile]);

  const handleNavigation: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();

      navigate(PATHS.exhibitors.profile.path);
    },
    [navigate],
  );

  return (
    <ExhibitorDashboardCard>
      <CardContent>
        <Chip color={chipColor} label={chipLabel} sx={{ mb: 3 }} />

        <Typography gutterBottom variant="h3">
          Inscription
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Consultez ici tous les détails en lien avec votre réservation.
        </Typography>
      </CardContent>

      <CardActions>
        <Button variant="text" onClick={handleNavigation}>
          Consulter
        </Button>
      </CardActions>
    </ExhibitorDashboardCard>
  );
};

export default ExhibitorDashboardBookingCard;
