import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import AccessOrderStatusChip from './access-orders/AccessOrderStatusChip';
import AssemblyStatusChip from './assemblies/AssemblyStatusChip';
import DetailedSheetStatusChip from './detailed-sheets/DetailedSheetStatusChip';
import ProfileStatusChip from './profiles/ProfileStatusChip';
import AccessOrderDto from '../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../dto/assemblies/out/assembly.dto';
import DetailedSheetDto from '../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../dto/profiles/out/profile.dto';

const CHIP_SX = {
  height: '16px',
  width: '16px',
  marginTop: '2px',
  marginRight: '4px',
};

interface Props {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const StatusesChips: FC<Props> = ({
  accessOrder,
  assembly,
  detailedSheet,
  profile,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', mr: 3 }}>
        <ProfileStatusChip profile={profile} chipSx={CHIP_SX} />

        <Typography variant="body2">Inscription</Typography>
      </Box>

      <Box sx={{ display: 'flex', mr: 3 }}>
        <DetailedSheetStatusChip
          detailedSheet={detailedSheet}
          chipSx={CHIP_SX}
        />

        <Typography variant="body2">Fiche</Typography>
      </Box>
      <Box sx={{ display: 'flex', mr: 3 }}>
        <AssemblyStatusChip assembly={assembly} chipSx={CHIP_SX} />

        <Typography variant="body2">Mont. Dém.</Typography>
      </Box>
      <Box sx={{ display: 'flex', mr: 3 }}>
        <AccessOrderStatusChip accessOrder={accessOrder} chipSx={CHIP_SX} />

        <Typography variant="body2">Commandes d&apos;accès</Typography>
      </Box>
    </Box>
  );
};

export default StatusesChips;
