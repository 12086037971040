import BookingPreferenceDto from '../../dto/booking-preferences/out/booking-preference.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserBookingPreferenceService {
  async find(): Promise<BookingPreferenceDto> {
    try {
      const { data } = await apiV1.get<BookingPreferenceDto>(
        '/booking-preferences/me',
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserBookingPreferenceService;
