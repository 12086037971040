import { useMutation, UseMutationResult } from 'react-query';

import SaveLocationDto from '../../dto/locations/in/save-location.dto';
import SaveLocationService from '../../services/locations/save-location.service';

const useSaveLocation = (): UseMutationResult<void, Error, SaveLocationDto> => {
  return useMutation(new SaveLocationService().save);
};

export default useSaveLocation;
