import { useMutation, UseMutationResult } from 'react-query';

import SaveOpenedStagesDto from '../../dto/editions/in/save-opened-stages.dto';
import SaveOpenedStagesService from '../../services/editions/save-opened-stages.service';

const useSaveOpenedStages = (): UseMutationResult<
  void,
  Error,
  SaveOpenedStagesDto
> => {
  return useMutation(new SaveOpenedStagesService().save);
};

export default useSaveOpenedStages;
