import SaveAssemblyDto from '../../dto/assemblies/in/save-assembly.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveAssemblyService {
  async save(id: string, dto: SaveAssemblyDto): Promise<void> {
    try {
      await apiV1.put<void>(`/assemblies/${id}`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveAssemblyService;
