import UpdateProfileDto from '../../dto/profiles/in/update-profile.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class UpdateProfileService {
  async update(id: string, dto: UpdateProfileDto): Promise<void> {
    try {
      await apiV1.put<void>(`/profiles/${id}`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default UpdateProfileService;
