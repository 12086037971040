import DetailedSheetDto from '../dto/detailed-sheets/out/detailed-sheet.dto';

export const DEFAULT_FILE_PREVIEW_URL = '/images/uploaded-file-placeholder.png';

export const getFileExtension = (file: File): string => {
  return file.name.split('.').pop() as string;
};

export const getFileHash = (): string => {
  return String(Math.floor(Math.random() * 1e12)).padStart(12, '0');
};

export const getFileKey = (
  file: NonNullable<
    DetailedSheetDto['logo'] | NonNullable<DetailedSheetDto['image']>
  >,
): string => {
  return file.keySmall ? file.keySmall : file.key;
};

export const isPlaceholderFile = (file: File): boolean => {
  return !file.size && !file.type;
};

export const sanitizeFileName = (value: string): string => {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9.]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+|-+$/g, '');
};
