import { useQuery, UseQueryResult } from 'react-query';

import AreaDto from '../../dto/areas/area.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import FindAreasService from '../../services/areas/find-areas.service';

const useFindAreas = (): UseQueryResult<PaginatedResultDto<AreaDto>> => {
  return useQuery<unknown, unknown, PaginatedResultDto<AreaDto>>(
    'areas',
    new FindAreasService().find,
  );
};

export default useFindAreas;
