import { useMutation, UseMutationResult } from 'react-query';

import CreateBookingPreferencesDto from '../../dto/booking-preferences/in/save-booking-preference.dto';
import SaveBookingPreferenceService from '../../services/booking-preferences/save-booking-preference.service';

const useSaveBookingPreference = (): UseMutationResult<
  void,
  Error,
  CreateBookingPreferencesDto
> => {
  return useMutation(async (dto: CreateBookingPreferencesDto) =>
    new SaveBookingPreferenceService().save(dto),
  );
};

export default useSaveBookingPreference;
