import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import TextField from '../forms/TextField';
import AccessOrderFieldValues from '../../interfaces/access-orders/access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

const AccessOrderPresaleTicketsForm: FC = () => {
  const { control, formState, trigger } =
    useFormContext<AccessOrderFieldValues>();

  const hasPresaleTicket =
    useWatch({ name: 'hasPresaleTicket', control }) === 'true';

  useEffect(() => {
    if (formState.isSubmitted) trigger('presaleTicketQuantity');
  }, [hasPresaleTicket, formState, trigger]);

  return (
    <>
      <Typography component="p" sx={{ mb: 3 }}>
        Invitez vos partenaires, clients et employés à venir vous voir à Expo
        habitat Québec! En achetant des billets de prévente, vous
        économisez&nbsp;5&nbsp;$ sur le prix régulier! Tarif unitaire
        préférentiel au coût de&nbsp;11&nbsp;$.
      </Typography>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <RadioButtons
              control={control}
              label="Désirez-vous vous prévaloir de cette offre ?"
              name="hasPresaleTicket"
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>

          {hasPresaleTicket && (
            <Grid item xs={12}>
              <TextField
                control={control}
                label="Quantité"
                name="presaleTicketQuantity"
                rules={validateRequired(hasPresaleTicket)}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </>
  );
};

export default AccessOrderPresaleTicketsForm;
