import FileDto from '../../dto/files/out/file.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class CopyFileService {
  async copy(id: string): Promise<FileDto> {
    try {
      const { data } = await apiV1.post<FileDto>(`/files/${id}/copy`);

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default CopyFileService;
