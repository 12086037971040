import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import GetAccessOrderHasVipParkingLabelService from '../../services/access-orders/get-access-order-has-vip-parking-label.service';

const getAccessOrderHasVipParkingLabelService =
  new GetAccessOrderHasVipParkingLabelService();

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderParkingsSection: FC<Props> = ({ accessOrder }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Stationnements
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Vous avez droit à un stationnement VIP, souhaitez-vous en
              bénéficier&nbsp;?
            </ReadonlyFieldLabel>

            <Typography>
              {getAccessOrderHasVipParkingLabelService.get(accessOrder)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Nombre d&apos;autres stationnements standards (autres que VIP)
              souhaités
            </ReadonlyFieldLabel>

            <Typography>{accessOrder.standardParkingQuantity}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default AccessOrderParkingsSection;
