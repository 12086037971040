import { AxiosError } from 'axios';
import HttpErrorInterface from '../http/interfaces/http-error.interface';

export const axiosHttpErrorToError = (error: unknown): Error => {
  const { message, response } = error as AxiosError<HttpErrorInterface>;

  const errorMessage = response?.data.error.message ?? message;

  return new Error(errorMessage);
};
