import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useController, Control, UseFormSetValue } from 'react-hook-form';

import BookingPreferenceLocationsForm from './BookingPreferenceLocationsForm';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Select from '../forms/Select';
import TextField from '../forms/TextField';
import Section from '../layouts/Section';
import SectionAlert from '../sections/SectionAlert';
import SectionCategoriesForm from '../sections/SectionCategoriesForm';
import SectionRadioLabel from '../sections/SectionRadioLabel';
import BookingPreferenceFieldValuesInterface from '../../interfaces/booking-preferences/booking-preference-field-values.interface';
import useFindAreas from '../../hooks/areas/find-areas.hook';
import useFindSections from '../../hooks/sections/find-sections.hook';
import { validateRequired } from '../../utils/validations';
import { PATHS } from '../../utils/paths';

interface Props {
  control: Control<BookingPreferenceFieldValuesInterface>;
  setValue: UseFormSetValue<BookingPreferenceFieldValuesInterface>;
}

const BookingPreferenceForm = ({
  control,
  setValue,
}: Props): ReactElement | null => {
  const { data: areas, status: areasStatus } = useFindAreas();

  const { data: sections, status: sectionsStatus } = useFindSections({
    take: 1000,
  });

  const [edited, setEdited] = useState(false);

  const firstRender = useRef(true);

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'sectionId',
    control,
    rules: validateRequired(),
  });

  const { value: sectionId } = field;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [sectionId, setEdited]);

  const isSim = useMemo(() => {
    const { nodes = [] } = sections ?? {};

    const section = nodes.find((section) => section.id === sectionId);

    return section?.code === 'SIM';
  }, [sections, sectionId]);

  if (sectionsStatus !== 'success' || areasStatus !== 'success') return null;

  return (
    <>
      <Section>
        <FormStack>
          <FormGrid>
            <Grid item xs={12}>
              <Typography variant="overline" component="h2">
                Secteurs d&apos;exposition
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl error={!!error} fullWidth>
                <FormLabel>
                  Le type d&apos;activité de votre entreprise déterminera le
                  secteur dans lequel vous pourrez choisir un emplacement à
                  l&apos;étape suivante.
                </FormLabel>

                <RadioGroup {...field} className="FormGroupRadio">
                  {sections.nodes.map((section, index) => {
                    const { id, description, closed } = section;

                    return (
                      <Fragment key={index}>
                        <FormControlLabel
                          control={<Radio />}
                          disabled={closed}
                          label={<SectionRadioLabel section={section} />}
                          value={id}
                        />

                        <Typography
                          variant="body2"
                          component="p"
                          sx={{
                            color: 'grey.700',
                            ml: '30px',
                            mb: 1,
                            mt: '-5px',
                          }}
                          dangerouslySetInnerHTML={{ __html: description }}
                        />

                        <SectionAlert section={section} />
                      </Fragment>
                    );
                  })}
                </RadioGroup>

                {!!error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>

              {sections.nodes.map(
                ({ id, categories }, index) =>
                  sectionId === id && (
                    <Fragment key={index}>
                      {isSim && (
                        <Alert
                          severity="warning"
                          sx={{ ml: '30px', mt: 2, boxShadow: 'none' }}
                        >
                          Pour simplifier votre participation et créer une
                          ambiance propre à ce secteur, seuls des emplacements
                          en formule clés en main sont disponibles.{' '}
                          <Link
                            href={PATHS.documents.rentalProperties.path}
                            target="_blank"
                          >
                            Cliquez ici
                          </Link>{' '}
                          pour visualiser l’aménagement du stand et connaître le
                          coût du forfait.
                        </Alert>
                      )}

                      <Box sx={isSim ? { display: 'none' } : undefined}>
                        <SectionCategoriesForm
                          key={index}
                          categories={categories}
                          control={control}
                          edited={edited}
                          sectionId={sectionId}
                          setValue={setValue}
                        />
                      </Box>
                    </Fragment>
                  ),
              )}
            </Grid>

            {!isSim && (
              <Grid item xs={12} md={9}>
                <TextField
                  control={control}
                  fullWidth
                  label="Si vous avez sélectionné plusieurs catégories et que vous désirez en prioriser une pour votre classement dans l'index, veuillez nous indiquer laquelle."
                  name="comments"
                  multiline
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Alert severity="info" sx={{ p: 2, my: 2, boxShadow: 'none' }}>
                <AlertTitle>Plan de l&apos;exposition</AlertTitle>
                <Link href={PATHS.documents.plan.path} target="_blank">
                  Consultez le plan PDF
                </Link>{' '}
                pour visualiser les espaces et la tarification correspondant à
                votre secteur. La mise à jour du plan ne s&apos;effectue pas
                automatiquement car nous devons analyser la situation propre à
                chaque exposant avant de confirmer l&apos;attribution des
                espaces. Il est donc possible que les espaces désirés ne soient
                plus disponibles. Par conséquent, vous êtes invité à effectuer
                trois choix d&apos;emplacement en ordre de priorité/préférence.
                Par la suite, nous communiquerons avec vous pour vous attribuer
                votre numéro d&apos;emplacement officiel.
                <br />
                <br />
                Communiquez avec nous si vous avez besoin d&apos;assistance.
              </Alert>
            </Grid>

            <Grid item xs={12} md={4}>
              <Select
                control={control}
                fullWidth
                label="Superficie souhaitée"
                name="areaId"
                options={areas.nodes.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                rules={validateRequired()}
              />
            </Grid>
          </FormGrid>
        </FormStack>
      </Section>

      {sectionId && (
        <Section>
          <BookingPreferenceLocationsForm
            control={control}
            setValue={setValue}
          />
        </Section>
      )}
    </>
  );
};

export default BookingPreferenceForm;
