import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserMostRecentDetailedSheetService {
  async find(): Promise<DetailedSheetDto> {
    try {
      const { data } = await apiV1.get<DetailedSheetDto>(
        '/detailed-sheets/me/most-recent',
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserMostRecentDetailedSheetService;
