import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import usePagination from '../../hooks/data-tables/pagination.hook';
import useTake from '../../hooks/data-tables/take.hook';
import PageInfoInterface from '../../interfaces/data-tables/pagination/page-info.interface';

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

interface Props {
  pageInfo: PageInfoInterface;
  rowsPerPage?: number[];
}

const DataTableFooter: FC<Props> = ({
  pageInfo,
  rowsPerPage = ROWS_PER_PAGE_OPTIONS,
}) => {
  const { navigateToPreviousPage, navigateToNextPage, page } = usePagination();
  const { setTake, take } = useTake();

  return (
    <Stack
      direction="row"
      alignItems={{ xs: 'flex-end', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent={{ xs: 'space-between' }}
      sx={{ px: 3, pb: 3, pt: 3 }}
    >
      <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
        <Select
          onChange={({ target }): void => setTake(+target.value)}
          value={take}
          sx={{ pr: 2 }}
        >
          {rowsPerPage.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ my: { xs: 2, sm: 0 } }}>
        <Typography variant="body2">
          Page: {pageInfo.page} / {pageInfo.totalPages} - Résultat total:{' '}
          {pageInfo.totalNodes}
        </Typography>
      </Box>

      <Box>
        <IconButton disabled={page === 1} onClick={navigateToPreviousPage}>
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          disabled={page >= pageInfo.totalPages}
          onClick={navigateToNextPage}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default DataTableFooter;
