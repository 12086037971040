import { Components, Theme } from '@mui/material';

import { getRmFromPx } from '../../utils/fonts';

const table = (theme: Theme): Components<Theme> => {
  return {
    MuiTable: {
      defaultProps: {
        size: 'small'
      },

      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            padding:  theme.spacing(1), 
            lineHeight: '1.22em',
            fontSize: getRmFromPx(13),
          },

          '& .MuiTableCell-head:first-of-type': {
            paddingLeft:  theme.spacing(3), 
          },
          '& .MuiTableCell-body': {
            paddingLeft:  theme.spacing(1),
          },
          '& .MuiTableCell-body:first-of-type': {
            paddingLeft:  theme.spacing(3),
          }
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor:  theme.palette.grey[200],
        }
      }
    }
  };
};

export default table;
