import ContractStatusesEnum from '../enums/profiles/contract-statuses.enum';
import PaymentStatusesEnum from '../enums/profiles/payment-statuses.enum';
import OptionInterface from '../interfaces/option.interface';
import GetContractStatusLabelService from '../services/profiles/get-contract-status-label.service';
import GetPaymentStatusLabelService from '../services/profiles/get-payment-status-label.service';

const getContractStatusLabelService = new GetContractStatusLabelService();
const getPaymentStatusLabelService = new GetPaymentStatusLabelService();

export const CONTRACT_OPTIONS: OptionInterface[] = [
  {
    label: 'Tous',
    value: '',
  },
  {
    label: 'Aucun',
    value: ContractStatusesEnum.INITIAL,
  },
  {
    label: getContractStatusLabelService.get(ContractStatusesEnum.SENT),
    value: ContractStatusesEnum.SENT,
  },
  {
    label: getContractStatusLabelService.get(ContractStatusesEnum.SIGNED),
    value: ContractStatusesEnum.SIGNED,
  },
];

export const PAYMENT_OPTIONS: OptionInterface[] = [
  {
    label: 'Tous',
    value: '',
  },
  {
    label: 'Non payé',
    value: PaymentStatusesEnum.INITIAL,
  },
  {
    label: getPaymentStatusLabelService.get(
      PaymentStatusesEnum.INVOICE_PENDING,
    ),
    value: PaymentStatusesEnum.INVOICE_PENDING,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PAID),
    value: PaymentStatusesEnum.PAID,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PAID_PARTIALLY),
    value: PaymentStatusesEnum.PAID_PARTIALLY,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PARTNER),
    value: PaymentStatusesEnum.PARTNER,
  },
  {
    label: getPaymentStatusLabelService.get(
      PaymentStatusesEnum.PAYMENT_PENDING,
    ),
    value: PaymentStatusesEnum.PAYMENT_PENDING,
  },
];
