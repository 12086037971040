import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import ProfileDto from '../../dto/profiles/out/profile.dto';
import FindProfileService from '../../services/profiles/find-profile.service';

const useFindProfile = (
  id: string,
  options?: Omit<UseQueryOptions<ProfileDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<ProfileDto, Error> => {
  return useQuery(
    ['profile', id],
    () => new FindProfileService().find(id),
    options,
  );
};

export default useFindProfile;
