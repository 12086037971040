import BookingDto from '../../dto/bookings/out/booking.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserBookingService {
  async find(): Promise<BookingDto | undefined> {
    try {
      const { data } = await apiV1.get<BookingDto | undefined>('/bookings/me');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserBookingService;
