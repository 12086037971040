import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, useCallback } from 'react';
import {
  FiCreditCard,
  FiList,
  FiMap,
  FiMapPin,
  FiPenTool,
  FiTruck,
  FiUsers,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import BookingDto from '../../dto/bookings/out/booking.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import { PATHS } from '../../utils/paths';

const { admin } = PATHS;

type TabValues =
  | 'access-order'
  | 'assembly'
  | 'details'
  | 'booking'
  | 'payment'
  | 'contract'
  | 'detailed-sheet';

interface Props {
  booking?: BookingDto;
  profile: ProfileDto;
  value: TabValues;
}

const ProfileTabs: FC<Props> = ({ booking, profile, value }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (newValue: TabValues) => {
      if (newValue === value) return;

      const { id } = profile;

      switch (newValue) {
        case 'details': {
          navigate(admin.profiles.edit.path(id));
          break;
        }
        case 'booking': {
          const path = !booking
            ? admin.bookings.new.path(id)
            : admin.bookings.edit.path(id);

          navigate(path);
          break;
        }
        case 'payment': {
          navigate(admin.profiles.payment.path(id));
          break;
        }
        case 'contract': {
          navigate(admin.profiles.contract.path(id));
          break;
        }
        case 'detailed-sheet': {
          navigate(admin.detailedSheets.edit.path(id));
          break;
        }
        case 'assembly': {
          navigate(admin.assemblies.edit.path(id));
          break;
        }
        case 'access-order': {
          navigate(admin.accessOrders.edit.path(id));
          break;
        }
      }
    },
    [booking, profile, value, navigate],
  );

  return (
    <Tabs value={value} sx={{ mt: 2 }} variant="scrollable">
      <Tab
        icon={<FiList />}
        iconPosition="start"
        label="Détails"
        onClick={(): void => handleClick('details')}
        value="details"
      />
      <Tab
        icon={<FiMapPin />}
        iconPosition="start"
        label="Réservations"
        onClick={(): void => handleClick('booking')}
        value="booking"
      />
      <Tab
        icon={<FiCreditCard />}
        iconPosition="start"
        label="Paiement"
        onClick={(): void => handleClick('payment')}
        value="payment"
      />
      <Tab
        icon={<FiPenTool />}
        iconPosition="start"
        label="Contrat"
        onClick={(): void => handleClick('contract')}
        value="contract"
      />
      <Tab
        icon={<FiMap />}
        iconPosition="start"
        label="Fiche exposant"
        onClick={(): void => handleClick('detailed-sheet')}
        value="detailed-sheet"
      />
      <Tab
        icon={<FiTruck />}
        iconPosition="start"
        label="Montage et démontage"
        onClick={(): void => handleClick('assembly')}
        value="assembly"
      />
      <Tab
        icon={<FiUsers />}
        iconPosition="start"
        label="Commandes d'accès"
        onClick={(): void => handleClick('access-order')}
        value="access-order"
      />
    </Tabs>
  );
};

export default ProfileTabs;
