import GetEditionReportDto from '../../dto/editions/in/get-edition-report.dto';
import EditionReportDto from '../../dto/editions/out/edition-report.dto';
import { apiV1 } from '../../http';

class GetEditionReportService {
  async get(
    editionId: string,
    dto: GetEditionReportDto,
  ): Promise<EditionReportDto[]> {
    const { data } = await apiV1.get<EditionReportDto[]>(
      `/editions/${editionId}/report`,
      {
        params: dto,
      },
    );

    return data;
  }
}

export default GetEditionReportService;
