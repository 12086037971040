import SaveBookingLocationDto from '../booking-locations/save-booking-location.dto';

class SaveBookingDto {
  id?: string;
  userId!: string;
  bookingLocations!: SaveBookingLocationDto[];
  price!: string;
  area!: string;
  notes?: string;
}

export default SaveBookingDto;
