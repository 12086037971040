import SaveProfilePaymentDto from '../../dto/profiles/in/save-profile-payment.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveProfilePaymentService {
  async save(id: string, dto: SaveProfilePaymentDto): Promise<void> {
    try {
      await apiV1.put<void>(`/profiles/${id}/payment`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveProfilePaymentService;
