import { useMutation, UseMutationResult } from 'react-query';

import SaveUserAssemblyDto from '../../dto/assemblies/in/save-user-assembly.dto';
import SaveUserAssemblyService from '../../services/assemblies/save-user-assembly.service';

const useSaveUserAssembly = (): UseMutationResult<
  void,
  Error,
  SaveUserAssemblyDto
> => {
  return useMutation(new SaveUserAssemblyService().save);
};

export default useSaveUserAssembly;
