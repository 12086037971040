import SaveAccessOrderDto from '../../dto/access-orders/in/save-access-order.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveAccessOrderService {
  async save(id: string, dto: SaveAccessOrderDto): Promise<void> {
    try {
      await apiV1.put<void>(`/access-orders/${id}`, dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveAccessOrderService;
