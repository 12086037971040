import { useMutation, UseMutationResult } from 'react-query';

import ApproveDetailedSheetService from '../../services/detailed-sheets/approve-detailed-sheet.service';

type Variables = { id: string };

const useApproveDetailedSheet = (): UseMutationResult<
  void,
  Error,
  Variables
> => {
  return useMutation(async (variables) =>
    new ApproveDetailedSheetService().approve(variables.id),
  );
};

export default useApproveDetailedSheet;
