import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import Link from '../navigation/Link';
import UserInfoDto from '../../dto/users/user-info.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  booking: WithRequiredKeys<UserInfoDto, 'booking'>['booking'];
  profile: WithRequiredKeys<UserInfoDto, 'profile'>['profile'];
}

const BookingAlert: FC<Props> = ({ booking, profile }) => {
  const renderLocations = useMemo(
    () =>
      booking.bookingLocations
        .map((bookingLocation) => bookingLocation.location.name)
        .join(', '),
    [booking],
  );

  return (
    <Alert severity="success" sx={{ mt: 4 }}>
      <AlertTitle>Réservation confirmée</AlertTitle>
      Nous vous avons réservé :
      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        Emplacement {booking.mainLocation}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        D&apos;une superficie de {booking.area} p2 dans le secteur «
        {profile.section.name}» pour un montant de {booking.price}$.
      </Typography>
      Cet emplacement comprend les espaces {renderLocations}.{' '}
      <MuiLink href={PATHS.documents.plan.path} target="_blank">
        Voir le plan du Salon.
      </MuiLink>
      <Typography variant="body2" sx={{ mt: 1 }}>
        <Link to={PATHS.exhibitors.path}>Revenir à l&apos;accueil</Link>
      </Typography>
    </Alert>
  );
};

export default BookingAlert;
