import AreaDto from '../../dto/areas/area.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import { apiV1 } from '../../http';

class FindAreasService {
  async find(): Promise<PaginatedResultDto<AreaDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<AreaDto>>('/areas');

    return data;
  }
}

export default FindAreasService;
