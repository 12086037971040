import MuiAutocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  HTMLAttributes,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import OptionInterface from '../../interfaces/option.interface';

interface Props {
  label: string;
  placeholder?: string;
}

const Autocomplete = <T extends FieldValues>({
  label,
  placeholder,
  ...props
}: Props &
  UseControllerProps<T> &
  Omit<
    AutocompleteProps<OptionInterface, true, undefined, undefined>,
    'getOptionLabel' | 'renderInput' | 'renderOption'
  >): ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const { onChange, value: values } = field;
  const { options } = props;

  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, options: OptionInterface[]): void => {
      onChange(options.map((option) => option.value) as PathValue<T, Path<T>>);
    },
    [onChange],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): ReactElement => (
      <TextField
        {...params}
        error={!!error}
        helperText={error?.message}
        label={label}
        placeholder={placeholder}
      />
    ),
    [error, label, placeholder],
  );

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      { label }: OptionInterface,
    ): ReactElement => <li {...props}>{label}</li>,
    [],
  );

  const autocompleteValue = useMemo(
    () =>
      (values as string[]).map(
        (value) =>
          options.find((option) => option.value === value) ?? {
            label: '',
            value: '',
          },
      ),
    [options, values],
  );

  return (
    <MuiAutocomplete
      {...field}
      {...props}
      getOptionDisabled={(option): boolean => option.disabled ?? false}
      getOptionLabel={(option): string => option.label}
      onChange={handleChange}
      renderInput={renderInput}
      renderOption={renderOption}
      value={autocompleteValue}
      disableCloseOnSelect
      multiple
    />
  );
};

export default Autocomplete;
