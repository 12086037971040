import { toast as toastify, ToastOptions } from 'react-toastify';

export const toast = {
  error(
    message = "Une erreur inattendue s'est produite, veuillez réessayer plus tard.",
    options?: ToastOptions,
  ): void {
    toastify.error(message, options);
  },
  success(message = 'Success', options?: ToastOptions): void {
    toastify.success(message, options);
  },
};
