import ProfileDto from '../../dto/profiles/out/profile.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserProfileService {
  async find(): Promise<ProfileDto> {
    try {
      const { data } = await apiV1.get<ProfileDto>('/profiles/me');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserProfileService;
