import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { PATHS } from '../../utils/paths';
import Link from '../navigation/Link';

const BookingProcessingAlert: FC = () => {
  return (
    <Alert severity="warning" sx={{ mt: 4 }}>
      <AlertTitle>Inscription en traitement</AlertTitle>
      Votre demande est en cours de traitement afin de vous proposer le meilleur
      emplacement possible. Vous recevrez une notification par courriel
      lorsq&apos;un emplacement officiel vous sera attribué.
      <Typography variant="body2" sx={{ mt: 1 }}>
        <Link to={PATHS.exhibitors.path}>Revenir à l&apos;accueil</Link>
      </Typography>
    </Alert>
  );
};

export default BookingProcessingAlert;
