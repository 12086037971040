import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { useToggle } from '@react-hookz/web';

import ModalConfirmation from '../../../ModalConfirmation';
import AdminAccessOrderAccreditationsForm from '../../../access-orders/AdminAccessOrderAccreditationsForm';
import AdminAccessOrderParkingsForm from '../../../access-orders/AdminAccessOrderParkingsForm';
import AdminAccessOrderPresaleTicketsForm from '../../../access-orders/AdminAccessOrderPresaleTicketsForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import ProfileTitle from '../../../profiles/ProfileTitle';
import ProfileTabs from '../../../profiles/ProfileTabs';
import SaveAccessOrderDto from '../../../../dto/access-orders/in/save-access-order.dto';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import useFindAccessOrderByUserId from '../../../../hooks/access-orders/find-access-order-by-user-id.hook';
import useSaveAccessOrder from '../../../../hooks/access-orders/save-access-order.hook';
import AdminAccessOrderFieldValues from '../../../../interfaces/access-orders/admin-access-order-field-values.interface';
import GetProfileCompanyNameService from '../../../../services/profiles/get-profile-compamy-name.service';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

interface OutletContext {
  accessOrder: AccessOrderDto;
  booking?: BookingDto;
  assembly: AssemblyDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const AdminEditAccessOrderPage: FC = () => {
  const [modalOpened, toggleModal] = useToggle(false);

  const { accessOrder, assembly, booking, detailedSheet, profile } =
    useOutletContext<OutletContext>();

  const { refetch } = useFindAccessOrderByUserId(profile.user.id);
  const { mutateAsync: saveAccessOrder } = useSaveAccessOrder();

  const formMethods = useForm<AdminAccessOrderFieldValues>({
    defaultValues: {
      companyName:
        accessOrder.companyName ?? getProfileCompanyNameService.get(profile),
      additionalAccreditationQuantity:
        accessOrder.additionalAccreditationQuantity !== undefined &&
        accessOrder.additionalAccreditationQuantity !== null
          ? `${accessOrder.additionalAccreditationQuantity}`
          : '',
      hasVipParking:
        accessOrder.vipParkingQuantity !== undefined &&
        accessOrder.vipParkingQuantity !== null
          ? `${accessOrder.vipParkingQuantity > 0}`
          : '',
      standardParkingQuantity:
        accessOrder.standardParkingQuantity !== undefined &&
        accessOrder.standardParkingQuantity !== null
          ? `${accessOrder.standardParkingQuantity}`
          : '',
      presaleTicketQuantity:
        accessOrder.presaleTicketQuantity !== undefined &&
        accessOrder.presaleTicketQuantity !== null
          ? `${accessOrder.presaleTicketQuantity}`
          : '',
    },
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = plainToClass(SaveAccessOrderDto, {
        companyName: fieldValues.companyName?.trim(),
        additionalAccreditationQuantity:
          fieldValues.additionalAccreditationQuantity,
        vipParkingQuantity: fieldValues.hasVipParking === 'true' ? 1 : 0,
        standardParkingQuantity: fieldValues.standardParkingQuantity,
        presaleTicketQuantity: fieldValues.presaleTicketQuantity,
      });

      await saveAccessOrder({ id: accessOrder.id, dto });
      await refetch();

      toast.success('Enregistré avec succès');
    } catch (error) {
      toast.error();

      Sentry.captureException(error);
    } finally {
      toggleModal(false);
    }
  }, [accessOrder, getValues, refetch, saveAccessOrder, toggleModal]);

  const onSubmit: SubmitHandler<AdminAccessOrderFieldValues> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="access-order" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Accréditations
            </Typography>

            <AdminAccessOrderAccreditationsForm
              accessOrder={accessOrder}
              booking={booking}
              profile={profile}
            />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Stationnements
            </Typography>

            <AdminAccessOrderParkingsForm />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Billets de prévente
            </Typography>

            <AdminAccessOrderPresaleTicketsForm />
          </Section>

          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ float: 'right' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Enregistrer
          </Button>

          <ModalConfirmation
            opened={modalOpened}
            title="Enregistrement de la commande d'accès"
            onSubmit={handleSave}
            onClose={(): void => toggleModal(false)}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default AdminEditAccessOrderPage;
