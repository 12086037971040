import { RawDraftContentState } from 'draft-js';

import StagesEnum from '../../../enums/editions/stages.enum';
import AccessOrderStatusesEnum from '../../../enums/access-orders/access-order-statuses.enum';
import AssemblyStatusesEnum from '../../../enums/assemblies/assembly-statuses.enum';
import DetailedSheetStatusesEnum from '../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import PaymentStatusesEnum from '../../../enums/profiles/payment-statuses.enum';
import ProfileStatusesEnum from '../../../enums/profiles/profile-statuses.enum';

export class SendNotificationProfilesDto {
  stage!: StagesEnum.REGISTRATIONS;
  statuses!: ProfileStatusesEnum[];
  paymentStatus!: PaymentStatusesEnum;
  subject!: string;
  body!: RawDraftContentState;
}

export class SendNotificationDetailedSheetsDto {
  stage!: StagesEnum.DETAILED_SHEETS;
  statuses!: DetailedSheetStatusesEnum[];
  subject!: string;
  body!: RawDraftContentState;
}

export class SendNotificationAssembliesDto {
  stage!: StagesEnum.ASSEMBLIES;
  statuses!: AssemblyStatusesEnum[];
  subject!: string;
  body!: RawDraftContentState;
}

export class SendNotificationAccessOrdersDto {
  stage!: StagesEnum.ACCESS_ORDERS;
  statuses!: AccessOrderStatusesEnum[];
  subject!: string;
  body!: RawDraftContentState;
}

type SendNotificationDto =
  | SendNotificationProfilesDto
  | SendNotificationDetailedSheetsDto
  | SendNotificationAssembliesDto
  | SendNotificationAccessOrdersDto;

export default SendNotificationDto;
