import { FC } from 'react';
import { useMediaQuery } from '@mui/material';

import logo from '../../images/logo-apchq-expo-habitat2.png';

const Logo: FC = () => {  
  const isLargeScreen = useMediaQuery('(min-width: 1024px)'); 
  
  return (
    <img
      src={logo}
      style={{ maxWidth: isLargeScreen ? '215px' : '150px', width: 'auto', marginTop: '10px' }}
      alt="Expo habitat"
    />
  );
};

export default Logo;
