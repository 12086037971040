import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import ProfileDto from '../../dto/profiles/out/profile.dto';
import FindUserProfileService from '../../services/profiles/find-user-profile.service';

const useFindUserProfile = (
  options?: Omit<UseQueryOptions<ProfileDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<ProfileDto, Error> => {
  return useQuery('profiles/me', new FindUserProfileService().find, options);
};

export default useFindUserProfile;
