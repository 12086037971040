import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import EditionTabs from '../../../editions/EditionTabs';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import SaveOpenedStagesDto from '../../../../dto/editions/in/save-opened-stages.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import StagesEnum from '../../../../enums/editions/stages.enum';
import useSaveOpenedStages from '../../../../hooks/editions/save-opened-stages.hook';
import GetStageLabelService from '../../../../services/editions/get-stage-label.service';
import GetStageOpenedService from '../../../../services/editions/get-stage-opened.service';
import { toast } from '../../../../utils/toast';

const getStageLabelService = new GetStageLabelService();
const getStageOpenedService = new GetStageOpenedService();

const STAGES: StagesEnum[] = [
  StagesEnum.DETAILED_SHEETS,
  StagesEnum.ASSEMBLIES,
  StagesEnum.ACCESS_ORDERS,
];

interface OutletContext {
  edition: EditionDto;
}

const StageCodesPage: FC = () => {
  const { mutateAsync: saveOpenedStages } = useSaveOpenedStages();

  const { edition } = useOutletContext<OutletContext>();

  const handleChange = useCallback(
    async (stage: StagesEnum, opened: boolean) => {
      try {
        const dto = plainToClass(SaveOpenedStagesDto, {
          stage,
          opened,
        });

        await saveOpenedStages(dto);

        const actionText = opened ? 'ouverte' : 'fermée';
        const stageLabel = getStageLabelService.get(stage);

        toast.success(`Étape "${stageLabel}" ${actionText} avec succès`);
      } catch (error) {
        toast.error();
      }
    },
    [saveOpenedStages],
  );

  return (
    <>
      <SectionHeader>
        <Typography variant="h2">Édition</Typography>
      </SectionHeader>

      <EditionTabs value="stages" />

      <Section>
        <FormStack>
          <FormGrid>
            <Grid xs={12} item>
              <Typography variant="overline" component="h2">
                Étapes
              </Typography>
            </Grid>

            {STAGES.map((stage, index) => (
              <Grid xs={12} item key={index}>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={getStageOpenedService.get(edition, stage)}
                      onChange={(_, checked): Promise<void> =>
                        handleChange(stage, checked)
                      }
                    />
                  }
                  label={getStageLabelService.get(stage)}
                />
              </Grid>
            ))}
          </FormGrid>
        </FormStack>
      </Section>
    </>
  );
};

export default StageCodesPage;
