import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';

import BookingForm from './BookingForm';
import BookingPreferenceLocationsChips from '../../../booking-preferences/BookingPreferenceLocationsChips';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import SaveBookingDto from '../../../../dto/bookings/save-booking.dto';
import SaveBookingLocationDto from '../../../../dto/booking-locations/save-booking-location.dto';
import PaginatedResultDto from '../../../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import LocationDto from '../../../../dto/locations/out/location.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import UserInfoDto from '../../../../dto/users/user-info.dto';
import useSaveBooking from '../../../../hooks/bookings/save-booking.hook';
import useFindUserInfoById from '../../../../hooks/users/find-user-info-by-id.hook';
import BookingFieldValuesInterface from '../../../../interfaces/bookings/booking-field-values.interface';
import RemoveEmptyStringService from '../../../../services/forms/remove-empty-string.service';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  detailedSheet: DetailedSheetDto;
  locations: PaginatedResultDto<LocationDto>;
  profile: ProfileDto;
  userInfo: UserInfoDto;
}

const AdminEditBookingPage: FC = () => {
  const navigate = useNavigate();

  const { accessOrder, assembly, detailedSheet, locations, profile, userInfo } =
    useOutletContext<OutletContext>();

  const { refetch } = useFindUserInfoById(profile.user.id);

  const { mutateAsync: saveBooking } = useSaveBooking();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<BookingFieldValuesInterface>({
    defaultValues: {
      area: userInfo.booking?.area,
      price: userInfo.booking?.price,
      mainLocation: userInfo.booking?.mainLocation,
      notes: userInfo.booking?.notes,
      bookingLocations:
        userInfo.booking?.bookingLocations.map(
          (bookingLocation) => bookingLocation.location.id,
        ) ?? [],
    },
  });

  const onSubmit: SubmitHandler<BookingFieldValuesInterface> = useCallback(
    async ({
      area,
      bookingLocations,
      mainLocation,
      price,
      notes,
    }: BookingFieldValuesInterface) => {
      try {
        const dto = plainToClass(SaveBookingDto, {
          id: userInfo.booking?.id,
          bookingLocations: bookingLocations.map((location, index) => {
            const id = userInfo.booking?.bookingLocations.find(
              (bookingLocation) => bookingLocation.location.id === location,
            )?.id;

            return plainToClass(SaveBookingLocationDto, {
              displayOrder: index,
              id,
              location,
            });
          }),
          price: price.trim(),
          area: area.trim(),
          mainLocation: mainLocation.trim(),
          notes: new RemoveEmptyStringService().remove(notes),
          userId: profile?.user.id,
        });

        await saveBooking(dto);
        await refetch();

        toast.success('Réservation faite avec succès');

        navigate(PATHS.admin.profiles.path);
      } catch (error) {
        toast.error();
      }
    },
    [navigate, profile, refetch, saveBooking, userInfo],
  );

  const { booking, bookingPreference } = userInfo;

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="booking" />

      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Réservations
          </Typography>

          {bookingPreference && (
            <BookingPreferenceLocationsChips
              bookingPreference={bookingPreference}
            />
          )}

          <BookingForm
            control={control}
            locations={locations.nodes}
            profile={profile}
          />
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer et notifier l&apos;exposant
        </Button>
      </form>
    </>
  );
};

export default AdminEditBookingPage;
