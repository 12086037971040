import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { FC } from 'react';

import NotificationRecipientsTableStatusChip from './NotificationRecipientsTableStatusChip';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import NotificationRecipientsDto from '../../dto/notifications/out/notification-recipients.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import GetProfileCompanyNameService from '../../services/profiles/get-profile-compamy-name.service';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

interface Props {
  notificationRecipients: NotificationRecipientsDto[];
}

const NotificationRecipientsTable: FC<Props> = ({ notificationRecipients }) => {
  return (
    <Table>
      <TableBody>
        {notificationRecipients.map((notificationRecipient) => {
          const {
            user: { id, email },
            profile,
          } = notificationRecipient;

          return (
            <DataTableBodyRow key={id}>
              <TableCell sx={{ width: '1px' }}>
                <NotificationRecipientsTableStatusChip
                  notificationRecipient={notificationRecipient}
                />
              </TableCell>
              <TableCell>
                {getProfileCompanyNameService.get(profile as ProfileDto)}
              </TableCell>
              <TableCell>{email}</TableCell>
            </DataTableBodyRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default NotificationRecipientsTable;
