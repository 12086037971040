import axios from 'axios';

import { setRequestInterceptor, setResponseInterceptor } from './interceptors';

const apiV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const axiosInstances = [apiV1];

for (const axiosInstance of axiosInstances) {
  setRequestInterceptor(axiosInstance);
  setResponseInterceptor(axiosInstance);
}

export { apiV1 };
