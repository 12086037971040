import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Control } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { validateWebsite } from '../../utils/validations';

const PLACEHOLDER = 'https://siteweb.com';

interface Props {
  control: Control<DetailedSheetFieldValuesInterface>;
}

const AdminDetailedSheetInfosForm: FC<Props> = ({ control }) => {
  return (
    <>
      <Typography component="p" sx={{ mb: 2 }}>
        Toutes ces adresses Web (URL) doivent être indiquées selon le format
        standard en commençant par http// ou https// comme par exemple :
        https://expohabitatquebec.com.
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Site Internet (facultatif)"
              placeholder={PLACEHOLDER}
              name="websiteUrl"
              rules={validateWebsite()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Vidéo Youtube ou Vimeo (facultatif)"
              placeholder={PLACEHOLDER}
              name="videoUrl"
              rules={validateWebsite()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Facebook (facultatif)"
              name="facebookUrl"
              placeholder={PLACEHOLDER}
              rules={validateWebsite()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Instagram (facultatif)"
              name="instagramUrl"
              placeholder={PLACEHOLDER}
              rules={validateWebsite()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Linkedin (facultatif)"
              name="linkedinUrl"
              placeholder={PLACEHOLDER}
              rules={validateWebsite()}
              fullWidth
            />
          </Grid>
        </FormGrid>
      </FormStack>
    </>
  );
};

export default AdminDetailedSheetInfosForm;
