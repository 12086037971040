import Chip, { ChipProps } from '@mui/material/Chip';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { FC, useMemo } from 'react';

import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';

const { CANCELED, APPROVED, INCOMPLETE, PROCESSING } =
  DetailedSheetStatusesEnum;

interface Props {
  detailedSheet: DetailedSheetDto;
  chipSx?: SxProps<Theme>;
}

const DetailedSheetStatusChip: FC<Props> = ({ chipSx = {}, detailedSheet }) => {
  const { status } = detailedSheet || { status: INCOMPLETE };

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case APPROVED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  const tooltipProps = useMemo((): Pick<TooltipProps, 'title'> => {
    switch (status) {
      case CANCELED: {
        return { title: 'Annulé' };
      }
      case INCOMPLETE: {
        return { title: 'Incomplet' };
      }
      case PROCESSING: {
        return { title: 'En traitement' };
      }
      case APPROVED: {
        return { title: 'Approuvé' };
      }
    }
  }, [status]);

  return (
    <Tooltip {...tooltipProps} arrow>
      <Chip
        {...props}
        sx={{
          height: '16px',
          width: '16px',
          ...chipSx,
        }}
      />
    </Tooltip>
  );
};

export default DetailedSheetStatusChip;
