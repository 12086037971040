import SaveUserDetailedSheetDto from '../../dto/detailed-sheets/in/save-user-detailed-sheet.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveUserDetailedSheetService {
  async save(dto: SaveUserDetailedSheetDto): Promise<void> {
    try {
      const method = !dto.id ? 'POST' : 'PUT';

      await apiV1.request<void>({
        url: '/detailed-sheets/me',
        data: dto,
        method,
      });
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveUserDetailedSheetService;
