import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import { PATHS } from '../../utils/paths';

const { CANCELED, INCOMPLETE } = ProfileStatusesEnum;

interface Props {
  profile: ProfilesDto;
}

const ProfilesDataTableAnchor: FC<Props> = ({ profile }) => {
  const navigate = useNavigate();

  const companyName = useMemo(() => {
    const { commonCompanyName, legalCompanyName } = profile;

    return commonCompanyName ? commonCompanyName : legalCompanyName;
  }, [profile]);

  const href = useMemo(() => {
    return PATHS.admin.profiles.edit.path(profile.id);
  }, [profile]);

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate],
  );

  if ([CANCELED, INCOMPLETE].includes(profile.status)) {
    return <>{companyName}</>;
  }

  return (
    <Link href={href} onClick={handleClick}>
      {companyName}
    </Link>
  );
};

export default ProfilesDataTableAnchor;
