import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

const TEXT_STYLES = {
  '& ul, & ol': {
    ml: 4,
    my: 2,
  },
  p: {
    my: 2,
  },
};

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetDescriptionsSection: FC<Props> = ({ detailedSheet }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Descriptions
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Texte descriptif complet, destiné à votre fiche en ligne
            </ReadonlyFieldLabel>

            <Typography
              sx={TEXT_STYLES}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  stateToHTML(convertFromRaw(detailedSheet.websiteDescription)),
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>Nouveautés</ReadonlyFieldLabel>

            <Typography
              sx={TEXT_STYLES}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  stateToHTML(
                    convertFromRaw(detailedSheet.highlightsDescription),
                  ),
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>Solution durable</ReadonlyFieldLabel>

            <Typography
              sx={TEXT_STYLES}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  stateToHTML(
                    convertFromRaw(
                      detailedSheet.sustainableSolutionDescription,
                    ),
                  ),
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>Concours</ReadonlyFieldLabel>

            <Typography
              sx={TEXT_STYLES}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  stateToHTML(convertFromRaw(detailedSheet.contestDescription)),
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>Mots-clés</ReadonlyFieldLabel>

            <Typography>{detailedSheet.tags}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetDescriptionsSection;
