enum ErrorTypesEnum {
  DUPLICATE_ENTRY_ERROR = 'DUPLICATE_ENTRY_ERROR',
  INACTIVE_ACTIVATION_CODE = 'INACTIVE_ACTIVATION_CODE',
  EXPIRED_TOKEN_ERROR = 'EXPIRED_TOKEN_ERROR',
  INACTIVE_TOKEN_ERROR = 'INACTIVE_TOKEN_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  WRONG_PASSWORD_ERROR = 'WRONG_PASSWORD_ERROR',
}

export default ErrorTypesEnum;
