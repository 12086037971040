import { Components, Theme } from '@mui/material';

import { getFontSizesForBreakpoints } from '../../utils/fonts';

const tabs = (theme: Theme): Components<Theme> => {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '58px',
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          ...getFontSizesForBreakpoints({ sm: 14, md: 16, lg: 18 }),
          fontWeight: '400',
          textTransform: 'none',
        },
      },
    },
  };
};

export default tabs;
