import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindUserAccessOrder from '../../../hooks/access-orders/find-user-access-order.hook';
import useFindUserAssembly from '../../../hooks/assemblies/find-user-assembly.hook';
import useFindUserDetailedSheet from '../../../hooks/detailed-sheets/find-user-detailed-sheet.hook';
import useFindActiveEdition from '../../../hooks/editions/find-active-edition.hook';
import useFindUserProfile from '../../../hooks/profiles/find-user-profile.hook';

const ExhibitorDashboardContainer: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();

  const { data: profile, status: profileStatus } = useFindUserProfile();

  const { data: accessOrder, status: accessOrderStatus } =
    useFindUserAccessOrder();

  const { data: assembly, status: assemblyStatus } = useFindUserAssembly();

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindUserDetailedSheet();

  if (
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle' ||
    editionStatus === 'loading' ||
    editionStatus === 'idle' ||
    profileStatus === 'loading' ||
    profileStatus === 'idle' ||
    detailedSheetStatus === 'loading' ||
    detailedSheetStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (
    accessOrderStatus === 'error' ||
    assemblyStatus === 'error' ||
    editionStatus === 'error' ||
    profileStatus === 'error' ||
    detailedSheetStatus === 'error'
  ) {
    return <Error />;
  }

  return (
    <Outlet
      context={{ accessOrder, assembly, detailedSheet, edition, profile }}
    />
  );
};

export default ExhibitorDashboardContainer;
