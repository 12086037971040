import Card, { CardProps } from '@mui/material/Card';
import { FC } from 'react';

const ExhibitorDashboardCard: FC<PropsWithChildren & CardProps> = ({
  children,
  ...props
}) => {
  return (
    <Card sx={{ p: 3, pt: 2, mb: 3 }} {...props}>
      {children}
    </Card>
  );
};

export default ExhibitorDashboardCard;
