import { useMutation, UseMutationResult } from 'react-query';

import SaveProfileDto from '../../dto/profiles/in/save-profile.dto';
import SaveProfileService from '../../services/profiles/save-profile.service';

const useSaveProfile = (): UseMutationResult<void, Error, SaveProfileDto> => {
  return useMutation(new SaveProfileService().save);
};

export default useSaveProfile;
