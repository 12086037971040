import { useMutation, UseMutationResult } from 'react-query';

import SaveDetailedSheetDto from '../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import SaveDetailedSheetService from '../../services/detailed-sheets/save-detailed-sheet.service';

const useSaveDetailedSheet = (): UseMutationResult<
  void,
  Error,
  SaveDetailedSheetDto
> => {
  return useMutation(new SaveDetailedSheetService().save);
};

export default useSaveDetailedSheet;
