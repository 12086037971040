import Typography from '@mui/material/Typography';
import { FC } from 'react';

import AccessOrdersDataTable from '../../../access-orders/AccessOrdersDataTable';
import SectionHeader from '../../../layouts/SectionHeader';
import { DataTableProvider } from '../../../../providers/data-tables/DataTableProvider';

const AdminAccessOrdersListPage: FC = () => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Commandes d&apos;accès au Salon</Typography>
      </SectionHeader>

      <DataTableProvider dataTableOptions={{ showArchives: true }}>
        <AccessOrdersDataTable />
      </DataTableProvider>
    </>
  );
};

export default AdminAccessOrdersListPage;
