import EditionDto from '../../dto/editions/out/edition.dto';
import { apiV1 } from '../../http';

class FindActiveEditionService {
  async find(): Promise<EditionDto> {
    const { data } = await apiV1.get<EditionDto>('/editions/active');

    return data;
  }
}

export default FindActiveEditionService;
