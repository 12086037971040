import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { FC, useMemo } from 'react';

import SectionDto from '../../dto/sections/section.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  section: SectionDto;
}

const SectionAlert: FC<Props> = ({ section }) => {
  const hasClosedCategories = useMemo(() => {
    return section.categories.some((category) => category.closed);
  }, [section]);

  if (section.closed) {
    return (
      <Alert severity="error" sx={{ ml: '30px', mt: 2, boxShadow: 'none' }}>
        Ce secteur est malheureusement complet. Si vous désirez vous inscrire
        sur la liste d&apos;attente, envoyez un courriel à l&apos;adresse{' '}
        <Link href="mailto:jlegare@apchqquebec.ca" underline="none">
          jlegare@apchqquebec.ca
        </Link>
        .
      </Alert>
    );
  }

  if (hasClosedCategories) {
    return (
      <Alert severity="warning" sx={{ ml: '30px', mt: 2, boxShadow: 'none' }}>
        Une ou plusieurs catégories de ce secteur sont complètes. Pour en savoir
        plus sur notre politique de limite d&apos;espace par catégorie,{' '}
        <Link
          target="_blank"
          href={PATHS.documents.squareFeetPolicy.path}
          underline="none"
        >
          cliquez ici
        </Link>
        .
      </Alert>
    );
  }

  return null;
};

export default SectionAlert;
