import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import { ReactElement, useMemo } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import OptionInterface from '../../interfaces/option.interface';

interface Props {
  options: OptionInterface[];
}

const Select = <T extends FieldValues>({
  children,
  label,
  options,
  ...props
}: UseControllerProps<T> & SelectProps & Props): ReactElement => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController(props);

  const renderHelperText = useMemo(() => {
    if (!error) return null;

    return <FormHelperText>{error.message}</FormHelperText>;
  }, [error]);

  const renderMenuItems = useMemo(() => {
    return options.map(({ disabled, label, value }, index) => (
      <MenuItem key={index} disabled={disabled} value={value}>
        {label}
      </MenuItem>
    ));
  }, [options]);

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>

      <MuiSelect
        {...props}
        {...field}
        onChange={(event): void =>
          onChange(event.target.value as PathValue<T, Path<T>>)
        }
        error={!!error}
        sx={{ marginTop: '-5px', paddingTop: '5px' }}
      >
        {renderMenuItems}
      </MuiSelect>

      {renderHelperText}
    </FormControl>
  );
};

export default Select;
