import { Components, Theme } from '@mui/material';

const select = (_theme: Theme): Components<Theme> => {
  return {
    MuiSelect: {
      defaultProps: {
        displayEmpty: true,
        variant: "outlined",
        fullWidth: true
      },
    }
  };
};

export default select;
