import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindDetailedSheetByUserIdService {
  async find(id: string): Promise<DetailedSheetDto> {
    try {
      const { data } = await apiV1.get<DetailedSheetDto>(
        `/users/${id}/detailed-sheet`,
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindDetailedSheetByUserIdService;
