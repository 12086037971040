import BookingDto from '../../dto/bookings/out/booking.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindBookingByUserIdService {
  async find(id: string): Promise<BookingDto | undefined> {
    try {
      const { data } = await apiV1.get<BookingDto | undefined>(
        `/users/${id}/bookings`,
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindBookingByUserIdService;
