import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Select from '../../../forms/Select';
import TextField from '../../../forms/TextField';
import Section from '../../../layouts/Section';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import SaveProfilePaymentDto from '../../../../dto/profiles/in/save-profile-payment.dto';
import AccessOrderPaymentStatusesEnum from '../../../../enums/access-orders/access-order-payment-statuses.enum';
import PaymentStatusesEnum from '../../../../enums/profiles/payment-statuses.enum';
import useFindAccessOrderByUserId from '../../../../hooks/access-orders/find-access-order-by-user-id.hook';
import useFindProfile from '../../../../hooks/profiles/find-profile.hook';
import useSaveProfilePayment from '../../../../hooks/profiles/save-profile-payment.hook';
import OptionInterface from '../../../../interfaces/option.interface';
import ProfilePaymentFieldValuesInterface from '../../../../interfaces/profiles/profile-payment-field-values.interface';
import GetAccessOrderPaymentStatusLabelService from '../../../../services/access-orders/get-access-order-payment-status-label.service';
import GetPaymentStatusLabelService from '../../../../services/profiles/get-payment-status-label.service';
import { toast } from '../../../../utils/toast';
import {
  onInvalidSubmit,
  validateRequired,
} from '../../../../utils/validations';

const getAccessOrderPaymentStatusLabelService =
  new GetAccessOrderPaymentStatusLabelService();

const getPaymentStatusLabelService = new GetPaymentStatusLabelService();

const PAYMENT_OPTIONS: OptionInterface[] = [
  {
    label: 'Aucun',
    value: PaymentStatusesEnum.INITIAL,
  },
  {
    label: getPaymentStatusLabelService.get(
      PaymentStatusesEnum.INVOICE_PENDING,
    ),
    value: PaymentStatusesEnum.INVOICE_PENDING,
  },
  {
    label: getPaymentStatusLabelService.get(
      PaymentStatusesEnum.PAYMENT_PENDING,
    ),
    value: PaymentStatusesEnum.PAYMENT_PENDING,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PAID_PARTIALLY),
    value: PaymentStatusesEnum.PAID_PARTIALLY,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PAID),
    value: PaymentStatusesEnum.PAID,
  },
  {
    label: getPaymentStatusLabelService.get(PaymentStatusesEnum.PARTNER),
    value: PaymentStatusesEnum.PARTNER,
  },
];

const ACCESS_ORDER_PAYMENT_OPTIONS: OptionInterface[] = [
  {
    label: 'Aucun',
    value: AccessOrderPaymentStatusesEnum.INITIAL,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.INVOICE_PENDING,
    ),
    value: AccessOrderPaymentStatusesEnum.INVOICE_PENDING,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.PAYMENT_PENDING,
    ),
    value: AccessOrderPaymentStatusesEnum.PAYMENT_PENDING,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.PAID,
    ),
    value: AccessOrderPaymentStatusesEnum.PAID,
  },
];

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const AdminProfilePaymentPage: FC = () => {
  const { accessOrder, assembly, booking, detailedSheet, profile } =
    useOutletContext<OutletContext>();

  const { mutateAsync: saveProfilePayment } = useSaveProfilePayment();

  const { refetch: refetchProfile } = useFindProfile(profile.id);

  const { refetch: refetchAccessOrder } = useFindAccessOrderByUserId(
    profile.user.id,
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<ProfilePaymentFieldValuesInterface>({
    defaultValues: {
      paymentNotes: profile.paymentNotes ?? '',
      paymentStatus: profile.paymentStatus,
      accessOrderPaymentNotes: accessOrder.paymentNotes ?? '',
      accessOrderPaymentStatus: accessOrder.paymentStatus,
    },
  });

  const onSubmit: SubmitHandler<ProfilePaymentFieldValuesInterface> =
    useCallback(
      async (fieldValues) => {
        try {
          const dto = plainToClass(SaveProfilePaymentDto, {
            paymentStatus: fieldValues.paymentStatus,
            paymentNotes: fieldValues.paymentNotes?.trim(),
            accessOrderPaymentStatus: fieldValues.accessOrderPaymentStatus,
            accessOrderPaymentNotes:
              fieldValues.accessOrderPaymentNotes?.trim(),
          });

          await saveProfilePayment({ id: profile.id, dto });

          await Promise.all([refetchProfile(), refetchAccessOrder()]);

          toast.success('Enregistré avec succès');
        } catch (error) {
          toast.error();
        }
      },
      [profile, refetchAccessOrder, refetchProfile, saveProfilePayment],
    );

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="payment" />

      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Section>
          <FormStack>
            <FormGrid>
              <Grid item xs={12}>
                <Typography variant="overline" component="h2">
                  Inscription
                </Typography>
              </Grid>

              <Grid xs={12} sm={4} item>
                <Select
                  control={control}
                  label="Statut"
                  name="paymentStatus"
                  options={PAYMENT_OPTIONS}
                  rules={validateRequired()}
                  fullWidth
                />
              </Grid>

              <Grid
                xs={12}
                md={8}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                item
              />

              <Grid xs={12} sm={6} item>
                <TextField
                  control={control}
                  label="Notes de suivi (visible par les administrateurs seulement)"
                  name="paymentNotes"
                  fullWidth
                  multiline
                />
              </Grid>
            </FormGrid>
          </FormStack>
        </Section>

        <Section>
          <FormStack>
            <FormGrid>
              <Grid item xs={12}>
                <Typography variant="overline" component="h2">
                  Commandes d&apos;accès au Salon
                </Typography>
              </Grid>

              <Grid xs={12} sm={4} item>
                <Select
                  control={control}
                  label="Statut"
                  name="accessOrderPaymentStatus"
                  options={ACCESS_ORDER_PAYMENT_OPTIONS}
                  rules={validateRequired()}
                  fullWidth
                />
              </Grid>

              <Grid
                xs={12}
                md={8}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                item
              />

              <Grid xs={12} sm={6} item>
                <TextField
                  control={control}
                  label="Notes de suivi (visible par les administrateurs seulement)"
                  name="accessOrderPaymentNotes"
                  fullWidth
                  multiline
                />
              </Grid>
            </FormGrid>
          </FormStack>
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer le paiement
        </Button>
      </form>
    </>
  );
};

export default AdminProfilePaymentPage;
