import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindAccessOrderByUserId from '../../../hooks/access-orders/find-access-order-by-user-id.hook';
import useFindAssemblyByUserId from '../../../hooks/assemblies/find-assembly-by-user-id.hook';
import useFindDetailedSheetByUserId from '../../../hooks/detailed-sheets/find-detailed-sheet-by-user-id.hook';
import useFindLocations from '../../../hooks/locations/find-locations.hook';
import useFindProfile from '../../../hooks/profiles/find-profile.hook';
import useFindUserInfoById from '../../../hooks/users/find-user-info-by-id.hook';

const AdminEditBookingContainer: FC = () => {
  const { id = '' } = useParams<{ id: string }>();

  const { data: profile, status: profileStatus } = useFindProfile(id);

  const userId = profile?.user.id ?? '';

  const { data: locations, status: locationsStatus } = useFindLocations({
    take: 1000,
  });

  const { data: accessOrder, status: accessOrderStatus } =
    useFindAccessOrderByUserId(userId, { enabled: !!profile });

  const { data: assembly, status: assemblyStatus } = useFindAssemblyByUserId(
    userId,
    { enabled: !!profile },
  );

  const { data: userInfo, status: userInfoStatus } = useFindUserInfoById(
    userId,
    { enabled: !!profile },
  );

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindDetailedSheetByUserId(userId, { enabled: !!profile });

  if (
    accessOrderStatus === 'loading' ||
    accessOrderStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle' ||
    detailedSheetStatus === 'loading' ||
    detailedSheetStatus === 'idle' ||
    locationsStatus === 'loading' ||
    locationsStatus === 'idle' ||
    profileStatus === 'loading' ||
    profileStatus === 'idle' ||
    userInfoStatus === 'loading' ||
    userInfoStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (
    accessOrderStatus === 'error' ||
    assemblyStatus === 'error' ||
    detailedSheetStatus === 'error' ||
    locationsStatus === 'error' ||
    profileStatus === 'error' ||
    userInfoStatus === 'error'
  ) {
    return <Error />;
  }

  return (
    <Outlet
      context={{
        accessOrder,
        assembly,
        detailedSheet,
        locations,
        profile,
        userInfo,
      }}
    />
  );
};

export default AdminEditBookingContainer;
