import { FC } from 'react';

import BookingAlert from '../bookings/BookingAlert';
import BookingProcessingAlert from '../bookings/BookingProcessingAlert';
import UserInfoDto from '../../dto/users/user-info.dto';
import ProfileCancelledAlert from './ProfileCancelledAlert';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';

interface Props {
  booking: UserInfoDto['booking'];
  profile: WithRequiredKeys<UserInfoDto, 'profile'>['profile'];
}

const ProfileAlert: FC<Props> = ({ booking, profile }) => {
  if (profile.status === ProfileStatusesEnum.CANCELED) {
    return <ProfileCancelledAlert />;
  }

  if (booking) return <BookingAlert booking={booking} profile={profile} />;

  return <BookingProcessingAlert />;
};

export default ProfileAlert;
