import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import EditionsDto from '../../dto/editions/out/editions.dto';
import FindEditionsService from '../../services/editions/find-editions.service';

const useFindEditions = (
  options?: Omit<UseQueryOptions<EditionsDto[], Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<EditionsDto[], Error> => {
  return useQuery('editions', new FindEditionsService().find, options);
};

export default useFindEditions;
