import { plainToClass } from 'class-transformer';
import { convertToRaw } from 'draft-js';

import SendNotificationDto, {
  SendNotificationAccessOrdersDto,
  SendNotificationAssembliesDto,
  SendNotificationDetailedSheetsDto,
  SendNotificationProfilesDto,
} from '../../dto/notifications/in/send-notification.dto';
import StagesEnum from '../../enums/editions/stages.enum';
import NotificationFieldValuesInterface from '../../interfaces/notifications/notification-field-values.interface';

class MapSendNotificationFormToDtoService {
  map(fieldValues: NotificationFieldValuesInterface): SendNotificationDto {
    const notificationContent = {
      subject: fieldValues.subject.trim(),
      body: convertToRaw(fieldValues.body.getCurrentContent()),
    };

    switch (fieldValues.stage) {
      default:
      case StagesEnum.REGISTRATIONS: {
        return plainToClass(SendNotificationProfilesDto, {
          stage: StagesEnum.REGISTRATIONS,
          statuses: fieldValues.statuses,
          paymentStatus: fieldValues.paymentStatus
            ? fieldValues.paymentStatus
            : undefined,
          ...notificationContent,
        });
      }
      case StagesEnum.DETAILED_SHEETS: {
        return plainToClass(SendNotificationDetailedSheetsDto, {
          stage: StagesEnum.DETAILED_SHEETS,
          statuses: fieldValues.statuses,
          ...notificationContent,
        });
      }
      case StagesEnum.ASSEMBLIES: {
        return plainToClass(SendNotificationAssembliesDto, {
          stage: StagesEnum.ASSEMBLIES,
          statuses: fieldValues.statuses,
          ...notificationContent,
        });
      }
      case StagesEnum.ACCESS_ORDERS: {
        return plainToClass(SendNotificationAccessOrdersDto, {
          stage: StagesEnum.ACCESS_ORDERS,
          statuses: fieldValues.statuses,
          paymentStatus: fieldValues.paymentStatus
            ? fieldValues.paymentStatus
            : undefined,
          ...notificationContent,
        });
      }
    }
  }
}

export default MapSendNotificationFormToDtoService;
