import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import BookingDto from '../../dto/bookings/out/booking.dto';
import FindUserBookingService from '../../services/bookings/find-user-booking.service';

const useFindUserBooking = (
  options?: Omit<
    UseQueryOptions<BookingDto | undefined, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<BookingDto | undefined, Error> => {
  return useQuery(['bookings/me'], new FindUserBookingService().find, options);
};

export default useFindUserBooking;
