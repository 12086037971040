import Button from '@mui/material/Button';
import axios from 'axios';
import saveAs from 'file-saver';
import { PDFDocument } from 'pdf-lib';
import { FC, useCallback, useState } from 'react';
import { FiPenTool } from 'react-icons/fi';

import BookingDto from '../../dto/bookings/out/booking.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import GetContractFileName from '../../services/profiles/get-contract-file-name.service';
import { PATHS } from '../../utils/paths';
import { toast } from '../../utils/toast';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';

interface Props {
  edition: EditionDto;
  profile: ProfileDto;
  booking?: BookingDto;
}

const ContractDownload: FC<Props> = ({ edition, profile, booking }) => {
  const [downloading, setDownloading] = useState(false);

  const handlePdf = useCallback(async () => {
    try {
      setDownloading(true);

      const { data } = await axios.get(PATHS.documents.contract.path, {
        responseType: 'arraybuffer',
      });

      const pdfDoc = await PDFDocument.load(data);

      const form = pdfDoc.getForm();

      const {
        legalCompanyName,
        commonCompanyName,
        address,
        city,
        province,
        postalCode,
        apchqMemberNumber,
        rbqMemberNumber,
      } = profile;

      form.getTextField('legalCompanyName').setText(legalCompanyName);
      form.getTextField('commonCompanyName').setText(commonCompanyName ?? '');
      form.getTextField('address').setText(address);
      form.getTextField('city').setText(city);
      form.getTextField('province').setText(province);
      form.getTextField('postalCode').setText(postalCode);
      form.getTextField('apchqMemberNumber').setText(apchqMemberNumber ?? '');
      form.getTextField('rbqMemberNumber').setText(rbqMemberNumber ?? '');

      form
        .getTextField('productsServices')
        .setText(
          profile.profileCategories
            .map((profileCategory) => profileCategory.category.name)
            .join(',\n'),
        );

      if (booking) {
        const { area, bookingLocations } = booking;

        form.getTextField('areaTotal').setText(area ?? '');

        form
          .getTextField('areaNotes')
          .setText(
            bookingLocations
              .map((bookingLocation) => bookingLocation.location.name)
              .join(', '),
          );
      }

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const fileName = new GetContractFileName().get(edition, profile);

      saveAs(modifiedBlob, fileName);
    } catch (_) {
      toast.error();
    } finally {
      setDownloading(false);
    }
  }, [booking, edition, profile]);

  return (
    <Button
      variant="text"
      onClick={handlePdf}
      startIcon={downloading ? <ButtonLoadingIcon /> : <FiPenTool />}
    >
      Générer le contrat
    </Button>
  );
};

export default ContractDownload;
