import UserInfoDto from '../../dto/users/user-info.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserInfoService {
  async find(): Promise<UserInfoDto> {
    try {
      const { data } = await apiV1.get<UserInfoDto>('/users/info');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserInfoService;
