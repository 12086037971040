import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class ArchiveProfileService {
  async archive(id: string): Promise<void> {
    try {
      await apiV1.delete<void>(`/profiles/${id}`);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default ArchiveProfileService;
