import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useController, Control, UseFormSetValue } from 'react-hook-form';

import AdminSectionCategoriesForm from './AdminSectionCategoriesForm';
import SectionRadioLabel from './SectionRadioLabel';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import SectionDto from '../../dto/sections/section.dto';
import AdminProfileFieldValuesInterface from '../../interfaces/profiles/admin-profile-field-values.interface';
import { validateRequired } from '../../utils/validations';

interface Props {
  control: Control<AdminProfileFieldValuesInterface>;
  profile: ProfileDto;
  sections: PaginatedResultDto<SectionDto>;
  setValue: UseFormSetValue<AdminProfileFieldValuesInterface>;
}

const AdminSectionForm = ({
  control,
  profile,
  setValue,
  sections,
}: Props): ReactElement | null => {
  const [edited, setEdited] = useState(false);

  const firstRender = useRef(true);

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'sectionId',
    control,
    rules: validateRequired(),
  });

  const { value: sectionId } = field;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [sectionId, setEdited]);

  const isSim = useMemo(() => {
    const { nodes = [] } = sections ?? {};

    const section = nodes.find((section) => section.id === sectionId);

    return section?.code === 'SIM';
  }, [sections, sectionId]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <Typography variant="overline" component="h2">
            Exposition
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl error={!!error} fullWidth>
            <FormLabel>Secteur</FormLabel>

            <RadioGroup {...field} className="FormGroupRadio">
              {sections.nodes.map((section, index) => {
                return (
                  <Fragment key={index}>
                    <FormControlLabel
                      control={<Radio />}
                      label={<SectionRadioLabel section={section} />}
                      value={section.id}
                    />
                  </Fragment>
                );
              })}
            </RadioGroup>

            {!!error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>

          {sections.nodes.map(
            ({ id, categories }, index) =>
              sectionId === id && (
                <Fragment key={index}>
                  <Box sx={isSim ? { display: 'none' } : undefined}>
                    <AdminSectionCategoriesForm
                      key={index}
                      categories={categories}
                      control={control}
                      edited={edited}
                      sectionId={sectionId}
                      setValue={setValue}
                    />
                  </Box>
                </Fragment>
              ),
          )}
        </Grid>

        <Grid item xs={12} md={6} sx={{ pt: 1 }}>
          <FormLabel>Commentaires</FormLabel>

          <Box>{profile.comments ? profile.comments : '(Aucun)'}</Box>
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminSectionForm;
