import { RawDraftContentState } from 'draft-js';

import SaveFileDto from '../../files/in/save-file.dto';
import ProvincesEnum from '../../../enums/provinces.enum';
import DetailedSheetStatusesEnum from '../../../enums/detailed-sheets/detailed-sheet-statuses.enum';

class SaveDetailedSheetDto {
  id?: string;
  userId?: string;
  companyName!: string;
  address?: string;
  city?: string;
  province?: ProvincesEnum;
  postalCode?: string;
  email?: string;
  phoneNumber?: string;
  phoneNumberExt?: string;
  rbqMemberNumber?: string;
  websiteUrl?: string;
  videoUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  linkedinUrl?: string;
  websiteDescription!: RawDraftContentState;
  highlightsDescription!: RawDraftContentState;
  sustainableSolutionDescription!: RawDraftContentState;
  contestDescription!: RawDraftContentState;
  tags!: string;
  logo!: SaveFileDto;
  image?: SaveFileDto;
  status!: DetailedSheetStatusesEnum;
}
export default SaveDetailedSheetDto;
