import { useMutation, UseMutationResult } from 'react-query';

import SaveAccessOrderDto from '../../dto/access-orders/in/save-access-order.dto';
import SaveAccessOrderService from '../../services/access-orders/save-access-order.service';

const useSaveAccessOrder = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveAccessOrderDto }
> => {
  return useMutation((variables) =>
    new SaveAccessOrderService().save(variables.id, variables.dto),
  );
};

export default useSaveAccessOrder;
