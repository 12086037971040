import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useCallback, useState } from 'react';
import { FiFileText } from 'react-icons/fi';

import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import EditionsDto from '../../dto/editions/out/editions.dto';
import ErrorTypesEnum from '../../enums/error-types.enum';
import DownloadAssembliesReportService from '../../services/assemblies/download-assemblies-report.service';
import GetAssembliesReportService from '../../services/assemblies/get-assemblies-report.service';
import { axiosHttpErrorToError } from '../../utils/errors';
import { toast } from '../../utils/toast';

interface Props {
  edition: EditionsDto;
}

const ReportAssembliesFile: FC<Props> = ({ edition }) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true);

      const data = await new GetAssembliesReportService().get(edition.id);

      new DownloadAssembliesReportService().download(edition, data);
    } catch (error) {
      const { message } = axiosHttpErrorToError(error);

      switch (message) {
        case ErrorTypesEnum.NOT_FOUND_ERROR: {
          toast.error('Le rapport ne contient aucun exposant');

          break;
        }
        default: {
          toast.error();

          break;
        }
      }
    } finally {
      setDownloading(false);
    }
  }, [edition, setDownloading]);

  return (
    <Grid xs={12} item>
      <Button
        onClick={(event): void => {
          event.preventDefault();

          handleDownload();
        }}
        startIcon={downloading ? <ButtonLoadingIcon /> : <FiFileText />}
      >
        Montage et démontage
      </Button>
    </Grid>
  );
};

export default ReportAssembliesFile;
