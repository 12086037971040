import ProvincesEnum from '../../../enums/provinces.enum';
import SaveManagerDto from '../../managers/in/save-manager.dto';

class SaveProfileDto {
  id?: string;
  legalCompanyName!: string;
  commonCompanyName?: string;
  address!: string;
  province!: ProvincesEnum;
  postalCode!: string;
  apchqMemberNumber?: string;
  rbqMemberNumber?: string;
  managers!: SaveManagerDto[];
}

export default SaveProfileDto;
