import { useMutation, UseMutationResult } from 'react-query';

import ArchiveProfileService from '../../services/profiles/archive-profile.service';

const useArchiveProfile = (): UseMutationResult<
  void,
  Error,
  { id: string }
> => {
  return useMutation((variables) =>
    new ArchiveProfileService().archive(variables.id),
  );
};

export default useArchiveProfile;
