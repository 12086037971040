import ActivationCodeDto from '../../dto/activation-codes/out/activation-code.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindActivationCodesService {
  async find(): Promise<ActivationCodeDto[]> {
    try {
      const { data } = await apiV1.get<ActivationCodeDto[]>(
        '/activation-codes',
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindActivationCodesService;
