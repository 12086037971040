import SaveUserAssemblyDto from '../../dto/assemblies/in/save-user-assembly.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveUserAssemblyService {
  async save(dto: SaveUserAssemblyDto): Promise<void> {
    try {
      await apiV1.put<void>('/assemblies/me', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveUserAssemblyService;
