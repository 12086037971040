import { FC, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindActiveEdition from '../../../hooks/editions/find-active-edition.hook';
import useFindUserCurrentStep from '../../../hooks/users/find-user-current-step.hook';
import { PATHS } from '../../../utils/paths';
import { roleIsAdmin } from '../../../utils/roles';

const { admin, exhibitors } = PATHS;

const ExhibitorsWorkflow: FC = () => {
  const location = useLocation();
  const isAdmin = roleIsAdmin();

  const { data: edition, status: editionStatus } = useFindActiveEdition({
    enabled: !isAdmin,
  });

  const editionClosed = edition?.closed ?? true;

  const { data: userCurrentStep, status: userCurrentStepStatus } =
    useFindUserCurrentStep({
      enabled: !isAdmin && !editionClosed,
    });

  const isNoActiveEditionPage = useMemo(
    () => location.pathname.includes('/no-active-edition'),
    [location],
  );

  if (userCurrentStepStatus === 'loading' || editionStatus === 'loading') {
    return <Loading />;
  }

  if (
    !['idle', 'success'].includes(userCurrentStepStatus) ||
    !['idle', 'success'].includes(editionStatus)
  ) {
    return <Error />;
  }

  if (isAdmin) return <Navigate to={admin.path} replace />;

  if (editionClosed && !isNoActiveEditionPage) {
    return <Navigate to={exhibitors.noActiveEdition.path} replace />;
  }

  if (!editionClosed && isNoActiveEditionPage) {
    return <Navigate to={exhibitors.path} replace />;
  }

  return <Outlet context={userCurrentStep} />;
};

export default ExhibitorsWorkflow;
