import { plainToClass } from 'class-transformer';
import { convertToRaw } from 'draft-js';
import { UseFormReturn } from 'react-hook-form';

import MapDetailedSheetImagesToSaveFileDto from './images/map-detailed-sheet-images-to-save-file-dto.service';
import SaveDetailedSheetDto from '../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';

const mapDetailedSheetImagesToSaveFileDto =
  new MapDetailedSheetImagesToSaveFileDto();

class MapFieldValuesToSaveDetailedSheetDto {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
    fieldValues: DetailedSheetFieldValuesInterface,
    formMethods: UseFormReturn<DetailedSheetFieldValuesInterface>,
  ): SaveDetailedSheetDto {
    const detailedSheetImages = mapDetailedSheetImagesToSaveFileDto.map(
      edition,
      profile,
      detailedSheet,
      fieldValues,
      formMethods,
    );

    return plainToClass(SaveDetailedSheetDto, {
      id: detailedSheet.id,
      userId: profile.user.id,
      companyName: fieldValues.companyName.trim(),
      address: fieldValues.address.trim(),
      city: fieldValues.city.trim(),
      province: fieldValues.province.trim(),
      postalCode: fieldValues.postalCode.trim(),
      email: fieldValues.email?.trim(),
      phoneNumber: fieldValues.phoneNumber?.trim(),
      phoneNumberExt: fieldValues.phoneNumberExt.trim(),
      rbqMemberNumber: fieldValues.rbqMemberNumber,
      websiteUrl: fieldValues.websiteUrl?.trim(),
      instagramUrl: fieldValues.instagramUrl?.trim(),
      facebookUrl: fieldValues.facebookUrl?.trim(),
      linkedinUrl: fieldValues.linkedinUrl?.trim(),
      videoUrl: fieldValues.videoUrl?.trim(),
      websiteDescription: convertToRaw(
        fieldValues.websiteDescription.getCurrentContent(),
      ),
      highlightsDescription: convertToRaw(
        fieldValues.highlightsDescription.getCurrentContent(),
      ),
      sustainableSolutionDescription: convertToRaw(
        fieldValues.sustainableSolutionDescription.getCurrentContent(),
      ),
      contestDescription: convertToRaw(
        fieldValues.contestDescription.getCurrentContent(),
      ),
      tags: fieldValues.tags.trim(),
      detailedSheetActivityFields: fieldValues.activityFields.map(
        (activityField) => ({
          activityField,
        }),
      ),
      logo: detailedSheetImages[0],
      image: detailedSheetImages[1],
    });
  }
}

export default MapFieldValuesToSaveDetailedSheetDto;
