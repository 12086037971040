import SaveIncludedAccreditationQuantityDto from '../../dto/access-orders/in/save-included-accreditation-quantity.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveIncludedAccreditationService {
  async save(
    id: string,
    dto: SaveIncludedAccreditationQuantityDto,
  ): Promise<void> {
    try {
      await apiV1.put<void>(
        `/access-orders/${id}/included-accreditation-quantity`,
        dto,
      );
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveIncludedAccreditationService;
