import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import FindUserMostRecentDetailedSheetService from '../../services/detailed-sheets/find-user-most-recent-detailed-sheet.service';

const useFindUserMostRecentDetailedSheet = (
  options?: Omit<
    UseQueryOptions<DetailedSheetDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<DetailedSheetDto, Error> => {
  return useQuery(
    'detailed-sheets/me/most-recent',
    new FindUserMostRecentDetailedSheetService().find,
    options,
  );
};

export default useFindUserMostRecentDetailedSheet;
