import { FC, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import Error from '../../Error';
import Loading from '../../Loading';
import NotificationRecipientsDto from '../../../dto/notifications/out/notification-recipients.dto';
import useFindNotificationRecipients from '../../../hooks/notifications/find-notification-recipients.hook';
import NotificationFieldValuesInterface from '../../../interfaces/notifications/notification-field-values.interface';
import MapFindNotificationRecipientsFormToDtoService from '../../../services/notifications/map-find-notification-recipients-form-to-dto.service';

const mapFindNotificationRecipientsFormToDtoService =
  new MapFindNotificationRecipientsFormToDtoService();

type Data = {
  notificationRecipients: NotificationRecipientsDto[];
};

interface Props {
  enabled: boolean;
  render: (data: Data) => ReactElement;
}

const NotificationModalConfirmationContainer: FC<Props> = ({
  enabled,
  render,
}) => {
  const { getValues } = useFormContext<NotificationFieldValuesInterface>();

  const fieldValues = getValues();

  const dto = mapFindNotificationRecipientsFormToDtoService.map(fieldValues);

  const { data: notificationRecipients, status: notificationRecipientsStatus } =
    useFindNotificationRecipients(dto, { enabled });

  if (
    notificationRecipientsStatus === 'loading' ||
    notificationRecipientsStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (notificationRecipientsStatus === 'error') return <Error />;

  return render({ notificationRecipients });
};

export default NotificationModalConfirmationContainer;
