import ProvincesEnum from '../enums/provinces.enum';

export const PROVINCES_OPTIONS = [
  { label: 'Québec', value: ProvincesEnum.QC },
  { label: 'Ontario', value: ProvincesEnum.ON },
  { label: 'Colombie-Britannique', value: ProvincesEnum.BC },
  { label: 'Île-du-Prince-Édouard', value: ProvincesEnum.PEI },
  { label: 'Manitoba', value: ProvincesEnum.MB },
  { label: 'Nouveau-Brunswick', value: ProvincesEnum.NB },
  { label: 'Nouvelle-Écosse', value: ProvincesEnum.NS },
  { label: 'Nunavut', value: ProvincesEnum.NU },
  { label: 'Saskatchewan', value: ProvincesEnum.SK },
  { label: 'Terre-Neuve-et-Labrador', value: ProvincesEnum.TNL },
  {
    label: 'Territoires du Nord-Ouest',
    value: ProvincesEnum.TNO,
  },
  { label: 'Yukon', value: ProvincesEnum.YU },
];
