import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './Footer';
import Logo from './Logo';

const AuthLayout: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Container
        sx={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              mb: { xs: 4, md: 8 },
            }}
          >
            <Logo />
          </Box>

          <Paper sx={{ py: { xs: 4, md: 6 }, px: { xs: 2, md: 6 } }}>
            <Outlet />
          </Paper>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default AuthLayout;
