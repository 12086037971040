import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { DefaultValues } from 'react-hook-form';

import GetProfileCompanyNameService from '../profiles/get-profile-compamy-name.service';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { hasRawDraftContentText } from '../../utils/rich-texts';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

class GetDetailedSheetDefaultFormValues {
  get(
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
    mostRecentDetailedSheet: DetailedSheetDto | null = null,
  ): DefaultValues<DetailedSheetFieldValuesInterface> {
    const isNotIncomplete =
      detailedSheet.status !== DetailedSheetStatusesEnum.INCOMPLETE;

    if (isNotIncomplete) {
      return {
        companyName: detailedSheet.companyName,
        address: detailedSheet.address,
        city: detailedSheet.city,
        province: detailedSheet.province,
        postalCode: detailedSheet.postalCode,
        email: detailedSheet.email,
        phoneNumber: detailedSheet.phoneNumber,
        phoneNumberExt: detailedSheet.phoneNumberExt ?? '',
        isRbqMember: `${!!detailedSheet.rbqMemberNumber}`,
        rbqMemberNumber: detailedSheet.rbqMemberNumber ?? '',
        websiteUrl: detailedSheet.websiteUrl ?? '',
        instagramUrl: detailedSheet.instagramUrl ?? '',
        facebookUrl: detailedSheet.facebookUrl ?? '',
        linkedinUrl: detailedSheet.linkedinUrl ?? '',
        videoUrl: detailedSheet.videoUrl ?? '',
        websiteDescription: EditorState.createWithContent(
          hasRawDraftContentText(detailedSheet.websiteDescription)
            ? convertFromRaw(detailedSheet.websiteDescription)
            : ContentState.createFromText(''),
        ),
        highlightsDescription: EditorState.createWithContent(
          hasRawDraftContentText(detailedSheet.highlightsDescription)
            ? convertFromRaw(detailedSheet.highlightsDescription)
            : ContentState.createFromText(''),
        ),
        sustainableSolutionDescription: EditorState.createWithContent(
          hasRawDraftContentText(detailedSheet.sustainableSolutionDescription)
            ? convertFromRaw(detailedSheet.sustainableSolutionDescription)
            : ContentState.createFromText(''),
        ),
        contestDescription: EditorState.createWithContent(
          hasRawDraftContentText(detailedSheet.contestDescription)
            ? convertFromRaw(detailedSheet.contestDescription)
            : ContentState.createFromText(''),
        ),
        tags: detailedSheet.tags,
        activityFields: detailedSheet.detailedSheetActivityFields.map(
          (detailedSheetActivityField) =>
            detailedSheetActivityField.activityField.id,
        ),
        logo: detailedSheet.logo
          ? new File([], detailedSheet.logo.name)
          : undefined,
        image: detailedSheet.image
          ? new File([], detailedSheet.image.name)
          : undefined,
        copyPreviousLogo: 'false',
        copyPreviousImage: 'false',
      };
    }

    return {
      companyName:
        mostRecentDetailedSheet?.companyName ||
        getProfileCompanyNameService.get(profile),
      address: mostRecentDetailedSheet?.address || profile.address,
      city: mostRecentDetailedSheet?.city || profile.city,
      province: mostRecentDetailedSheet?.province || profile.province,
      postalCode: mostRecentDetailedSheet?.postalCode || profile.postalCode,
      email: mostRecentDetailedSheet?.email || '',
      phoneNumber: mostRecentDetailedSheet?.phoneNumber || '',
      phoneNumberExt: mostRecentDetailedSheet?.phoneNumberExt || '',
      isRbqMember: `${!!(
        mostRecentDetailedSheet?.rbqMemberNumber || profile.rbqMemberNumber
      )}`,
      rbqMemberNumber:
        mostRecentDetailedSheet?.rbqMemberNumber ||
        profile.rbqMemberNumber ||
        '',
      websiteUrl: mostRecentDetailedSheet?.websiteUrl || '',
      instagramUrl: mostRecentDetailedSheet?.instagramUrl || '',
      facebookUrl: mostRecentDetailedSheet?.facebookUrl || '',
      linkedinUrl: mostRecentDetailedSheet?.linkedinUrl || '',
      videoUrl: mostRecentDetailedSheet?.videoUrl || '',
      websiteDescription: EditorState.createWithContent(
        mostRecentDetailedSheet?.websiteDescription &&
          hasRawDraftContentText(mostRecentDetailedSheet.websiteDescription)
          ? convertFromRaw(mostRecentDetailedSheet.websiteDescription)
          : ContentState.createFromText(''),
      ),
      highlightsDescription: EditorState.createWithContent(
        mostRecentDetailedSheet?.highlightsDescription &&
          hasRawDraftContentText(mostRecentDetailedSheet.highlightsDescription)
          ? convertFromRaw(mostRecentDetailedSheet.highlightsDescription)
          : ContentState.createFromText(''),
      ),
      sustainableSolutionDescription: EditorState.createWithContent(
        mostRecentDetailedSheet?.sustainableSolutionDescription &&
          hasRawDraftContentText(
            mostRecentDetailedSheet.sustainableSolutionDescription,
          )
          ? convertFromRaw(
              mostRecentDetailedSheet.sustainableSolutionDescription,
            )
          : ContentState.createFromText(''),
      ),
      contestDescription: EditorState.createWithContent(
        mostRecentDetailedSheet?.contestDescription &&
          hasRawDraftContentText(mostRecentDetailedSheet.contestDescription)
          ? convertFromRaw(mostRecentDetailedSheet.contestDescription)
          : ContentState.createFromText(''),
      ),
      tags: mostRecentDetailedSheet?.tags || '',
      activityFields: (
        mostRecentDetailedSheet?.detailedSheetActivityFields ?? []
      ).map(
        (detailedSheetActivityField) =>
          detailedSheetActivityField.activityField.id,
      ),
      logo: undefined,
      image: undefined,
      copyPreviousLogo: `${!!mostRecentDetailedSheet?.logo}`,
      copyPreviousImage: `${!!mostRecentDetailedSheet?.image}`,
    };
  }
}

export default GetDetailedSheetDefaultFormValues;
