import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useMemo } from 'react';

import Modal from './Modal';

interface Props {
  loading?: boolean;
  opened: boolean;
  title: string;
  text?: string;
  loadingText?: string;
  onClose: () => void;
  onSubmit: () => void | Promise<void>;
}

const ModalConfirmation: FC<Props> = ({
  loading = false,
  opened,
  title,
  text = 'Êtes-vous certain de vouloir procéder à cette action ?',
  loadingText = 'Veuillez patienter...',
  onClose,
  onSubmit,
}) => {
  const renderContent = useMemo(() => {
    if (loading)
      return (
        <>
          {loadingText && (
            <Grid item xs={12} mb={2}>
              <Typography>{loadingText}</Typography>
            </Grid>
          )}

          <Box sx={{ mt: 3, mb: 2, textAlign: 'center', width: '100%' }}>
            <CircularProgress />
          </Box>
        </>
      );

    return (
      <>
        <Grid item xs={12} mb={2}>
          <Typography>{text}</Typography>
        </Grid>

        <Grid item xs={12} textAlign="right">
          <Button sx={{ mr: 4 }} onClick={onClose}>
            Annuler
          </Button>

          <Button onClick={onSubmit} variant="contained">
            Oui, continuer
          </Button>
        </Grid>
      </>
    );
  }, [loading, loadingText, onClose, onSubmit, text]);

  return (
    <Modal open={opened} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="overline" component="h2">
            {title}
          </Typography>
        </Grid>

        {renderContent}
      </Grid>
    </Modal>
  );
};

export default ModalConfirmation;
