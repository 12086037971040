import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { roleIsAdmin } from '../../utils/roles';

const AppWorkflow: FC = () => {
  const isAdmin = roleIsAdmin();

  const path = isAdmin ? '/admin' : '/exhibitors';

  return <Navigate to={path} replace />;
};

export default AppWorkflow;
