import { useCallback } from 'react';

import TakeHookInterface from '../../interfaces/data-tables/take/take-hook.interface';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';

const useTake = (): TakeHookInterface => {
  const { dataTableOptions, setDataTableOptions } = useDataTable();

  const { take = 25 } = dataTableOptions;

  const setTake = useCallback(
    (value: number): void =>
      setDataTableOptions({
        ...dataTableOptions,
        page: 1,
        take: value,
      }),
    [dataTableOptions, setDataTableOptions],
  );

  return { setTake, take };
};

export default useTake;
