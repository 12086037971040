import { Components, Theme } from '@mui/material';

const cssBaseline = (theme: Theme): Components<Theme> => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.grey[100],
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        '.Toastify__toast': {
          color: 'white',
          lineHeight: '1.2rem'
        },
        '.Toastify__toast-body': {
          padding: theme.spacing(2)
        },
        '.Toastify__toast--error': {
          background: theme.palette.error.main,
        },
        '.Toastify__toast--success': {
          background: theme.palette.success.main,
        },
        '.Toastify__toast-icon': {
          width: '26px',
          marginRight: theme.spacing(2)
        },
        '.Toastify__toast-icon svg': {
          fill: 'white',
        },        
        '.Toastify__progress-bar': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },        
      },
    },
  };
}


export default cssBaseline;
