import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import RadioButtons from '../forms/RadioButtons';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { getFileKey } from '../../utils/files';

interface Props {
  description: string;
  image: NonNullable<DetailedSheetDto['logo'] | DetailedSheetDto['image']>;
  label: string;
  radioButtonsLabel: string;
  name: 'copyPreviousImage' | 'copyPreviousLogo';
}

const DetailedSheetCopiedImageForm: FC<Props> = ({
  description,
  image,
  radioButtonsLabel,
  label,
  name,
}) => {
  const { control } = useFormContext<DetailedSheetFieldValuesInterface>();

  return (
    <Grid xs={12} item>
      <ReadonlyFieldLabel>{label}</ReadonlyFieldLabel>

      <Typography variant="body2" color="grey.600" sx={{ mb: 2 }}>
        {description}
      </Typography>

      <Grid xs={12} md={6} item>
        <Box
          component="img"
          src={`${process.env.REACT_APP_ASSETS_URL}/${getFileKey(image)}`}
          alt={label}
          sx={{
            maxWidth: '300px',
            height: 'auto',
          }}
        />
      </Grid>

      <Grid sx={{ mt: 2 }} xs={12} item>
        <RadioButtons
          control={control}
          name={name}
          label={radioButtonsLabel}
          options={[
            { label: 'Oui', value: 'true' },
            { label: 'Non', value: 'false' },
          ]}
          row
        />
      </Grid>
    </Grid>
  );
};

export default DetailedSheetCopiedImageForm;
