import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import TextField from '../forms/TextField';
import AccessOrderFieldValues from '../../interfaces/access-orders/access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

const AccessOrderParkingsForm: FC = () => {
  const { control, formState, trigger } =
    useFormContext<AccessOrderFieldValues>();

  const hasStandardParking =
    useWatch({ name: 'hasStandardParking', control }) === 'true';

  useEffect(() => {
    if (formState.isSubmitted) trigger('standardParkingQuantity');
  }, [hasStandardParking, formState, trigger]);

  return (
    <>
      <Typography component="p" sx={{ mb: 3 }}>
        Bénéficiez d&apos;un tarif préférentiel sur le stationnement pendant le Salon
        en achetant, à l&apos;avance, votre laissez-passer de stationnement pour les
        4 jours, au coût de 40 $. Ce laissez-passer vous permet d&apos;entrer et
        sortir au courant de la journée, sans frais (maximum 3 fois/jour). Notez
        qu&apos;il n&apos;est malheureusement pas possible d&apos;acheter à l&apos;avance des
        billets de stationnement à l&apos;unité. Si vous payez à la guérite lors de
        votre arrivée sur le site du Salon, le coût sera alors de 15 $ pour la
        journée.
      </Typography>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <RadioButtons
              control={control}
              label="Désirez-vous bénéficier d'un laissez-passer de stationnement dans la zone VIP au coût de 40&nbsp;$&nbsp;? (Limite de 1 par entreprise pour 1 laissez-passer valide 4 jours)"
              name="hasVipParking"
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>

          <Grid item xs={12}>
            <RadioButtons
              control={control}
              label="Désirez-vous acheter des laissez-passer de stationnements supplémentaires standard au coût de 40&nbsp;$&nbsp;? (Laissez-passer 4 jours)"
              name="hasStandardParking"
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>

          {hasStandardParking && (
            <Grid item xs={12}>
              <TextField
                control={control}
                label="Quantité"
                name="standardParkingQuantity"
                rules={validateRequired(hasStandardParking)}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </>
  );
};

export default AccessOrderParkingsForm;
