import FindNotificationRecipientsDto from '../../dto/notifications/in/find-notification-recipients.dto';
import NotificationRecipientsDto from '../../dto/notifications/out/notification-recipients.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindNotificationRecipientsService {
  async find(
    dto: FindNotificationRecipientsDto,
  ): Promise<NotificationRecipientsDto[]> {
    try {
      const { data } = await apiV1.get<NotificationRecipientsDto[]>(
        '/notifications/recipients',
        { params: dto },
      );

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindNotificationRecipientsService;
