import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import AssemblyStatusesEnum from '../../../../enums/assemblies/assembly-statuses.enum';
import useFindUserAssembly from '../../../../hooks/assemblies/find-user-assembly.hook';
import useFindActiveEdition from '../../../../hooks/editions/find-active-edition.hook';
import { PATHS } from '../../../../utils/paths';

const EditAssemblyWorkflow: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();
  const { data: assembly, status: assemblyStatus } = useFindUserAssembly();

  if (
    editionStatus === 'loading' ||
    editionStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (editionStatus === 'error' || assemblyStatus === 'error') return <Error />;

  if (
    !edition.assembliesOpened ||
    assembly.status === AssemblyStatusesEnum.CANCELED
  ) {
    return <Navigate to={PATHS.exhibitors.path} replace />;
  }

  if (assembly.status !== AssemblyStatusesEnum.INCOMPLETE) {
    return <Navigate to={PATHS.exhibitors.assembly.path} replace />;
  }

  return <Outlet />;
};

export default EditAssemblyWorkflow;
