import CreateUserActivationCodeDto from '../../dto/users/create-user-activation-code.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class CreateUserActivationCodeService {
  async create(dto: CreateUserActivationCodeDto): Promise<void> {
    try {
      await apiV1.post<void>('/users/activation-codes', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default CreateUserActivationCodeService;
