import { Components, Theme } from '@mui/material';

const inputlabel = (theme: Theme): Components<Theme> => {
  return {
    MuiInputLabel: {
      defaultProps: { 
        shrink: true,
      },
      styleOverrides: {
        root: {
          position: 'relative',
          transform: 'none',
          marginBottom: theme.spacing(1),
          whiteSpace: 'break-spaces',
        }
      }
    },
  };
};

export default inputlabel;
