import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useToggle } from '@react-hookz/web';
import { FC, useState } from 'react';

import ProfilesDataTableBodyRowAction from './ProfilesDataTableBodyRowAction';
import ProfilesDataTableAnchor from './ProfilesDataTableAnchor';
import ProfilesDataTableFiltersModal from './ProfilesDataTableFiltersModal';
import ProfilesDataTableToolbar from './ProfilesDataTableToolbar';
import ProfileStatusChip from './ProfileStatusChip';
import Error from '../Error';
import Loading from '../Loading';
import NoData from '../NoData';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import DataTableFooter from '../data-tables/DataTableFooter';
import DataTableHead from '../data-tables/DataTableHead';
import ContractStatusesEnum from '../../enums/profiles/contract-statuses.enum';
import PaymentStatusesEnum from '../../enums/profiles/payment-statuses.enum';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import useFindProfiles from '../../hooks/profiles/find-profiles.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import GetPaymentStatusLabelService from '../../services/profiles/get-payment-status-label.service';
import { formatDateAndTime } from '../../utils/date';
import GetContractStatusLabelService from '../../services/profiles/get-contract-status-label.service';

const { BOOKED, INCOMPLETE, PROCESSING } = ProfileStatusesEnum;

const COLUMNS: DataTableColumnInterface[] = [
  { key: 'status', label: '', disabled: true, sx: { width: '60px', px: 0 } },
  {
    key: 'acceptedTermsAndConditions',
    label: "Date d'inscription",
    sx: { width: '175px' },
  },
  { key: 'commonCompanyName', label: 'Entreprise', sx: { width: '180px' } },
  { key: 'sections.name', label: 'Secteur', sx: { width: '150px' } },
  {
    key: 'bookings.mainLocation',
    label: 'Empl.\u00A0off.',
    sx: { width: '100px' },
  },
  { key: 'bookings.area', label: 'Superficie', sx: { width: '90px' } },
  { key: 'contractStatus', label: 'Contrat', sx: { width: 'auto' } },
  { key: 'paymentStatus', label: 'Paiement', sx: { width: 'auto' } },
  { key: 'menu', label: '', disabled: true, sx: { width: 'auto' } },
];

const ProfilesDataTable: FC = () => {
  const { dataTableOptions } = useDataTable();

  const [contractStatus, setContractStatus] = useState<
    ContractStatusesEnum | ''
  >('');

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusesEnum | ''>(
    '',
  );

  const [profileStatuses, setProfileStatuses] = useState<ProfileStatusesEnum[]>(
    [INCOMPLETE, PROCESSING, BOOKED],
  );

  const [filtersModalOpened, toggleFiltersModal] = useToggle(false);

  const { data, status } = useFindProfiles(
    {
      ...dataTableOptions,
      contractStatus: contractStatus || undefined,
      paymentStatus: paymentStatus || undefined,
      profileStatuses,
    },
    {
      keepPreviousData: true,
    },
  );

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;
  if (!data) return <NoData />;

  return (
    <Paper>
      <ProfilesDataTableToolbar toggleFiltersModal={toggleFiltersModal} />

      <Box sx={{ overflow: 'scroll' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <DataTableHead columns={COLUMNS} />

          <TableBody>
            {data.nodes.map((profile) => {
              const { id, acceptedTermsAndConditions, section, user } = profile;

              return (
                <DataTableBodyRow key={id}>
                  <TableCell>
                    <ProfileStatusChip profile={profile} />
                  </TableCell>
                  <TableCell>
                    {formatDateAndTime(acceptedTermsAndConditions)}
                  </TableCell>
                  <TableCell>
                    <ProfilesDataTableAnchor profile={profile} />
                  </TableCell>
                  <TableCell>{section?.name}</TableCell>
                  <TableCell>{user.booking?.mainLocation}</TableCell>
                  <TableCell>{user.booking?.area}</TableCell>
                  <TableCell>
                    {new GetContractStatusLabelService().get(
                      profile.contractStatus,
                    )}
                  </TableCell>
                  <TableCell>
                    {new GetPaymentStatusLabelService().get(
                      profile.paymentStatus,
                    )}
                  </TableCell>
                  <TableCell>
                    <ProfilesDataTableBodyRowAction profile={profile} />
                  </TableCell>
                </DataTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <DataTableFooter pageInfo={data.pageInfo} />

      <ProfilesDataTableFiltersModal
        contractStatus={contractStatus}
        opened={filtersModalOpened}
        paymentStatus={paymentStatus}
        profileStatuses={profileStatuses}
        onClose={(): void => toggleFiltersModal(false)}
        onProfileStatusesChange={({
          contractStatus,
          paymentStatus,
          profileStatuses,
        }): void => {
          setContractStatus(contractStatus);
          setPaymentStatus(paymentStatus);
          setProfileStatuses(profileStatuses);
        }}
      />
    </Paper>
  );
};

export default ProfilesDataTable;
