import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useToggle } from '@react-hookz/web';
import { FC, useState } from 'react';

import DetailedSheetsDataTableAnchor from './DetailedSheetsDataTableAnchor';
import DetailedSheetsDataTableFiltersModal from './DetailedSheetsDataTableFiltersModal';
import DetailedSheetsDataTableToolbar from './DetailedSheetsDataTableToolbar';
import DetailedSheetStatusChip from './DetailedSheetsStatusChip';
import Error from '../Error';
import Loading from '../Loading';
import NoData from '../NoData';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import DataTableFooter from '../data-tables/DataTableFooter';
import DataTableHead from '../data-tables/DataTableHead';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import useFindDetailedSheets from '../../hooks/detailed-sheets/find-detailed-sheets.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import { formatDateAndTime } from '../../utils/date';

const { APPROVED, INCOMPLETE, PROCESSING } = DetailedSheetStatusesEnum;

const COLUMNS: DataTableColumnInterface[] = [
  { key: 'status', label: '', disabled: true, sx: { width: '60px', px: 0 } },
  {
    key: 'acceptedTermsAndConditions',
    label: "Date d'inscription",
    sx: { width: '175px' },
  },
  { key: 'companyName', label: 'Entreprise', sx: { width: '180px' } },
];

const DetailedSheetsDataTable: FC = () => {
  const { dataTableOptions } = useDataTable();

  const [detailedSheetStatuses, setDetailedSheetStatuses] = useState<
    DetailedSheetStatusesEnum[]
  >([INCOMPLETE, PROCESSING, APPROVED]);

  const [filtersModalOpened, toggleFiltersModal] = useToggle(false);

  const { data, status } = useFindDetailedSheets(
    { ...dataTableOptions, detailedSheetStatuses },
    {
      keepPreviousData: true,
    },
  );

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;
  if (!data) return <NoData />;

  return (
    <Paper>
      <DetailedSheetsDataTableToolbar toggleFiltersModal={toggleFiltersModal} />

      <Box sx={{ overflow: 'scroll' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <DataTableHead columns={COLUMNS} />

          <TableBody>
            {data.nodes.map((detailedSheet) => {
              const { id, profile } = detailedSheet;
              const { acceptedTermsAndConditions } = profile;

              return (
                <DataTableBodyRow key={id}>
                  <TableCell>
                    <DetailedSheetStatusChip detailedSheet={detailedSheet} />
                  </TableCell>
                  <TableCell>
                    {acceptedTermsAndConditions
                      ? formatDateAndTime(acceptedTermsAndConditions)
                      : null}
                  </TableCell>
                  <TableCell>
                    <DetailedSheetsDataTableAnchor
                      detailedSheet={detailedSheet}
                    />
                  </TableCell>
                </DataTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <DataTableFooter pageInfo={data.pageInfo} />

      <DetailedSheetsDataTableFiltersModal
        opened={filtersModalOpened}
        onClose={(): void => toggleFiltersModal(false)}
        detailedSheetStatuses={detailedSheetStatuses}
        onDetailedSheetStatusesChange={(detailedSheetStatuses): void =>
          setDetailedSheetStatuses(detailedSheetStatuses)
        }
      />
    </Paper>
  );
};

export default DetailedSheetsDataTable;
