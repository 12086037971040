import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import RadioButtons from '../forms/RadioButtons';
import FormStack from '../forms/FormStack';
import FormGrid from '../forms/FormGrid';
import { validateRequired } from '../../utils/validations';
import AdminProfileFieldValuesInterface from '../../interfaces/profiles/admin-profile-field-values.interface';
import AdminManagerForm from './AdminManagerForm';

interface Props {
  control: Control<AdminProfileFieldValuesInterface>;
  setValue: UseFormSetValue<AdminProfileFieldValuesInterface>;
}

const AdminManagersForm: FC<Props> = ({ control, setValue }) => {
  const hasMoreThanOneManager =
    useWatch({
      name: 'hasMoreThanOneManager',
      control,
    }) === 'true';

  useEffect(() => {
    if (!hasMoreThanOneManager) {
      setValue('manager2FirstName', undefined);
      setValue('manager2LastName', undefined);
      setValue('manager2Email', undefined);
      setValue('manager2PhoneNumber1', undefined);
      setValue('manager2PhoneNumber2', undefined);
    }
  }, [hasMoreThanOneManager, setValue]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <Typography variant="overline" component="h2">
            Responsable(s) du Salon
          </Typography>
        </Grid>

        <AdminManagerForm control={control} index={1} />

        <Grid item xs={12}>
          <RadioButtons
            control={control}
            label="Souhaitez-vous indiquer un second responsable ?"
            name="hasMoreThanOneManager"
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            rules={validateRequired()}
            row
          />
        </Grid>
        <>
          {hasMoreThanOneManager && (
            <AdminManagerForm control={control} index={2} />
          )}
        </>
      </FormGrid>
    </FormStack>
  );
};

export default AdminManagersForm;
