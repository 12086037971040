import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  href: string;
  label: string;
}

const DataTableAnchor: FC<Props> = ({ href, label }) => {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate],
  );

  return (
    <Link href={href} onClick={handleClick}>
      {label}
    </Link>
  );
};

export default DataTableAnchor;
