import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { UseFormReturn } from 'react-hook-form';

import GetSignedUrlService from '../files/get-signed-urls.service';
import SaveDetailedSheetDto from '../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import SaveUserDetailedSheetDto from '../../dto/detailed-sheets/in/save-user-detailed-sheet.dto';
import GetSignedUrlDto from '../../dto/files/in/get-signed-url.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';

const getSignedUrlService = new GetSignedUrlService();

type UploadedFile = {
  dto: GetSignedUrlDto;
  file: File;
};

class UploadDetailedSheetFilesService {
  async upload(
    saveDetailedSheetDto: SaveDetailedSheetDto | SaveUserDetailedSheetDto,
    fieldValues: DetailedSheetFieldValuesInterface,
    { getFieldState }: UseFormReturn<DetailedSheetFieldValuesInterface>,
  ): Promise<void> {
    const uploadedFiles: UploadedFile[] = [];

    const copyPreviousLogo = fieldValues.copyPreviousLogo === 'true';
    const copyPreviousImage = fieldValues.copyPreviousImage === 'true';

    if (
      !copyPreviousLogo &&
      fieldValues.logo &&
      getFieldState('logo').isDirty &&
      saveDetailedSheetDto.logo
    ) {
      uploadedFiles.push({
        dto: plainToClass(GetSignedUrlDto, {
          name: saveDetailedSheetDto.logo.key,
          contentType: fieldValues.logo.type,
        }),
        file: fieldValues.logo,
      });
    }

    if (
      !copyPreviousImage &&
      fieldValues.image &&
      getFieldState('image').isDirty &&
      saveDetailedSheetDto.image
    ) {
      uploadedFiles.push({
        dto: plainToClass(GetSignedUrlDto, {
          name: saveDetailedSheetDto.image.key,
          contentType: fieldValues.image.type,
        }),
        file: fieldValues.image,
      });
    }

    const signedUrls = await getSignedUrlService.get(
      uploadedFiles.map((uploadedFile) => uploadedFile.dto),
    );

    await Promise.all(
      uploadedFiles.map(async (uploadedFile, index) => {
        const signedUrl = signedUrls[index];

        return axios.put(signedUrl, uploadedFile.file, {
          headers: {
            'Content-Type': uploadedFile.file.type,
          },
        });
      }),
    );
  }
}

export default UploadDetailedSheetFilesService;
