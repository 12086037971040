import { FC } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from 'react-router-dom';

import Error from '../../../Error';
import Loading from '../../../Loading';
import UserStepsEnum from '../../../../enums/users/user-steps.enum';
import useFindUserMostRecentProfile from '../../../../hooks/profiles/find-user-most-recent-profile.hook';
import useFindUserProfile from '../../../../hooks/profiles/find-user-profile.hook';
import { PATHS } from '../../../../utils/paths';

const { ACTIVATION_CODE_STEP, COMPLETED_STEP } = UserStepsEnum;

const SaveProfileWorkflow: FC = () => {
  const { pathname } = useLocation();
  const { data: profile, status: profileStatus } = useFindUserProfile();

  const { data: mostRecentProfile = null, status: mostRecentProfileStatus } =
    useFindUserMostRecentProfile({
      enabled: profileStatus === 'success' && pathname.includes('/new'),
    });

  const currentStep = useOutletContext<UserStepsEnum>();

  if ([profileStatus, mostRecentProfileStatus].includes('loading')) {
    return <Loading />;
  }

  if (![profileStatus, mostRecentProfileStatus].includes('success')) {
    return <Error />;
  }

  switch (currentStep) {
    case ACTIVATION_CODE_STEP: {
      return <Navigate to={PATHS.exhibitors.activationCode.path} replace />;
    }
    case COMPLETED_STEP: {
      return <Navigate to={PATHS.exhibitors.path} replace />;
    }
    default: {
      return <Outlet context={{ profile, mostRecentProfile }} />;
    }
  }
};

export default SaveProfileWorkflow;
