import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Autocomplete from '../forms/Autocomplete';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RichTextArea from '../forms/RichTextArea';
import Select from '../forms/Select';
import TextField from '../forms/TextField';
import OptionInterface from '../../interfaces/option.interface';
import NotificationFieldValuesInterface from '../../interfaces/notifications/notification-field-values.interface';
import {
  validateArrayRequired,
  validateRequired,
  validateRichText,
} from '../../utils/validations';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import StagesEnum from '../../enums/editions/stages.enum';
import AccessOrderPaymentStatusesEnum from '../../enums/access-orders/access-order-payment-statuses.enum';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';
import AssemblyStatusesEnum from '../../enums/assemblies/assembly-statuses.enum';
import GetAccessOrderPaymentStatusLabelService from '../../services/access-orders/get-access-order-payment-status-label.service';
import { PAYMENT_OPTIONS } from '../../utils/profiles';

const getAccessOrderPaymentStatusLabelService =
  new GetAccessOrderPaymentStatusLabelService();

const STAGE_OPTIONS: OptionInterface[] = [
  { label: 'Inscriptions', value: StagesEnum.REGISTRATIONS },
  { label: 'Fiches exposant', value: StagesEnum.DETAILED_SHEETS },
  { label: 'Montage et démontage', value: StagesEnum.ASSEMBLIES },
  { label: "Commandes d'accès au salon", value: StagesEnum.ACCESS_ORDERS },
];

const REGISTRATIONS_OPTIONS: OptionInterface[] = [
  { label: 'Réservé', value: ProfileStatusesEnum.BOOKED },
  { label: 'Annulé', value: ProfileStatusesEnum.CANCELED },
  { label: 'Incomplet', value: ProfileStatusesEnum.INCOMPLETE },
  { label: 'En traitement', value: ProfileStatusesEnum.PROCESSING },
];

const DETAILED_SHEETS_OPTIONS: OptionInterface[] = [
  { label: 'Annulé', value: DetailedSheetStatusesEnum.CANCELED },
  { label: 'Approuvé', value: DetailedSheetStatusesEnum.APPROVED },
  { label: 'Incomplet', value: DetailedSheetStatusesEnum.INCOMPLETE },
  { label: 'En traitement', value: DetailedSheetStatusesEnum.PROCESSING },
];

const ASSEMBLIES_OPTIONS: OptionInterface[] = [
  { label: 'Annulé', value: AssemblyStatusesEnum.CANCELED },
  { label: 'Confirmé', value: AssemblyStatusesEnum.CONFIRMED },
  { label: 'Incomplet', value: AssemblyStatusesEnum.INCOMPLETE },
  { label: 'En traitement', value: AssemblyStatusesEnum.PROCESSING },
];

const ACCESS_ORDERS_OPTIONS: OptionInterface[] = [
  { label: 'Annulé', value: AccessOrderStatusesEnum.CANCELED },
  { label: 'Confirmé', value: AccessOrderStatusesEnum.CONFIRMED },
  { label: 'Incomplet', value: AccessOrderStatusesEnum.INCOMPLETE },
  { label: 'En traitement', value: AccessOrderStatusesEnum.PROCESSING },
];

const ACCESS_ORDER_PAYMENT_OPTIONS: OptionInterface[] = [
  {
    label: 'Aucun',
    value: AccessOrderPaymentStatusesEnum.INITIAL,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.INVOICE_PENDING,
    ),
    value: AccessOrderPaymentStatusesEnum.INVOICE_PENDING,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.PAYMENT_PENDING,
    ),
    value: AccessOrderPaymentStatusesEnum.PAYMENT_PENDING,
  },
  {
    label: getAccessOrderPaymentStatusLabelService.get(
      AccessOrderPaymentStatusesEnum.PAID,
    ),
    value: AccessOrderPaymentStatusesEnum.PAID,
  },
];

const SaveNotificationForm: FC = () => {
  const { control, formState, setValue, trigger, watch } =
    useFormContext<NotificationFieldValuesInterface>();

  const stage = watch('stage');
  const statuses = watch('statuses');
  const paymentStatus = watch('paymentStatus');

  useEffect(() => {
    setValue('statuses', []);
    setValue('paymentStatus', '');
  }, [stage, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) trigger('statuses');
  }, [formState, stage, statuses, paymentStatus, trigger]);

  const statusesOptions = useMemo(() => {
    switch (stage) {
      case StagesEnum.REGISTRATIONS: {
        return REGISTRATIONS_OPTIONS;
      }
      case StagesEnum.DETAILED_SHEETS: {
        return DETAILED_SHEETS_OPTIONS;
      }
      case StagesEnum.ASSEMBLIES: {
        return ASSEMBLIES_OPTIONS;
      }
      case StagesEnum.ACCESS_ORDERS: {
        return ACCESS_ORDERS_OPTIONS;
      }
      default: {
        return [];
      }
    }
  }, [stage]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select
              control={control}
              label="Étape"
              name="stage"
              options={STAGE_OPTIONS}
              rules={validateRequired()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            control={control}
            label="Statuts"
            name="statuses"
            options={statusesOptions}
            rules={validateArrayRequired(
              1,
              undefined,
              stage !== StagesEnum.REGISTRATIONS || !paymentStatus,
            )}
            fullWidth
          />
        </Grid>

        {stage === StagesEnum.REGISTRATIONS && (
          <Grid item xs={12} sm={6}>
            <Select
              control={control}
              label="Statut de paiement"
              name="paymentStatus"
              options={PAYMENT_OPTIONS}
              fullWidth
            />
          </Grid>
        )}

        {stage === StagesEnum.ACCESS_ORDERS && (
          <Grid item xs={12} sm={6}>
            <Select
              control={control}
              label="Statut de paiement"
              name="paymentStatus"
              options={ACCESS_ORDER_PAYMENT_OPTIONS}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            control={control}
            label="Objet du courriel"
            name="subject"
            rules={validateRequired()}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <RichTextArea
            control={control}
            name="body"
            label="Corps du courriel"
            rules={validateRichText(false)}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default SaveNotificationForm;
