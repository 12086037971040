import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { authState } from '../../http/auth.state';

const PrivateRoute: FC = () => {
  const { pathname, search } = useLocation();

  const { token } = authState;

  if (!token) {
    return (
      <Navigate to="/sign-in" state={{ url: `${pathname}${search}` }} replace />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
