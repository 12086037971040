import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import TextField from '../forms/TextField';
import DoorTypesEnum from '../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../enums/assemblies/handling-equipments.enum';
import OptionInterface from '../../interfaces/option.interface';
import AssemblyFieldValues from '../../interfaces/assemblies/assembly-field-values.interface';
import { validateRequired } from '../../utils/validations';

const HANDLING_EQUIPMENT_OPTIONS: OptionInterface[] = [
  {
    label: 'Chariot élévateur',
    value: HandlingEquipmentsEnum.FORKLIFT,
  },
  {
    label: 'Chariot sur roulettes',
    value: HandlingEquipmentsEnum.DOLLY,
  },
  { label: 'Je suis autonome', value: HandlingEquipmentsEnum.NONE },
];

const DOOR_TYPES_OPTIONS: OptionInterface[] = [
  {
    label: 'Porte de garage au sol',
    value: DoorTypesEnum.GARAGE_GROUND_LEVEL,
  },
  {
    label: 'Quai de déchargement',
    value: DoorTypesEnum.LOADING_DOCK,
  },
];

const AssemblyNeedsPreferenceForm: FC = () => {
  const { control } = useFormContext<AssemblyFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item md={12}>
          <RadioButtons
            control={control}
            name="handlingEquipment"
            label="Besoins en manutention"
            options={HANDLING_EQUIPMENT_OPTIONS}
            rules={validateRequired()}
            row
          />
        </Grid>

        <Grid item md={12}>
          <RadioButtons
            control={control}
            name="doorType"
            label="Type de porte"
            options={DOOR_TYPES_OPTIONS}
            rules={validateRequired()}
            row
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            control={control}
            name="needsNotes"
            label="Autres besoins ou commentaires (facultatif)"
            fullWidth
            multiline
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AssemblyNeedsPreferenceForm;
