import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Select from '../forms/Select';
import OptionInterface from '../../interfaces/option.interface';
import AssemblyFieldValues from '../../interfaces/assemblies/assembly-field-values.interface';
import { validateRequired } from '../../utils/validations';
import { DISASSEMBLY_DELIVERY_DATES } from '../../values';

const DELIVERY_DATE_OPTIONS: OptionInterface[] = [
  { label: 'Sélectionner', value: '' },
  ...DISASSEMBLY_DELIVERY_DATES,
];

const AssemblyDisassemblyForm: FC = () => {
  const { control } = useFormContext<AssemblyFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item>
          <Select
            control={control}
            name="disassemblyDate"
            label={
              <>
                {'Période où vous effectuerez votre démontage. '}
                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                  À noter qu’aucune plage horaire ne sera attribuée pour le
                  démontage.
                </Typography>
              </>
            }
            options={DELIVERY_DATE_OPTIONS}
            rules={validateRequired()}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AssemblyDisassemblyForm;
