import { createTheme } from '@mui/material/styles';

import { overrideComponents } from './overrides';
import { palette } from './palette';
import { customShadows, shadows } from './shadows';
import { typography } from './typography';

const theme = createTheme({
  palette,
  customShadows,
  shadows,
  typography,
});

theme.components = overrideComponents(theme) as typeof theme.components;

export default theme;
