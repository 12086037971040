import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LocationsDto from '../../dto/locations/out/locations.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  location: LocationsDto;
}

const LocationsDataTableLocationNameAnchor: FC<Props> = ({ location }) => {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(PATHS.admin.locations.edit.path(location.id));
      }
    },
    [location, navigate],
  );

  return (
    <Link
      href={PATHS.admin.locations.edit.path(location.id)}
      onClick={handleClick}
    >
      {location.name}
    </Link>
  );
};

export default LocationsDataTableLocationNameAnchor;
