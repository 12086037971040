import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import DoorTypesEnum from '../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../enums/assemblies/handling-equipments.enum';
import {
  formatDateLong,
  formatTime,
  getDateFromDateAndTimeString,
} from '../../utils/date';
import { DISASSEMBLY_DELIVERY_DATES } from '../../values';

interface Props {
  assembly: AssemblyDto;
}

const AssemblyConfirmationAlert: FC<Props> = ({ assembly }) => {
  const handlingEquipment = useMemo(() => {
    if (!assembly.handlingEquipment) return '';

    switch (assembly.handlingEquipment) {
      default:
      case HandlingEquipmentsEnum.FORKLIFT: {
        return 'Chariot élévateur';
      }
      case HandlingEquipmentsEnum.DOLLY: {
        return 'Chariot sur roulettes';
      }
      case HandlingEquipmentsEnum.NONE: {
        return 'Je suis autonome';
      }
    }
  }, [assembly]);

  const doorType = useMemo(() => {
    if (!assembly.doorType) return '';

    switch (assembly.doorType) {
      default:
      case DoorTypesEnum.GARAGE_GROUND_LEVEL: {
        return 'Porte de garage au sol';
      }
      case DoorTypesEnum.LOADING_DOCK: {
        return 'Quai de déchargement';
      }
    }
  }, [assembly]);

  const deliveryDateAndTime = useMemo(() => {
    const deliveryDate = assembly.deliveryDate
      ? formatDateLong(parseISO(assembly.deliveryDate))
      : null;

    const deliveryTime =
      assembly.deliveryDate && assembly.deliveryTime
        ? formatTime(
            getDateFromDateAndTimeString(
              assembly.deliveryDate,
              assembly.deliveryTime,
            ),
          )
        : null;

    return (
      <>
        {deliveryDate}
        <br />à {deliveryTime}
      </>
    );
  }, [assembly]);

  const disassemblyDate = useMemo(() => {
    const option = DISASSEMBLY_DELIVERY_DATES.find(
      (option) => option.value === assembly.disassemblyDate,
    );

    if (!option) return null;

    return option.label;
  }, [assembly]);

  return (
    <Alert severity="success">
      <AlertTitle>
        Confirmation de vos horaires de montage et démontage
      </AlertTitle>

      <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
        Horaire de montage
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        {deliveryDateAndTime}
      </Typography>

      <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
        Porte ou débarcadère à utiliser
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        {doorType} numéro {assembly.doorNumber}
      </Typography>

      <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
        Besoins en manutention
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        {handlingEquipment}
      </Typography>

      <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
        Journée de démontage
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        {disassemblyDate}
      </Typography>

      <Typography variant="body2" sx={{ mb: 1 }}>
        Il est important de respecter l&apos;horaire qui vous a été attitré.
        Vous avez 30 minutes pour vous décharger. Nous vous demandons de libérer
        le débarcadère ou la porte de garage dès que vous avez terminé le
        déchargement afin de facilité les livraisons des autres exposants.
      </Typography>

      <Typography variant="body2">
        Pour toute question ou modification, veuillez contacter Jessica Légaré
        au{' '}
        <Link href="mailto:jlegare@apchqquebec.ca" underline="none">
          jlegare@apchqquebec.ca
        </Link>{' '}
        ou au 418-682-3353.
      </Typography>
    </Alert>
  );
};

export default AssemblyConfirmationAlert;
