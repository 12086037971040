import SortDirectionEnum from '../../../enums/data-tables/sorting/sort-directions.enum';

const { ASC, DESC } = SortDirectionEnum;

class GetNextSortDirectionService {
  static get(
    sortDirection: SortDirectionEnum,
    active: boolean,
  ): SortDirectionEnum {
    switch (true) {
      case active && sortDirection === ASC: {
        return DESC;
      }
      default: {
        return ASC;
      }
    }
  }
}

export default GetNextSortDirectionService;
