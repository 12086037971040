import { Components, Theme } from '@mui/material';
import { getFontSizesForBreakpoints, getRmFromPx } from '../../utils/fonts';

const formHelperText = (_theme: Theme): Components<Theme> => {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontSize: getRmFromPx(12),
          ...getFontSizesForBreakpoints({ sm: 12, md: 14, lg: 14 }),
        }
      }
    },
  };
};

export default formHelperText;
