import { utils, writeFile } from 'xlsx';

import EditionReportDto from '../../dto/editions/out/edition-report.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import { formatDate } from '../../utils/date';

class DownloadEditionReportService {
  download(edition: EditionDto, data: EditionReportDto[], name: string): void {
    const worksheet = utils.json_to_sheet(data);

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Rapport');

    writeFile(
      workbook,
      `Expo habitat ${edition.name} (${name}) - ${formatDate(
        new Date().toString(),
      )}.xlsx`,
    );
  }
}

export default DownloadEditionReportService;
