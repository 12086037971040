import RequestPasswordRequestDto from '../../dto/auth/request-password-reset.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class RequestPasswordResetService {
  async request(dto: RequestPasswordRequestDto): Promise<void> {
    try {
      await apiV1.post<void>('/auth/reset-password/request', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default RequestPasswordResetService;
