import AccessOrderPaymentStatusesEnum from '../../../enums/access-orders/access-order-payment-statuses.enum';
import PaymentStatusesEnum from '../../../enums/profiles/payment-statuses.enum';

class SaveProfilePaymentDto {
  paymentStatus!: PaymentStatusesEnum;
  paymentNotes?: string;
  accessOrderPaymentStatus!: AccessOrderPaymentStatusesEnum;
  accessOrderPaymentNotes?: string;
}

export default SaveProfilePaymentDto;
