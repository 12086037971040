import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import Select from '../forms/Select';
import TextField from '../forms/TextField';
import FormStack from '../forms/FormStack';
import FormGrid from '../forms/FormGrid';
import RadioButtons from '../forms/RadioButtons';
import ProfileFieldValuesInterface from '../../interfaces/profiles/profile-field-values.interface';
import { PROVINCES_OPTIONS } from '../../utils/provinces';
import {
  validateApchqMemberNumber,
  validatePostalCodeFormat,
  validateRbqMemberNumber,
  validateRequired,
} from '../../utils/validations';

interface Props {
  control: Control<ProfileFieldValuesInterface>;
  setValue: UseFormSetValue<ProfileFieldValuesInterface>;
}

const ProfileCompanyForm: FC<Props> = ({ control, setValue }) => {
  const isApchqMember = useWatch({ name: 'isApchqMember', control }) === 'true';
  const isRbqMember = useWatch({ name: 'isRbqMember', control }) === 'true';

  useEffect(() => {
    if (!isApchqMember) setValue('apchqMemberNumber', '');
  }, [isApchqMember, setValue]);

  useEffect(() => {
    if (!isRbqMember) setValue('rbqMemberNumber', '');
  }, [isRbqMember, setValue]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <Typography variant="overline" component="h2">
            Votre entreprise
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            control={control}
            fullWidth
            label="Nom légal de l'entreprise"
            name="legalCompanyName"
            rules={validateRequired()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            control={control}
            fullWidth
            label="Nom usuel (si applicable)"
            name="commonCompanyName"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            control={control}
            fullWidth
            label="Adresse"
            name="address"
            rules={validateRequired()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            control={control}
            fullWidth
            label="Ville"
            name="city"
            rules={validateRequired()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select
              control={control}
              label="Province"
              name="province"
              options={PROVINCES_OPTIONS}
              rules={validateRequired()}
            />
          </FormControl>
        </Grid>

        <Grid item sm={3}>
          <TextField
            control={control}
            fullWidth
            label="Code postal"
            name="postalCode"
            placeholder="ex: G1G 1G1"
            rules={validatePostalCodeFormat()}
          />
        </Grid>

        <Grid item md={6}>
          <RadioButtons
            control={control}
            label="Êtes-vous membre de l'APCHQ ?"
            name="isApchqMember"
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            rules={validateRequired()}
            row
          />
        </Grid>

        <>
          {isApchqMember && (
            <Grid item xs={12}>
              <TextField
                control={control}
                label="Numéro de membre APCHQ"
                name="apchqMemberNumber"
                rules={validateApchqMemberNumber(isApchqMember)}
              />
            </Grid>
          )}

          {!isApchqMember && (
            <Grid item xs={12}>
              <Alert severity="warning" sx={{ p: 2, boxShadow: 'none' }}>
                Saviez-vous que les membres de l&apos;APCHQ bénéficient
                d&apos;un tarif préférentiel pour la location d&apos;espaces?
                <br></br>Pour toutes questions, communiquez avec le département
                services aux membres de l&apos;APCHQ - région de Québec :{' '}
                <Link href="tel:4186823353" underline="none">
                  418&nbsp;682-3353
                </Link>
                .
              </Alert>
            </Grid>
          )}
        </>

        <Grid item md={6}>
          <RadioButtons
            control={control}
            label="Avez-vous un numéro de licence RBQ ?"
            name="isRbqMember"
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            rules={validateRequired()}
            row
          />
        </Grid>

        {isRbqMember && (
          <Grid item xs={12}>
            <TextField
              control={control}
              label="Numéro de licence RBQ"
              name="rbqMemberNumber"
              rules={validateRbqMemberNumber(isRbqMember)}
            />
          </Grid>
        )}
      </FormGrid>
    </FormStack>
  );
};

export default ProfileCompanyForm;
