import AccessOrdersReportDto from '../../dto/access-orders/out/access-orders-report.dto';
import { apiV1 } from '../../http';

class GetAccessOrdersReportService {
  async get(editionId: string): Promise<AccessOrdersReportDto[]> {
    const { data } = await apiV1.get<AccessOrdersReportDto[]>(
      `/editions/${editionId}/report/access-orders`,
    );

    return data;
  }
}

export default GetAccessOrdersReportService;
