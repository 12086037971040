import removeAccents from 'remove-accents';

import EditionDto from '../../dto/editions/out/edition.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';

class GetContractFileName {
  get(edition: EditionDto, profile: ProfileDto): string {
    const companyName = removeAccents(profile.legalCompanyName)
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s/g, '_');

    return `EH${edition.name}_Contrat_${companyName}.pdf`;
  }
}

export default GetContractFileName;
