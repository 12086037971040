import { Components, Theme } from '@mui/material';

const modal = (theme: Theme): Components<Theme> => {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiBox-root.MuiModal-Box-root' : {
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.customShadows?.z24,
            padding: theme.spacing(6),
  
            [theme.breakpoints.up('md')]: {
              width: '600px',
            }, 
          }
        },
      },
    },
  };
};

export default modal;
