import { useMutation, UseMutationResult } from 'react-query';

import UnapproveDetailedSheetService from '../../services/detailed-sheets/unapprove-detailed-sheet.service';

type Variables = { id: string };

const useUnapproveDetailedSheet = (): UseMutationResult<
  void,
  Error,
  Variables
> => {
  return useMutation(async (variables) =>
    new UnapproveDetailedSheetService().unapprove(variables.id),
  );
};

export default useUnapproveDetailedSheet;
