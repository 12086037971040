import Chip from '@mui/material/Chip';
import { FC } from 'react';

import SectionDto from '../../dto/sections/section.dto';

const CHIP_STYLE = {
  ml: 1,
  mt: '-2px',
};

interface Props {
  section: SectionDto;
}

const SectionRadioLabel: FC<Props> = ({ section }) => {
  return (
    <>
      {section.name}{' '}
      {section.closed && (
        <Chip label="Complet" color="error" size="small" sx={CHIP_STYLE} />
      )}
    </>
  );
};

export default SectionRadioLabel;
