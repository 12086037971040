import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import { formatDateLong } from '../../utils/date';

interface Props {
  assembly: AssemblyDto;
}

const AssemblyDisassemblySection: FC<Props> = ({ assembly }) => {
  const renderDisassemblyDate = useMemo(() => {
    if (!assembly.disassemblyDate) return null;

    const date = formatDateLong(parseISO(assembly.disassemblyDate));

    return (
      <Grid item xs={12}>
        <ReadonlyFieldLabel>
          Période où vous effectuerez votre démontage.
        </ReadonlyFieldLabel>
        <Typography>{date}</Typography>
      </Grid>
    );
  }, [assembly]);

  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Démontage
      </Typography>

      <FormStack>
        <FormGrid>{renderDisassemblyDate}</FormGrid>
      </FormStack>
    </Section>
  );
};

export default AssemblyDisassemblySection;
