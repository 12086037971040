import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import FindUserAssemblyService from '../../services/assemblies/find-user-assembly.service';

const useFindUserAssembly = (
  options?: Omit<UseQueryOptions<AssemblyDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<AssemblyDto, Error> => {
  return useQuery('assemblies/me', new FindUserAssemblyService().find, options);
};

export default useFindUserAssembly;
