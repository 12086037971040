import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import UserInfoDto from '../../dto/users/user-info.dto';
import FindUserInfoService from '../../services/users/find-user-info.service';

const useFindUserInfo = (
  options?: Omit<UseQueryOptions<UserInfoDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<UserInfoDto, Error> => {
  return useQuery('users/info', new FindUserInfoService().find, options);
};

export default useFindUserInfo;
