import Chip, { ChipProps } from '@mui/material/Chip';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { FC, useMemo } from 'react';

import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';

const { CANCELED, CONFIRMED, INCOMPLETE, PROCESSING } = AccessOrderStatusesEnum;

interface Props {
  accessOrder: AccessOrderDto;
  chipSx?: SxProps<Theme>;
}

const AccessOrderStatusChip: FC<Props> = ({ chipSx = {}, accessOrder }) => {
  const { status } = accessOrder || { status: INCOMPLETE };

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case CONFIRMED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  const tooltipProps = useMemo((): Pick<TooltipProps, 'title'> => {
    switch (status) {
      case CANCELED: {
        return { title: 'Annulé' };
      }
      case INCOMPLETE: {
        return { title: 'Incomplet' };
      }
      case PROCESSING: {
        return { title: 'En traitement' };
      }
      case CONFIRMED: {
        return { title: 'Confirmé' };
      }
    }
  }, [status]);

  return (
    <Tooltip {...tooltipProps} arrow>
      <Chip
        {...props}
        sx={{
          height: '16px',
          width: '16px',
          ...chipSx,
        }}
      />
    </Tooltip>
  );
};

export default AccessOrderStatusChip;
