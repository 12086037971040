import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Modal from '../Modal';
import Autocomplete from '../forms/Autocomplete';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Select from '../forms/Select';
import ContractStatusesEnum from '../../enums/profiles/contract-statuses.enum';
import PaymentStatusesEnum from '../../enums/profiles/payment-statuses.enum';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import OptionInterface from '../../interfaces/option.interface';
import ProfilesFiltersFieldValuesInterface from '../../interfaces/profiles/profiles-filters-field-values.interface';
import { CONTRACT_OPTIONS, PAYMENT_OPTIONS } from '../../utils/profiles';
import {
  onInvalidSubmit,
  validateArrayRequired,
} from '../../utils/validations';

const { BOOKED, CANCELED, INCOMPLETE, PROCESSING } = ProfileStatusesEnum;

const PROFILE_STATUSES_OPTIONS: OptionInterface[] = [
  {
    label: 'Réservé',
    value: BOOKED,
  },
  {
    label: 'En traitement',
    value: PROCESSING,
  },
  { label: 'Incomplet', value: INCOMPLETE },
  { label: 'Annulé', value: CANCELED },
];

interface Props {
  contractStatus: ContractStatusesEnum | '';
  opened: boolean;
  paymentStatus: PaymentStatusesEnum | '';
  profileStatuses: ProfileStatusesEnum[];
  onClose: () => void;
  onProfileStatusesChange: (statuses: {
    contractStatus: ContractStatusesEnum | '';
    paymentStatus: PaymentStatusesEnum | '';
    profileStatuses: ProfileStatusesEnum[];
  }) => void;
}

const ProfilesDataTableFiltersModal: FC<Props> = ({
  contractStatus,
  opened,
  paymentStatus,
  profileStatuses,
  onClose,
  onProfileStatusesChange,
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<ProfilesFiltersFieldValuesInterface>({
    defaultValues: {
      contractStatus,
      paymentStatus,
      profileStatuses,
    },
  });

  const onSubmit: SubmitHandler<ProfilesFiltersFieldValuesInterface> =
    useCallback(
      (fieldValues) => {
        onProfileStatusesChange({
          contractStatus: fieldValues.contractStatus,
          paymentStatus: fieldValues.paymentStatus,
          profileStatuses: fieldValues.profileStatuses,
        });
        onClose();
      },
      [onClose, onProfileStatusesChange],
    );

  return (
    <Modal open={opened} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <FormStack>
          <FormGrid>
            <Grid item xs={12}>
              <Typography variant="overline" component="h2">
                Filtres avancés
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                control={control}
                label="Statuts"
                name="profileStatuses"
                options={PROFILE_STATUSES_OPTIONS}
                rules={validateArrayRequired()}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                control={control}
                label="Statut de paiement"
                name="paymentStatus"
                options={PAYMENT_OPTIONS}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                control={control}
                label="Statut de contrat"
                name="contractStatus"
                options={CONTRACT_OPTIONS}
                fullWidth
              />
            </Grid>
          </FormGrid>
        </FormStack>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Filtrer
        </Button>
      </form>
    </Modal>
  );
};

export default ProfilesDataTableFiltersModal;
