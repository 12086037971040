export const getRmFromPx = (px: number): string => {
  return `${px / 16}rem`;
};

export const getFontSizesForBreakpoints = (fontSizes: {
  sm: number;
  md: number;
  lg: number;
}): Record<string, unknown> => {
  const { sm, md, lg } = fontSizes;

  return {
    '@media (min-width:600px)': {
      fontSize: getRmFromPx(sm),
    },
    '@media (min-width:900px)': {
      fontSize: getRmFromPx(md),
    },
    '@media (min-width:1200px)': {
      fontSize: getRmFromPx(lg),
    },
  };
};
