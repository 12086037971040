import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { FC } from 'react';

import { PATHS } from '../../../../utils/paths';

const NoActiveEditionPage: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Alert
        severity="warning"
        sx={{ mt: 4, maxWidth: '500px', width: '100%' }}
      >
        <AlertTitle>
          Expo habitat Québec sera de retour du 22 au 25 février 2024 au Centre
          de foires ExpoCité.
        </AlertTitle>
        La location d’espace pour les exposants débutera prochainement.{' '}
        <Link href={PATHS.documents.timetable.path} target="_blank">
          Consultez l’échéancier de location
        </Link>{' '}
        pour connaître les dates importantes. Pour toutes questions, communiquez
        avec Jessica Légaré à l’adresse{' '}
        <Link href="mailto:jlegare@apchqquebec.ca" underline="none">
          jlegare@apchqquebec.ca
        </Link>
        .
      </Alert>
    </Box>
  );
};

export default NoActiveEditionPage;
