class SaveFileDto {
  id?: string;
  key!: string;
  name!: string;
  extension!: string;
  contentType!: string;
  size!: number;
  originalName!: string;
  hasSmallVariant?: boolean;
}

export default SaveFileDto;
