import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import FindUserAccessOrderService from '../../services/access-orders/find-user-access-order.service';

const useFindUserAccessOrder = (
  options?: Omit<
    UseQueryOptions<AccessOrderDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<AccessOrderDto, Error> => {
  return useQuery(
    'access-orders/me',
    new FindUserAccessOrderService().find,
    options,
  );
};

export default useFindUserAccessOrder;
