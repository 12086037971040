import { plainToClass } from 'class-transformer';

import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';
import SaveFileDto from '../../../dto/files/in/save-file.dto';

class MapExistingDetailedSheetImageToSaveFileDtoService {
  map(
    image: DetailedSheetDto['logo'] | DetailedSheetDto['image'],
  ): SaveFileDto | null {
    if (!image) return null;

    return plainToClass(SaveFileDto, {
      id: image.id,
      key: image.key,
      name: image.name,
      extension: image.extension,
      contentType: image.contentType,
      size: image.size,
      originalName: image.originalName,
    });
  }
}

export default MapExistingDetailedSheetImageToSaveFileDtoService;
