import Stack from '@mui/material/Stack';
import { FC } from 'react';

const FormStack: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack direction="column" spacing={3} pb={4}>
      {children}
    </Stack>
  );
};

export default FormStack;
