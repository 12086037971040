import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import AssembliesDataTableOptionsDto from '../../dto/assemblies/in/assemblies-data-table-options.dto';
import AssembliesDto from '../../dto/assemblies/out/assemblies.dto';
import { apiV1 } from '../../http';

class FindAssembliesService {
  async find(
    dto: AssembliesDataTableOptionsDto,
  ): Promise<PaginatedResultDto<AssembliesDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<AssembliesDto>>(
      '/assemblies',
      { params: dto },
    );

    return data;
  }
}

export default FindAssembliesService;
