import Typography from '@mui/material/Typography';
import { FC } from 'react';

import Section from '../layouts/Section';
import UserInfoDto from '../../dto/users/user-info.dto';

const LABEL_STYLES = {
  mt: 3,
  color: 'grey.600',
};

interface Props {
  profile: WithRequiredKeys<UserInfoDto, 'profile'>['profile'];
}

const ProfileCompanySection: FC<Props> = ({ profile }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Votre entreprise
      </Typography>

      <Typography variant="subtitle1">{profile.legalCompanyName}</Typography>

      {!!profile.commonCompanyName && (
        <Typography>Nom usuel : {profile.commonCompanyName}</Typography>
      )}

      <Typography>
        {profile.address}
        <br />
        {profile.city}, {profile.postalCode} ({profile.province})
      </Typography>

      {!!profile.apchqMemberNumber && (
        <>
          <Typography variant="body2" sx={LABEL_STYLES}>
            Numéro de membre APCHQ
          </Typography>

          <Typography>{profile.apchqMemberNumber}</Typography>
        </>
      )}

      {!!profile.rbqMemberNumber && (
        <>
          <Typography variant="body2" sx={LABEL_STYLES}>
            Numéro de licence RBQ
          </Typography>

          <Typography>{profile.rbqMemberNumber}</Typography>
        </>
      )}
    </Section>
  );
};

export default ProfileCompanySection;
