import { useMutation, UseMutationResult } from 'react-query';

import ResetPasswordDto from '../../dto/auth/reset-password.dto';
import ResetPasswordService from '../../services/auth/reset-password.service';

const useResetPassword = (): UseMutationResult<
  void,
  Error,
  ResetPasswordDto
> => {
  return useMutation(new ResetPasswordService().reset);
};

export default useResetPassword;
