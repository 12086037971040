import { useMutation, UseMutationResult } from 'react-query';

import SendProfileReminderService from '../../services/profiles/send-profile-reminder.service';

const useSendProfileReminder = (): UseMutationResult<
  void,
  Error,
  { id: string }
> => {
  return useMutation((variables) =>
    new SendProfileReminderService().send(variables.id),
  );
};

export default useSendProfileReminder;
