import { FC } from 'react';

import BookingSection from '../../../bookings/BookingSection';
import ProfileAlert from '../../../profiles/ProfileAlert';
import Error from '../../../Error';
import Loading from '../../../Loading';
import ManagersSection from '../../../managers/ManagersSection';
import ProfileCompanySection from '../../../profiles/ProfileCompanySection';
import UserInfoDto from '../../../../dto/users/user-info.dto';
import useFindUserInfo from '../../../../hooks/users/find-user-info.hook';

const ProfilePage: FC = () => {
  const { data, status } = useFindUserInfo({ cacheTime: 0 });

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;

  const { booking, profile } = data as WithRequiredKeys<
    UserInfoDto,
    'bookingPreference' | 'profile'
  >;

  return (
    <>
      <ProfileAlert booking={booking} profile={profile} />

      {profile && (
        <>
          <ProfileCompanySection profile={profile} />
          <ManagersSection profile={profile} />
        </>
      )}

      <BookingSection booking={booking} profile={profile} />
    </>
  );
};

export default ProfilePage;
