import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AccessOrdersDto from '../../dto/access-orders/out/access-orders.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  accessOrder: AccessOrdersDto;
}

const AccessOrdersDataTableAnchor: FC<Props> = ({ accessOrder }) => {
  const navigate = useNavigate();

  const companyName = useMemo(() => {
    const { profile } = accessOrder;

    if (profile.commonCompanyName) return profile.commonCompanyName;
    if (profile.legalCompanyName) return profile.legalCompanyName;

    return null;
  }, [accessOrder]);

  const href = useMemo(() => {
    return PATHS.admin.accessOrders.edit.path(accessOrder.profile.id ?? '');
  }, [accessOrder]);

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate],
  );

  return (
    <Link href={href} onClick={handleClick}>
      {companyName}
    </Link>
  );
};

export default AccessOrdersDataTableAnchor;
