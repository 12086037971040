import Link from '@mui/material/Link';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import DetailedSheetsDto from '../../dto/detailed-sheets/out/detailed-sheets.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  detailedSheet: DetailedSheetsDto;
}

const DetailedSheetDataTableAnchor: FC<Props> = ({ detailedSheet }) => {
  const navigate = useNavigate();

  const companyName = useMemo(() => {
    const { profile } = detailedSheet;

    if (profile.commonCompanyName) return profile.commonCompanyName;
    if (profile.legalCompanyName) return profile.legalCompanyName;

    return null;
  }, [detailedSheet]);

  const href = useMemo(() => {
    return PATHS.admin.detailedSheets.edit.path(detailedSheet.profile.id ?? '');
  }, [detailedSheet]);

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate],
  );

  return (
    <Link href={href} onClick={handleClick}>
      {companyName}
    </Link>
  );
};

export default DetailedSheetDataTableAnchor;
