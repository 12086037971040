import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';

import Autocomplete from '../forms/Autocomplete';
import FormStack from '../forms/FormStack';
import FormGrid from '../forms/FormGrid';
import TextField from '../forms/TextField';
import useFindLocations from '../../hooks/locations/find-locations.hook';
import BookingPreferenceFieldValuesInterface from '../../interfaces/booking-preferences/booking-preference-field-values.interface';
import { validateArrayRequired } from '../../utils/validations';

interface Props {
  control: Control<BookingPreferenceFieldValuesInterface>;
  setValue: UseFormSetValue<BookingPreferenceFieldValuesInterface>;
}

const BookingPreferenceLocationsForm = ({
  control,
  setValue,
}: Props): ReactElement | null => {
  const firstRender = useRef(true);

  const sectionId = useWatch({ name: 'sectionId', control });

  const { data, status } = useFindLocations({
    sections: [sectionId],
    take: 1000,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setValue('bookingPreferenceLocations1', []);
      setValue('bookingPreferenceLocations2', []);
      setValue('bookingPreferenceLocations3', []);
    }
  }, [sectionId, setValue]);

  const { nodes = [] } = status === 'success' ? data : {};

  const locations = useMemo(
    () =>
      nodes.map(({ id, booked, name }) => ({
        disabled: booked,
        label: `${name}`,
        value: id,
      })),
    [nodes],
  );

  const availableLocations = useMemo(
    () => nodes.map(({ booked }) => !booked),
    [nodes],
  );

  return availableLocations.length ? (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <Typography variant="overline" component="h2">
            Préférences d&lsquo;emplacement
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ p: 2, my: 2, boxShadow: 'none' }}>
            <AlertTitle>Choix d&apos;emplacements</AlertTitle>
            Constituez votre emplacement en identifiant les numéros
            correspondant aux espaces désirés dans le secteur préalablement
            choisi. Par exemple, pour un emplacement de 400&nbsp;pi<sup>2</sup>,
            vous devez choisir 4 numéros d&apos;espaces de 100&nbsp;pi
            <sup>2</sup>.
          </Alert>
        </Grid>

        <Grid item xs={12} md={9}>
          <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
            Premier choix d&apos;emplacements
          </Typography>
          <Autocomplete
            control={control}
            fullWidth
            name="bookingPreferenceLocations1"
            label="Numéro d'emplacements souhaités (choix 1)"
            placeholder="Commencez à saisir un numéro d'emplacement..."
            options={locations}
            rules={validateArrayRequired()}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            control={control}
            fullWidth
            label="Commentaires (facultatif)"
            name="bookingPreferenceLocationComments1"
            multiline
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
            Deuxième choix d&apos;emplacements
          </Typography>
          <Autocomplete
            control={control}
            fullWidth
            name="bookingPreferenceLocations2"
            label="Numéro d'emplacements souhaités (choix 2)"
            placeholder="Commencez à saisir un numéro d'emplacement..."
            options={locations}
            rules={validateArrayRequired()}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            control={control}
            fullWidth
            label="Commentaires (facultatif)"
            name="bookingPreferenceLocationComments2"
            multiline
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
            Troisième choix d&apos;emplacements
          </Typography>
          <Autocomplete
            control={control}
            fullWidth
            name="bookingPreferenceLocations3"
            label="Numéro d'emplacements souhaités (choix 3)"
            placeholder="Commencez à saisir un numéro d'emplacement..."
            options={locations}
            rules={validateArrayRequired()}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            control={control}
            fullWidth
            label="Commentaires (facultatif)"
            name="bookingPreferenceLocationComments3"
            multiline
          />
        </Grid>
      </FormGrid>
    </FormStack>
  ) : (
    <Alert severity="error" sx={{ width: '100%' }}>
      <AlertTitle>Ce secteur affiche complet</AlertTitle>
      Il est toutefois possible de vous inscrire sur la liste d&apos;attente en
      envoyant un courriel à l&apos;adresse{' '}
      <Link
        href="mailto:jlegare@apchqquebec.ca?subject= Liste d'attente Expo habitat Québec"
        underline="none"
      >
        jlegare@apchqquebec.ca
      </Link>
      . Il est important de préciser dans votre message
      <strong>
        {' '}
        le nom et les coordonnées complètes de votre entreprise
      </strong>{' '}
      ainsi que le <strong>secteur</strong> et la superficie
      <strong>(pi2) désirés</strong>.
    </Alert>
  );
};

export default BookingPreferenceLocationsForm;
