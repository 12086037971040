import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Error from '../../../Error';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import Loading from '../../../Loading';
import SaveLocationForm from '../../../locations/SaveLocationForm';
import SaveLocationDto from '../../../../dto/locations/in/save-location.dto';
import useFindAreas from '../../../../hooks/areas/find-areas.hook';
import useSaveLocation from '../../../../hooks/locations/save-location.hook';
import useFindSections from '../../../../hooks/sections/find-sections.hook';
import LocationFieldValuesInterface from '../../../../interfaces/locations/location-field-values.interface';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const CreateLocationPage: FC = () => {
  const { mutateAsync: saveLocation } = useSaveLocation();
  const { data: areas, status: statusAreas } = useFindAreas();

  const { data: sections, status: statusSections } = useFindSections({
    take: 1000,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<LocationFieldValuesInterface>({
    defaultValues: {
      active: true,
      name: '',
      areaId: '',
      sectionId: '',
    },
  });

  const onSubmit: SubmitHandler<LocationFieldValuesInterface> = useCallback(
    async (fieldValues) => {
      try {
        const dto = plainToClass(SaveLocationDto, {
          name: fieldValues.name,
          active: fieldValues.active,
          areaId: fieldValues.areaId,
          sectionId: fieldValues.sectionId,
        });

        await saveLocation(dto);

        toast.success('Enregistré avec succès');

        reset({ active: true, name: '', areaId: '', sectionId: '' });
      } catch (error) {
        toast.error();
      }
    },
    [reset, saveLocation],
  );

  if ([statusAreas, statusSections].includes('loading')) return <Loading />;

  if (statusAreas !== 'success' || statusSections !== 'success') {
    return <Error />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <SectionHeader>
        <Typography variant="h1">Ajouter un emplacement</Typography>
      </SectionHeader>

      <Section>
        <SaveLocationForm control={control} areas={areas} sections={sections} />
      </Section>

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        sx={{ float: 'right' }}
        startIcon={isSubmitting && <ButtonLoadingIcon />}
      >
        Ajouter l&apos;emplacement
      </Button>
    </form>
  );
};

export default CreateLocationPage;
