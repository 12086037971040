import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import ActivationCodeDto from '../../dto/activation-codes/out/activation-code.dto';
import FindActivationCodesService from '../../services/activation-codes/find-activation-codes.service';

const useFindActivationCodes = (
  options?: Omit<
    UseQueryOptions<ActivationCodeDto[], Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<ActivationCodeDto[], Error> => {
  return useQuery(
    'activation-codes',
    new FindActivationCodesService().find,
    options,
  );
};

export default useFindActivationCodes;
