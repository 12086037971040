import SaveDetailedSheetDto from '../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveDetailedSheetService {
  async save(dto: SaveDetailedSheetDto): Promise<void> {
    try {
      const method = !dto.userId ? 'POST' : 'PUT';

      await apiV1.request<void>({
        url: '/detailed-sheets',
        data: dto,
        method,
      });
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveDetailedSheetService;
