import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useToggle } from '@react-hookz/web';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ModalConfirmation from '../../../ModalConfirmation';
import ActivityFieldsForm from '../../../activity-fields/ActivityFieldsForm';
import DetailedSheetCompanyForm from '../../../detailed-sheets/DetailedSheetCompanyForm';
import DetailedSheetDescriptionsForm from '../../../detailed-sheets/DetailedSheetDescriptionsForm';
import DetailedSheetImagesForm from '../../../detailed-sheets/DetailedSheetImagesForm';
import DetailedSheetInfosForm from '../../../detailed-sheets/DetailedSheetInfosForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import ActivityFieldDto from '../../../../dto/activity-fields/activity-field.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import PaginatedResultDto from '../../../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import useSaveUserDetailedSheet from '../../../../hooks/detailed-sheets/save-user-detailed-sheet.hook';
import DetailedSheetFieldValuesInterface from '../../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import CopyDetailedSheetFilesService from '../../../../services/detailed-sheets/copy-detailed-sheet-files.service';
import GetDetailedSheetDefaultFormValues from '../../../../services/detailed-sheets/get-detailed-sheet-default-form-values.service';
import MapFieldValuesToSaveUserDetailedSheetDto from '../../../../services/detailed-sheets/map-field-values-to-save-user-detailed-sheet-dto.service';
import UploadDetailedSheetFilesService from '../../../../services/detailed-sheets/upload-detailed-sheet-files.service';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const copyDetailedSheetFilesService = new CopyDetailedSheetFilesService();

const getDetailedSheetDefaultFormValues =
  new GetDetailedSheetDefaultFormValues();

const mapFieldValuesToSaveUserDetailedSheetDto =
  new MapFieldValuesToSaveUserDetailedSheetDto();

const uploadDetailedSheetFilesService = new UploadDetailedSheetFilesService();

interface OutletContext {
  activityFields: PaginatedResultDto<ActivityFieldDto>;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
  mostRecentDetailedSheet: DetailedSheetDto | null;
  profile: ProfileDto;
}

const EditDetailedSheetPage: FC = () => {
  const navigate = useNavigate();
  const [uploading, toggleUploading] = useToggle(false);
  const [modalOpened, toggleModal] = useToggle(false);

  const {
    activityFields,
    detailedSheet,
    edition,
    mostRecentDetailedSheet,
    profile,
    booking,
  } = useOutletContext<OutletContext>();

  const { mutateAsync: saveUserDetailedSheet } = useSaveUserDetailedSheet();

  const formMethods = useForm<DetailedSheetFieldValuesInterface>({
    defaultValues: getDetailedSheetDefaultFormValues.get(
      profile,
      detailedSheet,
      mostRecentDetailedSheet,
    ),
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    getValues,
    setValue,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      toggleUploading(true);

      const fieldValues = getValues();

      const dto = mapFieldValuesToSaveUserDetailedSheetDto.map(
        edition,
        profile,
        detailedSheet,
        fieldValues,
        formMethods,
      );

      const saveLogoDto =
        fieldValues.copyPreviousLogo === 'true' && mostRecentDetailedSheet?.logo
          ? await copyDetailedSheetFilesService.copy(
              mostRecentDetailedSheet.logo,
            )
          : dto.logo;

      const saveImageDto =
        fieldValues.copyPreviousImage === 'true' &&
        mostRecentDetailedSheet?.image
          ? await copyDetailedSheetFilesService.copy(
              mostRecentDetailedSheet.image,
            )
          : dto.image;

      await uploadDetailedSheetFilesService.upload(
        dto,
        fieldValues,
        formMethods,
      );

      await saveUserDetailedSheet({
        ...dto,
        logo: saveLogoDto,
        image: saveImageDto,
      });

      toast.success('Enregistré avec succès');

      navigate(PATHS.exhibitors.path);
    } catch (error) {
      toast.error();

      Sentry.captureException(error);
    } finally {
      toggleModal(false);
      toggleUploading(false);
    }
  }, [
    detailedSheet,
    edition,
    mostRecentDetailedSheet,
    profile,
    formMethods,
    getValues,
    navigate,
    saveUserDetailedSheet,
    toggleModal,
    toggleUploading,
  ]);

  const onSubmit: SubmitHandler<DetailedSheetFieldValuesInterface> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <SectionHeader>
          <Typography variant="h1" gutterBottom>
            Fiche exposant
          </Typography>
        </SectionHeader>

        {mostRecentDetailedSheet && (
          <Alert severity="info" sx={{ mb: 4 }}>
            <AlertTitle>Important</AlertTitle>
            Pour simplifier le processus, nous avons récupéré les informations
            provenant de la fiche exposant de la dernière édition. Veuillez les
            vérifier et confirmer qu&apos;elles sont exactes.
          </Alert>
        )}

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Votre entreprise
          </Typography>

          <DetailedSheetCompanyForm
            control={control}
            booking={booking}
            setValue={setValue}
          />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Votre présence en ligne
          </Typography>

          <DetailedSheetInfosForm control={control} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Descriptions
          </Typography>

          <DetailedSheetDescriptionsForm control={control} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Images
          </Typography>

          <DetailedSheetImagesForm
            detailedSheet={detailedSheet}
            mostRecentDetailedSheet={mostRecentDetailedSheet}
          />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Catégories
          </Typography>

          <ActivityFieldsForm activityFields={activityFields} />
        </Section>

        <Button
          disabled={isSubmitting || uploading}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={(isSubmitting || uploading) && <ButtonLoadingIcon />}
        >
          Enregistrer et envoyer
        </Button>

        <ModalConfirmation
          loading={uploading}
          loadingText="Transfert du formulaire et des images en cours. Veuillez patienter..."
          opened={modalOpened}
          title="Envoi de la fiche exposant"
          onSubmit={handleSave}
          onClose={(): void => toggleModal(false)}
        />
      </form>
    </FormProvider>
  );
};

export default EditDetailedSheetPage;
