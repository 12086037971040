import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonLoadingIcon from '../../forms/ButtonLoadingIcon';
import TextField from '../../forms/TextField';
import ResetPasswordDto from '../../../dto/auth/reset-password.dto';
import ErrorTypesEnum from '../../../enums/error-types.enum';
import useResetPassword from '../../../hooks/auth/reset-password.hook';
import ResetPasswordFieldValues from '../../../interfaces/auth/reset-password-field-values.interface';
import { PATHS } from '../../../utils/paths';
import { toast } from '../../../utils/toast';
import {
  validatePasswordMatch,
  validateRequired,
} from '../../../utils/validations';

const { EXPIRED_TOKEN_ERROR, INACTIVE_TOKEN_ERROR, NOT_FOUND_ERROR } =
  ErrorTypesEnum;

const ResetPasswordPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { mutateAsync: resetPassword } = useResetPassword();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ResetPasswordFieldValues>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordFieldValues> = useCallback(
    async ({ password }: ResetPasswordFieldValues) => {
      try {
        const dto = plainToClass(ResetPasswordDto, {
          password: password.trim(),
          id,
        });

        await resetPassword(dto);

        toast.success('Mot de passe réinitialisé avec succès');

        navigate(PATHS.auth.signIn.path);
      } catch (error) {
        const { message } = error as Error;

        switch (message) {
          case NOT_FOUND_ERROR: {
            toast.error('Session en erreur');

            break;
          }
          case EXPIRED_TOKEN_ERROR: {
            toast.error('Session expirée');

            break;
          }
          case INACTIVE_TOKEN_ERROR: {
            toast.error('Session inactive');

            break;
          }
          default: {
            toast.error();

            break;
          }
        }
      }
    },
    [id, navigate, resetPassword],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ alignItems: 'flex-start' }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Réinitialiser le mot de passe
        </Typography>

        <TextField
          control={control}
          fullWidth
          label="Mot de passe"
          name="password"
          rules={{
            ...validateRequired(),
          }}
          type="password"
        />

        <TextField
          control={control}
          fullWidth
          label="Confirmation du mot de passe"
          name="passwordConfirmation"
          rules={{
            ...validateRequired(),
            ...validatePasswordMatch(getValues),
          }}
          type="password"
        />

        <Box sx={{ width: '100%', textAlign: 'center', pt: 2 }}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            sx={{ mx: 'auto' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Réinitialiser le mot de passe
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ResetPasswordPage;
