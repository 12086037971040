import { useMutation, UseMutationResult } from 'react-query';

import SendNotificationDto from '../../dto/notifications/in/send-notification.dto';
import SendNotifcationService from '../../services/notifications/send-notification.service';

const useSendNotification = (): UseMutationResult<
  void,
  Error,
  SendNotificationDto
> => {
  return useMutation(new SendNotifcationService().send);
};

export default useSendNotification;
