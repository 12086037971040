import ResetPasswordDto from '../../dto/auth/reset-password.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class ResetPasswordService {
  async reset(dto: ResetPasswordDto): Promise<void> {
    try {
      await apiV1.post<void>('/auth/reset-password', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default ResetPasswordService;
