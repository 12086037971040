import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { FC, useMemo } from 'react';
import { Control } from 'react-hook-form';

import Autocomplete from '../../../forms/Autocomplete';
import FormGrid from '../../../forms/FormGrid';
import TextField from '../../../forms/TextField';
import LocationDto from '../../../../dto/locations/out/location.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import BookingFieldValuesInterface from '../../../../interfaces/bookings/booking-field-values.interface';
import {
  validateArrayRequired,
  validateRequired,
} from '../../../../utils/validations';

interface Props {
  control: Control<BookingFieldValuesInterface>;
  locations: LocationDto[];
  profile: ProfileDto;
}

const BookingForm: FC<Props> = ({ control, locations, profile }) => {
  const locationsOptions = useMemo(
    () =>
      locations.map(({ id, name, booked }) => {
        const labels = [`${name}`];

        if (booked) labels.push('(Réservé)');

        return {
          label: labels.join(' '),
          value: id,
        };
      }),
    [locations],
  );

  return (
    <FormGrid>
      <Grid xs={12} md={5} item>
        <Autocomplete
          control={control}
          label="Emplacements réservés"
          name="bookingLocations"
          options={locationsOptions}
          rules={validateArrayRequired()}
          fullWidth
        />
      </Grid>

      <Grid
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        md={6}
        item
      />

      <Grid xs={12} md={3} item>
        <TextField
          control={control}
          label="Emplacement officiel"
          name="mainLocation"
          rules={validateRequired()}
        />
      </Grid>

      <Grid xs={12} md={2} item>
        <TextField
          control={control}
          label="Prix"
          name="price"
          rules={validateRequired()}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid xs={12} md={7} sx={{ display: { xs: 'none', md: 'block' } }} item />

      <Grid xs={12} md={3} sx={{ pt: 1 }} item>
        <FormLabel>Superficie souhaitée</FormLabel>

        <Box>{profile.area?.name ?? ''}</Box>
      </Grid>

      <Grid xs={12} md={2} item>
        <TextField
          control={control}
          label="Superficie"
          name="area"
          type="number"
          rules={validateRequired()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                p<sup>2</sup>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid xs={12} md={7} sx={{ display: { xs: 'none', md: 'block' } }} item />

      <Grid xs={12} md={5} sx={{ pb: 4 }} item>
        <TextField
          control={control}
          label="Notes"
          name="notes"
          fullWidth
          multiline
        />
      </Grid>
    </FormGrid>
  );
};

export default BookingForm;
