import { utils, writeFile } from 'xlsx';

import AccessOrdersReportDto from '../../dto/access-orders/out/access-orders-report.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import { formatDate } from '../../utils/date';

class DownloadAccessOrdersReportService {
  download(edition: EditionDto, data: AccessOrdersReportDto[]): void {
    const worksheet = utils.json_to_sheet(data);

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Rapport');

    writeFile(
      workbook,
      `Expo habitat ${edition.name} (Commandes d'accès) - ${formatDate(
        new Date().toString(),
      )}.xlsx`,
    );
  }
}

export default DownloadAccessOrdersReportService;
