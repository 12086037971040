import { useMutation, UseMutationResult } from 'react-query';

import ConfirmAssemblyService from '../../services/assemblies/confirm-assembly.service';

type Variables = { id: string };

const useConfirmAssembly = (): UseMutationResult<void, Error, Variables> => {
  return useMutation(async (variables) =>
    new ConfirmAssemblyService().confirm(variables.id),
  );
};

export default useConfirmAssembly;
