import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserAssemblyService {
  async find(): Promise<AssemblyDto> {
    try {
      const { data } = await apiV1.get<AssemblyDto>('assemblies/me');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserAssemblyService;
