import SendNotificationDto from '../../dto/notifications/in/send-notification.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SendNotifcationPreviewService {
  async send(dto: SendNotificationDto): Promise<void> {
    try {
      await apiV1.post('/notifications/send/preview', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SendNotifcationPreviewService;
