import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useToggle } from '@react-hookz/web';

import ModalConfirmation from '../../../ModalConfirmation';
import AccessOrderParkingsForm from '../../../access-orders/AccessOrderParkingsForm';
import AccessOrderPresaleTicketsForm from '../../../access-orders/AccessOrderPresaleTicketsForm';
import AccessOrderAccreditationsForm from '../../../access-orders/AccessOrderAccreditationsForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import SaveUserAccessOrderDto from '../../../../dto/access-orders/in/save-user-access-order.dto';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import useSaveUserAccessOrder from '../../../../hooks/access-orders/save-user-access-order.hook';
import AccessOrderFieldValues from '../../../../interfaces/access-orders/access-order-field-values.interface';
import GetProfileCompanyNameService from '../../../../services/profiles/get-profile-compamy-name.service';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

interface OutletContext {
  accessOrder: AccessOrderDto;
  profile: ProfileDto;
}

const EditAccessOrderPage: FC = () => {
  const navigate = useNavigate();

  const [modalOpened, toggleModal] = useToggle(false);

  const { accessOrder, profile } = useOutletContext<OutletContext>();

  const { mutateAsync: saveUserAccessOrder } = useSaveUserAccessOrder();

  const formMethods = useForm<AccessOrderFieldValues>({
    defaultValues: {
      companyName:
        accessOrder.companyName ?? getProfileCompanyNameService.get(profile),
      hasAdditionalAccreditation:
        accessOrder.additionalAccreditationQuantity !== undefined &&
        accessOrder.additionalAccreditationQuantity !== null
          ? `${accessOrder.additionalAccreditationQuantity > 0}`
          : '',
      additionalAccreditationQuantity:
        accessOrder.additionalAccreditationQuantity !== undefined &&
        accessOrder.additionalAccreditationQuantity !== null
          ? `${accessOrder.additionalAccreditationQuantity}`
          : '',
      hasVipParking:
        accessOrder.vipParkingQuantity !== undefined &&
        accessOrder.vipParkingQuantity !== null
          ? `${accessOrder.vipParkingQuantity > 0}`
          : '',
      hasStandardParking:
        accessOrder.standardParkingQuantity !== undefined &&
        accessOrder.standardParkingQuantity !== null
          ? `${accessOrder.standardParkingQuantity > 0}`
          : '',
      standardParkingQuantity:
        accessOrder.standardParkingQuantity !== undefined &&
        accessOrder.standardParkingQuantity !== null
          ? `${accessOrder.standardParkingQuantity}`
          : '',
      hasPresaleTicket:
        accessOrder.presaleTicketQuantity !== undefined &&
        accessOrder.presaleTicketQuantity !== null
          ? `${accessOrder.presaleTicketQuantity > 0}`
          : '',
      presaleTicketQuantity:
        accessOrder.presaleTicketQuantity !== undefined &&
        accessOrder.presaleTicketQuantity !== null
          ? `${accessOrder.presaleTicketQuantity}`
          : '',
    },
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = plainToClass(SaveUserAccessOrderDto, {
        companyName: fieldValues.companyName?.trim(),
        additionalAccreditationQuantity:
          fieldValues.hasAdditionalAccreditation === 'true'
            ? fieldValues.additionalAccreditationQuantity
            : 0,
        vipParkingQuantity: fieldValues.hasVipParking === 'true' ? 1 : 0,
        standardParkingQuantity:
          fieldValues.hasStandardParking === 'true'
            ? fieldValues.standardParkingQuantity
            : 0,
        presaleTicketQuantity:
          fieldValues.hasPresaleTicket === 'true'
            ? fieldValues.presaleTicketQuantity
            : 0,
      });

      await saveUserAccessOrder(dto);

      toast.success('Enregistré avec succès');

      navigate(PATHS.exhibitors.path);
    } catch (error) {
      toast.error();

      Sentry.captureException(error);
    } finally {
      toggleModal(false);
    }
  }, [getValues, navigate, saveUserAccessOrder, toggleModal]);

  const onSubmit: SubmitHandler<AccessOrderFieldValues> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <SectionHeader>
          <Typography variant="h1" gutterBottom>
            Commandes d&apos;accès au Salon
          </Typography>
        </SectionHeader>

        <Alert severity="warning">
          <AlertTitle>Information importante</AlertTitle>
          Le formulaire ci-dessous regroupe à la fois les commandes pour les
          accréditations supplémentaires, les laissez-passer de stationnement
          ainsi que les billets de prévente. Vous devez remplir les trois
          sections au même moment, car une fois soumis, le formulaire ne sera
          plus éditable.
        </Alert>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Accréditations
          </Typography>

          <AccessOrderAccreditationsForm accessOrder={accessOrder} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Stationnements
          </Typography>

          <AccessOrderParkingsForm />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Billets de prévente
          </Typography>

          <AccessOrderPresaleTicketsForm />
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer et envoyer
        </Button>

        <ModalConfirmation
          opened={modalOpened}
          title="Voulez-vous vraiment envoyer la demande ?"
          text="Votre demande sera officiellement envoyée pour traitement. Une fois soumis, il ne vous sera plus possible de modifier le formulaire manuellement. "
          onSubmit={handleSave}
          onClose={(): void => toggleModal(false)}
        />
      </form>
    </FormProvider>
  );
};

export default EditAccessOrderPage;
