import { useMutation, UseMutationResult } from 'react-query';

import SaveUserAccessOrderDto from '../../dto/access-orders/in/save-user-access-order.dto';
import SaveUserAccessOrderService from '../../services/access-orders/save-user-access-order.service';

const useSaveUserAccessOrder = (): UseMutationResult<
  void,
  Error,
  SaveUserAccessOrderDto
> => {
  return useMutation(new SaveUserAccessOrderService().save);
};

export default useSaveUserAccessOrder;
