import SignUpDto from '../../dto/auth/sign-up.dto';
import JwtTokenResponseDto from '../../dto/jwt/jwt-token-response.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SignUpService {
  async signUp(dto: SignUpDto): Promise<void> {
    try {
      await apiV1.post<JwtTokenResponseDto>('/auth/sign-up', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SignUpService;
