import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import BookingPreferenceDto from '../../dto/booking-preferences/out/booking-preference.dto';
import FindUserBookingPreferenceService from '../../services/booking-preferences/find-user-booking-preference.service';

const useFindBookingPreference = (
  options?: Omit<
    UseQueryOptions<BookingPreferenceDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<BookingPreferenceDto, Error> => {
  return useQuery(
    'booking-preferences/me',
    new FindUserBookingPreferenceService().find,
    options,
  );
};

export default useFindBookingPreference;
