import DoorTypesEnum from '../../enums/assemblies/door-types.enum';

const { GARAGE_GROUND_LEVEL, LOADING_DOCK } = DoorTypesEnum;

class GetDoorTypeLabelService {
  get(doorType: DoorTypesEnum): string {
    switch (doorType) {
      case GARAGE_GROUND_LEVEL: {
        return 'Porte de garage au sol';
      }
      case LOADING_DOCK: {
        return 'Quai de déchargement';
      }
    }
  }
}

export default GetDoorTypeLabelService;
