import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import bytes from 'bytes';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { IconContext } from 'react-icons';
import { FiDownload, FiTrash } from 'react-icons/fi';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import DropzoneStatesEnum from '../../enums/dropzone-states.enum';
import theme from '../../styles/theme/index';
import { getDropzoneStatus } from '../../utils/dropzone';
import { DEFAULT_FILE_PREVIEW_URL } from '../../utils/files';
import { toast } from '../../utils/toast';

const getColor = (status: DropzoneStatesEnum): string => {
  switch (status) {
    case 'error': {
      return theme.palette.error.main;
    }
    case 'accepted': {
      return theme.palette.success.main;
    }
    case 'active': {
      return theme.palette.primary.main;
    }
    default: {
      return theme.palette.grey[400];
    }
  }
};

const Container = styled(Box)<{
  preview: string | null;
  status: DropzoneStatesEnum;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 300px;
  padding: 20px;
  background-image: url(${(props): string | null => props.preview});
  background-size: contain;
  background-color: ${(props): string => (props.preview ? '#eee' : '')};
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${(props): string => getColor(props.status)};
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
  cursor: pointer;
`;

interface Props {
  label?: string;
  description?: string;
  preview?: string;
  options?: DropzoneOptions;
}

const Dropzone = <T extends FieldValues>({
  control,
  label,
  description,
  name,
  preview,
  rules,
  options,
}: Props & UseControllerProps<T>): ReactElement => {
  const [removed, setRemoved] = useState(false);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name, rules });

  const { setValue } = useFormContext();

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    ...options,
    onDropAccepted: (files) => onChange(files[0] as PathValue<T, Path<T>>),
    onDropRejected: (fileRejections) => {
      for (const fileRejection of fileRejections) {
        const { file, errors } = fileRejection;

        for (const error of errors) {
          let message = '';

          switch (error.code) {
            case 'file-invalid-type': {
              message = `Le type du fichier téléversé n'est pas accepté.`;

              break;
            }
            case 'file-too-small': {
              message = `La taille du fichier téléversé est trop petite (min: ${bytes(
                options?.minSize ?? 0,
              )}).`;

              break;
            }
            case 'file-too-large': {
              message = `La taille du fichier téléversé est trop grande (max: ${bytes(
                options?.maxSize ?? 0,
              )}).`;

              break;
            }
            case 'too-many-files': {
              message = `Le nombre maximum de fichiers est dépassé (max: ${
                options?.maxFiles ?? 1
              })`;

              break;
            }
            default: {
              message = "Une erreur inattendue s'est produite.";
              break;
            }
          }

          toast.error(`${file.name}: ${message}`);
        }
      }
    },
  });

  const handleRemove = useCallback(() => {
    setValue(name as string, null, { shouldDirty: true });
    setRemoved(true);
  }, [name, setValue]);

  const dropzonePreview = useMemo(() => {
    const type: string = value?.type ?? '';

    if (type.startsWith('image/')) {
      return URL.createObjectURL(value);
    }

    if (type.startsWith('application/')) {
      return DEFAULT_FILE_PREVIEW_URL;
    }

    if (preview && !removed) return preview;

    return null;
  }, [removed, preview, value]);

  return (
    <FormControl error={!!error} fullWidth sx={{ mb: 4 }}>
      {label && <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>}
      {description && (
        <Typography variant="body2" color="grey.600" sx={{ mb: 1 }}>
          {description}
        </Typography>
      )}

      <Container
        sx={{ mt: 1 }}
        {...getRootProps({
          preview: dropzonePreview,
          status: getDropzoneStatus({
            error: !!error,
            isDragAccept,
            isDragActive,
            isDragReject,
          }),
        })}
      >
        <input {...getInputProps()} />

        {!value && (
          <>
            <IconContext.Provider value={{ size: '3em' }}>
              <FiDownload />
            </IconContext.Provider>

            <Typography sx={{ mt: 1 }}>
              Déposer une image ici ou cliquer pour téléverser.
            </Typography>
          </>
        )}
      </Container>

      {value && (
        <Box sx={{ mt: 1 }}>
          <Button variant="text" startIcon={<FiTrash />} onClick={handleRemove}>
            Retirer
          </Button>
        </Box>
      )}

      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default Dropzone;
