import { Components, Theme } from '@mui/material';

const textfield = (_theme: Theme): Components<Theme> => {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          'legend': {
            display: 'none',
          },
        },
      },
    },
  };
};

export default textfield;
