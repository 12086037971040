import SaveOpenedStagesDto from '../../dto/editions/in/save-opened-stages.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveOpenedStagesService {
  async save(dto: SaveOpenedStagesDto): Promise<void> {
    try {
      await apiV1.put<void>('/editions/stages', dto);
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveOpenedStagesService;
