import { Components, Theme } from '@mui/material';

const autocomplete = (_theme: Theme): Components<Theme> => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 0,
        }
      }
    },
  };
};

export default autocomplete;
