import { alpha, createTheme } from '@mui/material/styles';

import PaletteOptionsInterface from '../interfaces/palette-options.interface';

const DEFAULT_THEME = createTheme();

const GREYS = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  '500_8': alpha('#919EAB', 0.08),
  '500_12': alpha('#919EAB', 0.12),
  '500_16': alpha('#919EAB', 0.16),
  '500_24': alpha('#919EAB', 0.24),
  '500_32': alpha('#919EAB', 0.32),
  '500_48': alpha('#919EAB', 0.48),
  '500_56': alpha('#919EAB', 0.56),
  '500_80': alpha('#919EAB', 0.8),
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const palette: PaletteOptionsInterface = {
  primary: { main: '#00aeef', dark: '#2789ad' },
  secondary: { main: '#3366FF' },
  background: { paper: '#fff', default: GREYS[100] },
  success: { main: '#6cbc53' },
  warning: { main: '#FFC107' },
  error: { main: '#FF4842' },
  info: { main: '#54D62C' },
  text: { primary: GREYS[800], secondary: GREYS[600], disabled: GREYS[500] },
  grey: GREYS,
  pending: DEFAULT_THEME.palette.augmentColor({ color: { main: '#53BCB8' } }),
};
