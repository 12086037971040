import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { FiSearch } from 'react-icons/fi';
import { FC } from 'react';

import useFilter from '../../hooks/data-tables/filters.hook';

const DataTableToolbar: FC = () => {
  const { debouncedFilter, setFilter } = useFilter();

  return (
    <Toolbar
      sx={{
        justifyContent: 'space-between',
        pt: 5,
        pb: 2,
      }}
    >
      <TextField
        placeholder="Filtrer les résultats"
        onChange={({ target }): void => setFilter(target.value)}
        value={debouncedFilter}
        sx={{ width: '100%', maxWidth: '400px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FiSearch />
            </InputAdornment>
          ),
        }}
      />
    </Toolbar>
  );
};

export default DataTableToolbar;
