import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import UserInfoDto from '../../dto/users/user-info.dto';
import FindUserInfoByIdService from '../../services/users/find-user-info-by-id.service';

const useFindUserInfoById = (
  id: string,
  options?: Omit<UseQueryOptions<UserInfoDto, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<UserInfoDto, Error> => {
  return useQuery(
    ['info', id],
    () => new FindUserInfoByIdService().find(id),
    options,
  );
};

export default useFindUserInfoById;
