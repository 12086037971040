import { useDebouncedEffect } from '@react-hookz/web';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import DataTableOptionsDto from '../../dto/data-tables/data-table-options.dto';
import SortDirectionEnum from '../../enums/data-tables/sorting/sort-directions.enum';

const DEFAULT_CONTEXT: DataTableContextInterface = {
  dataTableOptions: {
    filter: '',
    page: 1,
    showArchives: false,
    sortColumn: 'id',
    sortDirection: SortDirectionEnum.ASC,
    take: 50,
  },
  debouncedFilter: '',
  setDataTableOptions: () => void 0,
  setDebouncedFilter: () => void 0,
};

const DEFAULT_DEBOUNCE_TIME = 300;

export interface DataTableContextInterface {
  dataTableOptions: DataTableOptionsDto;
  debouncedFilter: string;
  setDataTableOptions: Dispatch<SetStateAction<DataTableOptionsDto>>;
  setDebouncedFilter: Dispatch<SetStateAction<string>>;
}

export const DataTableContext = createContext<
  DataTableContextInterface | undefined
>(undefined);

export const useDataTable = (): DataTableContextInterface => {
  const context = useContext(DataTableContext);

  if (!context) throw Error('DataTable context not defined');

  return context;
};

export const DataTableProvider: FC<
  {
    dataTableOptions?: DataTableContextInterface['dataTableOptions'];
  } & PropsWithChildren
> = ({ children, dataTableOptions: defaultDataTableOptions = {} }) => {
  const [dataTableOptions, setDataTableOptions] = useState({
    ...DEFAULT_CONTEXT.dataTableOptions,
    ...defaultDataTableOptions,
  });

  const [debouncedFilter, setDebouncedFilter] = useState(
    dataTableOptions.filter ?? '',
  );

  useDebouncedEffect(
    (): void => {
      setDataTableOptions({
        ...dataTableOptions,
        filter: debouncedFilter,
      });
    },
    [debouncedFilter],
    DEFAULT_DEBOUNCE_TIME,
  );

  return (
    <DataTableContext.Provider
      value={{
        dataTableOptions,
        debouncedFilter,
        setDataTableOptions,
        setDebouncedFilter,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
};
