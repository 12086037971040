import Typography from '@mui/material/Typography';
import { FC } from 'react';

import DetailedSheetsDataTable from '../../../detailed-sheets/DetailedSheetsDataTable';
import SectionHeader from '../../../layouts/SectionHeader';
import { DataTableProvider } from '../../../../providers/data-tables/DataTableProvider';

const AdminDetailedSheetsListPage: FC = () => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Fiches exposant</Typography>
      </SectionHeader>

      <DataTableProvider dataTableOptions={{ showArchives: true }}>
        <DetailedSheetsDataTable />
      </DataTableProvider>
    </>
  );
};

export default AdminDetailedSheetsListPage;
