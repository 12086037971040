import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FC } from 'react';

import useSorting from '../../hooks/data-tables/sorting.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import { toLowercase } from '../../utils/enums';

interface Props {
  columns: DataTableColumnInterface[];
}

const DataTableHead: FC<Props> = ({ columns }) => {
  const { getDirection, isActive, setSort } = useSorting();

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ key, label, disabled = false, sx }) => {
          return (
            <TableCell key={key} sx={sx}>
              <TableSortLabel
                active={isActive(key)}
                disabled={disabled}
                direction={toLowercase(getDirection(key))}
                onClick={(): void => setSort(key)}
              >
                {label}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {/* <TableCell /> */}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;
