import { useMutation, UseMutationResult } from 'react-query';

import SignInDto from '../../dto/auth/sign-in.dto';
import JwtTokenResponseDto from '../../dto/jwt/jwt-token-response.dto';
import SignInService from '../../services/auth/sign-in.service';

const useSignIn = (): UseMutationResult<
  JwtTokenResponseDto,
  Error,
  SignInDto
> => {
  return useMutation(async (dto: SignInDto) => new SignInService().signIn(dto));
};

export default useSignIn;
