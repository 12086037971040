import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import BookingPreferenceForm from '../../../booking-preferences/BookingPreferenceForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import SectionHeader from '../../../layouts/SectionHeader';
import BookingPreferenceDto from '../../../../dto/booking-preferences/out/booking-preference.dto';
import useSaveBookingPreference from '../../../../hooks/booking-preferences/save-booking-preference.hook';
import BookingPreferenceFieldValuesInterface from '../../../../interfaces/booking-preferences/booking-preference-field-values.interface';
import MapSaveBookingPreferenceFormToDtoService from '../../../../services/booking-preferences/map-save-booking-preference-form-to-dto.service';
import { PATHS } from '../../../../utils/paths';
import { queryClient } from '../../../../utils/query-client';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const mapCreateBookingPreferenceFormToDtoService =
  new MapSaveBookingPreferenceFormToDtoService();

const CreateBookingPreferencePage: FC = () => {
  const navigate = useNavigate();
  const { bookingPreference } = useOutletContext<BookingPreferenceDto>();
  const { mutateAsync: saveBookingPreference } = useSaveBookingPreference();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<BookingPreferenceFieldValuesInterface>({
    defaultValues: {
      areaId: '',
      comments: '',
      sectionId: '',
      bookingPreferenceLocations1: [],
      bookingPreferenceLocationComments1: '',
      bookingPreferenceLocations2: [],
      bookingPreferenceLocationComments2: '',
      bookingPreferenceLocations3: [],
      bookingPreferenceLocationComments3: '',
      categories: [],
    },
  });

  const handleNavigate = useCallback(() => {
    navigate(PATHS.exhibitors.profiles.edit.path);
  }, [navigate]);

  const onSubmit: SubmitHandler<BookingPreferenceFieldValuesInterface> =
    useCallback(
      async (fieldValues: BookingPreferenceFieldValuesInterface) => {
        try {
          const dto =
            mapCreateBookingPreferenceFormToDtoService.map(fieldValues);

          await saveBookingPreference(dto);

          await queryClient.invalidateQueries('users/step');

          toast.success("Préférences d'emplacements sauvegardées avec succès");

          navigate(PATHS.exhibitors.termsAndConditions.path);

          await queryClient.invalidateQueries('booking-preferences/me');
        } catch (error) {
          toast.error();
        }
      },
      [navigate, saveBookingPreference],
    );

  if (bookingPreference && !isSubmitting) {
    return (
      <Navigate to={PATHS.exhibitors.bookingPreferences.edit.path} replace />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <SectionHeader>
        <Typography variant="h1" gutterBottom>
          Remplissez votre demande d&apos;emplacement
        </Typography>
      </SectionHeader>

      <Button
        onClick={handleNavigate}
        startIcon={<FiChevronLeft />}
        variant="text"
      >
        Étape précédente
      </Button>

      <BookingPreferenceForm control={control} setValue={setValue} />

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        sx={{ float: 'right' }}
        startIcon={isSubmitting && <ButtonLoadingIcon />}
      >
        Continuer l&apos;inscription
      </Button>
    </form>
  );
};

export default CreateBookingPreferencePage;
