import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetAddress: FC<Props> = ({ detailedSheet }) => {
  const { address, city, postalCode, province } = detailedSheet;

  const renderLine1 = useMemo(() => {
    if (!address) return null;

    return address;
  }, [address]);

  const renderLineBreak = useMemo(() => {
    if (!address || (!city && !postalCode && !province)) return null;

    return <br />;
  }, [address, city, postalCode, province]);

  const renderLine2 = useMemo(() => {
    const prefixParts: string[] = [];

    if (city) prefixParts.push(city);
    if (postalCode) prefixParts.push(postalCode);

    const prefix = prefixParts.join(', ');

    const line2Parts = [prefix];

    if (province) line2Parts.push(`(${province})`);

    const line2 = line2Parts.join(' ');

    if (!line2) return null;

    return line2;
  }, [city, postalCode, province]);

  return (
    <Typography>
      {renderLine1}
      {renderLineBreak}
      {renderLine2}
    </Typography>
  );
};

export default DetailedSheetAddress;
