import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import LocationDetailedDto from '../../dto/locations/out/location-detailed.dto';
import FindLocationService from '../../services/locations/find-location.service';

const useFindLocation = (
  id: string,
  options?: Omit<
    UseQueryOptions<LocationDetailedDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<LocationDetailedDto, Error> => {
  return useQuery(
    ['location', id],
    () => new FindLocationService().find(id),
    options,
  );
};

export default useFindLocation;
