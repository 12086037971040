import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindUserCurrentStepService {
  async find(): Promise<string> {
    try {
      const { data } = await apiV1.get<string>('/users/step');

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindUserCurrentStepService;
