import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import { ReactElement, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import OptionInterface from '../../interfaces/option.interface';

interface Props {
  disabled: (value: string[], optionValue: string) => boolean;
  error?: FieldError;
  onChange: (value: string[]) => void;
  options: OptionInterface<string | ReactNode>[];
  value: string[];
}

const CheckboxGroup = ({
  disabled,
  error,
  onChange,
  options,
  value,
  ...props
}: FormGroupProps & Props): ReactElement => {
  return (
    <FormControl error={!!error} sx={{ width: '100%' }}>
      <FormGroup {...props}>
        {options.map(
          (
            {
              label: optionLabel,
              value: optionValue,
              disabled: optionDisabled,
            },
            index,
          ) => (
            <FormControlLabel
              className="CheckboxLabelWrapper"
              key={index}
              control={<Checkbox />}
              disabled={optionDisabled || disabled(value, optionValue)}
              label={optionLabel}
              onChange={(_, checked): void => {
                const fieldValue = checked
                  ? [...value, optionValue]
                  : value.filter((id) => id !== optionValue);

                onChange(fieldValue);
              }}
              checked={value.includes(optionValue)}
              sx={{ display: 'flex' }}
            />
          ),
        )}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
