import Box from '@mui/material/Box';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import { FC } from 'react';

const Modal: FC<ModalProps> = ({ children, ...props }) => {
  return (
    <MuiModal {...props}>
      <Box className="MuiModal-Box-root">{children}</Box>
    </MuiModal>
  );
};

export default Modal;
