import DataTableOptionsDto from '../../dto/data-tables/data-table-options.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import SectionDto from '../../dto/sections/section.dto';
import { apiV1 } from '../../http';

class FindSectionsService {
  async find(
    dto: DataTableOptionsDto,
  ): Promise<PaginatedResultDto<SectionDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<SectionDto>>(
      '/sections',
      { params: dto },
    );

    return data;
  }
}

export default FindSectionsService;
