import EditionsDto from '../../dto/editions/out/editions.dto';
import { apiV1 } from '../../http';

class FindEditionsService {
  async find(): Promise<EditionsDto[]> {
    const { data } = await apiV1.get<EditionsDto[]>('/editions');

    return data;
  }
}

export default FindEditionsService;
