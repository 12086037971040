import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import App from './components/App';

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://0c02e0caa41554684e403f64f58d6a5e@o4508399456485376.ingest.us.sentry.io/4508399459041280',
    environment: process.env.REACT_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'https://app.expohabitatquebec.com',
      'https://staging.app.expohabitatquebec.com',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');

if (!container) throw new Error('App container is missing');

const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
