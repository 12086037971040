import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import Logo from './Logo';
import logoHubble from '../../images/logo-hubble.svg';
import { roleIsAdmin } from '../../utils/roles';

const Footer: FC = () => {
  const isAdmin = roleIsAdmin();

  return (
    <Box
      component="footer"
      sx={{
        borderTop: '1px solid',
        borderTopColor: '#35aeef2e',
        color: 'grey.800',
        mt: 8,
      }}
    >
      <Container
        sx={{ mt: 4, mb: 3, px: 6 }}
        disableGutters
        maxWidth={isAdmin ? 'lg' : 'md'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: { xs: 'flex', md: 'block' },
                justifyContent: { xs: 'center', md: 'initial' },
              }}
            >
              <Box
                sx={{
                  maxWidth: '250px',
                }}
              >
                <Logo />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Typography component="div" variant="body2" gutterBottom>
              APCHQ - région de Québec <br /> 1720 Bd Père-Lelièvre, Québec, QC
              G1M 3J6 <br /> Service aux exposants :{' '}
              <Link href="tel:4186823353" underline="none">
                418&nbsp;682-3353
              </Link>{' '}
              <br />
              <Link href="mailto:info@expohabitatquebec.com" underline="none">
                info@expohabitatquebec.com
              </Link>
              <br />
              <br />© 2023 Expo habitat Québec. Tous droits réservés.
              <Box
                component="div"
                sx={{
                  width: '100%',
                  fontSize: '12px',
                  float: 'left',
                  mt: 1,
                }}
              >
                Réalisé par{' '}
                <Link
                  href="https://teamhubble.com/"
                  target="_blank"
                  sx={{
                    ' img': {
                      display: 'inline',
                      position: 'relative',
                      top: '3px',
                      left: '3px',
                    },
                  }}
                >
                  <img src={logoHubble} alt="Hubble" />
                </Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
