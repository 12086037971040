import { useMutation, UseMutationResult } from 'react-query';

import SignUpDto from '../../dto/auth/sign-up.dto';
import SignUpService from '../../services/auth/sign-up.service';

const useSignUp = (): UseMutationResult<void, Error, SignUpDto> => {
  return useMutation(new SignUpService().signUp);
};

export default useSignUp;
