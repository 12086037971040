import SaveBookingDto from '../../dto/bookings/save-booking.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SaveBookingService {
  async save(dto: SaveBookingDto): Promise<void> {
    try {
      const { data } = await apiV1.post<void>('/bookings/create', dto);

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SaveBookingService;
