import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Checkbox from '../../../forms/Checkbox';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import ProfileCompanyForm from '../../../profiles/ProfileCompanyForm';
import ManagersForm from '../../../managers/ManagersForm';
import useSaveProfile from '../../../../hooks/profiles/save-profile.hook';
import ProfileFieldValuesInterface from '../../../../interfaces/profiles/profile-field-values.interface';
import MapSaveProfileFormToDtoService from '../../../../services/profiles/map-save-profile-form-to-dto.service';
import { PATHS } from '../../../../utils/paths';
import { queryClient } from '../../../../utils/query-client';
import { toast } from '../../../../utils/toast';
import {
  onInvalidSubmit,
  validateRequired,
} from '../../../../utils/validations';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import FormLabel from '@mui/material/FormLabel';

const mapCreateProfileFormToDtoService = new MapSaveProfileFormToDtoService();

const EditProfilePage: FC = () => {
  const navigate = useNavigate();
  const { profile } = useOutletContext<{ profile: ProfileDto }>();
  const { mutateAsync: saveProfile } = useSaveProfile();

  const [manager1, manager2] = profile?.managers ?? [];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<ProfileFieldValuesInterface>({
    defaultValues: {
      legalCompanyName: profile.legalCompanyName,
      commonCompanyName: profile.commonCompanyName,
      address: profile.address,
      city: profile.city,
      province: profile.province,
      postalCode: profile.postalCode,
      isApchqMember: `${!!profile.apchqMemberNumber}`,
      apchqMemberNumber: profile.apchqMemberNumber ?? '',
      isRbqMember: `${!!profile?.rbqMemberNumber}`,
      rbqMemberNumber: profile?.rbqMemberNumber ?? '',
      hasMoreThanOneManager: `${!!manager2}`,
      manager1Salutation: manager1?.salutation ?? '',
      manager1FirstName: manager1?.firstName ?? '',
      manager1LastName: manager1?.lastName ?? '',
      manager1Email: manager1?.email ?? '',
      manager1PhoneNumber1: manager1?.phoneNumber1 ?? '',
      manager1PhoneNumber1Ext: manager1?.phoneNumber1Ext ?? '',
      manager1PhoneNumber2: manager1?.phoneNumber2 ?? '',
      manager1PhoneNumber2Ext: manager1?.phoneNumber2Ext ?? '',
      manager2Salutation: manager2?.salutation ?? '',
      manager2FirstName: manager2?.firstName ?? '',
      manager2LastName: manager2?.lastName ?? '',
      manager2Email: manager2?.email ?? '',
      manager2PhoneNumber1: manager2?.phoneNumber1 ?? '',
      manager2PhoneNumber1Ext: manager2?.phoneNumber1Ext ?? '',
      manager2PhoneNumber2: manager2?.phoneNumber2 ?? '',
      manager2PhoneNumber2Ext: manager2?.phoneNumber2Ext ?? '',
      acceptedPaymentMethod: true,
    },
  });

  const onSubmit: SubmitHandler<ProfileFieldValuesInterface> = useCallback(
    async (fieldValues: ProfileFieldValuesInterface) => {
      try {
        const dto = mapCreateProfileFormToDtoService.map(fieldValues, profile);

        await saveProfile(dto);

        await queryClient.invalidateQueries('users/step');

        toast.success('Enregistré avec succès');

        navigate(PATHS.exhibitors.bookingPreferences.create.path);

        await queryClient.invalidateQueries('profiles/me');
      } catch (error) {
        toast.error();
      }
    },
    [navigate, profile, saveProfile],
  );

  if (!profile && !isSubmitting) {
    return <Navigate to={PATHS.exhibitors.profiles.create.path} replace />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <SectionHeader>
        <Typography variant="h1">Inscrivez-vous</Typography>
      </SectionHeader>

      <Section>
        <ProfileCompanyForm control={control} setValue={setValue} />
      </Section>

      <Section>
        <ManagersForm control={control} setValue={setValue} />

        <FormLabel>Mode de paiement </FormLabel>

        <Typography sx={{ mb: 1, mt: 1 }}>
          La procédure vous sera envoyée avec le contrat bail une fois votre
          emplacement attribué. À ce moment, vous aurez l&apos;option de payer
          le montant en totalité ou effectuer deux versements (certaines
          restrictions s&apos;appliquent).
        </Typography>

        <Checkbox
          control={control}
          name="acceptedPaymentMethod"
          label="J'ai compris."
          rules={validateRequired()}
        />
      </Section>

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        sx={{ float: 'right' }}
        startIcon={isSubmitting && <ButtonLoadingIcon />}
      >
        Continuer l&apos;inscription
      </Button>
    </form>
  );
};

export default EditProfilePage;
