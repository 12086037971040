import { FC } from 'react';
import { Control } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import RadioButtons from '../forms/RadioButtons';
import TextField from '../forms/TextField';
import SalutationsEnum from '../../enums/salutations.enum';
import {
  validateEmailFormat,
  validatePhoneNumberFormat,
  validateRequired,
} from '../../utils/validations';
import AdminProfileFieldValuesInterface from '../../interfaces/profiles/admin-profile-field-values.interface';

interface Props {
  control: Control<AdminProfileFieldValuesInterface>;
  index: 1 | 2;
}

const AdminManagerForm: FC<Props> = ({ control, index }) => {
  return (
    <>
      <Grid item xs={12}>
        <RadioButtons
          control={control}
          name={`manager${index}Salutation`}
          options={[
            { label: 'Monsieur', value: SalutationsEnum.MR },
            { label: 'Madame', value: SalutationsEnum.MRS },
          ]}
          rules={validateRequired()}
          row
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          control={control}
          fullWidth
          label="Prénom"
          name={`manager${index}FirstName`}
          rules={validateRequired()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          control={control}
          fullWidth
          label="Nom"
          name={`manager${index}LastName`}
          rules={validateRequired()}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          control={control}
          fullWidth
          label="Courriel"
          name={`manager${index}Email`}
          rules={{ ...validateRequired(), ...validateEmailFormat() }}
          type="email"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: { xs: 'none', sm: 'inherit' } }}
      />

      <Grid item xs={12} sm={8} md={4}>
        <TextField
          control={control}
          fullWidth
          label="Téléphone"
          name={`manager${index}PhoneNumber1`}
          placeholder="Ex: 418 111-2222"
          rules={validatePhoneNumberFormat()}
        />
      </Grid>

      <Grid item xs={8} sm={4} md={3}>
        <TextField
          control={control}
          label="Poste (facultatif)"
          placeholder="Ex: 1234"
          name={`manager${index}PhoneNumber1Ext`}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: { xs: 'none', md: 'inherit' } }}
      />

      <Grid item xs={12} sm={8} md={4}>
        <TextField
          control={control}
          fullWidth
          label="Autre Téléphone (facultatif)"
          name={`manager${index}PhoneNumber2`}
          placeholder="Ex: 418 111-2222"
          rules={validatePhoneNumberFormat(true)}
        />
      </Grid>

      <Grid item xs={8} sm={4} md={3}>
        <TextField
          control={control}
          label="Poste (facultatif)"
          placeholder="Ex: 1234"
          name={`manager${index}PhoneNumber2Ext`}
        />
      </Grid>
    </>
  );
};

export default AdminManagerForm;
