import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import axios from 'axios';
import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react';
import { FiUploadCloud } from 'react-icons/fi';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import { apiV1 } from '../../../../http';
import { toast } from '../../../../utils/toast';

interface Props {
  fileName: string;
  label: string;
  url: string;
}

const FileUpload: FC<Props> = ({ fileName, label, url }) => {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target }) => {
      const file = (target.files ?? [])[0];

      if (file) {
        setUploading(true);

        const { data: signedUrl } = await apiV1.get<string>(
          '/files/signed-url',
          {
            params: {
              name: fileName,
              contentType: 'application/pdf',
            },
          },
        );

        await axios.put(signedUrl, file, {
          headers: {
            'Cache-Control': 'max-age=0,no-cache,no-store,must-revalidate',
            'Content-Type': 'application/pdf',
          },
        });

        setUploading(false);

        toast.success('Document transféré avec succès');
      }
    },
    [fileName, setUploading],
  );

  return (
    <Grid xs={12} item>
      <FormLabel>{label}</FormLabel>

      <Box>
        <IconButton
          disabled={uploading}
          onClick={(): void => fileInputRef.current?.click()}
          color="primary"
          size="large"
        >
          {uploading ? <ButtonLoadingIcon /> : <FiUploadCloud />}
        </IconButton>

        <Link href={url} target="_blank">
          {fileName}
        </Link>
      </Box>

      <input
        id="plan-input"
        accept=".pdf"
        onChange={handleChange}
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
      />
    </Grid>
  );
};

export default FileUpload;
