import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Control, useController, UseFormSetValue } from 'react-hook-form';

import CategoryCheckboxLabel from '../categories/CategoryCheckboxLabel';
import CheckboxGroup from '../forms/CheckboxGroup';
import CategoryDto from '../../dto/categories/category.dto';
import { validateArrayRequired } from '../../utils/validations';
import AdminProfileFieldValuesInterface from '../../interfaces/profiles/admin-profile-field-values.interface';

interface Props {
  categories: CategoryDto[];
  control: Control<AdminProfileFieldValuesInterface>;
  edited: boolean;
  sectionId: string;
  setValue: UseFormSetValue<AdminProfileFieldValuesInterface>;
}

const AdminSectionCategoriesForm = ({
  categories,
  control,
  edited,
  sectionId,
  setValue,
}: Props): ReactElement => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: 'categories',
    rules: validateArrayRequired(1, 3),
    control,
  });

  const hasSingleCategory = useMemo(
    () => categories.length === 1,
    [categories],
  );

  useEffect(() => {
    if (!edited) return;

    const value = hasSingleCategory ? [categories[0].id] : [];

    setValue('categories', value);
  }, [categories, edited, hasSingleCategory, sectionId, setValue]);

  const isDisabled = useCallback(
    (selection: string[], category: string): boolean => {
      return (
        hasSingleCategory ||
        (!selection.includes(category) && selection.length >= 3)
      );
    },
    [hasSingleCategory],
  );

  const options = useMemo(() => {
    return categories.map((category) => {
      return {
        label: <CategoryCheckboxLabel category={category} />,
        value: category.id,
      };
    });
  }, [categories]);

  return (
    <Box sx={{ pt: 4 }}>
      <FormLabel>
        Catégories d&apos;activités du secteur
        <Typography variant="body2" component="p">
          Choisir 1 à 3 catégories
        </Typography>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormLabel>

      <CheckboxGroup
        sx={{
          backgroundColor: 'grey.200',
          display: 'block',
          padding: '15px 20px 10px 20px !important',
          marginBottom: '20px',
          mt: '20px !important',
        }}
        disabled={isDisabled}
        error={error}
        onChange={(value): void => onChange(value as string[])}
        options={options}
        value={value}
        row
      />
    </Box>
  );
};

export default AdminSectionCategoriesForm;
