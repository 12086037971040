import { useMutation, UseMutationResult } from 'react-query';

import SaveAssemblyDeliveryPreferencesDto from '../../dto/assemblies/in/save-assembly-delivery-preferences.dto';
import SaveAssemblyDeliveryPreferencesService from '../../services/assemblies/save-assembly-delivery-preferences.service';

const useSaveAssemblyDeliveryPreferences = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveAssemblyDeliveryPreferencesDto }
> => {
  return useMutation((variables) =>
    new SaveAssemblyDeliveryPreferencesService().save(
      variables.id,
      variables.dto,
    ),
  );
};

export default useSaveAssemblyDeliveryPreferences;
