import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import FindUserCurrentStepService from '../../services/users/find-user-current-step.service';

const useFindUserCurrentStep = (
  options?: Omit<UseQueryOptions<string, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<string, Error> => {
  return useQuery('users/step', new FindUserCurrentStepService().find, options);
};

export default useFindUserCurrentStep;
