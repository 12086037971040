import { Components, Theme } from '@mui/material';

const input = (theme: Theme): Components<Theme> => {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled }
          },
          '& .MuiInputBase-inputAdornedStart': {
            paddingLeft: `0!important`,
          }
        },
        input: {
          paddingTop: `${theme.spacing(1.5)}!important`,
          paddingRight: `${theme.spacing(2)}!important`,
          paddingBottom: `${theme.spacing(1.5)}!important`,
          paddingLeft: `${theme.spacing(2)}!important`,

          '&::placeholder': {
            padding: '1px',
            opacity: 1,
            color: theme.palette.text.disabled
          }
        },
        multiline: {
          padding: '0!important'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500],
          '&:hover': {
            backgroundColor: theme.palette.grey[500]
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
          },
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "&[role='radiogroup']": {
            marginTop: theme.spacing(1),
          },
          "&.FormGroupRadio": {
            width: '100%',
          },
          "&.FormGroupRadio .MuiFormControlLabel-root": {
            flex: '1',
            display: 'block',
          },
          "&.FormGroupRadio .MuiFormControlLabel-root .MuiCheckbox-root, &.FormGroupRadio3cols .MuiFormControlLabel-root .MuiRadio-root,": {
            marginTop: '-2px'
          }
        }
      }
    }, 
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
          }
        }
      }
    }
  };
};

export default input;
