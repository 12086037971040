import { plainToClass } from 'class-transformer';

import CopyFileService from '../files/copy-file.service';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import SaveFileDto from '../../dto/files/in/save-file.dto';

const copyFileService = new CopyFileService();

class CopyDetailedSheetFilesService {
  async copy(
    file: NonNullable<DetailedSheetDto['logo'] | DetailedSheetDto['image']>,
  ): Promise<SaveFileDto> {
    const dto = await copyFileService.copy(file.id ?? '');

    return plainToClass(SaveFileDto, {
      id: dto.id,
      key: dto.key,
      name: dto.name,
      extension: dto.extension,
      contentType: dto.contentType,
      size: dto.size,
      originalName: dto.originalName,
    });
  }
}

export default CopyDetailedSheetFilesService;
