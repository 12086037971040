import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class SetProfileTermsAndConditionsService {
  async set(): Promise<void> {
    try {
      await apiV1.post<void>('/profiles/terms-and-conditions');
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default SetProfileTermsAndConditionsService;
