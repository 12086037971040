import Chip, { ChipProps } from '@mui/material/Chip';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { FC, useMemo } from 'react';

import ProfileDto from '../../dto/profiles/out/profile.dto';
import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import { formatDateAndTime } from '../../utils/date';

const { BOOKED, CANCELED, INCOMPLETE, PROCESSING } = ProfileStatusesEnum;

interface Props {
  profile: ProfilesDto | ProfileDto;
  chipSx?: SxProps<Theme>;
}

const ProfileStatusChip: FC<Props> = ({ chipSx = {}, profile }) => {
  const { notifiedAt, status } = profile;

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case BOOKED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  const tooltipProps = useMemo((): Pick<TooltipProps, 'title'> => {
    switch (status) {
      case CANCELED: {
        return { title: 'Annulé' };
      }
      case INCOMPLETE: {
        const title = ['Incomplet'];

        if (notifiedAt) {
          title.push(`(Notifié le ${formatDateAndTime(notifiedAt)})`);
        }

        return {
          title: (
            <>
              Incomplet
              {notifiedAt && (
                <>
                  <br />
                  Notifié le {formatDateAndTime(notifiedAt)}
                </>
              )}
            </>
          ),
        };
      }
      case PROCESSING: {
        return { title: 'En traitement' };
      }
      case BOOKED: {
        return { title: 'Réservé' };
      }
    }
  }, [notifiedAt, status]);

  return (
    <Tooltip {...tooltipProps} arrow>
      <Chip
        {...props}
        sx={{
          height: '16px',
          width: '16px',
          ...chipSx,
        }}
      />
    </Tooltip>
  );
};

export default ProfileStatusChip;
