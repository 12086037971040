import Chip, { ChipProps } from '@mui/material/Chip';
import { FC, useMemo } from 'react';

import AccessOrdersDto from '../../dto/access-orders/out/access-orders.dto';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';

const { CANCELED, CONFIRMED, INCOMPLETE, PROCESSING } = AccessOrderStatusesEnum;

interface Props {
  accessOrder: AccessOrdersDto;
}

const AccessOrdersStatusChip: FC<Props> = ({ accessOrder }) => {
  const { status } = accessOrder;

  const props = useMemo((): Pick<ChipProps, 'color'> => {
    switch (status) {
      case CANCELED: {
        return { color: 'default' };
      }
      case INCOMPLETE: {
        return { color: 'error' };
      }
      case PROCESSING: {
        return { color: 'warning' };
      }
      case CONFIRMED: {
        return { color: 'success' };
      }
    }
  }, [status]);

  return (
    <Chip
      {...props}
      sx={{
        height: '16px',
        width: '16px',
      }}
    />
  );
};

export default AccessOrdersStatusChip;
