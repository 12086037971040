import { UseFormReturn } from 'react-hook-form';

import MapExistingDetailedSheetImageToSaveFileDtoService from './map-existing-detailed-sheet-image-to-save-file-dto.service';
import MapNewDetailedSheetImageToSaveFileDtoService from './map-new-detailed-sheet-image-to-save-file-dto.service';
import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../dto/editions/out/edition.dto';
import ProfileDto from '../../../dto/profiles/out/profile.dto';
import DetailedSheetFieldValuesInterface from '../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import SaveFileDto from '../../../dto/files/in/save-file.dto';

type DetailedSheetFieldValuesKey = keyof DetailedSheetFieldValuesInterface;

const mapExistingDetailedSheetImageToSaveFileDtoService =
  new MapExistingDetailedSheetImageToSaveFileDtoService();

const mapNewDetailedSheetImageToSaveFileDtoService =
  new MapNewDetailedSheetImageToSaveFileDtoService();

class MapDetailedSheetImagesToSaveFileDto {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
    fieldValues: DetailedSheetFieldValuesInterface,
    formMethods: UseFormReturn<DetailedSheetFieldValuesInterface>,
  ): (SaveFileDto | null)[] {
    const { logo, image } = fieldValues;
    const { getFieldState } = formMethods;

    const dtos: (SaveFileDto | null)[] = [];

    for (const { file, fileFieldName } of [
      {
        file: logo,
        fileFieldName: 'logo' as DetailedSheetFieldValuesKey,
      },
      {
        file: image,
        fileFieldName: 'image' as DetailedSheetFieldValuesKey,
      },
    ]) {
      if (!file) {
        dtos.push(null);

        continue;
      }

      const isDirty = getFieldState(fileFieldName).isDirty;

      const detailedSheetImage =
        detailedSheet[fileFieldName as 'logo' | 'image'];

      if (isDirty) {
        const dto = mapNewDetailedSheetImageToSaveFileDtoService.map(
          edition,
          profile,
          detailedSheetImage,
          file,
          fileFieldName,
        );

        dtos.push(dto);

        continue;
      }

      const dto =
        mapExistingDetailedSheetImageToSaveFileDtoService.map(
          detailedSheetImage,
        );

      if (dto) dtos.push(dto);
    }

    return dtos;
  }
}

export default MapDetailedSheetImagesToSaveFileDto;
