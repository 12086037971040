import SalutationsEnum from '../../../enums/salutations.enum';

class SaveManagerDto {
  id?: string;
  salutation!: SalutationsEnum;
  firstName!: string;
  lastName!: string;
  email!: string;
  phoneNumber1!: string;
  phoneNumber2?: string;
  displayOrder!: string;
}

export default SaveManagerDto;
