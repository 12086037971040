import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import { apiV1 } from '../../http';
import { axiosHttpErrorToError } from '../../utils/errors';

class FindAssemblyByUserIdService {
  async find(id: string): Promise<AssemblyDto> {
    try {
      const { data } = await apiV1.get<AssemblyDto>(`/users/${id}/assembly`);

      return data;
    } catch (error) {
      throw axiosHttpErrorToError(error);
    }
  }
}

export default FindAssemblyByUserIdService;
