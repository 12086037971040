import Button from '@mui/material/Button';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import AdminSaveProfileForm from '../../../profiles/AdminSaveProfileForm';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import PaginatedResultDto from '../../../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import SaveManagerDto from '../../../../dto/managers/in/save-manager.dto';
import SaveProfileCategoriesDto from '../../../../dto/profile-categories/in/save-profile-categories.dto';
import UpdateProfileDto from '../../../../dto/profiles/in/update-profile.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import SectionDto from '../../../../dto/sections/section.dto';
import useFindProfile from '../../../../hooks/profiles/find-profile.hook';
import useUpdateProfile from '../../../../hooks/profiles/update-profile.hook';
import useFindSections from '../../../../hooks/sections/find-sections.hook';
import AdminProfileFieldValuesInterface from '../../../../interfaces/profiles/admin-profile-field-values.interface';
import { sortByDisplayOrder } from '../../../../utils/arrays';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
  sections: PaginatedResultDto<SectionDto>;
}

const AdminEditProfilePage: FC = () => {
  const { accessOrder, assembly, booking, detailedSheet, profile, sections } =
    useOutletContext<OutletContext>();

  const { refetch: refetchProfile } = useFindProfile(profile.id);

  const { refetch: refetchSection } = useFindSections({
    take: 1000,
  });

  const { mutateAsync: updateProfile } = useUpdateProfile();

  const orderedManagers = sortByDisplayOrder(profile.managers);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<AdminProfileFieldValuesInterface>({
    defaultValues: {
      email: profile.user.email,
      legalCompanyName: profile.legalCompanyName,
      commonCompanyName: profile.commonCompanyName ?? '',
      address: profile.address,
      city: profile.city,
      province: profile.province,
      postalCode: profile.postalCode,
      isApchqMember: `${!!profile.apchqMemberNumber}`,
      apchqMemberNumber: profile.apchqMemberNumber ?? '',
      isRbqMember: `${!!profile.rbqMemberNumber}`,
      rbqMemberNumber: profile.rbqMemberNumber ?? '',
      hasMoreThanOneManager: `${!!profile.managers[1]}`,
      manager1Salutation: orderedManagers[0]?.salutation ?? '',
      manager1FirstName: orderedManagers[0]?.firstName ?? '',
      manager1LastName: orderedManagers[0]?.lastName ?? '',
      manager1Email: orderedManagers[0]?.email ?? '',
      manager1PhoneNumber1: orderedManagers[0]?.phoneNumber1 ?? '',
      manager1PhoneNumber1Ext: orderedManagers[0]?.phoneNumber1Ext ?? '',
      manager1PhoneNumber2: orderedManagers[0]?.phoneNumber2 ?? '',
      manager1PhoneNumber2Ext: orderedManagers[0]?.phoneNumber2Ext ?? '',
      manager2Salutation: orderedManagers[1]?.salutation ?? '',
      manager2FirstName: orderedManagers[1]?.firstName ?? '',
      manager2LastName: orderedManagers[1]?.lastName ?? '',
      manager2Email: orderedManagers[1]?.email ?? '',
      manager2PhoneNumber1: orderedManagers[1]?.phoneNumber1 ?? '',
      manager2PhoneNumber1Ext: orderedManagers[1]?.phoneNumber1Ext ?? '',
      manager2PhoneNumber2: orderedManagers[1]?.phoneNumber2 ?? '',
      manager2PhoneNumber2Ext: orderedManagers[1]?.phoneNumber2Ext ?? '',
      sectionId: profile.section?.id,
      categories: profile.profileCategories.map(
        (profileCategory) => profileCategory.category.id,
      ),
    },
  });

  const onSubmit: SubmitHandler<AdminProfileFieldValuesInterface> = useCallback(
    async (fieldValues) => {
      const managers = [
        plainToClass(SaveManagerDto, {
          id: profile.managers[0]?.id,
          salutation: fieldValues.manager1Salutation,
          firstName: fieldValues.manager1FirstName,
          lastName: fieldValues.manager1LastName,
          email: fieldValues.manager1Email,
          phoneNumber1: fieldValues.manager1PhoneNumber1,
          phoneNumber1Ext: fieldValues.manager1PhoneNumber1Ext,
          phoneNumber2: fieldValues.manager1PhoneNumber2,
          phoneNumber2Ext: fieldValues.manager1PhoneNumber2Ext,
          displayOrder: '1',
        }),
      ];

      if (fieldValues.hasMoreThanOneManager === 'true') {
        managers.push(
          plainToClass(SaveManagerDto, {
            id: profile.managers[1]?.id,
            salutation: fieldValues.manager2Salutation,
            firstName: fieldValues.manager2FirstName,
            lastName: fieldValues.manager2LastName,
            email: fieldValues.manager2Email,
            phoneNumber1: fieldValues.manager2PhoneNumber1,
            phoneNumber1Ext: fieldValues.manager2PhoneNumber1Ext,
            phoneNumber2: fieldValues.manager2PhoneNumber2,
            phoneNumber2Ext: fieldValues.manager2PhoneNumber2Ext,
            displayOrder: '2',
          }),
        );
      }

      try {
        const dto = plainToClass(UpdateProfileDto, {
          email: fieldValues.email,
          legalCompanyName: fieldValues.legalCompanyName,
          commonCompanyName: fieldValues.commonCompanyName,
          address: fieldValues.address,
          city: fieldValues.city,
          province: fieldValues.province,
          postalCode: fieldValues.postalCode,
          apchqMemberNumber: fieldValues.apchqMemberNumber,
          rbqMemberNumber: fieldValues.rbqMemberNumber,
          sectionId: fieldValues.sectionId,
          profileCategories: fieldValues.categories.map((category) =>
            plainToClass(SaveProfileCategoriesDto, {
              category,
            }),
          ),
          managers,
        });

        await updateProfile({ id: profile.id, dto });

        await Promise.all([refetchProfile(), refetchSection()]);

        toast.success('Enregistré avec succès');
      } catch (error) {
        toast.error();
      }
    },
    [profile, refetchProfile, refetchSection, updateProfile],
  );

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="details" />

      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <AdminSaveProfileForm
          control={control}
          profile={profile}
          sections={sections}
          setValue={setValue}
        />

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer l&apos;exposant
        </Button>
      </form>
    </>
  );
};

export default AdminEditProfilePage;
