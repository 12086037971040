import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import bytes from 'bytes';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import DetailedSheetCopiedImageForm from './DetailedSheetCopiedImageForm';
import Dropzone from '../forms/Dropzone';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { getFileKey } from '../../utils/files';
import { validateRequired } from '../../utils/validations';

const ACCEPT = { 'image/gif': [], 'image/jpeg': [], 'image/png': [] };
const MIN_IMAGE_SIZE = bytes('0Mb');
const MAX_IMAGE_SIZE = bytes('2Mb');

interface Props {
  detailedSheet: DetailedSheetDto;
  mostRecentDetailedSheet: DetailedSheetDto | null;
}

const DetailedSheetImagesForm: FC<Props> = ({
  detailedSheet,
  mostRecentDetailedSheet,
}) => {
  const { control, watch } =
    useFormContext<DetailedSheetFieldValuesInterface>();

  const copyPreviousLogo = watch('copyPreviousLogo') === 'true';
  const copyPreviousImage = watch('copyPreviousImage') === 'true';

  return (
    <FormStack>
      <FormGrid>
        <Grid item>
          {mostRecentDetailedSheet?.logo && (
            <DetailedSheetCopiedImageForm
              name="copyPreviousLogo"
              label="Logo"
              radioButtonsLabel="Souhaitez-vous le réutiliser ?"
              description="Voici le logo que vous avez utilisé lors de la dernière édition."
              image={mostRecentDetailedSheet.logo}
            />
          )}

          {!copyPreviousLogo && (
            <Box sx={{ mt: 2 }}>
              <Dropzone
                control={control}
                label={!mostRecentDetailedSheet?.logo ? 'Logo' : undefined}
                name="logo"
                description="Déposez le nouveau logo de votre compagnie sur fond blanc ou transparent. Le fichier ne doit pas excéder 2mo et doit être au format jpg, png ou gif."
                preview={
                  detailedSheet.logo
                    ? `${process.env.REACT_APP_ASSETS_URL}/${getFileKey(
                        detailedSheet.logo,
                      )}`
                    : undefined
                }
                rules={validateRequired(!copyPreviousLogo)}
                options={{
                  accept: ACCEPT,
                  minSize: MIN_IMAGE_SIZE,
                  maxSize: MAX_IMAGE_SIZE,
                  multiple: false,
                }}
              />
            </Box>
          )}

          {mostRecentDetailedSheet?.image && (
            <Box sx={{ mt: 2 }}>
              <DetailedSheetCopiedImageForm
                name="copyPreviousImage"
                label="Image (facultatif)"
                radioButtonsLabel="Souhaitez-vous la réutiliser ?"
                description="Voici l'image que vous avez utilisée lors de la dernière édition."
                image={mostRecentDetailedSheet.image}
              />
            </Box>
          )}

          {!copyPreviousImage && (
            <Box sx={{ mt: 2 }}>
              <Dropzone
                control={control}
                label={!mostRecentDetailedSheet?.image ? 'Image' : undefined}
                name="image"
                description="Déposez une nouvelle image supplémentaire que nous pourrons afficher sur le site Web et qui représente l'activité de votre compagnie. Elle ne doit pas excéder 2mo et doit être au format jpg, png ou gif."
                preview={
                  detailedSheet.image
                    ? `${process.env.REACT_APP_ASSETS_URL}/${getFileKey(
                        detailedSheet.image,
                      )}`
                    : undefined
                }
                options={{
                  accept: ACCEPT,
                  minSize: MIN_IMAGE_SIZE,
                  maxSize: MAX_IMAGE_SIZE,
                  multiple: false,
                }}
              />
            </Box>
          )}
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default DetailedSheetImagesForm;
