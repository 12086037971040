import { useQuery, UseQueryResult } from 'react-query';
import DataTableOptionsDto from '../../dto/data-tables/data-table-options.dto';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import SectionDto from '../../dto/sections/section.dto';
import FindSectionsService from '../../services/sections/find-sections.service';

const useFindSections = (
  dto: DataTableOptionsDto = {},
): UseQueryResult<PaginatedResultDto<SectionDto>> => {
  return useQuery<unknown, unknown, PaginatedResultDto<SectionDto>>(
    ['sections', dto],
    () => new FindSectionsService().find(dto),
  );
};

export default useFindSections;
