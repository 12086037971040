import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetsDataTableOptionsDto from '../../dto/detailed-sheets/in/detailed-sheets-data-table-options.dto';
import DetailedSheetsDto from '../../dto/detailed-sheets/out/detailed-sheets.dto';
import { apiV1 } from '../../http';

class FindDetailedSheetsService {
  async find(
    dto: DetailedSheetsDataTableOptionsDto,
  ): Promise<PaginatedResultDto<DetailedSheetsDto>> {
    const { data } = await apiV1.get<PaginatedResultDto<DetailedSheetsDto>>(
      '/detailed-sheets',
      { params: dto },
    );

    return data;
  }
}

export default FindDetailedSheetsService;
