import StagesEnum from '../../../enums/editions/stages.enum';
import AccessOrderStatusesEnum from '../../../enums/access-orders/access-order-statuses.enum';
import AssemblyStatusesEnum from '../../../enums/assemblies/assembly-statuses.enum';
import DetailedSheetStatusesEnum from '../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import PaymentStatusesEnum from '../../../enums/profiles/payment-statuses.enum';
import ProfileStatusesEnum from '../../../enums/profiles/profile-statuses.enum';

export class FindNotificationRecipientsProfilesDto {
  stage!: StagesEnum.REGISTRATIONS;
  statuses!: ProfileStatusesEnum[];
  paymentStatus!: PaymentStatusesEnum;
}

export class FindNotificationRecipientsDetailedSheetsDto {
  stage!: StagesEnum.DETAILED_SHEETS;
  statuses!: DetailedSheetStatusesEnum[];
}

export class FindNotificationRecipientsAssembliesDto {
  stage!: StagesEnum.ASSEMBLIES;
  statuses!: AssemblyStatusesEnum[];
}

export class FindNotificationAccessOrdersProfilesDto {
  stage!: StagesEnum.ACCESS_ORDERS;
  statuses!: AccessOrderStatusesEnum[];
  paymentStatus!: PaymentStatusesEnum;
}

type FindNotificationRecipientsDto =
  | FindNotificationRecipientsProfilesDto
  | FindNotificationRecipientsDetailedSheetsDto
  | FindNotificationRecipientsAssembliesDto
  | FindNotificationAccessOrdersProfilesDto;

export default FindNotificationRecipientsDto;
