import { Components, Theme } from '@mui/material';

const appBar = (_theme: Theme): Components<Theme> => {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
            backdropFilter: 'blur(6px)',
            backgroundColor: '#f9fafb6b',
            boxShadow: 'none',
        },
      },
    },
  };
};

export default appBar;
