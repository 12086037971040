import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import alert from './alert';
import appBar from './app-bar';
import autocomplete from './autocomplete';
import button from './button';
import cssBaseline from './css-baseline';
import formControlLabel from './formControlLabel';
import formHelperText from './formHelperText';
import input from './input';
import inputlabel from './inputLabel';
import modal from './modal';
import select from './select';
import tabs from './tabs';
import table from './table';
import textField from './textfield';
import paper from './paper';

export const overrideComponents = (theme: Theme): Components<Theme> => {
  return {
    ...alert(theme),
    ...appBar(theme),
    ...autocomplete(theme),
    ...button(theme),
    ...cssBaseline(theme),
    ...formControlLabel(theme),
    ...formHelperText(theme),
    ...paper(theme),
    ...input(theme),
    ...inputlabel(theme),
    ...modal(theme),
    ...select(theme),
    ...tabs(theme),
    ...table(theme),
    ...textField(theme),
  };
};
