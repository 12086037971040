import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import Link from '../navigation/Link';
import { PATHS } from '../../utils/paths';

interface Props {
  showBackToDashboard?: boolean;
}

const ProfileCancelledAlert: FC<Props> = ({ showBackToDashboard = true }) => {
  return (
    <Alert severity="error" sx={{ mt: 4 }}>
      <AlertTitle>Inscription annulée</AlertTitle>
      Votre inscription a été annulée. Pour davantage d&apos;informations,
      envoyez un courriel à l&apos;adresse{' '}
      <MuiLink href="mailto:jlegare@apchqquebec.ca" underline="none">
        jlegare@apchqquebec.ca
      </MuiLink>
      .
      {showBackToDashboard && (
        <Typography variant="body2" sx={{ mt: 1 }}>
          <Link to={PATHS.exhibitors.path}>Revenir à l&apos;accueil</Link>
        </Typography>
      )}
    </Alert>
  );
};

export default ProfileCancelledAlert;
