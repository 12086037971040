import { useMutation, UseMutationResult } from 'react-query';

import SaveIncludedAccreditationQuantityDto from '../../dto/access-orders/in/save-included-accreditation-quantity.dto';
import SaveIncludedAccreditationService from '../../services/access-orders/save-included-accreditation-quantity.service';

const useSaveIncludedAccreditationQuantity = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveIncludedAccreditationQuantityDto }
> => {
  return useMutation((variables) =>
    new SaveIncludedAccreditationService().save(variables.id, variables.dto),
  );
};

export default useSaveIncludedAccreditationQuantity;
