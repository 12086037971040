import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import ProfilesDataTableOptionsDto from '../../dto/profiles/in/profiles-data-table-options.dto';
import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import FindProfilesService from '../../services/profiles/find-profiles.service';

const useFindProfiles = (
  dto: ProfilesDataTableOptionsDto = {},
  options?: Omit<
    UseQueryOptions<PaginatedResultDto<ProfilesDto>, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<PaginatedResultDto<ProfilesDto>, Error> => {
  return useQuery(
    ['profiles', dto],
    () => new FindProfilesService().find(dto),
    options,
  );
};

export default useFindProfiles;
