import EditionDto from '../../dto/editions/out/edition.dto';
import StagesEnum from '../../enums/editions/stages.enum';

class GetStageOpenedService {
  get(edition: EditionDto, stage: StagesEnum): boolean {
    switch (stage) {
      case StagesEnum.REGISTRATIONS: {
        return edition.registrationsOpened;
      }
      case StagesEnum.DETAILED_SHEETS: {
        return edition.detailedSheetsOpened;
      }
      case StagesEnum.ASSEMBLIES: {
        return edition.assembliesOpened;
      }
      case StagesEnum.ACCESS_ORDERS: {
        return edition.accessOrdersOpened;
      }
    }
  }
}

export default GetStageOpenedService;
