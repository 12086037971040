import StagesEnum from '../../enums/editions/stages.enum';

class GetStageLabelService {
  get(stage: StagesEnum): string {
    switch (stage) {
      case StagesEnum.REGISTRATIONS: {
        return 'Inscriptions';
      }
      case StagesEnum.DETAILED_SHEETS: {
        return 'Fiches exposant';
      }
      case StagesEnum.ASSEMBLIES: {
        return 'Montage et démontage';
      }
      case StagesEnum.ACCESS_ORDERS: {
        return "Commandes d'accès au salon";
      }
    }
  }
}

export default GetStageLabelService;
