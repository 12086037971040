import { useMutation, UseMutationResult } from 'react-query';

import UnarchiveProfileService from '../../services/profiles/unarchive-profile.service';

const useUnarchiveProfile = (): UseMutationResult<
  void,
  Error,
  { id: string }
> => {
  return useMutation((variables) =>
    new UnarchiveProfileService().unarchive(variables.id),
  );
};

export default useUnarchiveProfile;
