enum PaymentStatusesEnum {
  INITIAL = 'INITIAL',
  INVOICE_PENDING = 'INVOICE_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAID_PARTIALLY = 'PAID_PARTIALLY',
  PAID = 'PAID',
  PARTNER = 'PARTNER',
}

export default PaymentStatusesEnum;
