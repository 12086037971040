import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, MouseEventHandler, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import ButtonLoadingIcon from '../../forms/ButtonLoadingIcon';
import TextField from '../../forms/TextField';
import SignInDto from '../../../dto/auth/sign-in.dto';
import ErrorTypesEnum from '../../../enums/error-types.enum';
import useSignIn from '../../../hooks/auth/sign-in.hook';
import { authState } from '../../../http/auth.state';
import SignInFieldValues from '../../../interfaces/auth/sign-in-field-values.interface';
import LocationStateInterface from '../../../interfaces/navigation/location-state.interface';
import { PATHS } from '../../../utils/paths';
import { toast } from '../../../utils/toast';
import {
  onInvalidSubmit,
  validateEmailFormat,
  validateRequired,
} from '../../../utils/validations';

const { NOT_FOUND_ERROR, WRONG_PASSWORD_ERROR } = ErrorTypesEnum;

const SignInPage: FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: signIn } = useSignIn();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInFieldValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<SignInFieldValues> = useCallback(
    async ({ email, password }: SignInFieldValues) => {
      try {
        const dto = plainToClass(SignInDto, {
          email: email.trim(),
          password: password.trim(),
        });

        const { token } = await signIn(dto);

        authState.setToken(token);

        const url = (state as LocationStateInterface)?.url ?? undefined;

        navigate(url ?? '/', { replace: true });
      } catch (error) {
        const { message } = error as Error;

        switch (message) {
          case NOT_FOUND_ERROR: {
            toast.error('Aucun utilisateur avec ce courriel');

            break;
          }
          case WRONG_PASSWORD_ERROR: {
            toast.error('Mauvais mot de passe');

            break;
          }
          default: {
            toast.error();

            break;
          }
        }
      }
    },
    [navigate, signIn, state],
  );

  const navigateToForgottenPassword: MouseEventHandler<HTMLSpanElement> =
    useCallback(
      (event) => {
        event.preventDefault();

        navigate(PATHS.auth.forgottenPassword.path);
      },
      [navigate],
    );

  const navigateToSignUp: MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      event.preventDefault();

      navigate(PATHS.auth.signUp.path);
    },
    [navigate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <Stack spacing={4} sx={{ alignItems: 'flex-start' }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          Connexion
        </Typography>

        <TextField
          control={control}
          fullWidth
          label="Courriel"
          name="email"
          rules={{
            ...validateRequired(),
            ...validateEmailFormat(),
          }}
          type="email"
        />

        <TextField
          control={control}
          fullWidth
          label="Mot de passe"
          name="password"
          rules={{
            ...validateRequired(),
          }}
          type="password"
        />

        <Typography sx={{ mt: '10px!important' }}>
          <Link href="#" onClick={navigateToForgottenPassword}>
            Mot de passe oublié ?
          </Link>
        </Typography>

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            sx={{ mx: 'auto' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Se connecter
          </Button>

          <Typography sx={{ mt: 4 }}>
            <Link href="#" onClick={navigateToSignUp}>
              Vous n&apos;avez pas de compte ? Inscrivez-vous.
            </Link>
          </Typography>
        </Box>
      </Stack>
    </form>
  );
};

export default SignInPage;
