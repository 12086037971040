import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindUserAssembly from '../../../hooks/assemblies/find-user-assembly.hook';
import useFindUserProfile from '../../../hooks/profiles/find-user-profile.hook';

const EditAssemblyContainer: FC = () => {
  const { data: profile, status: profileStatus } = useFindUserProfile();
  const { data: assembly, status: assemblyStatus } = useFindUserAssembly();

  if (
    profileStatus === 'loading' ||
    profileStatus === 'idle' ||
    assemblyStatus === 'loading' ||
    assemblyStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (profileStatus === 'error' || assemblyStatus === 'error') {
    return <Error />;
  }

  return <Outlet context={{ assembly, profile }} />;
};

export default EditAssemblyContainer;
