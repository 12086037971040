import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import TextField from '../forms/TextField';
import AdminAccessOrderFieldValues from '../../interfaces/access-orders/admin-access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

const AdminAccessOrderParkingsForm: FC = () => {
  const { control } = useFormContext<AdminAccessOrderFieldValues>();

  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <RadioButtons
            control={control}
            label="Stationnement VIP ?"
            name="hasVipParking"
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            rules={validateRequired()}
            row
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            label="Quantité stationnement standard"
            name="standardParkingQuantity"
            rules={validateRequired()}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAccessOrderParkingsForm;
