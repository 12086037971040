import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';

import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import LocationsChips from '../locations/LocationsChips';
import UserInfoDto from '../../dto/users/user-info.dto';
import { PATHS } from '../../utils/paths';

interface Props {
  booking?: UserInfoDto['booking'];
  profile: WithRequiredKeys<UserInfoDto, 'profile'>['profile'];
}

const BookingSection: FC<Props> = ({ booking, profile }) => {
  const locations = useMemo(() => {
    const { bookingLocations = [] } = booking ?? {};

    return (bookingLocations ?? []).map(
      (bookingLocation) => bookingLocation.location,
    );
  }, [booking]);

  const renderProfileCategories = useMemo(() => {
    const { profileCategories = [] } = profile ?? {};

    return profileCategories.map(({ category }, index) => (
      <Typography key={index}>{category.name}</Typography>
    ));
  }, [profile]);

  return (
    <Section>
      <Typography variant="overline" component="h2">
        Exposition
      </Typography>

      <Grid container rowSpacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <ReadonlyFieldLabel>Section</ReadonlyFieldLabel>
          <Typography>{profile.section.name}</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <ReadonlyFieldLabel>Catégorie(s)</ReadonlyFieldLabel>
          {renderProfileCategories}
        </Grid>

        {booking && (
          <>
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>
                Emplacement(s) correspondant(s) (
                <Link href={PATHS.documents.plan.path} target="_blank">
                  voir le plan
                </Link>
                )
              </ReadonlyFieldLabel>

              <LocationsChips locations={locations} />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>
                Superficie (en pieds carrés)
              </ReadonlyFieldLabel>

              <Typography>{booking.area}</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Emplacement officiel</ReadonlyFieldLabel>
              <Typography>{booking.mainLocation}</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Montant</ReadonlyFieldLabel>
              <Typography>{booking.price}$</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  );
};

export default BookingSection;
