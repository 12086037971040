import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import SectionHeader from '../../../layouts/SectionHeader';
import LocationsDataTable from '../../../locations/LocationsDataTable';
import { DataTableProvider } from '../../../../providers/data-tables/DataTableProvider';
import { PATHS } from '../../../../utils/paths';

const LocationsListPage: FC = () => {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        navigate(PATHS.admin.locations.new.path);
      }
    },
    [navigate],
  );

  return (
    <>
      <SectionHeader>
        <Typography variant="h1">Emplacements</Typography>

        <Button
          href={PATHS.admin.locations.new.path}
          onClick={handleClick}
          variant="contained"
          sx={{
            mt: { xs: 2, md: 0 },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          Ajouter un emplacement
        </Button>
      </SectionHeader>

      <DataTableProvider dataTableOptions={{ sortColumn: 'name' }}>
        <LocationsDataTable />
      </DataTableProvider>
    </>
  );
};

export default LocationsListPage;
