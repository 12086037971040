import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import TextField from '../../../forms/TextField';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import CreateUserActivationCodeDto from '../../../../dto/users/create-user-activation-code.dto';
import ErrorTypesEnum from '../../../../enums/error-types.enum';
import ProfileActivationCodeFieldValuesInterface from '../../../../interfaces/profiles/profile-activation-code-field-values.interface';
import useCreateUserActivationCode from '../../../../hooks/users/create-user-activation-code.hook';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import {
  onInvalidSubmit,
  validateRequired,
} from '../../../../utils/validations';
import UserStepsEnum from '../../../../enums/users/user-steps.enum';
import { queryClient } from '../../../../utils/query-client';

const { INACTIVE_ACTIVATION_CODE, NOT_FOUND_ERROR } = ErrorTypesEnum;

const {
  BOOKING_PREFERENCE_STEP,
  COMPLETED_STEP,
  PROFILE_STEP,
  TERMS_AND_CONDITIONS_STEP,
} = UserStepsEnum;

const CreateUserActivationCodePage: FC = () => {
  const navigate = useNavigate();
  const currentStep = useOutletContext<UserStepsEnum>();

  const { mutateAsync: createUserActivationCode, error } =
    useCreateUserActivationCode();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProfileActivationCodeFieldValuesInterface>({
    defaultValues: { activationCode: '' },
  });

  const onSubmit: SubmitHandler<ProfileActivationCodeFieldValuesInterface> =
    useCallback(
      async ({ activationCode }) => {
        try {
          const dto = plainToClass(CreateUserActivationCodeDto, {
            activationCode: activationCode.trim(),
          });

          await createUserActivationCode(dto);

          await queryClient.invalidateQueries('users/step');

          toast.success("Code d'activation validé avec succès");

          navigate(PATHS.exhibitors.profiles.create.path);
        } catch (error) {
          const { message } = error as Error;

          switch (message) {
            case NOT_FOUND_ERROR: {
              toast.error('Ce code est incorrect');

              break;
            }
            case INACTIVE_ACTIVATION_CODE: {
              toast.error('Ce code est inactif');

              break;
            }
            default: {
              toast.error();

              break;
            }
          }
        }
      },
      [createUserActivationCode, navigate],
    );

  const renderInactiveActivationCodeMessage = useMemo(() => {
    if (error?.message !== INACTIVE_ACTIVATION_CODE) return null;

    return (
      <Alert severity="warning" sx={{ mb: 4 }}>
        <AlertTitle>Code d&apos;accès valide mais inactif</AlertTitle>
        La location s&apos;effectue selon l&apos;ordre de priorité préalablement
        communiqué. Le code d&apos;accès associé à votre compte n&apos;est pas
        encore activé. Cliquez ici pour consulter{' '}
        <Link href={PATHS.documents.timetable.path} target="_blank">
          l&apos;échéancier des priorités.
        </Link>
      </Alert>
    );
  }, [error]);

  const renderActivationCodeNotFoundMessage = useMemo(() => {
    if (error?.message !== NOT_FOUND_ERROR) return null;

    return (
      <Alert severity="error" sx={{ mb: 4 }}>
        <AlertTitle>Code d&apos;accès incorrect</AlertTitle>
        Le code d&lsquo;accès que vous avez soumis est incorrect. Veuillez
        réessayer ou communiquer avec Jessica Légaré au 418&nbsp;682-3353 pour
        toutes questions.
      </Alert>
    );
  }, [error]);

  switch (currentStep) {
    case PROFILE_STEP: {
      return <Navigate to={PATHS.exhibitors.profiles.create.path} replace />;
    }
    case BOOKING_PREFERENCE_STEP: {
      return (
        <Navigate
          to={PATHS.exhibitors.bookingPreferences.create.path}
          replace
        />
      );
    }
    case TERMS_AND_CONDITIONS_STEP: {
      return <Navigate to={PATHS.exhibitors.termsAndConditions.path} replace />;
    }
    case COMPLETED_STEP: {
      return <Navigate to={PATHS.exhibitors.path} replace />;
    }
  }

  return (
    <Container maxWidth="sm">
      <SectionHeader>
        <Typography variant="h1">Code d&lsquo;accès</Typography>
      </SectionHeader>

      <Section>
        <Box
          sx={{
            pb: { xs: 0, sm: 4 },
            pt: { xs: 0, sm: 2 },
            px: { xs: 0, sm: 4 },
          }}
        >
          {renderActivationCodeNotFoundMessage}
          {renderInactiveActivationCodeMessage}

          <Typography component="p">
            Si vous étiez exposant lors de la dernière édition, un code d’accès
            vous sera envoyé par courriel au courant du mois de juin selon votre{' '}
            <Link
              href={PATHS.documents.timetable.path}
              target="_blank"
            >
              groupe de priorité de réservation.
            </Link>{' '}
            Si vous êtes un NOUVEL EXPOSANT, veuillez communiquer avec Jessica
            Légaré au{' '}
            <Link href="tel:4186823353" underline="none">
              418&nbsp;682-3353
            </Link>
            .
          </Typography>

          <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
            <TextField
              control={control}
              label="Code d'accès"
              name="activationCode"
              placeholder="ex: A1B2C3"
              rules={validateRequired()}
              sx={{ my: 4 }}
              fullWidth
            />

            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              startIcon={isSubmitting && <ButtonLoadingIcon />}
            >
              Valider le code
            </Button>
          </form>
        </Box>
      </Section>
    </Container>
  );
};

export default CreateUserActivationCodePage;
