import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import FindAccessOrderByUserIdService from '../../services/access-orders/find-access-order-by-user-id.service';

const useFindAccessOrderByUserId = (
  id: string,
  options?: Omit<
    UseQueryOptions<AccessOrderDto, Error>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<AccessOrderDto, Error> => {
  return useQuery(
    ['users/access-order', id],
    () => new FindAccessOrderByUserIdService().find(id),
    options,
  );
};

export default useFindAccessOrderByUserId;
