import { useMutation, UseMutationResult } from 'react-query';

import JwtTokenResponseDto from '../../dto/jwt/jwt-token-response.dto';
import RefreshTokenService from '../../services/auth/refresh-token.service';

const useRefreshToken = (): UseMutationResult<
  JwtTokenResponseDto,
  Error,
  void
> => {
  return useMutation(new RefreshTokenService().refresh);
};

export default useRefreshToken;
