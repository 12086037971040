import Icon from '@mui/material/Icon';
import { FC } from 'react';
import { FiLoader } from 'react-icons/fi';

const ButtonLoadingIcon: FC = () => {
  return (
    <Icon
      sx={{
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(0deg)',
          },
        },
      }}
    >
      <FiLoader />
    </Icon>
  );
};

export default ButtonLoadingIcon;
